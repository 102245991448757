import React from 'react';
import styled from 'styled-components';
import { ImageOverlayBackground } from '../common/ImageOverlay';
import Typography from '../common/Typography';

export interface PageHeadingProps extends React.HTMLAttributes<HTMLDivElement> {
  pageTitle?: string | React.ReactNode;
  pageSubtitle?: string | React.ReactNode;
  pageHeadingBackground?: string;
  backgroundFilter?: 'gradient' | 'solid';
  backgroundScale?: number;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  text-align: center;

  min-height: 150px;

  @media (min-width: 468px) {
    min-height: 200px;
    height: 200px;
  }
  @media (min-width: 980px) {
    height: 360px;
  }
`;

const Title = styled(Typography)`
  font-size: 32px;
  line-height: 36px;
  margin-top: 0;
  color: var(--mdc-theme-text-primary-on-dark) !important;
`;

const Subtitle = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 0;
  color: var(--mdc-theme-text-primary-on-dark) !important;
`;

const BackgroundImage = styled.div<{ src?: string; backgroundScale?: number }>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(2px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  transform: scale(1.2);
  ${(props) =>
    props.backgroundScale ? `transform: scale(${props.backgroundScale});` : ''}

  @media (max-width: 468px) {
    filter: blur(1px);
  }
`;

const StyledGradientBackground = styled(ImageOverlayBackground)`
  z-index: 1;
`;

const PageHeading: React.FC<PageHeadingProps> = ({
  pageTitle,
  pageSubtitle,
  pageHeadingBackground,
  backgroundScale,
  backgroundFilter = 'solid',
  ...props
}) => {
  return (
    <Container {...props}>
      <BackgroundImage
        src={pageHeadingBackground}
        backgroundScale={backgroundScale}
      />
      <StyledGradientBackground overlay={backgroundFilter}>
        {pageTitle && (
          <Title as="h1" fontSize="40px" letterSpacing="2px">
            {pageTitle}
          </Title>
        )}
        {pageSubtitle && <Subtitle as="h3">{pageSubtitle}</Subtitle>}
        {/* <img src={src} {...props} /> */}
      </StyledGradientBackground>
    </Container>
  );
};

export default PageHeading;
