import { IonButton, IonCol, IonItem, IonNote } from '@ionic/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import Form from '../../../components/common/Form';
import { uppercaseFirstLetter } from '../../../util/string';
import ReactCodeInput from 'react-code-input';
import styled from 'styled-components';
import { ButtonRow, CenteredRow } from '../common/styledComponents';
import Typography from '../../../components/common/Typography';
import { PhoneAuthCredential, PhoneAuthProvider } from 'firebase/auth';

interface PhoneSMSVerificationFormProps {
  verificationId: string;
  goBack: () => void;
  onSubmit: (credentials: {
    authCredential: PhoneAuthCredential;
    smsCode: string;
  }) => Promise<void>;
}

const Item = styled(IonItem)`
  padding: 0;
`;
const StyledReactCodeInput = styled(ReactCodeInput)`
  display: flex !important;
  width: 100%;

  input {
    width: auto;
    flex-basis: 16.667%;
    flex-shrink: 1;
    min-width: 0;
    margin: 0 4px;
    height: 42px;
    font-size: 32px;
    border: 1px solid lightgrey;
    border-radius: 3px;
    outline-color: var(--ion-color-primary);
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Message = styled.div`
  margin: 24px 0;
`;

const AutoSubmit: React.FC<{
  code?: string;
  isValid: boolean;
  submit: () => void;
}> = ({ code, isValid, submit }) => {
  React.useEffect(() => {
    if (code?.length === 6 && isValid) {
      console.log('submitting');
      submit();
    }
  }, [code, isValid, submit]);
  return null;
};
const ERROR_MESSAGE = {
  'auth/code-expired': 'Error: Verification code is expired.',
  'auth/invalid-verification-code': 'Error: Verification code is invalid.',
};

const PhoneSMSVerificationForm: React.FC<PhoneSMSVerificationFormProps> = ({
  verificationId,
  onSubmit,
  goBack,
}) => {
  return (
    <Formik
      initialValues={{ smsCode: '' }}
      validationSchema={Yup.object({
        smsCode: Yup.string()
          .matches(/^[0-9]{6}$/, 'Please enter all 6 digits')
          .required(),
      })}
      onSubmit={async (values, helpers) => {
        try {
          const authCredential = PhoneAuthProvider.credential(
            verificationId,
            values.smsCode
          );

          await onSubmit({ authCredential, smsCode: values.smsCode });
        } catch (e) {
          console.error(e);
          const message =
            ERROR_MESSAGE[(e as any)?.code] ||
            'Error: There was a problem verifying this code. Please try again.';
          helpers.setFieldError('smsCode', message);
        }
      }}
      validateOnMount
    >
      {({
        values,
        touched,
        errors,
        submitForm,
        setFieldValue,
        setFieldTouched,
        isValid,
        isSubmitting,
      }) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <Message>
            <Typography lineHeight="1.5em" fontSize="18px" textAlign="center">
              Enter the 6-digit verification code we sent to your number
            </Typography>
          </Message>

          <AutoSubmit
            code={values.smsCode}
            isValid={isValid}
            submit={submitForm}
          />

          <StyledReactCodeInput
            name="smsCode"
            inputMode="numeric"
            type="number"
            fields={6}
            value={values.smsCode}
            onChange={(value) => {
              console.log(value);
              if (!touched.smsCode) {
                setFieldTouched('smsCode', true);
              }
              setFieldValue('smsCode', value);
            }}
          />
          {touched.smsCode && errors.smsCode && (
            <IonNote slot="error">
              {uppercaseFirstLetter(errors.smsCode as string)}
            </IonNote>
          )}

          <ButtonRow>
            <IonCol>
              <IonButton
                id="phone-sign-in-btn"
                type="submit"
                disabled={!isValid || isSubmitting}
                expand="block"
              >
                Verify
              </IonButton>
            </IonCol>
          </ButtonRow>

          <CenteredRow>
            <IonButton expand="block" fill="clear" onClick={goBack}>
              Go Back
            </IonButton>
          </CenteredRow>
        </Form>
      )}
    </Formik>
  );
};

export default PhoneSMSVerificationForm;
