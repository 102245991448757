import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';

interface SplitPaneProps {
  list: React.ReactNode;
  details: React.ReactNode;
  fallback: React.ReactNode;
}

const Container = styled(IonContent)`
  display: flex;

  &::part(scroll) {
    display: flex;
  }
`;
const MenuPage = styled<{ $showDetails: boolean } & any>(IonPage)`
  overflow: hidden;
  position: relative;
  width: 100%;
  ${(props) => props.$showDetails && 'display: none;'}
  border-right: 1px solid var(--ion-border-color);
  @media (min-width: 768px) {
    width: 280px;
    display: block;
  }
  @media (min-width: 996px) {
    width: 420px;
  }
`;
const Details = styled(IonPage)`
  flex-grow: 1;
  position: relative;
  overflow: hidden;
`;

const Fallback = styled.div`
  flex-grow: 1;
  @media (max-width: 768px) {
    display: none;
  }
`;

const SplitPane: React.FC<SplitPaneProps> = ({ list, details, fallback }) => {
  return (
    <Container>
      <MenuPage $showDetails={!!details}>{list}</MenuPage>

      {details ? <Details>{details}</Details> : <Fallback>{fallback}</Fallback>}
    </Container>
  );
};

export default SplitPane;
