import { Database, ref, set, update } from 'firebase/database';
import * as O from 'fp-ts/lib/Option';

export function getStripeCustomerId(userId: string): Promise<O.Option<string>> {
  return null as any;
  // return firebase
  //   .database()
  //   .ref(`/stripeCustomers/${userId}`)
  //   .once('value')
  //   .then((snapshot) => {
  //     if (!snapshot.exists()) {
  //       return O.none;
  //     }

  //     return O.fromNullable(snapshot.val());
  //   })
  //   .catch((e) => {
  //     console.error(e);
  //     return O.none;
  //   });
}

export function setAppVersion({
  database,
  userId,
  device,
  version,
}: {
  database: Database;
  userId: string;
  device: 'ios' | 'android' | 'web';
  version: string;
}): Promise<void> {
  // const dataRef = ref(database, `users/${userId}/info/${device}/version`);
  // return set(dataRef, version);
  const userInfoRef = ref(database, `users/${userId}/info`);
  return update(userInfoRef, {
    [`${device}/version`]: version,
    lastLogin: new Date().toISOString(),
  });
}
