import { registerPlugin } from '@capacitor/core';
import PhoneAuthProvider from './phoneAuthProvider';

export interface FirebaseAuthPlugin {
  signInWithRedirect(options: { providerId: string }): Promise<{
    idToken: string;
    accessToken: string;
  }>;
}

const FirebaseAuth = registerPlugin<FirebaseAuthPlugin>('FirebaseAuth');

export { PhoneAuthProvider };
export default FirebaseAuth;
