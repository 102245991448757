import { createMachine, MachineConfig } from 'xstate';
import {} from 'xstate/lib/actions';
import creditCardStates from './creditCard';
import checkoutStates from './checkout';
import selectPaymentTypeStates, { PaymentType } from './selectPaymentType';
import { SubmitCaseContext, SubmitCaseEvent } from './types';
import * as guards from './guards';
import * as actions from './actions';
import { AllowedSpecies, Sex } from '../../services/buildfire/rdb/cases';

const config: MachineConfig<SubmitCaseContext, any, SubmitCaseEvent> = {
  id: 'Submit Case Form',
  initial: 'enterInfo',
  context: {
    paymentType: PaymentType.CREDIT_CARD,
    stripePublishableKey: '',
    creditCards: [],
    userId: '',
    email: '',
    name: '',
    caseInfo: {
      // submitterFirstName: 'Jeremy',
      // submitterLastName: 'Berglund',
      // patientFirstName: 'Test',
      // patientLastName: 'Cat',
      // age: 1,
      // sex: Sex.MALE,
      // species: AllowedSpecies.CAT,
      // fixed: true,
      // description: 'Some info here',
      // sites: [
      //   {
      //     location: 'Back',
      //     description: 'Test',
      //     images: ['test-image1.jpeg'],
      //   },
      // ],
    },
  } as any,
  on: {
    PARENT_ACCOUNT_CHANGE: {
      actions: 'updateParentAccount',
    },
  },
  states: {
    enterInfo: {
      on: {
        NEXT: [
          {
            target: 'selectPaymentType',
            cond: 'isCaseInfoValid',
            actions: 'setCaseInfo',
          },
        ],
      },
    },
    selectPaymentType: {
      ...(selectPaymentTypeStates as any),
      on: {
        NEXT: 'creditCard',
        BACK: 'enterInfo',
      },
      onDone: 'creditCard',
    },
    creditCard: {
      ...(creditCardStates as any),
      onDone: 'checkout',
      on: {
        BACK: [
          {
            target: 'enterInfo',
            cond: 'isInvoicingDisabled',
          },
          {
            target: 'selectPaymentType',
          },
        ],
      },
    },
    checkout: {
      ...(checkoutStates as any),
      onDone: 'submitted',
      on: {
        BACK: 'creditCard',
      },
    },
    error: {},
    submitted: {
      type: 'final',
    },
  },
};

const submitCaseMachine = createMachine(config, {
  actions: actions as any,
  guards: {
    ...guards,
    every: (ctx, event, { cond }) => {
      if (cond.type !== 'every') {
        throw new Error('Error: "every" condition expected type to be "every"');
      }
      return cond.guards.every((guardKey) => guards[guardKey](ctx, event));
    },
  },
});

export * from './types';

export default submitCaseMachine;
