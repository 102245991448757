import moment from 'moment';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import * as RDBCases from '../../services/buildfire/rdb/cases';
import Typography from '../common/Typography';
import StatusBadge from './StatusBadge';
import { formatCaseNumber } from '../../util/case';
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import Popover from '../common/Popover';
import { ellipsisVertical } from 'ionicons/icons';
import MobileToolbarButtons from '../common/MobileToolbarButtons';
import { getMode } from '@ionic/core';
import { useAdminAvatar } from '../../hooks/useAvatar';
import defaultAvatar from 'ionicons/dist/svg/person.svg';
import { useDialog } from '../../context/DialogContext';
import { getDatabase } from 'firebase/database';
import { useFirebase } from '../../services/firebase';
import CaseContext from '../../services/buildfire/rdb/CaseContext';
import { warning } from 'ionicons/icons';
import classNames from 'classnames';

interface CaseInfoHeaderProps {
  case: RDBCases.Case;
  isAdmin: boolean;
  actionItems?: React.ReactNode;
  showStatusBadge?: boolean;
}

const Header = styled(IonHeader)`
  // padding: 8px 0;
`;

const PlaceholderAvatar = styled.img`
  opacity: 0.2;
  background-color: var(--ion-border-color);

  &:hover: {
    opacity: 0.5;
  }
`;

const PlaceholderIcon: React.FC<{}> = (props) => {
  const name = 'placeholder';
  return (
    <svg className={`icon icon-${name}`} width="42px" height="42px" {...props}>
      <use xlinkHref={`${defaultAvatar}`} />
    </svg>
  );
};

const AssignmentAvatar: React.FC<{
  onClick: () => void;
  uid?: string;
}> = ({ uid, onClick }) => {
  const url = useAdminAvatar(uid);
  const ImgComponent = uid ? 'img' : PlaceholderAvatar;

  return (
    <IonAvatar
      slot="end"
      style={{
        margin: '0 20px',
        width: '42px',
        height: '42px',
        cursor: 'pointer',
      }}
    >
      <ImgComponent src={url} onClick={onClick} />
    </IonAvatar>
  );
};

const CaseInfoHeader: React.FC<CaseInfoHeaderProps> = ({
  case: c,
  isAdmin,
  actionItems,
  showStatusBadge = true,
  ...props
}) => {
  const mode = getMode();
  const HeaderComponent = mode === 'ios' ? IonHeader : Header;
  const { openDialogPromise } = useDialog();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { app, userMetadata, currentUser } = useFirebase();
  const { updateCase } = useContext(CaseContext);
  const [present] = useIonToast();
  const isOpen = c.status === RDBCases.CaseStatus.OPEN;

  const assignCaseToSelf = async () => {
    try {
      const db = getDatabase(app);
      const acceptedBy: RDBCases.Case['acceptedBy'] = {
        uid: currentUser!.uid,
        displayName: currentUser!.displayName!,
        signature: userMetadata?.signature || '',
        photoUrl: currentUser!.photoURL || '',
      };
      await RDBCases.acceptCase(db, c.id, acceptedBy);
      updateCase({ ...c, acceptedBy });
      present('Case assigned to you', 3000);
    } catch (e) {
      present({
        message: 'Failed to claim case, please try again',
        icon: warning,
        color: 'danger',
        duration: 6000,
      });
    }
  };

  const unassignCase = async () => {
    try {
      const db = getDatabase(app);
      await RDBCases.unclaimCase(db, c.id);
      updateCase({ ...c, acceptedBy: null as any });
      present('Case unassigned', 3000);
    } catch (e) {
      present({
        message: 'Failed to unassign case, please try again',
        icon: warning,
        color: 'danger',
        duration: 6000,
      });
    }
  };

  const handleAssign = async () => {
    if (c.status !== RDBCases.CaseStatus.OPEN) {
      return;
    }

    const isClaimed = !!c.acceptedBy;
    const isClaimedBySelf = isClaimed && c.acceptedBy?.uid === currentUser!.uid;

    if (isClaimedBySelf) {
      await unassignCase();
    } else if (isClaimed) {
      openDialogPromise(
        `Are you sure you want to unassign this case from ${
          c.acceptedBy!.displayName
        } and assign it to yourself?`
      )
        .then(assignCaseToSelf)
        .catch(() => console.log('Cancelled'));
    } else {
      await assignCaseToSelf();
    }
  };
  return (
    <HeaderComponent
      style={{ zIndex: 99 }}
      className={classNames({
        ['ion-no-border']: isOpen,
      })}
    >
      <IonToolbar>
        <MobileToolbarButtons slot="start">
          <IonBackButton defaultHref={'/cases'} />
        </MobileToolbarButtons>
        <IonTitle>
          <Typography
            as="h3"
            variant="secondary"
            fontSize="20px"
            fontWeight="400"
            lineHeight="1em"
            margin="0"
          >
            Case {formatCaseNumber(c.caseNumber!)}
          </Typography>
          <Typography
            opacity={0.9}
            fontSize="14px"
            fontWeight="400"
            lineHeight="20px"
          >
            {moment(c.createdAt).format('MMM DD')}
          </Typography>
        </IonTitle>
        {/* <IonTitle></IonTitle> */}
        {/* <TitleContaner> */}
        <IonButtons slot="end">
          {showStatusBadge && (
            <StatusBadge status={c.status} isAdmin={isAdmin} />
          )}
          {isAdmin && (
            <AssignmentAvatar onClick={handleAssign} uid={c.acceptedBy?.uid} />
          )}
          {actionItems && (
            <IonButton
              id="case-info-header_action-items"
              onClick={() => setIsPopoverOpen(true)}
            >
              <IonIcon icon={ellipsisVertical} slot="icon-only" />
            </IonButton>
          )}
          {/* <Status
            status={c.status}
            // $margin={actionItems ? '0 0 8px 0' : ''}
            $margin=""
          >
            {getStatusText(c.status, isAdmin)}
          </Status> */}
        </IonButtons>
        {/* </TitleContaner> */}
      </IonToolbar>
      {actionItems && (
        <Popover trigger="case-info-header_action-items" isOpen={isPopoverOpen}>
          <IonContent onClick={() => setIsPopoverOpen(false)}>
            <IonList lines="none">{actionItems}</IonList>
          </IonContent>
        </Popover>
      )}
    </HeaderComponent>
  );
};

export default CaseInfoHeader;
