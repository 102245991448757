import React from 'react';
import styled from 'styled-components';
import { TabContext } from './TabList';

const Container = styled.div<{ $display: string }>`
  display: ${(props) => props.$display};
`;

interface TabContentProps extends React.HTMLAttributes<HTMLDivElement> {
  defaultDisplay?: React.CSSProperties['display'];
}

const TabContent: React.FC<TabContentProps> = ({
  children,
  defaultDisplay = 'block',
  ...props
}) => {
  const [tabIndex, setTabIndex] = React.useState(-1);
  const { activeTab, registerTab } = React.useContext(TabContext);

  React.useEffect(() => {
    const tabIndex = registerTab();
    setTabIndex(tabIndex);
  }, []);

  return (
    <Container
      $display={activeTab === tabIndex ? defaultDisplay : 'none'}
      {...props}
    >
      {children}
    </Container>
  );
};

export default TabContent;
