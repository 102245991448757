import React from 'react';
import styled from 'styled-components';
import Checkbox from '../../mdc/Checkbox';
import List from '../../mdc/list/List';
import Alert from '../common/Alert';
import ExpandingButton from '../common/ExpandingButton';
import ModalLoader from '../common/ModalLoader';
import Typography from '../common/Typography';
import Subtotal from '../submitCase/components/Subtotal';
import TermsAndConditionsModal from '../submitCase/components/TermsAndConditionsModal';
import { FormValues } from './CaseForm';
import PatientInfo from './PatientInfo';
import SiteListItem from './site/SiteListItem';
import SubmitterInfo from './SubmitterInfo';
import { ParentUser } from '../../api/cloudFunctions';

interface CaseCheckoutProps {
  caseInfo: FormValues;
  caseUserId: string;
  clinicName: string;
  clinicEmail: string;
  submitText?: string;
  goBackText?: string;
  onSubmit: () => void;
  onBack: () => void;
  isLoading: boolean;
  progress?: {
    percent: number;
    message: string;
  };
  invoicingEnabled: boolean;
  parentAccount?: ParentUser;
  selectedInvoiceAccountUid: string;
}

const Padding = styled.div`
  padding: 0 8px 32px 8px;
`;

const TermsAndConditionsButton = styled.button`
  border: none;
  background: none;
  color: var(--mdc-theme-secondary);
  opacity: 0.8;
  text-decoration: underline;
  margin-left: 6px;
  font-size: 14px;

  &:hover {
    opacity: 1;
  }
`;

const StyledSubtotal = styled(Subtotal)`
  margin: 64px 0 32px 0;
`;

const StyledAlert = styled(Alert)`
  margin: 0 0 32px 0;
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InvoiceDisclaimer = styled(Typography)`
  margin: 8px 8px 16px 8px;
`;

const CaseCheckout: React.FC<CaseCheckoutProps> = ({
  caseInfo,
  clinicName,
  clinicEmail,
  caseUserId,
  onSubmit,
  isLoading,
  onBack,
  progress,
  invoicingEnabled,
  parentAccount,
  selectedInvoiceAccountUid,
  submitText = 'Submit',
  goBackText = 'Go back',
}) => {
  const [showTerms, setShowTerms] = React.useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = React.useState(false);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // send('NEXT');
          onSubmit();
        }}
      >
        <Padding>
          <Typography
            as="h3"
            fontSize="20px"
            variant="secondary"
            margin="16px 0"
          >
            Summary
          </Typography>

          <SubmitterInfo
            case={{
              submitter: {
                first: caseInfo.submitterFirstName,
                last: caseInfo.submitterLastName,
              },
            }}
            clinicName={clinicName}
            clinicEmail={clinicEmail}
          />

          <PatientInfo
            includeName
            case={{
              patient: {
                first: caseInfo.patientFirstName,
                last: caseInfo.patientLastName,
              },
              ...(caseInfo as any),
            }}
          />

          <Typography
            as="p"
            fontSize="16px"
            variant="secondary"
            margin="24px 0 16px 0"
          >
            Sites
          </Typography>
        </Padding>
        <List listStyle="two-line">
          {caseInfo.sites.map((s, i) => (
            <SiteListItem
              key={`site${i}`}
              siteIndex={i}
              site={s}
              userId={caseUserId}
            />
          ))}
        </List>

        <Padding>
          <StyledSubtotal numSites={caseInfo.sites.length} />

          <StyledAlert>
            <Typography
              as="span"
              fontSize="13px"
              lineHeight="1.2em"
              color="inherit"
            >
              {invoicingEnabled ? (
                <>
                  {selectedInvoiceAccountUid === caseUserId
                    ? `This case will be added to your invoice for this month. Invoices are sent at the beginning of the next month and are due within 14 days of receipt.`
                    : `This will be billed to ${
                        parentAccount?.displayName || 'N/A'
                      }`}
                </>
              ) : (
                <>
                  Your card will not be charged {/* <Br /> */}
                  until the case is signed off
                </>
              )}
            </Typography>
          </StyledAlert>

          {invoicingEnabled && (
            <InvoiceDisclaimer fontSize="14px">
              By submitting this case, you allow Pocket Pathologist to charge
              your credit card on file if the invoice is not paid by the due
              date.
            </InvoiceDisclaimer>
          )}

          <Checkbox
            id="terms-and-conditions"
            label={
              <Typography as="span" fontSize="14px">
                I accept the
                <TermsAndConditionsButton
                  id="terms-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowTerms(true);
                  }}
                >
                  Terms and Conditions
                </TermsAndConditionsButton>
              </Typography>
            }
            onChange={(e) => setHasAcceptedTerms(e.target.checked)}
          />

          <Centered>
            <ExpandingButton
              fullWidth
              buttonType="raised"
              type="submit"
              disabled={isLoading || !hasAcceptedTerms}
            >
              {submitText}
            </ExpandingButton>
            <ExpandingButton
              fullWidth
              emphasis="low"
              type="button"
              onClick={onBack}
              disabled={isLoading}
            >
              {goBackText}
            </ExpandingButton>
          </Centered>
        </Padding>

        {isLoading && (
          <ModalLoader
            ProgressBarProps={{
              indeterminate: !progress?.percent,
              progress: progress?.percent,
            }}
            progressText={progress?.message}
            onClose={onBack}
          />
        )}
      </form>
      <TermsAndConditionsModal trigger="terms-btn" />

      {/* <Dialog
        dialogId="terms-and-conditions"
        title="Terms and Conditions"
        isOpen={showTerms}
        onClose={() => setShowTerms(false)}
        type="fullscreen"
      >
        <TermsAndConditions />
      </Dialog> */}
    </>
  );
};

export default CaseCheckout;
