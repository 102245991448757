import React from 'react';
import styled from 'styled-components';
import { Case, CaseStatus, useCase } from '../services/buildfire/rdb/cases';
import Tab from '../components/common/Tab';
import TabContent from '../mdc/tab/TabContent';
import TabList from '../components/common/TabList';
import * as A from 'fp-ts/Array';
import * as N from 'fp-ts/Number';
import { flow, pipe } from 'fp-ts/lib/function';
import { contramap } from 'fp-ts/lib/Ord';
import { doesCaseHaveUnreadMessage, formatCaseNumber } from '../util/case';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonSearchbar,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import { CaseInfoProps } from '../components/case/caseInfo/CaseInfo';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { LOADING_STATE } from '../util/loading';
import useCaseIdParam from '../hooks/useCaseIdParam';
import SplitPane from '../components/common/SplitPane';
import NoCaseSelected from '../components/case/caseInfo/NoCaseSelected';
import EnterReport from './admin/EnterReport';
import NewCaseButton from '../components/case/NewCaseButton';
import { User } from 'firebase/auth';
import CaseList from '../components/case/CaseList';
import useQueryParams from '../hooks/useQueryParams';
import { PageArgs } from '../services/buildfire/rdb/CaseContext';
import '../style/tab-button.css';
import ProgressBar from '../components/common/ProgressBar';
import Fuse from 'fuse.js';
import FixedProgressBar from '../components/common/FixedProgressBar';
import { get } from 'lodash/fp';
import { isSafari } from '../util/device';
import { ParentUser } from '../api/cloudFunctions';

export interface CaseViewProps {
  cases: Case[];
  currentUser: User;
  caseUser: User;
  isAdmin: boolean;
  isLoading?: boolean;
  hasRetrievedAllCases: boolean;
  CaseInfo: React.ComponentType<CaseInfoProps>;
  Report: React.ComponentType<{}>;
  CaseListItemComponent: React.ComponentType<{ case: Case }>;
  getOpenCases: (userId: string) => Promise<void>;
  getClosedCases: (userId: string, pageArgs: PageArgs) => Promise<void>;
  onLoadSearchCases?: (searchTerm: string) => Promise<void>;
  fabDisabled?: boolean;
  parentAccount?: ParentUser;
}

const SearchBarToolbar = styled<{ $isIOS: boolean } & any>(IonToolbar)`
  ${(props) =>
    props.$isIOS
      ? '--padding-top: 0px !important; padding-top: 0px !important;'
      : ''}

  &.ios form {
    padding-top: 15px;
  }
`;

const StyledTab = styled(Tab)`
  width: 50%;
`;

const FAB = styled(NewCaseButton)`
  @media (min-width: 768px) {
    display: none;
  }
`;

const TabListContainer = styled(IonContent)`
  // subtract searchbar height
  height: calc(100% - 56px);
`;

const ClearSearchButton = styled(IonButton)`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
`;

const caseIsNot = (status: CaseStatus) => (c: Case) => c.status !== status;
const caseIs = (status: CaseStatus) => (c: Case) => c.status === status;
const caseIsArchived = (c: Case) => !!c.isArchived;

const byTime = pipe(
  N.Ord,
  contramap((c: Case) => c.createdAt || 0)
);
const byNewMessage = (caseUser: User, isAdmin: boolean) =>
  pipe(
    N.Ord,
    contramap((c: Case) => {
      if (!doesCaseHaveUnreadMessage(c, caseUser, isAdmin)) {
        return -1;
      }
      return c.lastMessage?.timestamp || 0;
    })
  );

const sortCases = (caseUser: User, isAdmin: boolean) =>
  flow(A.sortBy([byNewMessage(caseUser, isAdmin), byTime]), A.reverse);

const fuseOptions: Fuse.IFuseOptions<Case> = {
  // - case.caseNumber
  // - case.user.displayName
  // - case.user.email
  // - case.submitter.first + case.submitter.last
  // - case.patient.first + case.patient.last
  keys: [
    'caseNumber', // Highest priority so we can better handle exact matches
    'user.displayName',
    'user.email',
    // custom paths handled by custom `getFn`
    'submitter',
    'patient',
  ],

  getFn: (c, path) => {
    switch (path[0]) {
      case 'submitter':
        return `${c.submitter.first} ${c.submitter.last}`;
      case 'patient':
        return `${c.patient.first} ${c.patient.last}`;
      case 'caseNumber':
        return formatCaseNumber(c.caseNumber);
      default:
        return get(path, c);
    }
  },
};
const filterCases = (searchTerm: string, cases: Case[]): Case[] => {
  const fuse = new Fuse(cases, fuseOptions);
  return fuse.search(searchTerm).map((result) => result.item);
};

function useFilteredCases(searchCases: Case[]) {
  const queryParams = useQueryParams();
  const search = queryParams.get('search');
  const isSearching = !!search;
  const [filteredCases, setFilteredCases] = React.useState<Case[]>([]);
  React.useEffect(() => {
    const filteredSearchCases = isSearching
      ? filterCases(search!, searchCases)
      : [];
    setFilteredCases(filteredSearchCases);
  }, [search, searchCases]);

  return { isSearching, filteredCases };
}

const PAGE_SIZE = 25;

const Cases: React.FC<CaseViewProps> = ({
  cases,
  currentUser,
  caseUser,
  isAdmin,
  isLoading,
  hasRetrievedAllCases,
  CaseInfo,
  Report,
  CaseListItemComponent,
  getOpenCases,
  getClosedCases,
  onLoadSearchCases = () => Promise.resolve(),
  fabDisabled,
  parentAccount,
}) => {
  const caseId = useCaseIdParam();
  const { path } = useRouteMatch();
  const sortedCases = sortCases(caseUser, !!isAdmin)(cases);

  const partitionFn = isAdmin ? caseIsNot(CaseStatus.OPEN) : caseIsArchived;
  const { left: openCases, right: closedCases } =
    A.partition(partitionFn)(sortedCases);

  const { isSearching, filteredCases } = useFilteredCases(cases);

  const { loadingStatus, case: c } = useCase(caseId);
  const showCaseLoading = loadingStatus === LOADING_STATE.LOADING;
  const showCaseError = loadingStatus === LOADING_STATE.ERROR;
  const showCase = !!c && loadingStatus === LOADING_STATE.LOADED;

  React.useEffect(() => {
    (window as any).__SET_CASES_TAB__ = () => {};
  }, []);

  const hasUnreadMessage = (c: Case) => {
    const canMessage = currentUser?.uid === c.uid || isAdmin;
    return canMessage && doesCaseHaveUnreadMessage(c, caseUser!, !!isAdmin);
  };
  const hasUnreadOpenNotif = openCases.some(hasUnreadMessage);
  const hasUnreadClosedNotif = closedCases.some(hasUnreadMessage);

  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();

  const handleSearch = (searchTerm = '') => {
    const params = new URLSearchParams(location.search);
    if (searchTerm) {
      params.set('search', searchTerm || '');
    } else {
      params.delete('search');
    }
    history.replace({
      // pathname: isAdmin ? '/admin/cases' : '/cases',
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const searchList = (
    <TabListContainer>
      <FixedProgressBar show={isLoading} top={56} indeterminate />
      <CaseList
        key="search-results"
        cases={filteredCases}
        isLoading={isLoading}
        CaseListItemComponent={CaseListItemComponent}
        onLoadMoreCases={() => Promise.resolve()}
        paginated={false}
        hasRetrievedAllCases={true}
        bottomPadding="60px"
      />
      <ClearSearchButton color="light" onClick={() => handleSearch()}>
        Clear Search
      </ClearSearchButton>
    </TabListContainer>
  );

  const caseList = (
    <TabListContainer>
      <TabList
        tabs={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div style={{ flexDirection: 'row', flex: 1, display: 'flex' }}>
              <StyledTab
                tabIndex={0}
                variant="secondary"
                showNotif={hasUnreadOpenNotif}
              >
                OPEN
              </StyledTab>
              <StyledTab
                tabIndex={1}
                variant="secondary"
                showNotif={hasUnreadClosedNotif}
              >
                ARCHIVED
              </StyledTab>
            </div>
          </div>
        }
        isLoading={isLoading}
        ProgressBarProps={{
          indeterminate: true,
          progressBarType: 'secondary',
          top: 56,
        }}
      >
        <TabContent>
          <CaseList
            key="open"
            cases={openCases}
            isLoading={isLoading}
            CaseListItemComponent={CaseListItemComponent}
            onLoadMoreCases={() => {
              return getOpenCases(caseUser.uid).then(() =>
                console.log('retrieved open cases')
              );
            }}
            paginated={false}
          />
        </TabContent>
        <TabContent>
          <CaseList
            key="closed"
            cases={closedCases}
            isLoading={isLoading}
            CaseListItemComponent={CaseListItemComponent}
            onLoadMoreCases={async () => {
              const pageSize = PAGE_SIZE;
              return await getClosedCases(caseUser.uid, {
                pageSize,
                startAfter: cases[0],
              });
            }}
            paginated
            hasRetrievedAllCases={hasRetrievedAllCases}
          />
        </TabContent>
      </TabList>
    </TabListContainer>
  );
  const isIos = isPlatform('ios') || isSafari;
  const menu = (
    <>
      <IonHeader>
        <SearchBarToolbar $isIOS={isIos}>
          <form
            action="search"
            onSubmit={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              const el = document.getElementById('case-search');
              const search = (el as any)?.value;

              await onLoadSearchCases(search);
              handleSearch(search);
            }}
          >
            <IonSearchbar
              id="case-search"
              value={queryParams.get('search')}
              showClearButton="always"
              onIonClear={() => handleSearch()}
              onIonChange={(e) => {
                if (e.target.value === '') {
                  handleSearch();
                }
              }}
            />
          </form>
        </SearchBarToolbar>
      </IonHeader>

      {isSearching ? searchList : caseList}

      {!isSearching && !isAdmin && !fabDisabled && <FAB />}
    </>
  );

  const caseInfo = (
    <>
      {showCaseLoading && <div>Loading...</div>}
      {showCaseError && <div>ERROR</div>}
      {/* {showCase && (
        <CaseInfo case={c!} isAdmin={!!isAdmin} currentUser={currentUser} />
      )} */}
      <Switch>
        <Route path={`${path}/report/create`}>
          <EnterReport />
        </Route>
        <Route path={`${path}/report`}>
          <Report />
        </Route>
        <Route path={path}>
          {isAdmin && c?.status === CaseStatus.SIGNED_OFF ? (
            <Report />
          ) : (
            c && (
              <CaseInfo
                case={c}
                isAdmin={!!isAdmin}
                currentUser={currentUser}
                parentAccount={parentAccount}
              />
            )
          )}
        </Route>
      </Switch>
    </>
  );

  return (
    <IonContent>
      <SplitPane
        list={menu}
        details={
          showCase ? (
            caseInfo
          ) : showCaseLoading ? (
            <ProgressBar indeterminate />
          ) : undefined
        }
        fallback={<NoCaseSelected />}
      />
    </IonContent>
  );
};

export default Cases;
