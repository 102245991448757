import React, { useRef } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import HelperText from './HelperText';

export interface InputProps<T> extends React.InputHTMLAttributes<T> {
  label: string;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  helpText?: string | React.ReactNode;
  error?: string | React.ReactNode;
  textFieldType?: 'filled' | 'outlined';
  fullWidth?: boolean;
  suffix?: string | React.ReactNode;
}

const Label = styled.label`
  width: 100%;

  .mdc-floating-label {
    text-transform: uppercase;
  }

  &.mdc-text-field:not(.mdc-text-field--focused)
    .mdc-floating-label:not(.mdc-floating-label--float-above) {
    vertical-align: top;
    font-size: 14px !important;
  }

  &.mdc-text-field .mdc-floating-label {
    font-size: 14px !important;
  }

  &.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid)
    .mdc-floating-label {
    color: var(--mdc-theme-text-secondary-on-light) !important;
  }
  &.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid)
    .mdc-floating-label {
    color: var(--mdc-theme-primary) !important;
    font-size: 14px !important;
  }

  &.mdc-text-field input.mdc-text-field__input {
    font-size: 14px;
  }
`;

const Suffix = styled.div`
  font-size: 14px;
  padding: 12px 16px 14px;
  display: flex;
  align-items: center;
  color: var(--mdc-theme-text-secondary-on-light) !important;
`;

export function useTextField<T extends HTMLElement = HTMLLabelElement>() {
  const textFieldRef = useRef<T>(null);
  const mdcComponent = useRef<mdc.textField.MDCTextField>();
  React.useEffect(() => {
    if (textFieldRef.current) {
      mdcComponent.current = new mdc.textField.MDCTextField(
        textFieldRef.current
      );
      return () => mdcComponent.current?.destroy();
    }
  }, []);
  return { textFieldRef, mdcComponent };
}

const LabeledInput = React.forwardRef<
  HTMLInputElement,
  InputProps<HTMLInputElement>
>(
  (
    {
      label,
      labelProps,
      name,
      error,
      textFieldType = 'outlined',
      helpText,
      className,
      suffix,
      ...props
    },
    inputRef
  ) => {
    const { textFieldRef, mdcComponent } = useTextField();

    const labelSpan =
      textFieldType === 'filled' ? (
        <span className="mdc-floating-label" id={name}>
          {label}
        </span>
      ) : (
        <span className="mdc-notched-outline">
          <span className="mdc-notched-outline__leading"></span>
          <span className="mdc-notched-outline__notch">
            {label && (
              <span className="mdc-floating-label" id={name}>
                {label}
              </span>
            )}
          </span>
          <span className="mdc-notched-outline__trailing"></span>
        </span>
      );

    return (
      <>
        <Label
          className={classnames([
            `mdc-text-field`,
            `mdc-text-field--${textFieldType}`,
            !!error && 'mdc-text-field--invalid',
          ])}
          htmlFor={name}
          ref={textFieldRef}
          {...labelProps}
        >
          {textFieldType === 'filled' && (
            <span className="mdc-text-field__ripple"></span>
          )}
          {labelSpan}
          <input
            style={{ width: '100%' }}
            id={name}
            name={name}
            className={classnames(['mdc-text-field__input', className])}
            type="text"
            aria-labelledby={name}
            ref={inputRef}
            {...props}
          />
          {suffix && (
            <Suffix
              className="mdc-text-field__affix mdc-text-field__affix--suffix"
              onClick={() => {
                mdcComponent.current?.focus();
              }}
            >
              {suffix}
            </Suffix>
          )}

          {textFieldType === 'filled' && (
            <span className="mdc-line-ripple"></span>
          )}
        </Label>
        {helpText && !error && <HelperText>{helpText}</HelperText>}
        {error && <HelperText type="error">{error}</HelperText>}
      </>
    );
  }
);

export default LabeledInput;
