import { IonPopover } from '@ionic/react';
import React from 'react';
import { JSX } from '@ionic/core/components';
import styled from 'styled-components';

interface PopoverProps extends JSX.IonPopover {}

const StyledPopover = styled(IonPopover)`
  &::part(content) {
    cursor: pointer;
  }
`;

export function useIonPopoverProps() {
  const ref = React.useRef<HTMLIonPopoverElement>(null);
  React.useEffect(() => {
    const controller = ref.current;
    return () => {
      controller?.dismiss?.();
    };
  }, []);
  return { ref };
}

const Popover: React.FC<PopoverProps> = (props) => {
  const popoverProps = useIonPopoverProps();
  return <StyledPopover {...popoverProps} {...props} />;
};

export default Popover;
