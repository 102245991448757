import React from 'react';
import Dialog, {
  DialogActionButton,
  DialogProps,
} from '../components/common/Dialog';
import * as A from 'fp-ts/Array';
import * as S from 'fp-ts/string';
import { flow } from 'fp-ts/lib/function';

export interface IDialogContext {
  openDialogsRef: React.MutableRefObject<string[]>;
  openDialogs: string[];
  addDialog: (dialogId: string) => void;
  removeDialog: (dialogId: string) => void;

  openDialog: (
    content: string | React.ReactNode,
    props?: Partial<DialogProps>
  ) => void;
  openDialogPromise: (
    content: string | React.ReactNode,
    props?: Partial<DialogProps>
  ) => Promise<void>;
}

export const DialogContext = React.createContext<IDialogContext>(
  undefined as any
);

interface DialogProviderProps {}

const dedup = A.uniq(S.Eq);

export const openDialog: IDialogContext['openDialog'] = (content, props) => {
  (window as any).__OPEN_DIALOG__?.(content, props);
};

const DialogProvider: React.FC<DialogProviderProps> = ({
  children,
  ...props
}) => {
  const openDialogsRef = React.useRef<string[]>([]);
  const [openDialogs, setOpenDialogs] = React.useState<string[]>([]);
  const [dialog, setDialog] = React.useState<{
    content: string | React.ReactNode;
    props?: Partial<DialogProps>;
  }>();

  const openDialog = React.useCallback(
    (content: string | React.ReactNode, props?: Partial<DialogProps>) => {
      setDialog({ content, props });
    },
    []
  );
  (window as any).__OPEN_DIALOG__ = openDialog;

  React.useEffect(() => {
    openDialogsRef.current = openDialogs;
  }, [openDialogs]);

  const addDialog = React.useCallback((dialogId: string) => {
    const append = A.concat([dialogId]);
    setOpenDialogs(flow(append, dedup));
  }, []);

  const removeDialog = React.useCallback((dialogId: string) => {
    setOpenDialogs((dialogs) => dialogs.filter((id) => id !== dialogId));
  }, []);

  const openDialogPromise = React.useCallback(
    (content: string | React.ReactNode, props?: Partial<DialogProps>) =>
      new Promise<void>((resolve, reject) => {
        openDialog(content, {
          actions: (
            <>
              <DialogActionButton action="close" emphasis="low">
                Cancel
              </DialogActionButton>
              <DialogActionButton action="accept">OK</DialogActionButton>
            </>
          ),
          ...props,
          onClose: (action) => {
            if (action === 'accept') {
              resolve();
            } else {
              reject();
            }
            props?.onClose?.(action);
          },
        });
      }),
    []
  );

  return (
    <DialogContext.Provider
      value={{
        openDialogs,
        openDialogsRef,
        openDialog,
        openDialogPromise,
        addDialog,
        removeDialog,
      }}
    >
      {children}
      {dialog && (
        <Dialog
          isOpen
          dialogId="top-level-dialog"
          title=""
          actions={
            <>
              <DialogActionButton action="accept">OK</DialogActionButton>
            </>
          }
          {...dialog.props}
          onClose={(action) => {
            setDialog(undefined);
            dialog.props?.onClose?.(action);
          }}
        >
          {dialog.content}
        </Dialog>
      )}
    </DialogContext.Provider>
  );
};

export function useDialog() {
  return React.useContext(DialogContext);
}

export default DialogProvider;
