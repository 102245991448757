import { AuthCredential, UserCredential } from 'firebase/auth';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useQueryParams from '../../hooks/useQueryParams';
import { useFirebase } from '../../services/firebase';
import { modEmail } from '../../services/firebase/database';
import EnterPhoneNumber from './phone/EnterPhoneNumber';
import NewPasswordForm from './phone/NewPasswordForm';
import PhoneSMSVerificationForm from './phone/PhoneSMSVerificationForm';
import { redirectAfterAuth } from '../../util/auth';

interface PhoneLoginFormProps {
  usersInNeedOfMigration: string[];
}

enum PHONE_LOGIN_SCREEN {
  PHONE_NUMBER = 'phone_number',
  SMS_CODE = 'sms_code',
  CHANGE_PASSWORD = 'change_password',
  COMPLETE = 'complete',
}

interface State {
  verificationId?: string;
  authCredential?: AuthCredential;
  userCredential?: UserCredential;
}

function getNextScreen(
  currentScreen: PHONE_LOGIN_SCREEN,
  state: State,
  props: PhoneLoginFormProps
) {
  switch (currentScreen) {
    case PHONE_LOGIN_SCREEN.PHONE_NUMBER: {
      return PHONE_LOGIN_SCREEN.SMS_CODE;
    }
    case PHONE_LOGIN_SCREEN.SMS_CODE: {
      const moddedEmail = modEmail(state.userCredential?.user.email || '');
      const isPasswordResetRequired = props.usersInNeedOfMigration.find(
        (email) => moddedEmail === email
      );
      if (isPasswordResetRequired) {
        return PHONE_LOGIN_SCREEN.CHANGE_PASSWORD;
      }

      return PHONE_LOGIN_SCREEN.COMPLETE;
    }
    case PHONE_LOGIN_SCREEN.CHANGE_PASSWORD:
      return PHONE_LOGIN_SCREEN.COMPLETE;
    default:
      return PHONE_LOGIN_SCREEN.PHONE_NUMBER;
  }
}

const PhoneLoginForm: React.FC<PhoneLoginFormProps> = (props) => {
  const [screen, setScreen] = React.useState<PHONE_LOGIN_SCREEN>(
    PHONE_LOGIN_SCREEN.PHONE_NUMBER
  );
  const { signInWithCredential } = useFirebase();
  const [state, setState] = React.useState<State>({});

  switch (screen) {
    case PHONE_LOGIN_SCREEN.PHONE_NUMBER:
      return (
        <EnterPhoneNumber
          onSubmit={(verificationId) => {
            const newState = { ...state, verificationId };
            const nextScreen = getNextScreen(screen, newState, props);
            setState(newState);
            setScreen(nextScreen);
          }}
          {...props}
        />
      );
    case PHONE_LOGIN_SCREEN.SMS_CODE:
      return (
        <PhoneSMSVerificationForm
          verificationId={state.verificationId!}
          onSubmit={async ({ authCredential }) => {
            const userCredential = await signInWithCredential(authCredential);
            const newState = { ...state, userCredential, authCredential };
            const nextScreen = getNextScreen(screen, newState, props);
            setState(newState);
            setScreen(nextScreen);
          }}
          goBack={() => setScreen(PHONE_LOGIN_SCREEN.PHONE_NUMBER)}
          {...props}
        />
      );
    case PHONE_LOGIN_SCREEN.CHANGE_PASSWORD:
      return (
        <NewPasswordForm
          userCredential={state.userCredential!}
          authCredential={state.authCredential!}
          onComplete={() => {
            const nextScreen = getNextScreen(screen, state, props);
            setScreen(nextScreen);
          }}
        />
      );
    case PHONE_LOGIN_SCREEN.COMPLETE:
      return <OnComplete />;
    default:
      return null;
  }
};

const OnComplete: React.FC<{}> = (props) => {
  const history = useHistory();
  const params = useQueryParams();

  React.useEffect(() => {
    redirectAfterAuth(params, history);
  }, []);
  return null;
};

export default PhoneLoginForm;
