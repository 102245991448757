import React from 'react';
import styled from 'styled-components';
import * as RDBCases from '../../services/buildfire/rdb/cases';
import InfoSection, { LineItem } from './caseInfo/InfoSection';
import moment from 'moment';

interface SubmitterInfoProps {
  case: Partial<RDBCases.Case> & {
    submitter: RDBCases.Case['submitter'];
  };
  clinicName: string;
  clinicEmail: string;
  mailtoEmail?: boolean;
}

const MailTo = styled.a`
  text-decoration: underline;
`;

const SubmitterInfo: React.FC<SubmitterInfoProps> = ({
  case: userCase,
  clinicName,
  clinicEmail,
  mailtoEmail,
}) => {
  if (!userCase) {
    return null;
  }

  const hasSubmitterEmail =
    userCase.submitter.email && userCase.submitter.email !== clinicEmail;
  const submitterEmail = hasSubmitterEmail ? (
    mailtoEmail ? (
      <>
        {' '}
        (
        <MailTo href={`mailto:${userCase.submitter.email}`}>
          {userCase.submitter.email}
        </MailTo>
        )
      </>
    ) : (
      <> ({userCase.submitter.email})</>
    )
  ) : undefined;

  return (
    <InfoSection title="Submitted by">
      <LineItem name="Clinic" description={clinicName} />
      <LineItem
        name="Email"
        description={
          mailtoEmail ? (
            <MailTo href={`mailto:${clinicEmail}`}>{clinicEmail}</MailTo>
          ) : (
            clinicEmail
          )
        }
      />
      <LineItem
        name="Veterinarian"
        description={
          <>
            {userCase.submitter.first} {userCase.submitter.last}
            {submitterEmail}
          </>
        }
      />
      <LineItem
        name="Submission time"
        description={moment(userCase.createdAt).format('llll')}
      />
    </InfoSection>
  );
};

export default SubmitterInfo;
