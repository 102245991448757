import React from 'react';
import LoadingScreen from '../../components/common/LoadingScreen';
import useFetch from '../../hooks/useFetch';
import { useFirebase } from '../../services/firebase';
import { getAuth, applyActionCode } from 'firebase/auth';
import useQueryParams from '../../hooks/useQueryParams';
import Typography from '../../components/common/Typography';
import SendEmailVerificationButton from '../../components/user/SendEmailVerificationButton';
import styled from 'styled-components';
import { IonButton, IonIcon } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import { withAuthRequired } from '../../services/buildfire/auth';

interface VerifyEmailProps {}

const Success = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VerifyEmail: React.FC<VerifyEmailProps> = (props) => {
  const { currentUser, app } = useFirebase();
  const params = useQueryParams();
  const history = useHistory();
  const continueUrl = params.get('continueUrl') || '/login';
  const code = params.get('oobCode') || '';

  const { loadingStatus, data, error } = useFetch(() => {
    const auth = getAuth(app);
    // If user has already been verified, skip
    if (currentUser?.emailVerified) {
      return Promise.resolve();
    }

    return applyActionCode(auth, code).catch((e) => {
      switch (e.code) {
        case 'auth/invalid-action-code':
          if (currentUser) {
            throw new Error(
              `Email verification link invalid for account ${currentUser?.email}. Please make sure you are logged into the correct account and try again.`
            );
          } else {
            throw new Error(
              `Email verification link invalid. Please make sure you are logged into the correct account and try again.`
            );
          }
        case 'auth/expired-action-code': {
          throw new Error(
            'Your email verification link has expired. Please try again.'
          );
        }
        default:
          throw e;
      }
    });
  });

  return (
    <LoadingScreen
      loadingStatus={loadingStatus}
      error={error}
      errorContent={
        error &&
        currentUser &&
        !currentUser.emailVerified && (
          <SendEmailVerificationButton user={currentUser} app={app} />
        )
      }
    >
      <Success>
        <IonIcon
          color="success"
          icon={checkmarkCircle}
          style={{ marginRight: '8px', fontSize: '20px' }}
        />

        <Typography variant="primary">Email successfully verified</Typography>
      </Success>

      <IonButton
        expand="block"
        onClick={() => {
          history.push('/');
        }}
        style={{ marginTop: '32px' }}
      >
        Return to home
      </IonButton>
    </LoadingScreen>
  );
};

export default withAuthRequired(VerifyEmail);
