import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonPage,
} from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import { Case } from '../../services/buildfire/rdb/cases';
import { LOADING_STATE } from '../../util/loading';
import Typography from '../common/Typography';

interface CaseListProps {
  cases: Case[];
  isLoading?: boolean;
  CaseListItemComponent: React.ComponentType<{ case: Case }>;
  onLoadMoreCases: () => Promise<any>;
  hasRetrievedAllCases?: boolean;
  paginated?: boolean;
  bottomPadding?: string;
}

const Container = styled(IonPage)`
  // margin-bottom: 100px;
`;

const List = styled<any>(IonList)`
  padding: 0;
  list-styled-type: none;
  margin-bottom: ${(props) => props.$bottomPadding};
`;

const NoCases: React.FC<any> = () => {
  return (
    <Typography fontSize="18px" textAlign="center" margin="32px">
      No cases
    </Typography>
  );
};

const CaseList: React.FC<CaseListProps> = ({
  cases,
  CaseListItemComponent,
  onLoadMoreCases,
  hasRetrievedAllCases,
  paginated,
  bottomPadding = '16px',
}) => {
  const [loadingStatus, setLoadingStatus] = React.useState(LOADING_STATE.IDLE);
  const isLoading = loadingStatus === LOADING_STATE.LOADING;

  const loadCases = async () => {
    setLoadingStatus(LOADING_STATE.LOADING);
    try {
      await onLoadMoreCases();
      setLoadingStatus(LOADING_STATE.LOADED);
    } catch (e) {
      setLoadingStatus(LOADING_STATE.ERROR);
      throw e;
    }
  };

  const disabled = !paginated || hasRetrievedAllCases;

  return (
    <Container>
      <IonContent>
        <List className="mdc-list" $bottomPadding={bottomPadding}>
          {!cases.length && !isLoading && <NoCases />}
          {cases.map((c) => (
            <CaseListItemComponent key={c.id} case={c} />
          ))}
        </List>
        <IonInfiniteScroll
          threshold="100px"
          disabled={disabled}
          onIonInfinite={async (event) => {
            await loadCases();
            event.target.complete();
          }}
        >
          <IonInfiniteScrollContent loadingText="Loading..." />
        </IonInfiniteScroll>
      </IonContent>
    </Container>
  );
};

export default CaseList;
