import React from 'react';
import { useHistory } from 'react-router-dom';
import Button, { ButtonProps } from './Button';

type LinkButtonProps = ButtonProps & {
  to: string;
};

export function useLinkButtonProps(args: { newWindow?: boolean } = {}) {
  const history = useHistory();
  const props = {
    role: 'link',
    onClick:
      (to: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (args.newWindow) {
          window.open(to, '_blank');
        } else {
          history.push(to);
        }
      },
  };
  return props;
}

const LinkButton: React.FC<LinkButtonProps> = ({ to, ...props }) => {
  const { role, onClick } = useLinkButtonProps();
  return (
    <Button
      {...props}
      role={role}
      onClick={(e) => {
        onClick(to)(e);
        props.onClick?.(e);
      }}
    />
  );
  // return <Link {...linkProps} component={() => <Button as="a" {...props} />} />;
};

export default LinkButton;
