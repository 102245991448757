import {
  FirebaseStorage,
  ref,
  UploadTask,
  getDownloadURL,
  getStorage,
  uploadBytesResumable,
  uploadBytes,
} from 'firebase/storage';
import React from 'react';
// Unsure why this is needed, but throws an error without it
// SEE https://github.com/babel/babel/issues/9849
import 'regenerator-runtime/runtime';
import { PreUploadSite } from '../../components/case/CaseForm';
import { Site } from '../buildfire/rdb/cases';
import useFirebase from './useFirebase';

export const isPreuploadSite = (site: any): site is PreUploadSite =>
  Array.isArray(site.files);

export const uploadSites = (
  storage: FirebaseStorage,
  sites: (PreUploadSite | Site)[],
  userId: string,
  uploadCallback?: (
    num: number,
    total: number,
    name: string,
    siteIndex: number
  ) => void
): Promise<Site[]> => {
  const uploadPromises = sites.map((site, siteIndex) =>
    isPreuploadSite(site)
      ? uploadImagesInBatches(
          storage,
          3,
          site.files,
          userId,
          (num, total, name) => uploadCallback?.(num, total, name, siteIndex)
        )
      : Promise.resolve(site.images)
  );
  return Promise.all(uploadPromises).then((imageUrls) => {
    return sites.map((site, i) => ({
      images: imageUrls[i],
      location: site.location,
      description: site.description,
      additionalInfo: site.additionalInfo,
    }));
  });
};

const TIMEOUT_MS = 90000;

const uploadImagesInBatches = async (
  storage: FirebaseStorage,
  batchSize: number,
  files: FileList | File[],
  uid: string,
  onUploaded?: (num: number, total: number, name: string) => void
) => {
  const fileArr: File[] = [...(files as any)];
  let batch = batchSize === 0 ? 999 : batchSize;

  let results: string[] = [];
  const totalBatches = Math.ceil(fileArr.length / batch);
  let batchNum = 1;
  let uploadedCount = 0;
  for (let i = 0; i < fileArr.length; i += batch) {
    const chunk = fileArr.slice(i, i + batch);
    console.log(`Uploading batch ${batchNum}/${totalBatches}`);
    const batchResults = await uploadImages(storage, chunk, uid, (name) => {
      console.log(`Uploaded image ${uploadedCount} of ${fileArr.length}`);
      onUploaded?.(uploadedCount, fileArr.length, name);
      uploadedCount++;
    });
    console.log(`Uploaded batch ${batchNum}/${totalBatches}`);
    results = results.concat(batchResults);
    batchNum++;
  }

  return results;
};

export const uploadProfilePicture = (
  storage: FirebaseStorage,
  image: File,
  uid: string
): Promise<string> => {
  const imageRef = ref(storage, `profile_pictures/${uid}/${image.name}`);
  return new Promise<string>(async (resolve, reject) => {
    try {
      const result = await uploadBytes(imageRef, image);
      const { name } = result.ref;
      resolve(name);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

const uploadImages = (
  storage: FirebaseStorage,
  files: FileList | File[],
  uid: string,
  onUploaded?: (name: string) => void
): Promise<string[]> => {
  const userFolderRef = ref(storage);

  const tasks: UploadTask[] = [];
  for (let i = 0; i < files.length; i++) {
    const index = i;
    const imageRef = ref(storage, `user_uploads/${uid}/${files[index].name}`);
    const task = uploadBytesResumable(imageRef, files[index]);
    tasks.push(task);
  }

  const promises = tasks.map((task, i) => {
    return new Promise<string>((resolve, reject) => {
      // Periodically check to make sure progress is being made on this upload
      // Otherwise throw a Timeout error
      let lastCalled = Date.now();
      const interval = setInterval(() => {
        if (Date.now() - lastCalled > TIMEOUT_MS) {
          tasks.forEach((task) => task.cancel());
          reject(
            new Error(
              'Error: timeout uploading files. Please check your internet connection and try again.'
            )
          );
          clearInterval(interval);
        }
      }, 1000);

      task.on(
        'state_changed',
        (snapshot) => {
          lastCalled = Date.now();

          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload ${snapshot.ref.name}: ${progress}%`);
        },
        (err) => {
          console.error(`ERROR uploading ${task.snapshot.ref.name}`, err);

          // Cancel remaining uploads
          tasks.forEach((task) => task.cancel());
          clearInterval(interval);
          reject(err);
        },
        () => {
          const { name } = task.snapshot.ref;
          console.log(`Uploaded ${name} successfully`);
          onUploaded?.(name);
          resolve(name);
          clearInterval(interval);
        }
      );
    });
  });

  return Promise.all(promises);
};

const getUserUploadFileRef = (
  storage: FirebaseStorage,
  file: string,
  uid: string
) => {
  return ref(storage, `user_uploads/${uid}/${file}`);
};

const getFileDownloadUrl = (
  storage: FirebaseStorage,
  file: string,
  uid: string
): Promise<string> => {
  const fileRef = getUserUploadFileRef(storage, file, uid);
  return getDownloadURL(fileRef);
};

const getImageAsFile = async (
  storage: FirebaseStorage,
  file: string,
  uid: string
): Promise<File> => {
  const downloadUrl = await getFileDownloadUrl(storage, file, uid);
  const response = await fetch(downloadUrl, {
    method: 'GET',
    // mode: 'no-cors',
  });
  const data = await response.blob();
  const metadata = {
    type: response.headers.get('content-type') || 'image/jpeg',
    size: response.headers.get('content-size'),
  };
  return new File([data], file, metadata);
};

const useFiles = (imageUrls: string[], uid: string) => {
  const [files, setFiles] = React.useState<File[]>([]);
  const { app } = useFirebase();
  React.useEffect(() => {
    const storage = getStorage(app);
    Promise.all(imageUrls.map((url) => getImageAsFile(storage, url, uid))).then(
      setFiles
    );
  }, [uid, imageUrls, app]);
  return files;
};

export {
  uploadImages,
  getFileDownloadUrl,
  getImageAsFile,
  useFiles,
  getUserUploadFileRef as getFileRef,
};
