import {
  GoogleAuthProvider,
  OAuthCredential,
  signInWithPopup,
} from 'firebase/auth';
import React, { useContext, useEffect } from 'react';
import SignInButton from './SignInButton';
import googleIcon from '../../../images/third-party/btn_google_light_normal_ios.svg';
import { useFirebase } from '../../../services/firebase';
import { isDesktop } from '../../../util/device';
import FirebaseAuthPlugin from '../../../capactior/plugins/firebase-auth';
import { useHistory } from 'react-router-dom';
import { LoginContext } from '../../Login';
import useQueryParams from '../../../hooks/useQueryParams';
import { redirectAfterAuth } from '../../../util/auth';

interface GoogleSignInButtonProps {}

const provider = new GoogleAuthProvider();

const GoogleSignInButton: React.FC<GoogleSignInButtonProps> = (props) => {
  const { signInWithCredential } = useFirebase();
  const history = useHistory();
  const params = useQueryParams();

  const { addRedirectListener } = useContext(LoginContext);
  useEffect(() => {
    addRedirectListener(async (result) => {
      const redirectSource = params.get('redirect');
      if (!result || redirectSource !== 'google') {
        return;
      }

      const authCredential = await GoogleAuthProvider.credentialFromResult(
        result
      );
      await signInWithCredential(authCredential!);

      redirectAfterAuth(params, history);
    });
  }, []);

  return (
    <SignInButton
      imageSrc={googleIcon}
      provider={provider}
      signIn={async (auth) => {
        let authCredential: OAuthCredential;
        if (!isDesktop()) {
          history.push('/login?redirect=google');
          const result = await FirebaseAuthPlugin.signInWithRedirect({
            providerId: GoogleAuthProvider.PROVIDER_ID,
          });
          console.log({ googleSignInResult: result });
          authCredential = GoogleAuthProvider.credential(
            result.idToken,
            result.accessToken
          );
          console.log(authCredential);
          await signInWithCredential(authCredential);
          console.log('SUCCESSFULLY SIGNED IN');
        } else {
          const result = await signInWithPopup(auth, provider);
          authCredential = await GoogleAuthProvider.credentialFromResult(
            result
          )!;
        }
        return authCredential;
      }}
      title="Sign in with Google"
    />
  );
};

export default GoogleSignInButton;
