import React from 'react';

interface ITabContext {
  registerTab: () => number;
  activeTab: number;
}

export const TabContext = React.createContext<ITabContext>(undefined as any);

export interface TabListProps extends React.HTMLAttributes<HTMLDivElement> {
  onTabChange?: (tabIndex: number) => void;
  tabs: React.ReactNode;
  scrollable?: boolean;
}

const TabList: React.FC<TabListProps> = ({
  tabs,
  onTabChange,
  scrollable,
  children,
  ...props
}) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const numRegisteredTabs = React.useRef(0);
  const ref = React.useRef<HTMLDivElement>(null);
  const mdcComponent = React.useRef<mdc.tabBar.MDCTabBar>();
  React.useEffect(() => {
    if (ref.current) {
      mdcComponent.current = new mdc.tabBar.MDCTabBar(ref.current);
      const callback = (e: any) => {
        const tabIndex = e.detail.index;
        onTabChange?.(tabIndex);
        setActiveTab(tabIndex);
      };
      mdcComponent.current.listen('MDCTabBar:activated', callback);
      return () => mdcComponent.current!.destroy();
    }
  });
  const registerTab = React.useCallback(() => {
    const currentIndex = numRegisteredTabs.current;
    numRegisteredTabs.current = currentIndex + 1;
    return currentIndex;
  }, []);

  return (
    <TabContext.Provider value={{ activeTab, registerTab }}>
      <div className="mdc-tab-bar" role="tablist" ref={ref} {...props}>
        <div className="mdc-tab-scroller">
          <div className="mdc-tab-scroller__scroll-area">
            <div className="mdc-tab-scroller__scroll-content">{tabs}</div>
          </div>
        </div>
      </div>
      {children}
    </TabContext.Provider>
  );
};

export default TabList;
