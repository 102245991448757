import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { JSX } from '@ionic/core/components';
import CaseAttachment from './CaseAttachment';
import styled from 'styled-components';
import {
  TransformComponent,
  TransformWrapper,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

interface FullscreenAttachmentsProps extends JSX.IonModal {
  images: string[];
  initialImage?: string;
  /**
   * Required for case images in order to get download URL
   */
  userId?: string;
  onClose: () => void;
  /**
   * Set to true if using regular img srcs that we don't need to get a Firebase
   * download URL using userId, etc.
   *
   * If not a case image, we don't need userId and just use a regular img tag
   */
  isNonCaseImage?: boolean;
}

const Modal = styled(IonModal)`
  --width: 100%;
  --height: 100%;
  --min-width; 100%;
  --min-height: 100%;

  .swiper {
    height: 100%;
  }
`;

const CenterButtons = styled(IonButtons)`
  justify-content: center;
`;

const Slide = styled(SwiperSlide)`
  .react-transform-wrapper,
  .react-transform-component {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  .react-transform-component {
    justify-content: center;
    img {
      margin: auto;
      max-height: 100%;
      max-width: 100%;
    }
    video {
      max-height: 100%;
    }
  }
`;

const FullscreenAttachments = React.forwardRef<
  HTMLIonModalElement,
  FullscreenAttachmentsProps
>(
  (
    { images, initialImage, userId, onClose, isNonCaseImage, ...props },
    ref
  ) => {
    const [slideIndex, setSlideIndex] = React.useState<number>(() => {
      const newIndex = images.findIndex((i) => i === initialImage);
      return newIndex === -1 ? 0 : newIndex;
    });
    React.useEffect(() => {
      setSlideIndex(images.findIndex((i) => i === initialImage));
    }, [images.length, initialImage]);
    const slider = React.useRef<any>();
    const transform = React.useRef<(ReactZoomPanPinchRef | null)[]>([]);

    React.useEffect(() => {
      if (!isNonCaseImage && !userId) {
        console.warn(
          `FullscreenAttachments: WARNING: userId required if isNonCaseImage=false`
        );
      }
    }, [userId, isNonCaseImage]);

    return (
      <Modal
        {...props}
        ref={ref}
        onKeyDown={(e) => {
          switch (e.key) {
            case 'ArrowRight':
              return slider.current?.slideNext();
            case 'ArrowLeft':
              return slider.current?.slidePrev();
            default:
              return;
          }
        }}
      >
        <>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={onClose}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen scrollY={false}>
            <Swiper
              initialSlide={slideIndex === -1 ? 0 : slideIndex}
              height={window.innerHeight}
              simulateTouch={false}
              onBeforeInit={(swiper) => {
                slider.current = swiper;
                (window as any).slider = swiper;
              }}
              onSlideChange={(swiper) => {
                setSlideIndex(swiper.realIndex);
              }}
            >
              {images.map((image, i) => (
                <Slide key={image}>
                  <TransformWrapper
                    ref={(transformRef) => {
                      if (transform.current) {
                        transform.current[i] = transformRef;
                      }
                    }}
                  >
                    <TransformComponent>
                      {isNonCaseImage ? (
                        <img src={image} />
                      ) : (
                        // userId is required for case images
                        <CaseAttachment fileId={image} userId={userId!} />
                      )}
                    </TransformComponent>
                  </TransformWrapper>
                </Slide>
              ))}
            </Swiper>
          </IonContent>
          <IonFooter>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  disabled={slideIndex === 0}
                  onClick={() => slider.current?.slidePrev()}
                >
                  Prev
                </IonButton>
              </IonButtons>
              <CenterButtons>
                <IonButton
                  onClick={() => {
                    transform.current?.[slider.current.realIndex]?.zoomOut();
                  }}
                >
                  &minus;
                </IonButton>
                <IonButton
                  onClick={() => {
                    transform.current?.[slider.current.realIndex]?.zoomIn();
                  }}
                >
                  +
                </IonButton>
              </CenterButtons>
              <IonButtons slot="end">
                <IonButton
                  disabled={slideIndex === images.length - 1}
                  onClick={() => slider.current?.slideNext()}
                >
                  Next
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonFooter>
        </>
      </Modal>
    );
  }
);

export default FullscreenAttachments;
