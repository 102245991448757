import React from 'react';
import styled from 'styled-components';
import MDCTabList, {
  TabListProps as MDCTabListProps,
} from '../../mdc/tab/TabList';
import FixedProgressBar, { FixedProgressBarProps } from './FixedProgressBar';

interface TabListProps extends MDCTabListProps {
  isLoading?: boolean;
  ProgressBarProps?: FixedProgressBarProps;
}

const ContentContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  height: calc(100% - 48px);
  overflow: auto;
`;

const TabList: React.FC<TabListProps> = ({
  isLoading,
  children,
  ProgressBarProps,
  ...props
}) => {
  return (
    <MDCTabList {...props}>
      <ContentContainer>
        <FixedProgressBar show={isLoading} {...ProgressBarProps} />
        {children}
      </ContentContainer>
    </MDCTabList>
  );
};

export default TabList;
