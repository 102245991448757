import React, { useContext } from 'react';
import styled from 'styled-components';
import * as RDBCase from '../../services/buildfire/rdb/cases';
import Typography from '../../components/common/Typography';
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from '../../components/dialogs/ConfirmationDialog';
import { useHistory } from 'react-router';
import { useRouteMatch, generatePath } from 'react-router-dom';
import Page from '../../components/page/Page';
import withCase from '../../util/withCase';
import { LOADING_STATE } from '../../util/loading';
import { useReport } from '../../services/buildfire/rdb/reports';
import SnackbarContext from '../../context/SnackbarContext';
import { withAuthRequired } from '../../services/buildfire/auth';
import ReportComponent from '../../components/case/report/Report';
import CaseContext from '../../services/buildfire/rdb/CaseContext';
import { useDownloadReport } from '../../components/case/actionMenu/actions/DownloadPDF';
import ModalLoader from '../../components/common/ModalLoader';
import { useFirebase } from '../../services/firebase';
import MessageFab from '../../components/messages/MessageFab';
import { doesCaseHaveUnreadMessage } from '../../util/case';
import ExpandingButton from '../../components/common/ExpandingButton';
import { Functions, getFunctions, httpsCallable } from 'firebase/functions';
import { getDatabase } from 'firebase/database';
import { getVersion } from '../../util/app';
import MessageProvider from '../../context/MessageContext';

interface AdminReportProps extends React.HTMLAttributes<HTMLElement> {
  case: RDBCase.Case;
}

const StyledPage = styled(Page)`
  padding-top: 32px;
`;

const ContentContainer = styled.div`
  padding: 0 16px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const ReportTitle = styled(Typography)`
  margin: 8px 0 0 0;
`;

const ReportSection = styled.div`
  margin: 16px 0;
`;

const ReportData = styled(Typography)`
  opacity: 0.8;
`;

async function signOff(
  functions: Functions,
  caseId: string,
  acceptedBy: RDBCase.Case['acceptedBy']
) {
  const fn = httpsCallable<any, any>(functions, 'caseSignOff');

  const appVersion = await getVersion();
  return await fn({
    caseId,
    acceptedBy,
    appVersion,
  });
}

function useViewed(caseId?: string) {
  const { app } = useFirebase();
  React.useEffect(() => {
    if (caseId) {
      const database = getDatabase(app);
      RDBCase.markAsViewed(database, caseId);
    }
  }, [caseId, app]);
}

const AdminReport: React.FC<AdminReportProps> = ({ case: c, ...props }) => {
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const history = useHistory();
  const { currentUser, app, userMetadata } = useFirebase();
  const { report, loadingStatus: reportLoadingStatus } = useReport(c.id);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const snackbar = useContext(SnackbarContext);
  const { updateCase } = useContext(CaseContext);
  const {
    downloadReport,
    isLoading: isDownloadingReport,
    negate,
  } = useDownloadReport(c, report);
  useViewed(c.id);
  const match = useRouteMatch();
  const reportPath = generatePath(`${match.path}/report`, {
    caseId: c.id,
  });
  const functions = getFunctions(app);

  if (reportLoadingStatus === LOADING_STATE.LOADING) {
    return null;
  }

  if (!report) {
    return (
      <Typography variant="primary" fontSize="24px">
        Report not found for case {c.id}
      </Typography>
    );
  }

  function returnToCaseInfo() {
    // Return to case info screen
    history.go(-(report!.items.length + 1));
  }

  async function onConfirmSubmission(ok: boolean) {
    if (!ok) {
      return setShowConfirmation(false);
    }

    setIsSubmitting(true);
    try {
      const result = await signOff(functions, c.id, {
        displayName: currentUser!.displayName!,
        uid: currentUser!.uid,
        signature: userMetadata?.signature || '',
        photoUrl: currentUser!.photoURL || '',
      });
      if (result.data.status !== 'ok' || !result.data.case) {
        console.error(result);
        throw new Error('Error submitting report, please try again');
      }

      updateCase(result.data.case);
      setIsSubmitting(false);
      returnToCaseInfo();
    } catch (e) {
      setShowConfirmation(false);
      setIsSubmitting(false);
      console.error(e);
      const { message } = e as any;
      snackbar.setMessage({
        text: message
          ? `Error: ${message}`
          : 'Error submitting report, please try again',
      });
    }
  }
  const isUnread = doesCaseHaveUnreadMessage(c, currentUser!, true);

  return (
    <>
      <ReportComponent
        case={c}
        report={report}
        clinicEmail={c.user.email}
        clinicName={c.user.displayName}
        isAdmin
      >
        <ContentContainer>
          {c.status === RDBCase.CaseStatus.OPEN && (
            <>
              <div>
                <ExpandingButton
                  size="large"
                  onClick={() => setShowConfirmation(true)}
                  disabled={isSubmitting}
                >
                  SIGN OFF
                </ExpandingButton>
              </div>
              <div>
                <ExpandingButton
                  onClick={returnToCaseInfo}
                  emphasis="low"
                  disabled={isSubmitting}
                >
                  Save for later
                </ExpandingButton>
              </div>
            </>
          )}
          {c.status === RDBCase.CaseStatus.SIGNED_OFF && (
            <>
              <ExpandingButton size="large" onClick={downloadReport}>
                DOWNLOAD REPORT
              </ExpandingButton>
              {isDownloadingReport && <ModalLoader onClose={negate} />}
            </>
          )}
        </ContentContainer>
      </ReportComponent>

      <MessageProvider>
        <MessageFab unread={isUnread} />
      </MessageProvider>

      <SubmitReportConfirmationDialog
        isOpen={showConfirmation}
        callback={onConfirmSubmission}
      />
    </>
  );
};

const SubmitReportConfirmationDialog: React.FC<ConfirmationDialogProps> = (
  props
) => {
  return (
    <ConfirmationDialog {...props}>
      <Typography variant="body" fontSize="14px">
        Are you sure?
      </Typography>
    </ConfirmationDialog>
  );
};

export default withAuthRequired(withCase(AdminReport));
