import React from 'react';
import { StripeInvoice } from '../../api/cloudFunctions';
import Badge from '../common/Badge';
import { isInvoicePastDue } from '../../util/invoice';

interface InvoiceStatusBadgeProps {
  invoice: StripeInvoice;
}

const COLORS = {
  draft: {
    font: '#163349',
    background: '#c0b0e1',
  },
  // open: {
  //   font: 'var(--ion-color-warning-contrast)',
  //   background: 'var(--ion-color-warning-shade)',
  // },
  paid: {
    font: 'rgb(0, 105, 8)',
    background: 'rgb(215, 247, 194)',
  },
  pastDue: {
    font: 'rgb(179,9,60)',
    background: 'rgb(255, 231, 242)',
  },
  void: {
    font: 'rgb(84, 89, 105)',
    background: 'rgb(235, 238, 241)',
  },
};

const getTextForStatus = (status: string) => {
  switch (status) {
    case 'draft':
      return 'Pending';
    case 'paid':
      return 'Paid';
    case 'void':
      return 'Void';
    default:
      return null;
  }
};

const InvoiceStatusBadge: React.FC<InvoiceStatusBadgeProps> = ({ invoice }) => {
  const isPastDue = isInvoicePastDue(invoice);
  if (isPastDue) {
    return (
      <Badge
        fontColor={COLORS.pastDue.font}
        backgroundColor={COLORS.pastDue.background}
      >
        Past Due
      </Badge>
    );
  }

  return (
    <Badge
      fontColor={COLORS[invoice.status]?.font}
      backgroundColor={COLORS[invoice.status]?.background}
    >
      {getTextForStatus(invoice.status)}
    </Badge>
  );
};

export default InvoiceStatusBadge;
