import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from './Logo';

interface ToolbarLogoProps {}

const StyledLogo = styled(Logo)`
  max-height: 48px;
`;

const ToolbarLogo: React.FC<ToolbarLogoProps> = (props) => {
  return (
    <Link to="/">
      <StyledLogo {...props} />
    </Link>
  );
};

export default ToolbarLogo;
