import { Sex } from '../services/buildfire/rdb/cases';

export enum SpayNeuter {
  SPAYED = 'Spayed', // female
  NEUTERED = 'Neutered', // male
  INTACT = 'Intact',
}

export const SpayNeuterLabel = {
  [Sex.FEMALE]: SpayNeuter.SPAYED,
  [Sex.MALE]: SpayNeuter.NEUTERED,
};
