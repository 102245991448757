import React from 'react';
import {
  updateFcmTimestamp,
  setFcmInfo,
  FCMData,
} from '../services/firebase/database';
import { LOADING_STATE } from '../util/loading';
import useRDBUserInfo from './useRDBUserInfo';
import * as A from 'fp-ts/Array';
import { useFirebase } from '../services/firebase';
import { getDatabase } from 'firebase/database';
import { getPlatforms } from '@ionic/core';

const REFRESH_DAYS = 60;
const REFRESH_MS = 1000 * 60 * 60 * 24 * REFRESH_DAYS;

function isOutdated(time: number) {
  return Date.now() - time > REFRESH_MS;
}

function useFCMTokenSync(fcmToken?: string, err?: string) {
  const { userInfo, currentUser, loadingState } = useRDBUserInfo();
  const { app, setCurrentFcmTokenId, isImpersonatingUser } = useFirebase();
  React.useEffect(() => {
    const isLoading = loadingState !== LOADING_STATE.LOADED;
    if (isLoading || !currentUser || !fcmToken || !app || isImpersonatingUser) {
      return;
    }

    console.log(`FCM Token, ${fcmToken}`);

    const database = getDatabase(app);

    const fcmMap = userInfo?.fcm || {};
    const fcmArr = Object.keys(fcmMap)
      .filter((key) => key !== 'token' && key !== 'timestamp')
      .map((key) => ({ id: key, fcm: { ...fcmMap[key] } }));

    // Only remove old fcm tokens if they corelate with this device
    const partitionFn = ({ fcm }: { id: string; fcm: FCMData }) =>
      !fcm.timestamp || (isOutdated(fcm.timestamp) && fcm.token === fcmToken);
    const { left: fcm, right: outDatedFcm } = A.partition(partitionFn)(fcmArr);

    // outDatedFcm.forEach((data) =>
    //   removeFcmInfo(database, currentUser.uid, data.id)
    // );

    const index = fcm.findIndex((data) => data.fcm.token === fcmToken);
    const isFound = index !== -1;
    if (isFound) {
      const { id } = fcm[index];
      updateFcmTimestamp(database, currentUser.uid, id);
      setCurrentFcmTokenId(id);
    } else {
      setFcmInfo(database, currentUser.uid, {
        timestamp: Date.now(),
        token: fcmToken,
        devices: getPlatforms(),
      }).then(({ id }) => {
        console.log(id);
        if (id) {
          setCurrentFcmTokenId(id);
        }
      });
    }

    // Check for outdated FCM tokens and revoke
  }, [userInfo, currentUser, fcmToken, loadingState, app, isImpersonatingUser]);
}

export default useFCMTokenSync;
