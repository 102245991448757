import { useRollbarPerson as _useRollbarPerson } from '@rollbar/react';
import { useFirebase } from '../services/firebase';

function useRollbarPerson() {
  const { currentUser } = useFirebase();
  _useRollbarPerson(
    currentUser
      ? {
          id: currentUser?.uid,
          username: currentUser.displayName || '',
          email: currentUser.email || '',
        }
      : (null as any)
  );
}

export default useRollbarPerson;
