import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import { JSX } from '@ionic/core/components';
import styled from 'styled-components';

type ActionItemProps = JSX.IonItem &
  React.HTMLAttributes<HTMLIonItemElement> & {
    LabelComponent?: React.ComponentType<JSX.IonLabel>;
    IconComponent?: React.ComponentType<JSX.IonLabel>;
    icon?: string;
    label: string | React.ReactNode;
  };

const Item = styled(IonItem)`
  //
`;

const Icon = styled(IonIcon)`
  margin-right: 12px;
`;

const ActionItem: React.FC<ActionItemProps> = ({
  IconComponent = Icon,
  LabelComponent = IonLabel,
  label,
  icon,
  ...props
}) => {
  return (
    <Item {...props}>
      {icon && <IconComponent icon={icon} />}
      <LabelComponent>{label}</LabelComponent>
    </Item>
  );
};

export default ActionItem;
