import React from 'react';
import IconButton from '../../mdc/IconButton';
import Menu, { MenuProps } from '../../mdc/menu/Menu';
import MenuAnchor from '../../mdc/menu/MenuAnchor';

interface ActionMenuProps {
  MenuProps?: Partial<MenuProps>;
  disabled?: boolean;
}

const ActionMenu: React.FC<ActionMenuProps> = ({
  children,
  disabled,
  MenuProps,
  ...props
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <MenuAnchor
      menu={
        <Menu isOpen={isOpen} onClose={() => setIsOpen(false)} {...MenuProps}>
          {children}
        </Menu>
      }
    >
      <IconButton
        disabled={disabled}
        icon="more_vert"
        onClick={() => setIsOpen((open) => !open)}
      />
    </MenuAnchor>
  );
};

export default ActionMenu;
