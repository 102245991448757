import React, { useMemo } from 'react';
import { PaymentElement, PaymentElementProps } from '@stripe/react-stripe-js';

export interface CreditCardInputProps extends PaymentElementProps {}

function useOptions(mergeOptions?: PaymentElementProps['options']) {
  return useMemo<PaymentElementProps['options']>(
    () => ({
      fields: {
        billingDetails: {
          name: 'auto',
        },
      },
      terms: {
        card: 'never',
      },
      ...mergeOptions,
      style: {
        //
      },
    }),
    []
  );
}

const CreditCardInput: React.FC<CreditCardInputProps> = ({
  options,
  ...props
}) => {
  const mergedOptions = useOptions();

  return <PaymentElement options={mergedOptions} {...props} />;
};

export default CreditCardInput;
