import React from 'react';
import styled from 'styled-components';
import { Case, Site } from '../../../services/buildfire/rdb/cases';
import Typography from '../../common/Typography';
import SiteListItem from './SiteListItem';

interface SitesListProps {
  case: Case;
  sites: Site[];
  userId: string;
  showTitle?: boolean;
}

const List = styled.ul`
  padding: 0;
  list-styled-type: none;
  // margin-bottom: 100px;
`;
const SitesTitle = styled(Typography)`
  margin: 8px 16px 4px 16px;
`;

const SitesList: React.FC<SitesListProps> = ({
  case: c,
  sites,
  userId,
  showTitle = true,
}) => {
  return (
    <>
      {showTitle && (
        <SitesTitle variant="secondary" fontSize="16px" fontWeight="600">
          Sites
        </SitesTitle>
      )}
      {/* <ListGroupHeader>Sites</ListGroupHeader> */}
      <List>
        {sites.map((s, i) => (
          <SiteListItem
            key={`${s.location}-${i}`}
            case={c}
            site={s}
            userId={userId}
            siteIndex={i}
            showButtons={false}
            onEdit={(s, e) => {
              // TODO
            }}
            onDelete={(s, e) => {
              e.stopPropagation();
              // onSiteRemoved(s);
            }}
          />
        ))}
      </List>
    </>
  );
};

export default SitesList;
