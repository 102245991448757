import React from 'react';
import { useIonToast } from '@ionic/react';
import useQueryParams from '../../../hooks/useQueryParams';
import {
  confirmPasswordReset,
  getAuth,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { useFirebase } from '../../../services/firebase';
import useFetch from '../../../hooks/useFetch';
import LoadingScreen from '../../../components/common/LoadingScreen';
import ResetPasswordForm from './ResetPasswordForm';
import Typography from '../../../components/common/Typography';
import styled from 'styled-components';
import AlertBox from '../../../components/common/AlertBox';
import { useHistory } from 'react-router-dom';
import ForgotPasswordForm from '../../login/ForgotPasswordForm';

interface ResetPasswordProps {}

const Text = styled.div`
  margin: 32px 0;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const { app } = useFirebase();
  const params = useQueryParams();
  const code = params.get('oobCode') || '';

  const { loadingStatus, data, error } = useFetch(() => {
    const auth = getAuth(app);
    return verifyPasswordResetCode(auth, code).catch((e) => {
      switch (e.code) {
        case 'auth/invalid-action-code':
        case 'auth/expired-action-code': {
          throw new Error(
            'Your request to reset your password has expired or the link has already been used.'
          );
        }
        default:
          throw e;
      }
    });
  });

  const [message, setMessage] = React.useState<{
    text: string;
    type: 'info' | 'error';
  }>();
  const [present] = useIonToast();
  const history = useHistory();

  return (
    <LoadingScreen
      loadingStatus={loadingStatus}
      error={message ? null : error}
      errorContent={
        <>
          {message && <AlertBox text={message.text} type={message.type} />}
          <ForgotPasswordForm
            onSuccess={() => {
              setMessage({
                text: 'Email successfully sent, please check your inbox',
                type: 'info',
              });
            }}
            onError={() => {
              setMessage({
                text: 'Error sending password reset email, please try again',
                type: 'error',
              });
            }}
          />
        </>
      }
    >
      {data && (
        <>
          <Text>
            <Typography lineHeight="1.5em" fontSize="18px" textAlign="center">
              Enter your new password for account <Bold>{data}</Bold>
            </Typography>
          </Text>

          {message && (
            <>
              <AlertBox text={message.text} type={message.type} />
            </>
          )}

          <ResetPasswordForm
            onSubmit={async (password) => {
              try {
                const auth = getAuth(app);
                await confirmPasswordReset(auth, code, password);

                present('Password successfully changed', 4000);
                history.push('/login');
              } catch (e: any) {
                console.error(e);
                setMessage({
                  text: e?.message || 'There was a problem, please try again.',
                  type: 'error',
                });
              }
            }}
          />
        </>
      )}
    </LoadingScreen>
  );
};

export default ResetPassword;
