import React from 'react';
import styled from 'styled-components';

export interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  variant?: 'primary' | 'secondary' | 'body' | 'error' | 'body2';
  background?: 'light' | 'dark';
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'auto' | 'inherit';
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  fontStyle?: string;
  uppercase?: boolean;
  as?: React.ComponentType<any> | string;
  margin?: string;
  opacity?: number;
  color?: 'inherit' | string;
  letterSpacing?: string;
}

const Copy = styled.p<TypographyProps>`
  ${(props) => `
    color: ${props.color || `var(--mdc-theme-text-${props.variant}-on-light)`};

    ${props.textAlign ? `text-align: ${props.textAlign};` : ''}
    ${props.fontSize ? `font-size: ${props.fontSize};` : ''}
    ${props.fontWeight ? `font-weight: ${props.fontWeight};` : ''}
    ${props.fontStyle ? `font-style: ${props.fontStyle};` : ''}
    ${props.lineHeight ? `line-height: ${props.lineHeight};` : ''}
    ${props.margin ? `margin: ${props.margin};` : ''}
    ${props.opacity ? `opacity: ${props.opacity};` : ''}
    ${props.uppercase ? `text-transform: uppercase;` : ''}
    ${props.letterSpacing ? `letter-spacing: ${props.letterSpacing};` : ''}

    ${
      props.variant === 'body'
        ? `color: var(--mdc-theme-text-primary-on-${props.background});`
        : ''
    }
    ${
      props.variant === 'body2'
        ? `color: var(--mdc-theme-text-primary-on-background);`
        : ''
    }
    ${props.variant === 'primary' ? 'color: var(--mdc-theme-primary);' : ''}
    ${props.variant === 'secondary' ? 'color: var(--mdc-theme-secondary);' : ''}
    ${props.variant === 'error' ? 'color: var(--mdc-theme-error);' : ''}
  `}
`;

const Typography: React.FC<TypographyProps> = ({
  variant = 'body',
  background = 'light',
  margin = '0',
  ...props
}) => {
  return (
    <Copy
      variant={variant}
      background={background}
      margin={margin}
      {...(props as any)}
    />
  );
};

export default Typography;
