import { IonContent } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import Page from './Page';
import PageFooter from './PageFooter';
import PageHeading, { PageHeadingProps } from './PageHeading';

type MarketingPageProps = React.HTMLAttributes<HTMLDivElement> &
  PageHeadingProps & {};

const PageContent = styled.div`
  padding: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MarketingPage: React.FC<MarketingPageProps> = ({
  children,
  pageTitle,
  pageSubtitle,
  pageHeadingBackground,
  backgroundFilter,
  backgroundScale,
  ...props
}) => {
  return (
    <>
      <Page alignItems="unset" justifyContent="unset" {...props}>
        <IonContent>
          {pageHeadingBackground && (
            <PageHeading
              pageTitle={pageTitle}
              pageSubtitle={pageSubtitle}
              pageHeadingBackground={pageHeadingBackground}
              backgroundFilter={backgroundFilter}
              backgroundScale={backgroundScale}
            />
          )}
          <PageContent>{children}</PageContent>
        </IonContent>
        <PageFooter />
      </Page>
    </>
  );
};

export default MarketingPage;
