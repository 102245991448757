// Copied from quill-image-uploader/src/quill.imageUploader.js:
import Quill, { RangeStatic, DeltaStatic } from 'quill';
import { documentOutline as downloadIcon } from 'ionicons/icons';

import LoadingImage from 'quill-image-uploader/src/blots/image';
import { ioniconContent, getSvgContent } from './util';

import 'quill-image-uploader/src/quill.imageUploader.css';

const BlockEmbed = Quill.import('blots/block/embed');

class File extends BlockEmbed {
  static create(value) {
    const { name, url, size } = value;
    let node = super.create();
    node.setAttribute('data-url', url);
    node.setAttribute('data-name', name);
    node.contentEditable = 'false';

    const svgWrapper = document.createElement('span');
    svgWrapper.className = 'quill-file_svg-wrapper ionicon';

    getSvgContent(downloadIcon, false).then(() => {
      svgWrapper.innerHTML = ioniconContent.get(downloadIcon);
    });

    const fileName = document.createElement('span');
    fileName.className = 'quill-file_name';
    fileName.innerHTML = name;

    const fileSize = document.createElement('span');
    fileSize.className = 'quill-file_size';
    fileSize.innerHTML = size;

    const textWrapper = document.createElement('span');
    textWrapper.className = 'quill-file_text-wrapper';
    textWrapper.appendChild(fileName);
    textWrapper.appendChild(fileSize);

    const anchor = document.createElement('a');
    anchor.className = 'quill-file_anchor';
    anchor.href = url;
    anchor.target = '_blank';
    anchor.appendChild(svgWrapper);
    anchor.appendChild(textWrapper);

    node.appendChild(anchor);

    return node;
  }

  static value(domNode) {
    return {
      name: domNode.getAttribute('data-name'),
      url: domNode.getAttribute('data-url'),
    };
  }
}

File.blotName = 'file';
File.className = 'quill-file';
File.tagName = 'span';

Quill.register({ 'formats/file': File });

export interface ImageUploadResult {
  name: string;
  url: string;
  type: string;
  size: string;
}

interface ImageUploaderOptions {
  upload: (file: File) => Promise<ImageUploadResult>;
}

const IMAGE_MIME_REGEX = /^image\/(jpe?g|gif|png|svg|webp)$/i;

class ImageUploader {
  private quill: Quill;
  private options: ImageUploaderOptions;
  private range: RangeStatic | null;
  private placeholderDelta: DeltaStatic | null;
  private fileHolder: HTMLInputElement | undefined;

  constructor(quill: Quill, options: ImageUploaderOptions) {
    this.quill = quill;
    this.options = options;
    this.range = null;
    this.placeholderDelta = null;

    if (typeof this.options.upload !== 'function')
      console.warn(
        '[Missing config] upload function that returns a promise is required'
      );

    const toolbar = this.quill.getModule('toolbar');
    if (toolbar) {
      toolbar.addHandler('image', this.selectLocalImage.bind(this));
    }

    this.handleDrop = this.handleDrop.bind(this);
    this.handlePaste = this.handlePaste.bind(this);

    this.quill.root.addEventListener('drop', this.handleDrop, false);
    this.quill.root.addEventListener('paste', this.handlePaste, false);
  }

  selectLocalImage() {
    this.quill.focus();
    this.range = this.quill.getSelection() || { index: 0, length: 0 };
    this.fileHolder = document.createElement('input');
    this.fileHolder.setAttribute('type', 'file');
    this.fileHolder.setAttribute('accept', 'image/*,.pdf');
    this.fileHolder.setAttribute('style', 'visibility:hidden');

    this.fileHolder.onchange = this.fileChanged.bind(this);

    document.body.appendChild(this.fileHolder);

    this.fileHolder.click();

    window.requestAnimationFrame(() => {
      document.body.removeChild(this.fileHolder as any);
    });
  }

  handleDrop(evt) {
    if (
      evt.dataTransfer &&
      evt.dataTransfer.files &&
      evt.dataTransfer.files.length
    ) {
      evt.stopPropagation();
      evt.preventDefault();
      if (document.caretRangeFromPoint) {
        const selection = document.getSelection();
        const range = document.caretRangeFromPoint(evt.clientX, evt.clientY);
        if (selection && range) {
          selection.setBaseAndExtent(
            range.startContainer,
            range.startOffset,
            range.startContainer,
            range.startOffset
          );
        }
      } else {
        const selection = document.getSelection();
        const range = (document as any).caretPositionFromPoint(
          evt.clientX,
          evt.clientY
        );
        if (selection && range) {
          selection.setBaseAndExtent(
            range.offsetNode,
            range.offset,
            range.offsetNode,
            range.offset
          );
        }
      }

      this.quill.focus();
      this.range = this.quill.getSelection();
      let file = evt.dataTransfer.files[0];

      setTimeout(() => {
        this.quill.focus();
        this.range = this.quill.getSelection();
        this.readAndUploadFile(file);
      }, 0);
    }
  }

  handlePaste(evt) {
    let clipboard = evt.clipboardData || (window as any).clipboardData;

    // IE 11 is .files other browsers are .items
    if (clipboard && (clipboard.items || clipboard.files)) {
      let items = clipboard.items || clipboard.files;

      for (let i = 0; i < items.length; i++) {
        if (IMAGE_MIME_REGEX.test(items[i].type)) {
          let file = items[i].getAsFile ? items[i].getAsFile() : items[i];

          if (file) {
            this.quill.focus();
            this.range = this.quill.getSelection();
            evt.preventDefault();
            setTimeout(() => {
              this.quill.focus();
              this.range = this.quill.getSelection();
              this.readAndUploadFile(file);
            }, 0);
          }
        }
      }
    }
  }

  readAndUploadFile(file) {
    let isUploadReject = false;

    const fileReader = new FileReader();

    fileReader.addEventListener(
      'load',
      () => {
        if (!isUploadReject) {
          let base64ImageSrc = fileReader.result;
          this.insertBase64Image(base64ImageSrc);
        }
      },
      false
    );

    if (file) {
      fileReader.readAsDataURL(file);
    }

    this.options.upload(file).then(
      (result) => {
        this.insertToEditor(result);
      },
      (error) => {
        isUploadReject = true;
        this.removeBase64Image();
        console.warn(error);
      }
    );
  }

  fileChanged() {
    const file = this.fileHolder!.files![0];
    this.readAndUploadFile(file);
  }

  insertBase64Image(url) {
    const range = this.range!;

    this.placeholderDelta = this.quill.insertEmbed(
      range.index,
      LoadingImage.blotName,
      `${url}`,
      'user'
    );
  }

  insertToEditor(result: ImageUploadResult) {
    const range = this.range!;

    const lengthToDelete = this.calculatePlaceholderInsertLength();

    // Delete the placeholder image
    this.quill.deleteText(range.index, lengthToDelete, 'user');
    // Insert the server saved image
    if (IMAGE_MIME_REGEX.test(result.type)) {
      this.quill.insertEmbed(range.index, 'image', result.url, 'user');
    } else {
      this.quill.insertEmbed(range.index, 'file', result, 'user');
    }

    range.index++;
    this.quill.setSelection(range, 'user');
  }

  // The length of the insert delta from insertBase64Image can vary depending on what part of the line the insert occurs
  calculatePlaceholderInsertLength() {
    return this.placeholderDelta!.ops!.reduce((accumulator, deltaOperation) => {
      if (deltaOperation.hasOwnProperty('insert')) accumulator++;

      return accumulator;
    }, 0);
  }

  removeBase64Image() {
    const range = this.range!;
    const lengthToDelete = this.calculatePlaceholderInsertLength();

    this.quill.deleteText(range.index, lengthToDelete, 'user');
  }
}

export default ImageUploader;
