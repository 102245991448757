import React from 'react';
import styled from 'styled-components';
import pattern from '../images/patterns/pattern-15.png';
import drKateImg from '../images/dr-kate-baker.jpg';
import ElevatedContainer from '../components/common/ElevatedContainer';
import Typography from '../components/common/Typography';
import LinkButton from '../components/common/LinkButton';
import Hr from '../components/common/Hr';
import { GradientBackground } from '../components/common/Gradient';
import Br from '../components/common/Br';
import MarketingPage from '../components/page/MarketingPage';
import TrackView from '../components/analytics/TrackView';
import { VIEW_EVENTS } from '../services/buildfire/analytics/events';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import Toolbar from '../components/common/Toolbar';
import AlertBanner from '../components/common/AlertBanner';

interface AboutProps {}

const InfoContainer = styled.div`
  padding: 0 8px;
  text-align: center;
`;

const DrKateContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DrKate = styled.div<{ backgroundImageUrl: string }>`
  background-image: url('${(props) => props.backgroundImageUrl}');

  border-radius: 8px;
  margin-bottom: 16px;

  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100% auto;
  height: 260px;
  width: 100%;

  @media (min-width: 481px) {
    height: 400px;
  }
  @media (min-width: 721px) {
    width: 720px;
  }
`;

const StyledGradientBackground = styled(GradientBackground)`
  justify-content: flex-end;
  border-radius: 8px;
`;

const Header: React.FC<{}> = ({ children }) => (
  <Typography
    variant="secondary"
    as="h2"
    fontSize="16px"
    textAlign="left"
    fontWeight="bold"
    margin="0 0 12px 0"
  >
    {children}
  </Typography>
);
const Subheader: React.FC<{}> = ({ children }) => (
  <Typography
    variant="secondary"
    as="h4"
    fontSize="14px"
    textAlign="left"
    fontWeight="bold"
    margin="4px 0"
  >
    {children}
  </Typography>
);
const Body: React.FC<{}> = ({ children }) => (
  <Typography
    textAlign="left"
    fontSize="14px"
    lineHeight="1.4em"
    margin="0 0 16px 0"
    as="p"
  >
    {children}
  </Typography>
);

const About: React.FC<AboutProps> = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <Toolbar showBackButton showMenu />
        <AlertBanner />
      </IonHeader>
      <IonContent>
        <TrackView name={VIEW_EVENTS.ABOUT}>
          <MarketingPage
            pageTitle={
              <>
                About Pocket
                <Br maxWidth={468} /> Pathologist
              </>
            }
            pageHeadingBackground={pattern}
          >
            <ElevatedContainer maxWidth="600px">
              <InfoContainer>
                <Header>What is Pocket Pathologist?</Header>
                <Body>
                  Pocket Pathologist provides efficient and affordable
                  telecytology consultations through smartphone digital image
                  capture. Smartphone microscopic photos are taken in-house and
                  sent to our experienced and friendly board-certified clinical
                  pathologists via a convenient app. We are dedicated to helping
                  veterinarians make quick and confident clinical decisions by
                  serving as your expert second set of eyes.
                </Body>

                <LinkButton to="/faq" variant="primary" fullWidth>
                  Tips for getting started
                </LinkButton>
              </InfoContainer>

              <Hr />

              <InfoContainer>
                <Header>What matters to us</Header>
                <Subheader>You and your team.</Subheader>
                <Body>
                  We want you to feel supported. In order to feel confident in
                  your clinical decisions, you need to feel confident in what
                  you’re seeing under your microscope. Think of our pathologists
                  as an extension of your team, providing expert diagnostic
                  backup so you can better help your patients.
                </Body>

                <Subheader>Remaining cost-effective</Subheader>
                <Body>
                  Static image telecytology (the fancy term for microscopic
                  images taken with a camera) does not require expensive or
                  space-occupying equipment which allows it to remain affordable
                  and convenient. A wealth of information can be obtained
                  quickly by simply using your smartphone camera and our free
                  app to consult with an experienced pathologist virtually.
                </Body>

                <Subheader>
                  Clear, practical, and friendly communication
                </Subheader>
                <Body>
                  Pathology reports can be overwhelming to read (and translate
                  to the pet owner!). This is why we are committed to clear,
                  practical, and open communication with you. All of our
                  pathologists have spent time in clinical practice, so we know
                  what you are going through in the trenches. Our #1 goal is to
                  help you confidently reach your next steps.
                </Body>

                <Subheader>Speed</Subheader>
                <Body>
                  Whether you have a patient that needs immediate treatment or
                  an anxious owner who needs immediate information, you need
                  diagnostic results quickly. The very nature of telecytology
                  cuts off hours to days of waiting time and allows you to
                  proceed with patient care in a timely manner.
                </Body>

                <Subheader>Accuracy</Subheader>
                <Body>
                  Static image telecytology has been validated in both human and
                  veterinary medicine as an acceptable and efficient method of
                  obtaining expert interpretation on clinical cases. In cases
                  where the images provided are not suitable for review, we will
                  share our best tips for obtaining quality samples and
                  representative images. If additional diagnostics are needed
                  for a more confident diagnosis (histopathology, culture, PCR,
                  etc), we will communicate those that we recommend.
                </Body>
              </InfoContainer>

              <Hr />

              <DrKateContainer>
                <DrKate backgroundImageUrl={drKateImg}>
                  <StyledGradientBackground>
                    <Typography
                      fontSize="24px"
                      textAlign="justify"
                      background="dark"
                    >
                      Hi, I'm Kate Baker
                      <br />
                      DVM, MS, DACVP!
                    </Typography>
                  </StyledGradientBackground>
                </DrKate>
              </DrKateContainer>

              <Body>
                It’s nice to virtually meet you! I am a board-certified
                veterinary clinical pathologist and the Founder of Pocket
                Pathologist.
              </Body>

              <Body>
                You may know me from my social media channels (
                <a href="https://www.facebook.com/groups/VetCytoCoffeehouse">
                  Veterinary Cytology Coffeehouse
                </a>{' '}
                on Facebook,{' '}
                <a href="https://www.instagram.com/clinpathkate/">
                  @clinpathkate
                </a>{' '}
                on Instagram) where I teach veterinary professionals about the
                wonderful world of cytology.
              </Body>

              <Body>
                After spending a lot of time with veterinarians in these virtual
                spaces over the past 3 years, I began to see that veterinarians
                are facing a conundrum in practice. You need help reading
                in-house cytology!
              </Body>

              <Body>
                Whether it be because of limited owner finances, your clinic’s
                remote location, or cases that demand quick results,
                veterinarians need a way to quickly and cost-effectively obtain
                a pathologist’s opinion on cytologic samples without extra
                equipment or long turnaround times.
              </Body>

              <Body>And thus, Pocket Pathologist was born.</Body>

              <Body>
                After reviewing thousands of cytology photos online, it has
                become clear just how much information can be obtained through
                static image telecytology (the fancy words for taking photos
                with your smartphone and sending them to a pathologist for
                remote review). By utilizing this simple form of telecytology,
                the practitioner and the pathologist form a powerful team,
                leading to improved patient outcomes and happier clients.
              </Body>

              <Body>
                This service exists in an effort to support you. We want you to
                feel more confident in your clinical decisions by utilizing us
                as your second set of eyes at your microscope. Thank you for
                trusting us to help you help your patients. We promise to never
                take that trust for granted and are excited to be a part of your
                team!
              </Body>
            </ElevatedContainer>
          </MarketingPage>
        </TrackView>
      </IonContent>
    </IonPage>
  );
};

export default About;
