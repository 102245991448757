import { registerPlugin } from '@capacitor/core';

export interface PhoneAuthProviderPlugin {
  verify(options: { phoneNumber: string }): Promise<{ verificationId: string }>;
}

const PhoneAuthProvider =
  registerPlugin<PhoneAuthProviderPlugin>('PhoneAuthProvider');

export default PhoneAuthProvider;
