import React from 'react';
import styled from 'styled-components';
import MDCProgressBar, {
  ProgressBarProps as MDCProgressBarProps,
} from '../../mdc/ProgressBar';

export interface ProgressBarProps extends MDCProgressBarProps {
  progressBarType?: 'primary' | 'secondary';
}

interface StyledProps {
  $progressBarType: ProgressBarProps['progressBarType'];
}

const StyledProgressBar = styled(MDCProgressBar)<
  ProgressBarProps & StyledProps
>`
  &.mdc-linear-progress {
    .mdc-linear-progress__bar .mdc-linear-progress__bar-inner {
      ${(props) => `
        border-color: var(--mdc-theme-${props.$progressBarType});
      `}
    }
  }
`;

const ProgressBar: React.FC<ProgressBarProps> = ({
  progressBarType = 'primary',
  ...props
}) => {
  return (
    <StyledProgressBar
      show={true}
      $progressBarType={progressBarType}
      {...props}
    />
  );
};

export default ProgressBar;
