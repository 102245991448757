import React from 'react';
import styled from 'styled-components';
import PriceHeading, { PriceHeadingProps } from './PriceHeading';
import LinkButton from '../../components/common/LinkButton';

type PricingItemProps = React.HTMLAttributes<HTMLElement> &
  PriceHeadingProps & {
    description: string | React.ReactNode;
    showSubmitCase?: boolean;
  };

const Container = styled.div`
  padding: 0 16px;
`;

const DescriptionContainer = styled.div`
  margin: 16px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PricingItem: React.FC<PricingItemProps> = ({
  title,
  description,
  price,
  iconBackground,
  showSubmitCase = true,
  ...props
}) => {
  return (
    <Container {...props}>
      <PriceHeading
        price={price}
        title={title}
        iconBackground={iconBackground}
      />
      <DescriptionContainer>{description}</DescriptionContainer>
      {showSubmitCase && (
        <ButtonContainer>
          <LinkButton to="/cases/create">Submit Case</LinkButton>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default PricingItem;
