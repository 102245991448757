import React from 'react';
import styled from 'styled-components';
import MDCTab, { TabProps as MDCTabProps } from '../../mdc/tab/Tab';

interface TabProps extends MDCTabProps {
  showNotif?: boolean;
}

const StyledTab = styled<React.FC<MDCTabProps & { $showNotif?: boolean }>>(
  MDCTab
)`
  ${(props) => `
    ${
      props.$showNotif
        ? `
      .mdc-tab__content:after {
        content: '•';
        display: inline-block;
        margin-left: 6px;
        font-size: 32px;
        color: var(--mdc-theme-${props.variant || 'primary'});
      }
    `
        : ''
    }
  `}
`;

const Tab: React.FC<TabProps> = ({ showNotif, ...props }) => {
  return <StyledTab $showNotif={showNotif} {...props} />;
};

export default Tab;
