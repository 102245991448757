import React, { useContext, useState } from 'react';
import { useFirebase } from '../services/firebase';
import { LoginState } from '../services/firebase/FirebaseContext';
import { useHistory } from 'react-router-dom';
import { isDesktop } from '../util/device';
import useFCMTokenSync from './useFCMTokenSync';
import { useBuildfireSettings } from '../context/SettingsContext';
import { useIonToast } from '@ionic/react';
import { NotifContext } from '../context/NotifContext';
import { getMessaging, getToken } from 'firebase/messaging';

export function useWebNotifications() {
  const { currentUser, loginState, app } = useFirebase();
  const { settings } = useBuildfireSettings();
  const history = useHistory();
  const { notifState } = useContext(NotifContext);
  // const [present, dismiss] = useIonModal(NewMessageModal, {
  //   onClose: () => dismiss(),
  // });
  const [present, dismiss] = useIonToast();
  const [tokenResult, setTokenResult] = useState<{
    token?: string;
    err?: string;
  }>();
  useFCMTokenSync(tokenResult?.token, tokenResult?.err);

  const isWeb = isDesktop();
  const areWebNotifsEnabled =
    !!settings.firebase.fcmVapidKey &&
    (!isWeb || loginState !== LoginState.LOGGED_IN);

  React.useEffect(() => {
    if (areWebNotifsEnabled) {
      return;
    }

    try {
      const messaging = getMessaging(app);
      getToken(messaging, { vapidKey: settings.firebase.fcmVapidKey })
        .then((token) => {
          console.log('==== GET TOKEN ====');
          console.log(token);
          if (token) {
            setTokenResult({ token });
            // Can show notifs
          } else {
            setTokenResult({ err: 'TODO' });
          }
        })
        .catch((e) => {
          console.error('#getToken DENIED');
          console.error(e);
          setTokenResult({ err: 'DENIED' });
        });
    } catch (e) {
      console.error(e);
      setTokenResult({ err: 'DENIED' });
    }
    return () => {
      setTokenResult(undefined);
    };
  }, [areWebNotifsEnabled, app]);
}
