import React from 'react';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import styled from 'styled-components';
import Toolbar from '../../components/common/Toolbar';
import Logo from '../../components/common/Logo';
import LoadingCover from '../../components/common/LoadingCover';

interface LoginScreenProps {
  title?: string;
  isLoading?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px 0 42px 0;
  padding: 0 16px;
`;

const LogoContainer = styled.div`
  max-width: 400px;
`;

const ContentContainer = styled.div`
  max-width: 100%;
  width: 400px;
  margin: 48px 0;
`;

const LoginScreen: React.FC<LoginScreenProps> = ({
  title,
  isLoading,
  children,
}) => {
  return (
    <IonPage>
      <IonHeader>
        <Toolbar showBackButton title={title} />
      </IonHeader>
      <IonContent>
        {isLoading && <LoadingCover show={isLoading} />}
        <Container>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <ContentContainer>{children}</ContentContainer>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default LoginScreen;
