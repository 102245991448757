import React from 'react';
import { useParams } from 'react-router';
import { Case, useCase } from '../services/buildfire/rdb/cases';
import { LOADING_STATE } from './loading';

function withCase<T extends { case: Case } = { case: Case }>(
  Component: React.ComponentType<T>
) {
  const WrappedComponent: React.FC<Omit<T, 'case'>> = (props) => {
    const { caseId } = useParams<{ caseId: string }>();
    const { case: c, err, loadingStatus } = useCase(caseId);
    if (loadingStatus === LOADING_STATE.LOADING) {
      return null;
    }
    if (!c || err) {
      const errMessage = typeof err === 'string' ? err : err?.message;
      throw new Error(errMessage || `Cannot find case ${caseId}`);
    }
    return <Component case={c} {...(props as any)} />;
  };
  return WrappedComponent;
}

export default withCase;
