import React from 'react';
import { UserMetadataWithId } from '../services/firebase/user';
import { useFirebase } from '../services/firebase';
import { Database, get, getDatabase, ref } from 'firebase/database';

export interface IAdminContext {
  adminMetadata: {
    [uid: string]: UserMetadataWithId;
  };
  updateAdminMetadata: (
    uid: string,
    metadata: Partial<UserMetadataWithId>
  ) => void;
}

export const AdminContext = React.createContext<IAdminContext>(
  undefined as any
);

interface AdminProviderProps {}

const getUserMetadata = (db: Database) => async (uid: string) => {
  const userRef = ref(db, `userMetadata/${uid}`);
  const userSnap = await get(userRef);
  return { uid, ...userSnap.val() } as UserMetadataWithId;
};

const AdminProvider: React.FC<AdminProviderProps> = ({
  children,
  ...props
}) => {
  const [adminMetadata, setAdminMetadata] = React.useState<
    IAdminContext['adminMetadata']
  >({});
  const { app } = useFirebase();

  React.useEffect(() => {
    (async function () {
      try {
        const db = getDatabase(app);
        const adminsRef = ref(db, 'admins');
        const snap = await get(adminsRef);
        if (!snap.exists()) {
          return;
        }

        const adminUids = Object.keys(snap.val());

        const getUserMetadataFn = getUserMetadata(db);
        const metadataArray = await Promise.all(
          adminUids.map(getUserMetadataFn)
        );
        const metadataMap = metadataArray.reduce((acc, metadata) => {
          acc[metadata.uid] = metadata;
          return acc;
        }, {});
        setAdminMetadata(metadataMap);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const updateAdminMetadata = React.useCallback(
    (uid: string, metadata: Partial<UserMetadataWithId>) => {
      setAdminMetadata((prev) => {
        return {
          ...prev,
          [uid]: {
            ...prev[uid],
            ...metadata,
          },
        };
      });
    },
    []
  );

  return (
    <AdminContext.Provider
      value={{
        adminMetadata,
        updateAdminMetadata,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export function useDialog() {
  return React.useContext(AdminContext);
}

export default AdminProvider;
