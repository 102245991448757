import { useRollbar } from '@rollbar/react';
import React from 'react';
import { IDialogContext, useDialog } from '../../context/DialogContext';

interface ErrorBoundaryProps {
  openDialog: IDialogContext['openDialog'];
  onLog: (e: any) => void;
}

interface State {
  hasError?: boolean;
}

class _ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }
  componentDidCatch(e: any) {
    console.error(e);
    this.props.onLog(e);
    this.props.openDialog(
      e?.message || e || 'There was a problem. Please try again'
    );
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}

const ErrorBoundary: React.FC<{}> = (props) => {
  const { openDialog } = useDialog();
  const rollbar = useRollbar();
  return (
    <_ErrorBoundary
      openDialog={openDialog}
      onLog={(e) => {
        rollbar.error(e);
      }}
      {...props}
    />
  );
};

export default ErrorBoundary;
