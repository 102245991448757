import React from 'react';
import { Field, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Form from '../common/Form';
import LabeledInput from '../../mdc/LabeledInput';
import { uppercaseFirstLetter } from '../../util/string';
import ProfilePictureUpload from './ProfilePictureUpload';
import styled from 'styled-components';
import { IonButton, IonCol, IonRouterLink, IonRow } from '@ionic/react';
import HelperText from '../../mdc/HelperText';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { User } from 'firebase/auth';
import EmailVerifiedCheckmark from '../user/EmailVerifiedCheckmark';

export interface Profile {
  email: string;
  phoneNumber?: string;
  displayName: string;
  photoFile?: File | null;
  signature?: string; // Admin only
}

export type UnsavedProfile = Omit<Profile, 'photoURL'> & {
  photoFile: File | null;
};

interface ProfileFormProps {
  // user: User;
  initialPhotoUrl?: string;
  userProfile: Profile;
  onSubmit: (
    profile: UnsavedProfile,
    helpers: FormikHelpers<UnsavedProfile>
  ) => any;
  user: User;
  isAdmin: boolean;
}

const StyledForm = styled(Form)`
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  padding: 0 24px;
`;
const Link = styled(IonRouterLink)`
  margin: 16px 0;
`;
const ProfilePictureWrapper = styled.div`
  margin-bottom: 16px;
`;
const CenteredRow = styled(IonRow)`
  justify-content: center;
`;
const ButtonRow = styled(IonRow)`
  margin-top: 8px;
  width: 100%;
`;
const FlexCol = styled(IonCol)`
  display: flex;
  justify-content: center;
`;
const StyledPhoneInput = styled(PhoneInput)`
  margin-left: -40px;
`;
const LinksContainer = styled.div`
  margin-top: 48px;
`;
const DangerLink = styled(IonRouterLink)`
  margin: 16px 0;
  color: var(--ion-color-danger);
`;

const ProfileForm: React.FC<ProfileFormProps> = ({
  userProfile,
  initialPhotoUrl,
  onSubmit,
  user,
  isAdmin,
}) => {
  const { emailVerified } = user;
  const nameLabel = isAdmin ? 'Name' : 'Clinic name';

  return (
    <Formik
      initialValues={
        {
          email: userProfile.email,
          photoFile: null,
          phoneNumber: userProfile.phoneNumber || '',
          displayName: userProfile.displayName || '',
          signature: userProfile.signature || '',
        } as UnsavedProfile
      }
      onSubmit={onSubmit}
      validateOnMount
      validationSchema={Yup.object({
        email: Yup.string().email().required(),
        photoFile: Yup.object().nullable(),
        phoneNumer: Yup.string().length(10),
        signature: isAdmin
          ? Yup.string().min(1).max(180).required()
          : Yup.string(),
        displayName: Yup.string()
          .min(3, `${nameLabel} is too short`)
          .max(120, `${nameLabel} is too long`)
          .required(`${nameLabel} is required`),
      })}
    >
      {({
        touched,
        values,
        errors,
        dirty,
        isSubmitting,
        isValid,
        setFieldTouched,
        setFieldValue,
      }) => (
        <StyledForm>
          <IonRow>
            <FlexCol>
              <ProfilePictureWrapper>
                <ProfilePictureUpload
                  photoURL={initialPhotoUrl || null}
                  name="photoURL"
                  onChange={(file) => {
                    setFieldValue('photoFile', file);
                    setFieldTouched('photoFile', true);
                  }}
                />
                {touched.photoFile && errors.photoFile && (
                  <HelperText>
                    {uppercaseFirstLetter(errors.photoFile)}
                  </HelperText>
                )}
              </ProfilePictureWrapper>
            </FlexCol>
          </IonRow>
          <IonRow>
            <IonCol>{emailVerified && <EmailVerifiedCheckmark />}</IonCol>
          </IonRow>
          {/* <IonRow>
            <IonCol> */}
          <Field
            name="displayName"
            as={LabeledInput}
            label={nameLabel}
            error={
              touched.displayName &&
              uppercaseFirstLetter(errors.displayName as string)
            }
          />
          {/* </IonCol>
          </IonRow> */}
          <IonRow>
            <IonCol>
              <Field
                name="email"
                as={LabeledInput}
                label="Email Address"
                error={
                  touched.email && uppercaseFirstLetter(errors.email as string)
                }
              />
            </IonCol>
          </IonRow>
          {isAdmin && (
            <Field
              name="signature"
              as={LabeledInput}
              label="Signature"
              error={
                touched.signature &&
                uppercaseFirstLetter(errors.signature as string)
              }
            />
          )}
          <IonRow>
            <IonCol>
              <StyledPhoneInput
                name="phoneNumber"
                type="tel"
                inputMode="tel"
                defaultCountry="US"
                label="Phone number"
                inputComponent={LabeledInput}
                value={values.phoneNumber}
                onChange={(phoneNumber) => {
                  const value = phoneNumber || '';
                  setFieldValue('phoneNumber', value);
                  if (!touched.phoneNumber) {
                    setFieldTouched('phoneNumber', true);
                  }
                }}
              />
            </IonCol>
          </IonRow>
          <ButtonRow>
            <IonCol>
              <IonButton
                type="submit"
                disabled={!isValid || isSubmitting}
                expand="block"
              >
                Save
              </IonButton>
            </IonCol>
          </ButtonRow>

          <LinksContainer>
            <CenteredRow>
              <Link routerLink="/update-password">Change Password</Link>
            </CenteredRow>

            <CenteredRow>
              <DangerLink routerLink="/delete-account">
                Delete Account
              </DangerLink>
            </CenteredRow>
          </LinksContainer>
        </StyledForm>
      )}
    </Formik>
  );
};

export default ProfileForm;
