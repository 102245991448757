import React from 'react';
import MarketingPage from '../components/page/MarketingPage';
import pattern from '../images/patterns/pattern-17.png';
import ElevatedContainer from '../components/common/ElevatedContainer';
import styled from 'styled-components';
import Typography from '../components/common/Typography';
import Hr from '../components/common/Hr';
import Br from '../components/common/Br';
import BREAKPOINTS from '../constants/breakpoints';
import Button from '../components/common/Button';
import TrackView from '../components/analytics/TrackView';
import { VIEW_EVENTS } from '../services/buildfire/analytics/events';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import Toolbar from '../components/common/Toolbar';
import AlertBanner from '../components/common/AlertBanner';

interface FAQProps {}

const VideoPlaceholder = styled.div`
  height: 150px;
  border-radius: 8px;
  background-color: #d8d8d8;
`;

const P = styled(Typography)`
  margin: 0;
  color: #111;
`;

const ButtonContainer = styled.div`
  margin: 8px 0;
  display: flex;
  justify-content: center;
`;

const FAQHeader: React.FC<{}> = (props) => (
  <P
    variant="body"
    fontSize="14px"
    fontWeight="bold"
    lineHeight="18px"
    {...props}
  />
);

const FAQAnswer: React.FC<{}> = (props) => (
  <P
    variant="body"
    fontSize="14px"
    lineHeight="14px"
    opacity={0.7}
    {...props}
  />
);

const FAQItem: React.FC<{ question: string; answer: string }> = ({
  question,
  answer,
}) => {
  return (
    <>
      <Hr />
      <FAQHeader>{question}</FAQHeader>
      <FAQAnswer>{answer}</FAQAnswer>
    </>
  );
};

const IS_CLAIM_ENABLED = false; // TODO this might be introduced again later

const FAQ: React.FC<FAQProps> = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <Toolbar showBackButton showMenu />
        <AlertBanner />
      </IonHeader>
      <IonContent>
        <TrackView name={VIEW_EVENTS.FAQ}>
          <MarketingPage
            pageTitle="Getting Started"
            pageSubtitle={
              <>
                Helpful tips and answers on how to
                <Br maxWidth={BREAKPOINTS.small} /> start the process
              </>
            }
            pageHeadingBackground={pattern}
          >
            {/* <VideoPlaceholder /> */}
            <Typography
              variant="secondary"
              as="h4"
              fontSize="16px"
              lineHeight="1.5em"
              textAlign="center"
              fontWeight="400"
              margin="8px 0 0 0"
            >
              Read this quick guide for getting started &mdash;{' '}
              <Br maxWidth={BREAKPOINTS.med} />
              it will help you submit great photos!
            </Typography>
            <ButtonContainer>
              <Button
                as="a"
                style={{ width: '200px' }}
                fullWidth={false}
                {...{
                  href: 'https://drive.google.com/file/d/1ndg9DBxg3E6uxqsAO5kqLDpPUNLIeqj3/view?usp=sharing',
                }}
              >
                VIEW GUIDE
              </Button>
            </ButtonContainer>

            {IS_CLAIM_ENABLED && (
              <ElevatedContainer maxWidth="600px">
                <Typography
                  variant="secondary"
                  fontSize="28px"
                  textAlign="center"
                  fontWeight="400"
                  as="h4"
                >
                  Claim your adapter!
                </Typography>
                <Hr />

                <Typography
                  variant="body"
                  as="p"
                  fontSize="14px"
                  margin="16px 0"
                  lineHeight="1.4em"
                >
                  After submitting your first case to Pocket Pathologist, you
                  are eligible to receive a free phone to microscope adapter!
                  This adapter is easy to use and will help you take excellent
                  microscope photos. Claiming your adapter is easy-- simply fill
                  out this{' '}
                  <a href="https://forms.gle/BivpdAmVSRMa4Qqw8">form</a>, and
                  you will receive your adapter via mail shortly after.
                </Typography>

                <Typography
                  variant="body"
                  as="p"
                  fontSize="14px"
                  margin="16px 0"
                  lineHeight="1.4em"
                >
                  If your clinic already owns an adapter, that is great! Be sure
                  to use it! If you need help operating your adapter, please
                  contact us at{' '}
                  <a href="mailto:info@pocketpathologist.com">
                    info@pocketpathologist.com
                  </a>{' '}
                  and we will do everything we can to assist you.
                </Typography>
                <ButtonContainer>
                  <Button
                    as="a"
                    style={{ width: '200px' }}
                    fullWidth={false}
                    {...{
                      href: 'https://forms.gle/BivpdAmVSRMa4Qqw8',
                    }}
                  >
                    CLAIM YOUR ADAPTER
                  </Button>
                </ButtonContainer>
              </ElevatedContainer>
            )}

            <ElevatedContainer maxWidth="600px">
              <Typography
                variant="secondary"
                fontSize="28px"
                textAlign="center"
                fontWeight="400"
                as="h4"
              >
                FAQ
              </Typography>
              <FAQItem
                question="Does this require a contract?"
                answer="Nope! You can use the service as much or as little as you want. No case minimums or limits."
              />
              <FAQItem
                question="What is the turnaround time?"
                answer="Guaranteed same day response if received before 4 PM CST."
              />
              <FAQItem
                question="When can I submit a case?"
                answer="Anytime! However, at this time cases will only be read during business hours (after-hours will be available at a later date). If a case is submitted outside of business hours, it will be completed the next business day."
              />
              {/* <FAQItem
          question="How do I pay?"
          answer="You can choose either end-of-month invoicing or to complete payment at the time of each consult. "
        /> */}
              <FAQItem
                question="Are there any cases you don't review?"
                answer="This service is limited to dogs, cats and equids. Bone marrow aspirates will not be assessed using this service."
              />
              <FAQItem
                question="What kinds of samples can I submit?"
                answer="Fine needle aspirates, impression smears, blood smears, and fluids (e.g. effusions, urine) are all accepted."
              />
              <FAQItem
                question="We have a camera attached to our microscope. Can I take photos with that instead of a smartphone?"
                answer="Absolutely! As long as the photos are not too large to transfer via the app, feel free to send them."
              />
              <FAQItem
                question="What if my sample is non-diagnostic?"
                answer="No worries, it happens! You can add more photos to your open case within 24 hours of initial case submission at no extra charge."
              />
            </ElevatedContainer>
          </MarketingPage>
        </TrackView>
      </IonContent>
    </IonPage>
  );
};

export default FAQ;
