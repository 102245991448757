import React from 'react';
import ForgotPasswordForm from './login/ForgotPasswordForm';
import LoginScreen from './login/LoginScreen';
import AlertBox from '../components/common/AlertBox';

interface ForgotPasswordProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const [message, setMessage] = React.useState<{
    text: string;
    type: string;
  }>();
  return (
    <LoginScreen title="Reset Password">
      {message && <AlertBox text={message.text} />}
      <ForgotPasswordForm
        onSuccess={() => {
          setMessage({
            text: 'Please check your email for a link to reset your password.',
            type: '',
          });
        }}
        onError={() => {
          setMessage({
            text: 'There was a problem, please try again.',
            type: '',
          });
        }}
      />
    </LoginScreen>
  );
};

export default ForgotPassword;
