import React from 'react';
import styled from 'styled-components';
import { CaseStatus } from '../../../services/buildfire/rdb/cases';
import ExpandingButton from '../../common/ExpandingButton';
import { useLinkButtonProps } from '../../common/LinkButton';
import CaseInfo, { CaseInfoProps } from './CaseInfo';
import CaseInfoHeader from '../CaseInfoHeader';
import { generatePath, useRouteMatch } from 'react-router-dom';

interface CustomerCaseInfoProps extends CaseInfoProps {}

const ReportContainer = styled.div`
  padding: 0 32px;
  text-align: center;
`;

const CustomerCaseInfo: React.FC<CustomerCaseInfoProps> = ({
  case: c,
  ...props
}) => {
  const linkButtonProps = useLinkButtonProps();
  const isBillingParentAccount =
    c.invoiceAccount && c.invoiceAccount.uid !== c.uid;
  const banner = isBillingParentAccount
    ? `Case billed to ${c.invoiceAccount!.displayName}`
    : null;
  const match = useRouteMatch();
  const reportPath = generatePath(`${match.path}/report`, {
    caseId: c.id,
    userId: c.uid,
  });
  return (
    <>
      <CaseInfoHeader
        case={c}
        actionItems={props.actionItems}
        isAdmin={false}
      />

      <CaseInfo case={c} bannerContent={banner} {...props}>
        {c.status === CaseStatus.SIGNED_OFF && (
          <ReportContainer>
            <ExpandingButton
              variant="primary"
              size="large"
              role={linkButtonProps.role}
              onClick={linkButtonProps.onClick(reportPath)}
            >
              View report
            </ExpandingButton>
          </ReportContainer>
        )}
      </CaseInfo>
    </>
  );
};

export default CustomerCaseInfo;
