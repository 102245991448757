import React from 'react';
import styled from 'styled-components';

interface ImagePlaceholderProps extends React.HTMLAttributes<HTMLElement> {}

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const Spacer = styled.div`
  // Fallback
  margin-top: 80%;
  margin-top: min(80%, 250px);
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e3e3e3;
  border-radius: 8px;

  animation-name: pulsate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  @keyframes pulsate {
    0% {
      background-color: #e3e3e3;
    }
    50% {
      background-color: #d3d3d3;
    }
    100% {
      background-color: #e3e3e3;
      // background-color: #c3c3c3;
    }
  }
`;

const ImagePlaceholder: React.FC<ImagePlaceholderProps> = (props) => {
  return (
    <Container {...props}>
      <Spacer />
      <Inner />
    </Container>
  );
};

export default ImagePlaceholder;
