import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { ListContext } from './List';

export interface ListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  preliminaryContent?: React.ReactNode;
  text: string | React.ReactNode;
  subtext?: string | React.ReactNode;
  buttons?: React.ReactNode;
  showButtonsOnHover?: boolean;
  clickable?: boolean;
  disabled?: boolean;
}

const Container = styled.li<{ clickable?: boolean; $disabled?: boolean }>`
  // padding: 0;
  display: flex;
  justify-content: space-between;
  // color: #333;
  height: auto;
  min-height: 64px;
  ${(props) => props.$disabled && 'opacity: 0.5;'}
`;

const TextContainer = styled.div`
  max-width: 100%;
  display: flex;
`;

const ButtonContainer = styled.div<{ $margin: string }>`
  flex-shrink: 0;
  ${(props) => `margin: ${props.$margin};`}
`;

const Text = styled.span``;

const PrimaryText = styled.span`
  color: #333;
  font-size: 14px;
`;

const SecondaryText = styled.span`
  font-size: 12px;
  // font-size: 10px;
  // line-height: 12px;
  // padding-right: 12px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
`;

const ListItem: React.FC<ListItemProps> = ({
  text,
  subtext,
  buttons,
  showButtonsOnHover,
  onClick,
  preliminaryContent,
  clickable = !!onClick,
  disabled,
  ...props
}) => {
  const { listStyle } = React.useContext(ListContext);
  const showButtons = !!buttons && showButtonsOnHover !== false;
  // TODO track hover state

  const textContent = (
    <Text className="mdc-list-item__text">
      {listStyle === 'one-line' ? (
        text
      ) : (
        <PrimaryText className="mdc-list-item__primary-text">
          {text}
        </PrimaryText>
      )}
      {subtext && (
        <SecondaryText className="mdc-list-item__secondary-text">
          {subtext}
        </SecondaryText>
      )}
    </Text>
  );

  return (
    <>
      <Container
        onClick={onClick}
        clickable={clickable}
        {...props}
        $disabled={disabled}
        className={classnames(
          {
            ['mdc-list-item']: true,
          },
          props.className
        )}
      >
        {preliminaryContent ? (
          <>
            <TextContainer>
              {preliminaryContent}
              {textContent}
            </TextContainer>
          </>
        ) : (
          textContent
        )}
        {showButtons && (
          <ButtonContainer $margin={buttons ? '0 0 0 16px' : ''}>
            {buttons}
          </ButtonContainer>
        )}
      </Container>
    </>
  );
};

export default ListItem;
