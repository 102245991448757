import { Database, ref, set } from 'firebase/database';

export const setNotifCount = (
  database: Database,
  uid: string,
  count: number
): Promise<number> => {
  const countRef = ref(database, `notif/${uid}/count`);
  return set(countRef, count).then(() => count);
};
