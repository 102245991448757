import React from 'react';
import Users from '../Users';
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import styled from 'styled-components';
import { personAdd } from 'ionicons/icons';
import InviteUserModal from '../../components/hospital/InviteUserModal';
import {
  UserMetadataWithId,
  useUserMetadata,
} from '../../services/firebase/user';
import { Redirect } from 'react-router-dom';
import { LOADING_STATE } from '../../util/loading';
import {
  SubaccountsResult,
  getSubaccounts,
  inviteUser,
  removeUserFromAccount,
} from '../../api/cloudFunctions';
import { HttpsCallableResult, getFunctions } from 'firebase/functions';
import { useFirebase } from '../../services/firebase';
import { FirebaseApp } from 'firebase/app';
import {
  getDatabase,
  onChildAdded,
  onChildRemoved,
  ref,
} from 'firebase/database';
import { User, getAdditionalUserInfo } from 'firebase/auth';
import { getUserInfo } from '../../services/firebase/database';
import useFetch, { FetchResult } from '../../hooks/useFetch';
import Typography from '../../components/common/Typography';
import { useDialog } from '../../context/DialogContext';

interface UsersProps {}

const FabButton = styled(IonFabButton)`
  margin-bottom: 8px;
`;

const InviteUsersButton: React.FC<{}> = ({}) => {
  return (
    <IonFab title="Invite User" vertical="bottom" horizontal="end">
      <FabButton id="invite-users">
        <IonIcon icon={personAdd} />
      </FabButton>
    </IonFab>
  );
};

function useSubaccounts(app: FirebaseApp, currentUser: User) {
  return useFetch(() => {
    const fns = getFunctions(app);
    return getSubaccounts(fns, { uid: currentUser.uid });
  });
}

const HospitalUsers: React.FC<UsersProps> = ({}) => {
  const { app, currentUser } = useFirebase();
  const [pendingUsers, setPendingUsers] = React.useState<
    Partial<UserMetadataWithId>[]
  >([]);
  const {
    loadingStatus,
    data: subaccounts,
    error,
    setData,
  } = useSubaccounts(app, currentUser!);
  const isLoading = loadingStatus === LOADING_STATE.LOADING;

  const onRemoveUser = async (userToRemove: Partial<UserMetadataWithId>) => {
    const fns = getFunctions(app);
    await removeUserFromAccount(fns, {
      uid: userToRemove.uid,
      email: userToRemove.email,
    });
    setPendingUsers((pendingUsers) =>
      pendingUsers.filter((u) => u.email !== userToRemove.email)
    );

    const filteredUsers =
      subaccounts?.data.users.filter((u) => u.email !== userToRemove.email) ||
      [];
    setData({ data: { users: filteredUsers } });
  };

  const users = subaccounts?.data.users || [];
  const allUsers = pendingUsers.concat(users);
  return (
    <Users
      isAdmin={false}
      users={allUsers}
      isLoading={isLoading}
      onRemoveUser={onRemoveUser as any}
      // Hospital users always shows full list of users
      disableInfiniteScroll
    >
      {error && (
        <Typography fontSize="16px" margin="16px">
          {'An error occurred. Please refresh and try again. '}
          Error:{' '}
          {(error as any)?.details?.message || error?.message || 'unknown'}
        </Typography>
      )}
      <InviteUserModal
        trigger="invite-users"
        onSubmit={async ({ email }) => {
          if (allUsers.find((u) => u.email === email)) {
            throw new Error(
              'A user with that email address already exists on your account'
            );
          }

          const fns = getFunctions(app);
          // TODO handle errors here
          const result = await inviteUser(fns, {
            inviteEmail: email.toLowerCase().trim(),
            parentName: currentUser!.displayName!,
            parentUid: currentUser!.uid!,
          });

          setPendingUsers((pendingUsers) =>
            [{ email }].concat(pendingUsers as any)
          );
        }}
      />
      <InviteUsersButton />
    </Users>
  );
};

export default HospitalUsers;
