import { User } from 'firebase/auth';
import { Database, getDatabase } from 'firebase/database';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Message } from '.';
import useCaseIdParam from '../../hooks/useCaseIdParam';
import CaseContext from '../../services/buildfire/rdb/CaseContext';
import {
  Case,
  LastMessageStatus,
  markConversationAs,
  useCase,
} from '../../services/buildfire/rdb/cases';
import {
  Message as IMessage,
  useMessages,
} from '../../services/buildfire/rdb/messages';
import { useFirebase } from '../../services/firebase';
import { LOADING_STATE } from '../../util/loading';
import Typography from '../common/Typography';
import { groupBy, orderBy } from 'lodash/fp';
import moment from 'moment';

export interface ConversationProps {
  case: Case;
  messages: IMessage[];
  currentUser: User;
  loadingStatus: LOADING_STATE;
}

const MessageList = styled.div`
  flex: 1 1 auto;
  padding: 8px;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
`;

const InputContainer = styled.div`
  padding: 8px;
`;

const MessageTabContentInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StartConverstion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;
  text-align: center;
`;

const DayLabel = styled(Typography)`
  text-align: center;
  margin: 16px 0;
  font-size: 14px;
`;

const MessagBundleContainer = styled.div<{ $incoming: boolean }>`
  margin-top: 16px;
`;

const MessageContainer = styled.div<{ $incoming: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.$incoming ? 'flex-start' : 'flex-end')};
`;

const Sender = styled(Typography)`
  font-size: 14px;
  margin-left: 12px;
`;

const groupByDay = groupBy((message: IMessage) =>
  moment(message.timestamp).startOf('day').format()
);

const bundleByUser = (messages: IMessage[]) => {
  let bundles: IMessage[][] = [];
  let currentBundle: IMessage[] = [];
  let lastMessage: IMessage | null = null;

  for (const message of messages) {
    if (lastMessage && lastMessage.uid !== message.uid) {
      bundles.push(currentBundle);
      currentBundle = [];
    }

    currentBundle.push(message);
    lastMessage = message;
  }

  if (currentBundle.length > 0) {
    bundles.push(currentBundle);
  }

  return bundles;
};

const sortByDateDesc = orderBy(
  (time: string) => {
    return moment(time).format('YYYYMMDD');
  },
  ['desc']
);

const MessageBundle: React.FC<{
  case: Case;
  currentUser: User;
  messages: IMessage[];
}> = ({ currentUser, messages, case: c }) => {
  const isCurrentUserAdmin = c.uid !== currentUser.uid;
  const [timeState, setTimeState] = React.useState({
    [messages[messages.length - 1].id]: true,
  });
  const isSelf = messages[0].uid === currentUser?.uid;
  const messageType =
    currentUser?.uid === messages[0].uid ? 'outgoing' : 'incoming';
  return (
    <MessagBundleContainer $incoming={messageType === 'incoming'}>
      {!isSelf && (
        <Sender>
          {messages[0].name ||
            (isCurrentUserAdmin ? 'Submitter' : 'Pathologist')}
        </Sender>
      )}

      {messages.map((message, i) => (
        <MessageContainer
          $incoming={messageType === 'incoming'}
          key={message.id}
          onClick={() =>
            setTimeState({ ...timeState, [message.id]: !timeState[message.id] })
          }
        >
          <Message
            message={message}
            messageType={messageType}
            showTime={timeState[message.id]}
            firstInBundle={i === 0}
            lastInBundle={i === messages.length - 1}
          />
        </MessageContainer>
      ))}
    </MessagBundleContainer>
  );
};

export const Conversation: React.FC<ConversationProps> = ({
  case: c,
  messages,
  loadingStatus,
  currentUser,
  ...props
}) => {
  const isLoading = loadingStatus === LOADING_STATE.LOADING;

  const messageGroups = groupByDay([...messages]);
  const sortedByDay = sortByDateDesc(Object.keys(messageGroups));

  return (
    <MessageTabContentInnerContainer className="MessageTabInnerContainer">
      {!isLoading && messages.length === 0 && (
        <StartConverstion>
          <Typography variant="primary" fontSize="24px">
            Have a question?
          </Typography>
          <Typography variant="body" fontSize="18px" margin="16px 0">
            Send us a message!
          </Typography>
        </StartConverstion>
      )}
      <MessageList>
        {sortedByDay.map((day) => {
          const messageBundles = bundleByUser(
            [...messageGroups[day]].reverse()
          );
          return (
            <React.Fragment key={day}>
              {messageBundles.map((bundle, i) => (
                <MessageBundle
                  key={i}
                  case={c}
                  currentUser={currentUser}
                  messages={bundle.reverse()}
                />
              ))}
              {/* Day label goes at the end here since the message list is reversed */}
              <DayLabel>{moment(day).format('dddd, MMM D')}</DayLabel>
            </React.Fragment>
          );
        })}
      </MessageList>
    </MessageTabContentInnerContainer>
  );
};

const updateLastMessageStatus = async (
  database: Database,
  c?: Case,
  userId?: string
) => {
  if (c && c.lastMessage?.uid !== userId && c.lastMessageStatus === 'unread') {
    await markConversationAs(database, c.id, c.uid, LastMessageStatus.READ);
  }
};

const Connected: React.FC<{}> = (props) => {
  const caseId = useCaseIdParam();
  const caseContext = useContext(CaseContext);
  const { case: c, loadingStatus } = useCase(caseId);
  const { currentUser, app } = useFirebase();
  const database = getDatabase(app);
  const { messages, status: messageLoadingStatus } = useMessages(caseId, {
    // When new messages come in while this screen is open, mark as read
    onMessageAdded: () => {
      // if (activeTab === 1) {
      updateLastMessageStatus(database, c, currentUser!.uid);
      // }
    },
  });

  React.useEffect(() => {
    if (c && c.lastMessageStatus === LastMessageStatus.UNREAD) {
      updateLastMessageStatus(database, c, currentUser!.uid);
      caseContext.updateCase({
        ...c!,
        lastMessageStatus: LastMessageStatus.READ,
      });
    }
  }, [c?.lastMessageStatus]);
  // Block message notifs while Conversation screen is open
  // useMessageNotifBlock(() => true);

  if (!c) {
    return null;
  }

  return (
    <Conversation
      case={c}
      loadingStatus={messageLoadingStatus}
      currentUser={currentUser!}
      messages={messages}
    />
  );
};

export default Connected;
