import { getDatabase } from 'firebase/database';
import React from 'react';
import { setAppVersion } from '../services/buildfire/rdb/user';
import { useFirebase } from '../services/firebase';
import { getDevice } from '../util/device';
import { getVersion } from '../util/app';

function useAppInfoLog() {
  const { app, currentUser } = useFirebase();
  React.useEffect(
    (async () => {
      const device = getDevice();
      const version = await getVersion();
      console.log(`UID=${currentUser?.uid}`);
      console.log(`DEVICE=${device}`);
      console.log(`VERSION=${version}`);

      if (!currentUser) {
        return;
      }

      try {
        const database = getDatabase(app);
        setAppVersion({
          database,
          userId: currentUser.uid,
          device,
          version,
        });
      } catch (e: any) {
        window.onerror?.(
          e?.message || 'Failed to record app version',
          window.location.href
        );
      }
    }) as any,
    [app, currentUser]
  );
}

export default useAppInfoLog;
