import {
  IonContent,
  IonHeader,
  IonModal,
  IonPage,
  useIonToast,
} from '@ionic/react';
import {
  getAuth,
  PhoneAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
  updatePhoneNumber,
  updateProfile,
  UserCredential,
} from 'firebase/auth';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import React from 'react';
import styled from 'styled-components';
import { PhoneAuthProvider as CustomPhoneAuthProvider } from '../capactior/plugins/firebase-auth';
import ProfileForm, {
  Profile,
  UnsavedProfile,
} from '../components/account/ProfileForm';
import AlertBanner from '../components/common/AlertBanner';
import ModalLoader from '../components/common/ModalLoader';
import Toolbar from '../components/common/Toolbar';
import useRecaptcha from '../hooks/useRecaptcha';
import { withAuthRequired } from '../services/buildfire/auth';
import { useFirebase } from '../services/firebase';
import { uploadProfilePicture } from '../services/firebase/storage';
import { isNotNativeApp } from '../util/device';
import PhoneSMSVerificationForm from './login/phone/PhoneSMSVerificationForm';
import EmailNotVerifiedWarning from '../components/user/EmailNotVerifiedWarning';
import ReauthEmailModal from '../components/modals/ReauthEmailModal';
import useIonicModalProps from '../hooks/useIonicModalProps';
import { LoginContext } from './Login';
import { LOADING_STATE } from '../util/loading';
import { setUserMetadata } from '../services/firebase/user';
import { getDatabase } from 'firebase/database';
import { AUTH_ERROR_MAP, isFirebaseError } from '../util/auth';

interface AccountProps {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px 0 42px 0;
  padding: 0 16px;
`;

const ContentContainer = styled.div`
  max-width: 100%;
  width: 400px;
  margin: 48px 0;
`;

const SCREEN = {
  ENTER_INFO: 'enter_info',
  VERIFY_PHONE: 'verify_phone',
};

const RECAPTCHA_ID = 'recaptcha-container';

const Account: React.FC<AccountProps> = (props) => {
  const { currentUser, app, triggerUpdate, isAdmin, userMetadata } =
    useFirebase();
  const [profile, setProfile] = React.useState<Profile | UnsavedProfile>({
    email: currentUser?.email || '',
    photoFile: null,
    phoneNumber: currentUser?.phoneNumber || '',
    displayName: currentUser?.displayName || '',
    // Pathologist only
    signature: userMetadata?.signature || '',
  });
  const [showReauthModal, setShowReauthModal] = React.useState(false);
  const [screen, setScreen] = React.useState(SCREEN.ENTER_INFO);
  const [present] = useIonToast();
  const [verificationId, setVerificationId] = React.useState<string>();
  const [isUploading, setIsUploading] = React.useState(false);
  const modalProps = useIonicModalProps();
  const recaptcha = useRecaptcha(RECAPTCHA_ID, {
    size: 'invisible',
    callback: () => console.log('reCAPTCHA verified'),
    expiredCallback: () => console.log('EXPIRED'),
  });
  const saveProfile = async (
    newValues: UnsavedProfile,
    userCredential?: UserCredential
  ) => {
    if (!currentUser) {
      console.error('Error: user not logged in');
      return;
    }

    const newEmail = newValues.email.trim();
    const isChangingEmail = newEmail !== currentUser.email;
    const requireAuth = isChangingEmail && !userCredential;

    if (requireAuth) {
      console.log('Showing reauth');
      setShowReauthModal(true);
      return;
    }

    if (isChangingEmail) {
      console.log('changing email');
      await updateEmail(currentUser, newValues.email);
      console.log('email updated');
    }

    let photoUrl = '';
    if (newValues.photoFile) {
      setIsUploading(true);
      const storage = getStorage(app);
      const profileImage = await uploadProfilePicture(
        storage,
        newValues.photoFile!,
        currentUser!.uid
      );
      const fileRef = ref(
        storage,
        `profile_pictures/${currentUser!.uid}/${profileImage}`
      );
      photoUrl = await getDownloadURL(fileRef);

      await updateProfile(currentUser, {
        photoURL: photoUrl,
        displayName: newValues.displayName,
      });
      setIsUploading(false);
    } else {
      await updateProfile(currentUser, {
        displayName: newValues.displayName,
      });
    }

    // User metadata
    await setUserMetadata(getDatabase(app), currentUser, {
      signature: newValues.signature,
      displayName: newValues.displayName,
      email: newEmail,
      photoUrl:
        photoUrl || userMetadata?.photoUrl || currentUser?.photoURL || '',
    });

    setProfile({
      email: newEmail || '',
      photoFile: null,
      phoneNumber: newValues.phoneNumber || '',
      displayName: newValues.displayName,
      signature: newValues.signature || '',
    });
    setVerificationId(undefined);

    try {
      (recaptcha.current as any)?.recaptcha.reset();
    } catch (e) {
      console.error(e);
      throw e;
    }
    triggerUpdate();

    present('Successfully updated profile', 4000);
  };

  const CONTENT = {
    [SCREEN.ENTER_INFO]: () => (
      <ProfileForm
        userProfile={profile}
        user={currentUser!}
        initialPhotoUrl={currentUser?.photoURL || ''}
        isAdmin={isAdmin}
        onSubmit={async (newProfile) => {
          setProfile(newProfile);

          try {
            const phoneNumber = newProfile.phoneNumber || '';
            const isChangingPhoneNumber =
              (Boolean(phoneNumber) || Boolean(currentUser?.phoneNumber)) &&
              phoneNumber !== currentUser?.phoneNumber;
            if (!isChangingPhoneNumber) {
              await saveProfile(newProfile);
              return;
            }

            if (isNotNativeApp()) {
              const auth = getAuth(app);
              const provider = new PhoneAuthProvider(auth);

              const verificationId = await provider.verifyPhoneNumber(
                phoneNumber,
                recaptcha.current!
              );
              setVerificationId(verificationId);
            } else {
              const result = await CustomPhoneAuthProvider.verify({
                phoneNumber: phoneNumber,
              });
              setVerificationId(result.verificationId);
            }

            setScreen(SCREEN.VERIFY_PHONE);
          } catch (e) {
            setIsUploading(false);
            console.error(e);
            present(
              (e as any).message || 'Error saving profile, please try again',
              6000
            );
          }
        }}
      />
    ),
    [SCREEN.VERIFY_PHONE]: () => (
      <PhoneSMSVerificationForm
        verificationId={verificationId!}
        onSubmit={async ({ authCredential }) => {
          try {
            await updatePhoneNumber(currentUser!, authCredential);
            await saveProfile(profile as any);
            setScreen(SCREEN.ENTER_INFO);
          } catch (e) {
            console.error(e);
            present(
              (e as any).message || 'Error saving profile, please try again',
              6000
            );
          }
        }}
        goBack={() => setScreen(SCREEN.ENTER_INFO)}
      />
    ),
  };

  return (
    <IonPage>
      <IonHeader>
        <Toolbar showBackButton title="Account" />
        <AlertBanner />
      </IonHeader>
      <IonContent>
        <Container>
          {currentUser && !currentUser.emailVerified && (
            <EmailNotVerifiedWarning user={currentUser} />
          )}
          <ContentContainer>{CONTENT[screen]?.()}</ContentContainer>
        </Container>
      </IonContent>
      <div id={RECAPTCHA_ID} />

      {isUploading && <ModalLoader onClose={() => {}} />}

      <IonModal
        isOpen={showReauthModal}
        {...modalProps}
        onDidDismiss={() => {
          setShowReauthModal(false);
        }}
      >
        <LoginContext.Provider
          value={{
            redirectResult: { loadingState: LOADING_STATE.IDLE },
            addRedirectListener: () => {},
          }}
        >
          <ReauthEmailModal
            user={currentUser!}
            onClose={() => {
              setShowReauthModal(false);
              modalProps.onClose();
            }}
            onReauth={async (credential) => {
              try {
                const reauthResult = await reauthenticateWithCredential(
                  currentUser!,
                  credential
                );
                setShowReauthModal(false);
                await saveProfile(profile as any, reauthResult);
              } catch (error) {
                console.error(error);
                const message =
                  typeof error === 'object'
                    ? isFirebaseError(error)
                      ? AUTH_ERROR_MAP[error.code] || error.message
                      : (error as any)?.message
                    : error;
                present(
                  message || 'Error saving profile, please try again',
                  6000
                );
              }
            }}
          />
        </LoginContext.Provider>
      </IonModal>
    </IonPage>
  );
};

export default withAuthRequired(Account);
