import React from 'react';
import { useActor } from '@xstate/react';
import SubmitCaseViewProps from './types/SubmitCaseViewProps';
import { useSubmitCaseContext } from '../../context/SubmitCaseContext';
import CaseCheckout from '../case/CaseCheckout';
import { PaymentType } from '../../stateMachines/submitCase/selectPaymentType';

interface CheckoutProps extends SubmitCaseViewProps {}

const Checkout: React.FC<CheckoutProps> = ({ ...props }) => {
  const { submitCaseService } = useSubmitCaseContext();
  const [state, send] = useActor(submitCaseService);

  const { caseInfo, uploadProgress } = state.context;

  const isLoading = state.matches({ checkout: 'submitCase' });

  if (!caseInfo) {
    return null;
  }

  return (
    <CaseCheckout
      caseUserId={state.context.userId}
      clinicName={state.context.name}
      clinicEmail={state.context.email}
      invoicingEnabled={state.context.paymentType === PaymentType.INVOICE}
      parentAccount={state.context.parentAccount}
      onSubmit={() => send('NEXT')}
      onBack={() => send('BACK')}
      caseInfo={caseInfo}
      isLoading={isLoading}
      progress={uploadProgress}
      selectedInvoiceAccountUid={state.context.selectedInvoiceAccountUid}
    />
  );
};

export default Checkout;
