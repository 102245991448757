import React from 'react';
import RegistrationForm from './register/RegistrationForm';
import LoginScreen from './login/LoginScreen';
import { useFirebase } from '../services/firebase';
import { useHistory } from 'react-router-dom';
import { redirectAfterAuth } from '../util/auth';
import useQueryParams from '../hooks/useQueryParams';

interface RegisterProps {}

const Register: React.FC<RegisterProps> = (props) => {
  const { createUser } = useFirebase();
  const history = useHistory();
  const [registrationError, setRegistrationError] = React.useState<Error>();
  const params = useQueryParams();

  return (
    <LoginScreen title="Register">
      <RegistrationForm
        error={registrationError}
        clearError={() => setRegistrationError(undefined)}
        onSubmit={async (values) => {
          try {
            await createUser(
              values.email,
              values.password,
              values.displayName,
              {
                address: values.address,
                displayName: values.displayName,
                email: values.email,
                createdAt: String(Date.now()),
              }
            );
            redirectAfterAuth(params, history);
          } catch (e) {
            console.error(e);
            setRegistrationError(e as any);
          }
        }}
      />
    </LoginScreen>
  );
};

export default Register;
