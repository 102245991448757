import React from 'react';
import styled from 'styled-components';

interface HelperTextProps extends React.HTMLAttributes<HTMLElement> {
  type?: 'help' | 'error';
}

const HelperLine = styled.div`
  margin-top: -8px;
  .mdc-select + &.mdc-text-field-helper-line {
    padding: 0 16px;
  }
  .mdc-text-field-helper-text {
    font-size: 10px;
  }
`;

const Text = styled.div`
  &.mdc-text-field-helper-text {
    opacity: 1 !important;
  }
`;

const ErrorText = styled.div`
  &.mdc-text-field-helper-text {
    opacity: 1 !important;
    color: var(--mdc-theme-error) !important;
  }
`;

const HelperText: React.FC<HelperTextProps> = ({
  type = 'help',
  children,
  ...props
}) => {
  const TextComponent = type === 'error' ? ErrorText : Text;
  return (
    <HelperLine className="mdc-text-field-helper-line" {...props}>
      <TextComponent className="mdc-text-field-helper-text">
        {children}
      </TextComponent>
    </HelperLine>
  );
};

export default HelperText;
