import React from 'react';
import { User } from 'firebase/auth';
import styled from 'styled-components';
import { warning } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import Typography from '../common/Typography';
import SendEmailVerificationButton from './SendEmailVerificationButton';
import { useFirebase } from '../../services/firebase';

interface EmailNotVerifiedWarningProps
  extends React.HTMLAttributes<HTMLDivElement> {
  user: User;
}

const Container = styled.div`
  max-width: 600px;
  border-radius: 4px;
  border: 1px solid var(--ion-color-medium);
  width: 100%;
  display: flex;
  padding: 24px;
  align-items: center;
`;

const TextContainer = styled.div`
  overflow: hidden;
  text-align: center;

  & ion-button {
    margin-top: 16px;
    text-wrap: wrap;
  }
`;

const WarningIcon = styled(IonIcon)`
  font-size: 32px;
  margin-right: 16px;
`;

const EmailNotVerifiedWarning: React.FC<EmailNotVerifiedWarningProps> = ({
  user,
  ...props
}) => {
  const { app } = useFirebase();
  return (
    <Container {...props}>
      <div>
        <WarningIcon color="warning" icon={warning} />
      </div>

      <TextContainer>
        <Typography>Your email address has not yet been verified</Typography>

        <SendEmailVerificationButton user={user} app={app} />
      </TextContainer>
    </Container>
  );
};

export default EmailNotVerifiedWarning;
