import { assign } from 'xstate';
import { Event, Context } from './types';

export const setPassword = assign<Context, Event>((context, ev) => {
  if (ev.type === 'REAUTH') {
    return {
      ...context,
      password: ev.password,
      error: undefined,
    };
  }

  console.warn(
    `WARNING: #setPassword action expected REAUTH event but received ${ev.type}`
  );
  return context;
});
