import { IonAlert } from '@ionic/react';
import React from 'react';
import {
  BlockCallback,
  useNavBlock,
} from '../../services/buildfire/history/useBreadcrumbs';
import { Location } from 'history';
import { useHistory } from 'react-router-dom';
import { History } from 'history';

interface PromptProps {
  when?: boolean | BlockCallback;
  message?: React.ReactNode | string;
  onContinue?: (args: NavAction) => void;
  onStay?: (args: NavAction) => void;
}
type NavAction = {
  action: 'POP' | 'REPLACE' | 'PUSH';
  location: Location<unknown>;
};
function executeAction(
  { action, location }: NavAction,
  history: History<unknown>
) {
  switch (action) {
    case 'POP':
      return history.goBack();
    case 'PUSH':
      return history.push(location);
    case 'REPLACE':
      return history.replace(location);
    default:
      return;
  }
}

const Prompt: React.FC<PromptProps> = ({
  when,
  message,
  onContinue,
  onStay,
}) => {
  const [navAction, setNavAction] = React.useState<NavAction>();
  const history = useHistory();

  const whenFn = typeof when === 'function' ? when : () => !!when;
  useNavBlock(({ action, location }) => {
    if (!navAction && whenFn({ action, location })) {
      setNavAction({ action, location });
      return false;
    }

    return true;
  });

  return (
    <IonAlert
      isOpen={!!navAction}
      onWillDismiss={() => setNavAction(undefined)}
      message={message as any}
      buttons={[
        {
          text: 'Stay Here',
          role: 'cancel',
          handler: () => {
            onStay?.(navAction!);
            setNavAction(undefined);
          },
        },
        {
          text: 'Continue',
          role: 'confirm',
          handler: () => {
            onContinue?.(navAction!);
            executeAction(navAction!, history);
            setNavAction(undefined);
          },
        },
      ]}
    />
  );
};

export default Prompt;
