import React from 'react';
import Button from '../../../common/Button';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import AddCardDialog from './AddCardDialog';

export interface AddNewPaymentMethodProps {
  userId: string;
  email: string;
  clientSecret: string;
  isSubmitting: boolean;
  onSubmit: (values: {
    stripe: Stripe;
    elements: StripeElements;
  }) => void | Promise<void>;
  isDisabled: boolean;
  isDialogOpen: boolean;
  isLoading: boolean;
  onWillAddCreditCard: () => void;
  onAddCardDialogClose: () => void;
}

export const AddNewPaymentMethod: React.FC<AddNewPaymentMethodProps> = ({
  userId,
  email,
  clientSecret,
  isSubmitting,
  onSubmit,
  isDisabled,
  isDialogOpen,
  isLoading,
  onWillAddCreditCard,
  onAddCardDialogClose,
}) => {
  return (
    <>
      <Button
        fullWidth
        emphasis="low"
        type="button"
        onClick={onWillAddCreditCard}
        disabled={isDisabled}
      >
        + Add new card
      </Button>
      <AddCardDialog
        isOpen={isDialogOpen}
        isLoading={isLoading}
        onClose={onAddCardDialogClose}
        FormProps={{
          userId,
          email,
          clientSecret,
          isSubmitting,
          onSubmit: async (values) => {
            await onSubmit(values);
          },
        }}
      />
    </>
  );
};

export default AddNewPaymentMethod;
