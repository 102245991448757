import React from 'react';
import styled from 'styled-components';
import ProgressBar, { ProgressBarProps } from './ProgressBar';

export interface FixedProgressBarProps extends ProgressBarProps {
  top?: number;
}

const StyledProgressBar = styled<any & { $top: number }>(ProgressBar)`
  position: fixed;
  top: ${(props) => (props.$top ? `${props.$top}px` : '0')};
  left: 0;
  right: 0;
  z-index: 1;
`;

const FixedProgressBar: React.FC<FixedProgressBarProps> = ({
  top,
  ...props
}) => {
  return <StyledProgressBar $top={top} {...props} />;
};

export default FixedProgressBar;
