import React from 'react';
import styled from 'styled-components';
import { Form as FormikForm } from 'formik';

interface FormProps extends React.HTMLAttributes<HTMLFormElement> {}

const StyledForm = styled(FormikForm)`
  .mdc-select,
  .mdc-text-field {
    margin: 8px 0;
  }
`;

const Form: React.FC<FormProps> = (props) => {
  return <StyledForm {...props} />;
};

export default Form;
