import React from 'react';
import styled, { css } from 'styled-components';
import MDCButton, { ButtonProps as MDCButtonProps } from '../../mdc/Button';

export interface ButtonProps extends MDCButtonProps {
  variant?: 'primary' | 'secondary';
  emphasis?: 'transparent' | 'low' | 'high';
  outlined?: boolean;
  size?: 'medium' | 'large' | 'x-large';
  fullWidth?: boolean;
  uppercase?: boolean;
}

const buildfireThemeMixin = css`
  ${(props) => `
    color: ${props.theme.colors.titleBarTextAndIcons} !important;
  `}
`;

const FONT_SIZES = {
  medium: '12px',
  large: '20px',
  ['x-large']: '24px',
};
const PADDING = {
  medium: '12px 24px',
  large: '12px',
  ['x-large']: '16px',
};

const highEmphasis = css<ButtonProps>`
  ${(props) => `
    &:not(:disabled) {
      background-color: var(--mdc-theme-${props.variant}) !important;
    }

    .mdc-button__label {
      ${
        props.buttonType === 'raised'
          ? `
        color: var(--mdc-theme-text-${props.variant}-on-dark);
        `
          : ''
      }
    }
      
    &:not(:disabled):hover {
      background-color: var(--mdc-theme-${props.variant}) !important;
    }
  `}
`;

const lowEmphasis = css<ButtonProps>`
  ${(props) => `

    &:not(:disabled) .mdc-button__label {
      ${
        props.buttonType === 'raised'
          ? `
        color: var(--mdc-theme-${props.variant}) !important;
        `
          : ''
      }
    }
      
    &:hover,
    &:disabled,
    &:focus,
    &:not(:disabled) {
      background-color: transparent !important;
      border-color: var(--mdc-theme-${props.variant}) !important;
      box-shadow: none;
    }

    &:disabled > span.mdc-button__label {
      color: var(--mdc-theme-text-disabled-on-background) !important;
    }
  `}
`;

const transparentEmphasis = css<ButtonProps>`
  ${(props) => `
    background-color: transparent !important;
    border-color: var(--mdc-theme-${props.variant}) !important;
    box-shadow: none;

    .mdc-button__label {
      ${
        props.buttonType === 'raised'
          ? `
        color: var(--mdc-theme-${props.variant}) !important;
        `
          : ''
      }
    }
      
    &:hover,
    &:focus {
      background-color: transparent !important;
      box-shadow: none;
    }
  `}
`;

const StyledButton = styled<React.FC<ButtonProps>>(MDCButton)`
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  line-height: 1em;
  height: auto !important;
  text-decoration: none;
  color: white;

  ${buildfireThemeMixin}

  &:disabled {
    cursor: not-allowed;
  }

  ${(props) => (props.uppercase ? 'text-transform: uppercase;' : '')}

  ${(props) =>
    props.outlined
      ? `border: 1px solid var(--mdc-theme-text-${props.variant}-on-dark);`
      : ''}

  border-radius: 999px;

  ${(props) => `
    font-size: ${FONT_SIZES[props.size!]};
    padding: ${PADDING[props.size!]};  
  `}

  ${(props) =>
    props.fullWidth &&
    `
    width: 100%;
    display: block;
  `}

  &.mdc-button--raised:hover .mdc-button__ripple, 
  &.mdc-button--unelevated:hover .mdc-button__ripple {
    border-radius: 999px;
  }

  ${(props) => props.emphasis === 'low' && lowEmphasis}
  ${(props) => props.emphasis === 'high' && highEmphasis}
  ${(props) => props.emphasis === 'transparent' && lowEmphasis}
`;

const Button: React.FC<
  ButtonProps & { as?: React.ComponentType<any> | string }
> = ({
  variant = 'primary',
  emphasis = 'high',
  size = 'medium',
  uppercase = true,
  buttonType = 'raised',
  ...props
}) => {
  return (
    <StyledButton
      variant={variant}
      emphasis={emphasis}
      size={size}
      uppercase={uppercase}
      buttonType={buttonType}
      {...props}
    />
  );
};

export default Button;
