import React from 'react';
import { pencil } from 'ionicons/icons';
import CaseListItem from '../../components/customer/CaseListItem';
import Typography from '../../components/common/Typography';
import CaseContext, {
  PageArgs,
} from '../../services/buildfire/rdb/CaseContext';
import CasesScreen from '../Cases';
import { LOADING_STATE } from '../../util/loading';
import Dialog, { DialogActionButton } from '../../components/common/Dialog';
import { withAuthRequired } from '../../services/buildfire/auth';
import { IonHeader, IonPage } from '@ionic/react';
import { useFirebase } from '../../services/firebase';
import Toolbar from '../../components/common/Toolbar';
import CustomerReport from '../../components/case/report/CustomerReport';
import CustomerCaseInfo from '../../components/case/caseInfo/CustomerCaseInfo';
import { useHistory, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { isDesktop } from '../../util/device';
import { CaseInfoProps } from '../../components/case/caseInfo/CaseInfo';
import AlertBanner from '../../components/common/AlertBanner';
import * as A from 'fp-ts/Array';
import { caseIsEqual } from '../../util/case';
import { pipe } from 'fp-ts/lib/function';
import {
  Case,
  CaseStatus,
  getByUser_Deprecated,
} from '../../services/buildfire/rdb/cases';
import { getDatabase } from 'firebase/database';
import { User } from 'firebase/auth';
import DownloadPDF from '../../components/case/actionMenu/actions/DownloadPDF';
import ActionItem from '../../components/case/actionMenu/ActionItem';
import ArchiveCase from '../../components/case/actionMenu/actions/ArchiveCase';

interface CaseViewProps {
  user: User;
}
const CustomerActionItems: React.FC<{
  case?: Case;
  loadingStatus: LOADING_STATE;
}> = ({ case: c, loadingStatus }) => {
  const history = useHistory();

  const isClosedByUser = c?.status === CaseStatus.CLOSED;
  const isLoadingCase = loadingStatus === LOADING_STATE.LOADING;
  const isReportAvailable = c?.status === CaseStatus.SIGNED_OFF;
  const isCaseOpen = c?.status === CaseStatus.OPEN;
  const canArchive = !c?.isArchived && !isClosedByUser;
  if (isClosedByUser) {
    return null;
  }

  const editCase = () => {
    history.push(`/cases/${c!.id}/edit`);
  };

  return isLoadingCase || isClosedByUser ? null : (
    <>
      {isReportAvailable && <DownloadPDF case={c!} />}
      {isCaseOpen && (
        <ActionItem icon={pencil} onClick={editCase} label="Edit" />
      )}
      {canArchive && <ArchiveCase case={c!} />}
    </>
  );
};

export const UserCases: React.FC<CaseViewProps> = ({ user, children }) => {
  const [loadingStatus, setLoadingStatus] = React.useState(
    LOADING_STATE.LOADING
  );
  const {
    cases,
    setCases,
    getUserCases,
    getCasesPaginated,
    hasRetrievedAllCases,
    setHasRetrievedAllCases,
  } = React.useContext(CaseContext);
  const { app, parentAccount } = useFirebase();

  const match = useRouteMatch('/cases/:caseId');
  const showMainHeader = !match?.url || isDesktop();

  const isLoading = loadingStatus === LOADING_STATE.LOADING;

  const getOpenCases = async (userId: string) => {
    const openCases = await getCasesPaginated(userId, CaseStatus.OPEN, {});
    setCases((c) => openCases.concat(c));
  };

  const getClosedCases = async (userId: string, pageArgs: PageArgs) => {
    const newCases = await getCasesPaginated(
      userId,
      CaseStatus.CLOSED,
      pageArgs
    );
    const hasRetrievedAllCases = newCases.length < (pageArgs.pageSize || 1);
    if (hasRetrievedAllCases) {
      console.log('Retrieved all user cases');
      setHasRetrievedAllCases(true);
    }
    setCases((cases) => {
      // IMPORTANT to concat newCases before cases, to preserve order in DB
      const allCases = pipe([newCases, cases], A.flatten, A.uniq(caseIsEqual));
      return allCases;
    });
  };

  const getAllCases_Deprecated = async (userId: string) => {
    const database = getDatabase(app);
    const allCases = await getByUser_Deprecated(database, userId);
    setCases(allCases);
  };

  // Get initial cases on page load
  React.useEffect(() => {
    setLoadingStatus(LOADING_STATE.LOADING);
    const uid = user.uid;
    // getOpenCases(uid).then(() => getClosedCases(uid, {}));
    getAllCases_Deprecated(uid)
      .then(() => {
        setLoadingStatus(LOADING_STATE.LOADED);
      })
      .catch((e) => {
        console.error(e);
        setLoadingStatus(LOADING_STATE.ERROR);
      });
  }, []);

  return (
    <IonPage>
      <IonHeader
        className={classNames({
          ['ion-no-border']: true,
          ['hide-sm']: !showMainHeader,
        })}
      >
        <Toolbar showBackButton title="My Cases" />
        <AlertBanner />
      </IonHeader>

      <CasesScreen
        cases={cases}
        currentUser={user}
        caseUser={user}
        isLoading={isLoading}
        CaseInfo={CustomerCaseInfoWithActionItems}
        Report={CustomerReport}
        CaseListItemComponent={CaseListItem}
        hasRetrievedAllCases={hasRetrievedAllCases}
        isAdmin={false}
        getOpenCases={getOpenCases}
        getClosedCases={getClosedCases}
        parentAccount={parentAccount}
      />

      {/* {isOnCaseListScreen && <NewCaseButton />} */}

      <Dialog
        title=""
        dialogId="cases-error"
        isOpen={loadingStatus === LOADING_STATE.ERROR}
        actions={<DialogActionButton action="accept">Ok</DialogActionButton>}
      >
        <Typography fontSize="16px">
          There was a problem retrieving your cases. Please refresh and try
          again.
        </Typography>
      </Dialog>
    </IonPage>
  );
};

const CustomerCaseInfoWithActionItems: React.FC<CaseInfoProps> = (props) => (
  <CustomerCaseInfo
    {...props}
    actionItems={
      <CustomerActionItems
        case={props.case}
        loadingStatus={LOADING_STATE.LOADED}
      />
    }
  />
);

const Cases: React.FC<{}> = ({}) => {
  const { currentUser } = useFirebase();
  return <UserCases user={currentUser!} />;
};

export default withAuthRequired(Cases);
