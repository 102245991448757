import React from 'react';
import classnames from 'classnames';

interface SnackbarProps extends React.HTMLAttributes<HTMLElement> {
  actions?: React.ReactNode;
  timeoutMS?: number;
  openImmediately?: boolean;
  /**
   * Stack action buttons below snackbar text
   */
  stacked?: boolean;

  leading?: boolean;

  onOpen?: () => void;

  onOpening?: () => void;

  onClose?: () => void;

  onClosing?: () => void;
}

function useCallback(callback?: () => void) {
  const callbackRef = React.useRef<() => void>(callback || (() => {}));
  React.useEffect(() => {
    callbackRef.current = callback!;
  }, [callback]);
  return callbackRef.current;
}

const Snackbar: React.FC<SnackbarProps> = ({
  actions,
  children,
  timeoutMS,
  leading,
  stacked,
  openImmediately = true,
  onOpen,
  onOpening,
  onClose,
  onClosing,
  ...props
}) => {
  const ref = React.useRef<HTMLElement>(null);
  const mdcComponent = React.useRef<mdc.snackbar.MDCSnackbar>();

  const handleOpen = useCallback(onOpen);
  const handleOpening = useCallback(onOpening);
  const handleClose = useCallback(onClose);
  const handleClosing = useCallback(onClosing);
  React.useEffect(() => {
    if (ref.current) {
      mdcComponent.current = new mdc.snackbar.MDCSnackbar(ref.current);
      (window as any).snack = mdcComponent.current;
      mdcComponent.current.listen('MDCSnackbar:opened', handleOpen);
      mdcComponent.current.listen('MDCSnackbar:opening', handleOpening);
      mdcComponent.current.listen('MDCSnackbar:closed', handleClose);
      mdcComponent.current.listen('MDCSnackbar:closing', handleClosing);
      if (openImmediately) {
        mdcComponent.current.open();
      }
      return () => mdcComponent.current?.destroy();
    }
  }, []);

  // TODO does this work?
  React.useEffect(() => {
    if (timeoutMS !== undefined && mdcComponent.current) {
      mdcComponent.current.timeoutMS = timeoutMS;
    }
  }, [timeoutMS]);

  return (
    <aside
      className={classnames({
        ['mdc-snackbar']: true,
        ['mdc-snackbar--stacked']: stacked,
        ['mdc-snackbar--leading']: leading,
      })}
      ref={ref}
      {...props}
    >
      <div
        className="mdc-snackbar__surface"
        role="status"
        aria-relevant="additions"
      >
        <div className="mdc-snackbar__label" aria-atomic="false">
          {children}
        </div>
        {actions && (
          <div className="mdc-snackbar__actions" aria-atomic="true">
            {actions}
          </div>
        )}
      </div>
    </aside>
  );
};

interface SnackbarActionProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  //
}

export const SnackbarAction: React.FC<SnackbarActionProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <button
      type="button"
      className={classnames('mdc-button', 'mdc-snackbar__action', className)}
      {...props}
    >
      <div className="mdc-button__ripple"></div>
      <span className="mdc-button__label">{children}</span>
    </button>
  );
};

export default Snackbar;
