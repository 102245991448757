import React from 'react';
import styled from 'styled-components';

export interface UnreadNotifProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  variant?: 'primary' | 'secondary';
}

const Notif = styled.span<{ $variant?: string }>`
  font-size: 32px;
  height: 11px;
  color: var(--mdc-theme-${(props) => props.$variant});
`;

const UnreadNotif: React.FC<UnreadNotifProps> = ({
  variant = 'primary',
  ...props
}) => {
  return (
    <Notif $variant={variant} {...props}>
      •
    </Notif>
  );
};

export default UnreadNotif;
