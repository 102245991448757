import React from 'react';
import { Formik } from 'formik';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import CreditCardInput, {
  CreditCardInputProps,
} from '../common/CreditCardInput';
import Form from '../common/Form';
import Button from '../common/Button';

interface CreditCardFormProps extends CreditCardInputProps {
  userId: string;
  email: string;
  stripeCustomerId?: string;
  renderButtons?: (props: {
    disabled: boolean;
    onClick: () => void;
  }) => React.ReactNode;
  Form?: React.ComponentType<{}>;
  onSubmit: (values: {
    stripe: Stripe;
    elements: StripeElements;
  }) => Promise<void>;
}

const CreditCardForm: React.FC<CreditCardFormProps> = ({
  userId,
  email,
  stripeCustomerId,
  renderButtons,
  onSubmit,
  Form: CustomForm,
  ...creditCardInputProps
}) => {
  const stripe = useStripe();
  const elements = useElements();
  if (!stripe || !elements) {
    return null;
  }

  const FormComponent = CustomForm || Form;

  return (
    <Formik
      initialValues={{
        name: '',
      }}
      onSubmit={() => onSubmit({ stripe, elements })}
      validateOnMount
    >
      {({ isValid, isSubmitting, submitForm }) => (
        <>
          <FormComponent>
            <CreditCardInput {...creditCardInputProps} />
          </FormComponent>
          {renderButtons ? (
            renderButtons({
              disabled: !isValid || isSubmitting,
              onClick: submitForm,
            })
          ) : (
            <Button type="submit">ADD CARD</Button>
          )}
        </>
      )}
    </Formik>
  );
};

export default CreditCardForm;
