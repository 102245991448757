import React from 'react';
import styled from 'styled-components';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { flow } from 'fp-ts/lib/function';
import { openDialog } from '../context/DialogContext';
import moment from 'moment';
import IconButton from '../mdc/IconButton';
import { useBuildfireSettings } from '../context/SettingsContext';

interface DebugMessage {
  content: any;
  timestamp: number;
}

interface IDebugContext {
  messages: any[];
  setEnabled: (enabled: boolean) => void;
  addMessage: (message: Omit<DebugMessage, 'timestamp'>) => void;
}

export const DebugContext = React.createContext<IDebugContext>(
  undefined as any
);

const Overlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ccc;
  opacity: 0.7;
  min-height: 24px;
  max-height: 300px;
  overflow: auto;
  color: black;
  padding: 8px;
`;

const ListItem = styled.div`
  padding: 8px 4px;
  overflow: hidden;
  word-break: break-word;

  .copy-btn {
    font-size: 14px !important;
  }

  &:nth-child(even) {
    background-color: #eee;
  }
`;

const ListItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const addDebugMessage = (message: Omit<DebugMessage, 'timestamp'>) => {
  (window as any).__ADD_DEBUG_MESSAGE__?.(message);
};

const lastMessage = (messages: DebugMessage[]) => A.last(messages);

const getMessageToDisplay = flow(
  lastMessage,
  O.map(JSON.stringify),
  O.getOrElse(() => '')
);

const DebugProvider: React.FC<{}> = ({ children }) => {
  const { settings } = useBuildfireSettings();
  const shouldPopup = React.useRef<boolean>(false);
  const [isEnabled, setIsEnabled] = React.useState(settings.debugMode);
  const [messages, setMessages] = React.useState<DebugMessage[]>([]);

  React.useEffect(() => {
    setIsEnabled(settings.debugMode);
  }, [settings.debugMode]);

  const addMessage = React.useCallback(
    (message: Omit<DebugMessage, 'timestamp'>) => {
      const fullMessage: DebugMessage = {
        ...message,
        timestamp: Date.now(),
      };
      console.log('DEBUG', fullMessage);
      if (shouldPopup.current) {
        window.alert(fullMessage);
      }
      setMessages((m) => m.concat(fullMessage));
    },
    []
  );

  const setEnabled = React.useCallback((enabled: boolean) => {
    setIsEnabled(enabled);
  }, []);

  React.useEffect(() => {
    (window as any).__ADD_DEBUG_MESSAGE__ = addMessage;
  }, [addMessage]);

  return (
    <DebugContext.Provider value={{ messages, addMessage, setEnabled }}>
      {children}
      {isEnabled && (
        <Overlay>
          {getMessageToDisplay(messages)}
          <button
            onClick={() => {
              openDialog(
                <>
                  {messages.map((m) => (
                    <ListItem>
                      <ListItemHeader>
                        <span>{moment(m.timestamp).format('hh:mm:ss SS')}</span>
                        <IconButton
                          className="copy-btn"
                          icon="content_copy"
                          onClick={async () => {
                            try {
                              await navigator.clipboard.writeText(
                                JSON.stringify(m.content)
                              );
                            } catch (e) {
                              fallbackCopy(JSON.stringify(m.content));
                            }
                          }}
                        />
                      </ListItemHeader>
                      <div>{JSON.stringify(m.content)}</div>
                    </ListItem>
                  ))}
                </>
              );
            }}
          >
            Open
          </button>
        </Overlay>
      )}
    </DebugContext.Provider>
  );
};

function fallbackCopy(str: string) {
  var textArea = document.createElement('textarea');
  textArea.value = str;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }
  document.body.removeChild(textArea);
}

export function useDebugLog({
  title,
  ...data
}: {
  title: string;
  [key: string]: any;
}) {
  React.useEffect(() => {
    addDebugMessage({
      content: {
        title: 'widget render',
        ...data,
      },
    });
  }, [title, ...Object.values(data)]);
}

export default DebugProvider;
