import { IonFab, IonFabButton, IonIcon, isPlatform } from '@ionic/react';
import React from 'react';
import { add } from 'ionicons/icons';
import styled from 'styled-components';

interface NewCaseButtonProps {
  //
}

const FabButton = styled(IonFabButton)`
  margin-bottom: 8px;
`;

const NewCaseButton: React.FC<NewCaseButtonProps> = ({ ...props }) => {
  const isDesktop = isPlatform('desktop');
  return (
    <IonFab
      title="Create case"
      vertical="bottom"
      horizontal={isDesktop ? 'end' : 'center'}
      {...props}
    >
      <FabButton routerLink={`/cases/create`}>
        <IonIcon icon={add} />
      </FabButton>
    </IonFab>
  );
};

export default NewCaseButton;
