import React from 'react';
import styled from 'styled-components';

interface BrProps extends React.HTMLAttributes<HTMLBRElement> {
  minWidth?: number;
  maxWidth?: number;
}

const Styled = styled.br<BrProps>`
  display: none;
  ${(props) =>
    props.minWidth
      ? `
  @media(min-width: ${props.minWidth}px) {
    display: block;
  }
  `
      : ''}
  ${(props) =>
    props.maxWidth
      ? `
  @media(max-width: ${props.maxWidth}px) {
    display: block;
  }
  `
      : ''}
`;

const Br: React.FC<BrProps> = (props) => {
  return <Styled {...props} />;
};

export default Br;
