import React from 'react';
import { SpayNeuterLabel } from '../../constants/spayNeuter';
import * as RDBCases from '../../services/buildfire/rdb/cases';
import { formatAge } from '../../util/age';
import InfoSection, { LineItem } from './caseInfo/InfoSection';

interface PatientInfoProps {
  case: Partial<RDBCases.Case> & {
    patient: RDBCases.Case['patient'];
    species: RDBCases.Case['species'];
    age: RDBCases.Case['age'];
    sex: RDBCases.Case['sex'];
  };
  includeName?: boolean;
}

const SPECIES_MAP = {
  [RDBCases.AllowedSpecies.CAT]: 'Cat',
  [RDBCases.AllowedSpecies.DOG]: 'Dog',
  [RDBCases.AllowedSpecies.HORSE]: 'Horse',
};

const GENDER_MAP = {
  [RDBCases.Sex.MALE]: 'Male',
  [RDBCases.Sex.FEMALE]: 'Female',
};

const PatientInfo: React.FC<PatientInfoProps> = ({ case: c, includeName }) => {
  return (
    <InfoSection title="Patient Info">
      {includeName && (
        <LineItem
          name="Name"
          description={`${c.patient.first} ${c.patient.last}`}
        />
      )}
      <LineItem name="Species" description={SPECIES_MAP[c.species]} />
      <LineItem name="Age" description={formatAge(c.age, c.ageMonths)} />
      <LineItem name="Sex" description={GENDER_MAP[c.sex]} />
      <LineItem
        name={SpayNeuterLabel[c.sex]}
        description={c.fixed ? 'Yes' : 'No'}
      />
      <LineItem
        name="Relevant clinical history"
        description={c.description}
        multiLine
        pre
      />
    </InfoSection>
  );
};

export default PatientInfo;
