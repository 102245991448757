import React from 'react';
import { LOADING_STATE } from '../util/loading';

export type FetchResult<T> =
  | {
      loadingStatus: LOADING_STATE.LOADING;
      data?: T;
      error?: Error;
    }
  | {
      loadingStatus: LOADING_STATE.ERROR;
      data?: T;
      error: Error;
    }
  | {
      loadingStatus: LOADING_STATE.LOADED;
      data: T;
      error?: Error;
    };

type FetchReturnType<T> = FetchResult<T> & {
  setData: (data: T) => void;
};

function useFetch<T = any>(
  fn: () => Promise<T>,
  onUnmount: () => void = () => {},
  deps: React.DependencyList = []
): FetchReturnType<T> {
  const [data, setData] = React.useState<FetchResult<T>>({
    loadingStatus: LOADING_STATE.LOADING,
  });
  const canceled = React.useRef(false);

  React.useEffect(() => {
    setData({
      data: data.data,
      error: undefined,
      loadingStatus: LOADING_STATE.LOADING,
    });
    canceled.current = false;

    fn()
      .then((data) => {
        if (!canceled.current) {
          setData({
            loadingStatus: LOADING_STATE.LOADED,
            data,
            error: undefined,
          });
        }
      })
      .catch((e) => {
        if (!canceled.current) {
          console.error(e);
          setData({
            loadingStatus: LOADING_STATE.ERROR,
            error: e,
          });
        }
      });

    return () => {
      canceled.current = true;
      onUnmount();
    };
  }, deps);

  const overrideData = (newData: T) =>
    setData((d) => ({ ...d, data: newData }));

  return { ...data, setData: overrideData };
}

export default useFetch;
