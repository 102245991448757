import { IonContent, IonHeader, IonPage, useIonAlert } from '@ionic/react';
import { useActor } from '@xstate/react';
import React from 'react';
import styled from 'styled-components';
import { State } from 'xstate';
import Confirm from '../components/account/deleteAccount/Confirm';
import SubmitFeedback from '../components/account/deleteAccount/SubmitFeedback';
import Warning from '../components/account/deleteAccount/Warning';
import TrackView from '../components/analytics/TrackView';
import Platform from '../components/common/Platform';
import ProgressBar from '../components/common/ProgressBar';
import Toolbar from '../components/common/Toolbar';
import Typography from '../components/common/Typography';
import {
  DeleteAccountContextProvider,
  useDeleteAccountContext,
} from '../context/DeleteAccountContext';
import { VIEW_EVENTS } from '../services/buildfire/analytics/events';
import { Context, Event } from '../stateMachines/deleteAccount/types';

interface DeleteAccountProps {}

const LoadingContainer = styled.div`
  margin: 200px 0;
`;

const getView = (
  views: StringMap<React.ComponentType<any>>,
  state: State<Context, Event, any, any>
) => {
  const viewKey = Object.keys(views).find((stateKey) =>
    state.matches(stateKey)
  );
  const View = viewKey ? views[viewKey] : null;
  return View ? <View /> : null;
};

const DeleteAccount: React.FC<DeleteAccountProps> = (props) => {
  const { deleteAccountService } = useDeleteAccountContext();
  const [state, send] = useActor(deleteAccountService);

  const [present] = useIonAlert();

  const views = {
    initial: () => (
      <Warning onNext={() => send('NEXT')} onBack={() => send('BACK')} />
    ),
    submitFeedback: () => (
      <SubmitFeedback
        onNext={({ feedback }) => send({ type: 'NEXT', feedback })}
        onBack={() => send('BACK')}
      />
    ),
    reauth: () => (
      <Confirm
        isSubmitting={false}
        error={state.context.error}
        onNext={async ({ password }) => {
          await present(
            'Are you sure you want to delete your account? This action is irreversable.',
            [
              { text: 'Cancel', role: 'cancel' },
              {
                text: 'Delete Account',
                cssClass: 'danger',
                role: 'destructive',
                handler: () => send({ type: 'REAUTH', password }),
              },
            ]
          );
        }}
        onBack={() => send('BACK')}
      />
    ),
    deleteAccount: () => {
      return (
        <LoadingContainer>
          <ProgressBar indeterminate />
        </LoadingContainer>
      );
    },
  };

  const view = getView(views, state);

  return view;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 48px 0;
  padding: 0 16px;
`;

const Content = styled.div`
  max-width: 100%;
  width: 400px;
`;

const Title = styled.div`
  margin: 24px 0;
`;

const WrappedDeleteAccount: React.FC<{}> = () => {
  return (
    <IonPage>
      <IonHeader>
        <Toolbar showBackButton title="Delete Account" />
      </IonHeader>
      <IonContent>
        <Container>
          <Content>
            <Platform.Desktop>
              <Title>
                <Typography fontSize="24px">Delete Account</Typography>
              </Title>
            </Platform.Desktop>
            <DeleteAccountContextProvider>
              <TrackView name={VIEW_EVENTS.DELETE_ACCOUNT}>
                <DeleteAccount />
              </TrackView>
            </DeleteAccountContextProvider>
          </Content>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default WrappedDeleteAccount;
