import React from 'react';
import { warning, ellipsisVertical } from 'ionicons/icons';
import { UserProps } from '../../views/User';
import { UserMetadataWithId } from '../../services/firebase/user';
import { useFirebase } from '../../services/firebase';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import Avatar from '../common/Avatar';
import Typography from '../common/Typography';
import Popover from '../common/Popover';
import { Functions, getFunctions } from 'firebase/functions';
import {
  generateInvoice,
  impersonateUser,
  setAdmin,
} from '../../api/cloudFunctions';
import ActionItem from '../case/actionMenu/ActionItem';
import SendWelcomeEmail from './actionItems/admin/SendWelcomeEmail';
import SetInvoiceStatus from './actionItems/admin/SetInvoiceStatus';

const UserHeader: React.FC<
  UserProps & {
    userMetadata?: Partial<UserMetadataWithId>;
    updateUser: (metadata: Partial<UserMetadataWithId>) => void;
  }
> = ({ user, userMetadata, isAdmin, updateUser }) => {
  const { currentUser, app, signInWithCustomToken } = useFirebase();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const isJeremy = currentUser!.email === 'jtberglund@gmail.com';
  const [present] = useIonToast();
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton>
            <Avatar
              photoUrl={(user as any).photoUrl}
              userIdentifier={user.uid!}
            />
          </IonButton>
        </IonButtons>

        <IonTitle>
          <Typography
            as="h3"
            variant="secondary"
            fontSize="20px"
            fontWeight="400"
            lineHeight="1em"
            margin="0"
          >
            {user.displayName}
          </Typography>
        </IonTitle>
        {isAdmin && (
          <IonButtons slot="end">
            <IonButton
              id="user-info-actions"
              onClick={() => setIsPopoverOpen(true)}
            >
              <IonIcon icon={ellipsisVertical} slot="icon-only" />
            </IonButton>
          </IonButtons>
        )}
        <Popover trigger="user-info-actions" isOpen={isPopoverOpen}>
          <IonContent onClick={() => setIsPopoverOpen(false)}>
            <IonList lines="none">
              <ActionItem
                label="View invoices"
                href={`/admin/users/${user.uid}/invoices`}
              />
              {isJeremy && (
                <ActionItem
                  label="Generate invoice"
                  onClick={async () => {
                    const fns = getFunctions(app);
                    try {
                      await generateInvoice(fns, { userId: user.uid! });
                      present('Invoice generated', 4000);
                    } catch (e) {
                      console.error(e);
                      present({
                        message: 'Failed to generate invoice',
                        icon: warning,
                        color: 'danger',
                        duration: 6000,
                      });
                    }
                  }}
                />
              )}
              {isAdmin && (
                <ActionItem
                  label="Make admin"
                  onClick={async () => {
                    const fns = getFunctions(app);
                    try {
                      await setAdmin(fns, { uid: user.uid!, admin: true });
                      present('User given admin priveleges', 4000);
                    } catch (e) {
                      console.error(e);
                      present({
                        message: 'Failed to make admin',
                        icon: warning,
                        color: 'danger',
                        duration: 6000,
                      });
                    }
                  }}
                />
              )}
              {isAdmin && (
                <ActionItem
                  label="Impersonate user"
                  onClick={async () => {
                    const fns = getFunctions(app);
                    try {
                      const result = await impersonateUser(fns, {
                        uid: user.uid!,
                      });
                      await signInWithCustomToken(result.authToken, true);
                      present(`Logged in as ${user.displayName}`, 4000);
                    } catch (e) {
                      console.error(e);
                      present({
                        message: 'Failed to log in as user',
                        icon: warning,
                        color: 'danger',
                        duration: 6000,
                      });
                    }
                  }}
                />
              )}
              {isAdmin && (
                <SetInvoiceStatus
                  uid={user.uid!}
                  user={userMetadata}
                  updateUser={updateUser}
                />
              )}
              {isAdmin && <SendWelcomeEmail email={user.email || ''} />}
            </IonList>
          </IonContent>
        </Popover>
      </IonToolbar>
    </IonHeader>
  );
};

export default UserHeader;
