import React from 'react';
import styled from 'styled-components';
import Typography from '../../components/common/Typography';
import PriceIcon from './PriceIcon';
import pricingBackground1 from '../../images/paw-prints1.png';
import pricingBackground3 from '../../images/paw-prints3.png';

export type PriceHeadingProps = React.HTMLAttributes<HTMLElement> & {
  price: string;
  title: string | React.ReactNode | JSX.Element;
  iconBackground: 'pawprints1' | 'pawprints3';
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

interface IconContainerProps {
  $iconBackground: PriceHeadingProps['iconBackground'];
}
const IconContainer = styled.div<IconContainerProps>`
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => `
  
    background-image: url('${
      props.$iconBackground === 'pawprints1'
        ? pricingBackground1
        : pricingBackground3
    }');
    ${
      props.$iconBackground === 'pawprints1'
        ? `
          margin-left: -20px;
          margin-right: 8px;
          `
        : ''
    }
  `}
  background-size: 110px;
  background-position: center;
  width: 100px;
  height: 80px;
`;

const TitleContainer = styled.div`
  text-align: left;
  display: flex;
  justify-content: center;
`;

const PriceHeading: React.FC<PriceHeadingProps> = ({
  price,
  title,
  iconBackground,
  ...props
}) => {
  return (
    <Container {...props}>
      <IconContainer $iconBackground={iconBackground}>
        <PriceIcon price={price} />
      </IconContainer>
      <TitleContainer>
        <Typography variant="secondary" fontSize="18px" fontWeight="600">
          {title}
        </Typography>
      </TitleContainer>
    </Container>
  );
};

export default PriceHeading;
