import React from 'react';
import styled from 'styled-components';
import ProgressBar, { ProgressBarProps } from './ProgressBar';
import { render, createPortal } from 'react-dom';
import { useDialog } from '../../context/DialogContext';

interface ModalLoaderProps {
  parent?: string | Element;
  ProgressBarProps?: ProgressBarProps;
  onClose: () => void;
  progressText?: string;
}

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.32);
  padding: 64px;
  z-index: 10;
  flex-direction: column;
`;

const StyledProgressBar = styled(ProgressBar)``;

const Text = styled.p`
  margin: 32px 0;
  color: white;
  font-weight: bold;
  font-size: 20px;
`;

const DIALOG_ID = 'ModalLoader';

function useDialogAttributes({ onClose }: { onClose: () => void }) {
  const { openDialogs, addDialog, removeDialog } = useDialog();
  const isOpen = React.useRef(false);

  React.useEffect(() => {
    if (!openDialogs.includes(DIALOG_ID) && isOpen.current) {
      onClose();
      isOpen.current = false;
    }
  }, [openDialogs]);

  React.useEffect(() => {
    addDialog(DIALOG_ID);
    isOpen.current = true;
    return () => {
      if (isOpen.current) {
        removeDialog(DIALOG_ID);
      }
    };
  }, []);
}

function useFocus<T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>
) {
  React.useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current]);
}

const ModalLoader: React.FC<ModalLoaderProps> = ({
  parent = document.body,
  ProgressBarProps,
  onClose,
  progressText,
  ...props
}) => {
  const parentElement =
    typeof parent === 'string' ? document.querySelector(parent) : parent;
  const ref = React.useRef<HTMLDivElement>(null);

  useDialogAttributes({
    onClose,
  });

  useFocus(ref);

  if (!parentElement) {
    console.error('ModalLoader: Could not find parent element', parent);
    return null;
  }

  return createPortal(
    <Container
      ref={ref}
      tabIndex={1}
      onKeyDown={(e) => e.stopPropagation()}
      onKeyPress={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <StyledProgressBar
        progressBarType="primary"
        indeterminate
        {...ProgressBarProps}
      />
      {progressText && <Text>{progressText}</Text>}
    </Container>,
    parentElement,
    String(ProgressBarProps?.progress)
  );
};

export default ModalLoader;
