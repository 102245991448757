import React, { useState } from 'react';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import useFCMTokenSync from '../../useFCMTokenSync';
import { isNotNativeApp } from '../../../util/device';
import { FCM } from '@capacitor-community/fcm';
import { isPlatform } from '@ionic/core';
import { PluginListenerHandle } from '@capacitor/core';

function usePushNotifications() {
  const [status, setStatus] = React.useState<'denied' | 'granted' | 'loading'>(
    'loading'
  );
  const [tokenResult, setTokenResult] = useState<{
    token?: Token;
    err?: any;
  }>();
  const isWebDevice = isNotNativeApp();
  const isIOS = isPlatform('ios');
  useFCMTokenSync(tokenResult?.token?.value, tokenResult?.err);

  React.useEffect(() => {
    let registrationHandle: PluginListenerHandle;
    let registrationErrorHandle: PluginListenerHandle;
    let notifReceivedHandle: PluginListenerHandle;
    let notifActionHandle: PluginListenerHandle;
    if (isWebDevice) {
      return;
    }

    (async function () {
      if (isIOS) {
        FCM.getToken().then((result) => {
          console.log(result);
          setTokenResult({ token: { value: result.token } });
        });

        FCM.setAutoInit({ enabled: true });

        // Check the auto initialization status
        FCM.isAutoInitEnabled().then((r) => {
          console.log('Auto init is ' + (r.enabled ? 'enabled' : 'disabled'));
        });
      }

      registrationHandle = await PushNotifications.addListener(
        'registration',
        (token) => {
          console.log('Push notifications registered');
          console.log('Token=', token.value);
          if (!isIOS) {
            setTokenResult({ token });
          }

          PushNotifications.removeAllDeliveredNotifications();
        }
      );
      registrationErrorHandle = await PushNotifications.addListener(
        'registrationError',
        (err) => {
          console.log('Push notifications registration error');
          console.error(err);
          setTokenResult((r) => ({ ...r, err }));
        }
      );
      notifReceivedHandle = await PushNotifications.addListener(
        'pushNotificationReceived',
        (notif) => {
          console.log('Push notif received');
          console.log(notif);
        }
      );
      notifActionHandle = await PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (action) => {
          console.log('Push notif action performed');
          console.log(action);
        }
      );

      try {
        let permStatus = await PushNotifications.checkPermissions();
        console.log(`Notif permission status=${permStatus}`);

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive === 'granted') {
          console.log(`Registering push notifications...`);
          await PushNotifications.register();
          setStatus('granted');
        } else {
          setStatus('denied');
        }
      } catch (e) {
        console.error(e);
        setStatus('denied');
      }
    })();

    return () => {
      registrationHandle.remove();
      registrationErrorHandle.remove();
      notifReceivedHandle.remove();
      notifActionHandle.remove();
    };
  }, [isWebDevice, isIOS]);

  return status;
}

export default usePushNotifications;
