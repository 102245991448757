import React, { useContext } from 'react';
import md5 from 'blueimp-md5';
import defaultAvatar from 'ionicons/dist/svg/person.svg';
import { AdminContext } from '../context/AdminContext';

const GRAVATAR = 'https://gravatar.com/avatar/';

const getDefaultAvatar = (uid: string) => `${GRAVATAR}${md5(uid)}?d=identicon`;

const getPhotoUrl = (photoUrl?: string, uid?: string) => {
  if (!uid) {
    return defaultAvatar;
  }
  return photoUrl || getDefaultAvatar(uid);
};

function useAvatar(photoUrl?: string, uid?: string) {
  const [avatar, setAvatar] = React.useState(getPhotoUrl(photoUrl, uid));

  React.useEffect(() => {
    setAvatar(getPhotoUrl(photoUrl, uid));
  }, [photoUrl, uid]);

  return avatar;
}

export function useAdminAvatar(uid?: string) {
  const { adminMetadata } = useContext(AdminContext);
  const metadata = uid ? adminMetadata[uid] : undefined;
  return getPhotoUrl(metadata?.photoUrl, uid);
}

export default useAvatar;
