import React from 'react';
import styled from 'styled-components';
import { ReportItem } from '../../../services/buildfire/rdb/reports';
import Hr from '../../common/Hr';
import Typography from '../../common/Typography';

interface ReportItemInfoProps {
  item: ReportItem;
  index: number;
}

const ContentContainer = styled.div`
  padding: 0 16px;
`;

const ReportTitle = styled(Typography)`
  margin: 8px 0 0 0;
`;

const ReportSection = styled.div`
  margin: 16px 0;
`;

const ReportData = styled(Typography)`
  opacity: 0.8;
  white-space: pre-wrap;
`;

const ReportItemInfo: React.FC<ReportItemInfoProps> = ({ item, index }) => {
  return (
    <ContentContainer>
      <Typography variant="secondary" fontSize="20px">
        Site #{index + 1}
      </Typography>
      <ReportSection>
        <ReportTitle variant="secondary">
          Microscopic Interpretation
        </ReportTitle>
        <ReportData>{item.interpretation}</ReportData>
      </ReportSection>
      <ReportSection>
        <ReportTitle variant="secondary">Pathologist's Comments</ReportTitle>
        <ReportData>{item.comments}</ReportData>
      </ReportSection>
      <ReportSection>
        <ReportTitle variant="secondary">Suggested Next Steps</ReportTitle>
        <ReportData>{item.nextSteps}</ReportData>
      </ReportSection>
      <Hr />
    </ContentContainer>
  );
};

export default ReportItemInfo;
