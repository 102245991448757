import React, { useContext } from 'react';
import styled from 'styled-components';
import { useActor } from '@xstate/react';
import SubmitCaseViewProps from './types/SubmitCaseViewProps';
import { useSubmitCaseContext } from '../../context/SubmitCaseContext';
import Button from '../common/Button';
import Typography from '../common/Typography';
import FullscreenLoader from '../common/FullscreenLoader';
import PaymentMethodList, {
  PaymentMethodListProps,
} from './components/paymentList/PaymentMethodList';
import AddNewPaymentMethod, {
  AddNewPaymentMethodProps,
} from './components/paymentList/AddNewPaymentMethod';
import SnackbarContext from '../../context/SnackbarContext';
import { Functions, getFunctions, httpsCallable } from 'firebase/functions';
import { useFirebase } from '../../services/firebase';
import { StripeError } from '@stripe/stripe-js';
import AlertBox from '../common/AlertBox';
import { helpCircle } from 'ionicons/icons';
import InvoiceSelection from './components/InvoiceSelection';
import { PaymentType } from '../../stateMachines/submitCase/selectPaymentType';

type CreditCardProps = SubmitCaseViewProps & {
  isLoading: boolean;
  PaymentMethodListProps: PaymentMethodListProps;
  AddNewPaymentMethodProps: AddNewPaymentMethodProps;
  onNext: () => void;
  onGoBack: () => void;
  nextDisabled: boolean;
  backDisabled: boolean;
  isInvoiceEnabled: boolean;
  error?: Error | string | StripeError;
};

export async function removePaymentMethod(functions: Functions, id: string) {
  const fn = httpsCallable<any, any>(functions, 'removePaymentMethod');

  return await fn({
    paymentMethodId: id,
  });
}

const ButtonContainer = styled.div`
  margin-top: 64px;
  padding: 0 8px;
`;

const AlertContainer = styled.div`
  margin-top: 16px;
  padding: 0 16px;
`;

const Or = styled.div`
  margin: 32px;
  color: var(--mdc-theme-text-primary-on-light);

  display: flex;
  align-items: center;
  text-align: center;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid var(--mdc-theme-text-secondary-on-light);
  }

  &:not(:empty)::before {
    margin-right: 1em;
  }

  &:not(:empty)::after {
    margin-left: 1em;
  }
`;

export const CreditCard: React.FC<CreditCardProps> = ({
  isLoading,
  PaymentMethodListProps,
  AddNewPaymentMethodProps,
  onNext,
  onGoBack,
  nextDisabled,
  backDisabled,
  isInvoiceEnabled,
  error,
}) => {
  const hasError = error && !isLoading;

  return (
    <FullscreenLoader show={isLoading}>
      {!isLoading && (
        <>
          <Typography
            as="h3"
            fontSize="20px"
            variant="secondary"
            margin="16px 8px"
          >
            {isInvoiceEnabled ? 'Backup credit card' : 'Payment'}
          </Typography>

          <Typography fontSize="12px" margin="16px 8px">
            {isInvoiceEnabled
              ? 'In order to add this case to your monthly invoice, you must have a backup credit card on file'
              : 'Select a credit card. Your selected card will not be charged until the case is signed off.'}
          </Typography>

          <PaymentMethodList error={error} {...PaymentMethodListProps} />
          {!hasError && <AddNewPaymentMethod {...AddNewPaymentMethodProps} />}

          {isInvoiceEnabled && (
            <AlertContainer>
              <AlertBox
                icon={helpCircle}
                text="This card will NOT be charged unless your invoice is not paid by the due date. "
              />
            </AlertContainer>
          )}

          <ButtonContainer>
            <Button
              fullWidth
              type="button"
              onClick={onNext}
              disabled={nextDisabled}
            >
              REVIEW
            </Button>
            <Button
              fullWidth
              emphasis="low"
              type="button"
              onClick={onGoBack}
              disabled={backDisabled}
            >
              GO BACK
            </Button>
          </ButtonContainer>
        </>
      )}
    </FullscreenLoader>
  );
};

interface ControlledCreditCardProps extends SubmitCaseViewProps {}

const ControlledCreditCard: React.FC<ControlledCreditCardProps> = ({
  ...props
}) => {
  const { submitCaseService } = useSubmitCaseContext();
  const [state, send] = useActor(submitCaseService);
  const isLoading =
    state.matches({ creditCard: 'load' }) ||
    state.matches({ creditCard: 'confirmSetupIntent' });
  const isInvoicingSelected = state.context.paymentType === PaymentType.INVOICE;
  const { clientSecret } = state.context;
  const numSavedPaymentMethods = state.context.paymentMethods?.length || 0;
  const hasSavedPaymentMethods = numSavedPaymentMethods > 0;
  const [isAddingRemovingPaymentMethod, setIsAddingRemovingPaymentMethod] =
    React.useState(false);
  const { setMessage } = useContext(SnackbarContext);
  const { app, parentAccount } = useFirebase();
  const functions = getFunctions(app);
  const hasError = state.matches({ creditCard: 'error' });

  const showInvoiceSelection = state.matches({
    creditCard: 'selectInvoiceAccount',
  });

  if (showInvoiceSelection) {
    return (
      <InvoiceSelection
        currentUser={props.currentUser}
        parentAccount={parentAccount!}
        selectedInvoiceAccountUid={state.context.selectedInvoiceAccountUid}
        onInvoiceAccountChange={(e) => {
          if (e.target.value !== state.context.selectedInvoiceAccountUid) {
            send({
              type: 'SET_INVOICE_ACCOUNT',
              invoiceAccountUid: e.target.value!,
            });
          }
        }}
        onNext={() => send({ type: 'NEXT' })}
        onGoBack={() => send({ type: 'BACK' })}
      />
    );
  }

  return (
    <CreditCard
      currentUser={props.currentUser}
      error={hasError ? state.context.error || new Error() : undefined}
      isLoading={isLoading}
      isInvoiceEnabled={isInvoicingSelected}
      PaymentMethodListProps={{
        paymentMethods: state.context.paymentMethods!,
        selectedPaymentMethod: state.context.selectedPaymentMethod!,
        onSelectPaymentMethod: async (id) => {
          send({ type: 'SELECT_CARD', id });
        },
        onRemovePaymentMethod: async (id) => {
          setIsAddingRemovingPaymentMethod(true);
          try {
            await removePaymentMethod(functions, id);
            send({ type: 'REMOVE_CREDIT_CARD', paymentMethodId: id });
            setMessage({ text: 'Card successfully removed' });
          } catch (e) {
            console.error('ERROR removing payment method', id, e);
            setMessage({ text: 'Error removing card, please try again' });
          }

          setIsAddingRemovingPaymentMethod(false);
        },
        isLoading,
        disabled: isAddingRemovingPaymentMethod,
      }}
      AddNewPaymentMethodProps={{
        userId: props.currentUser.uid,
        email: props.currentUser.email!,
        clientSecret: clientSecret!,
        isSubmitting: state.matches({
          creditCard: { addCreditCard: 'addingCreditCard' },
        }),
        onSubmit: async ({ stripe, elements }) => {
          send({
            type: 'ADD_CARD',
            stripe,
            elements,
          });
        },
        isDisabled: isAddingRemovingPaymentMethod,
        isDialogOpen: state.matches({ creditCard: 'addCreditCard' }),
        isLoading: state.matches({ creditCard: { addCreditCard: 'load' } }),
        onWillAddCreditCard: () => {
          send({
            type: 'WILL_ADD_CREDIT_CARD',
          });
        },
        onAddCardDialogClose: () => {
          send({ type: 'CLOSE' });
        },
      }}
      onNext={() => send({ type: 'NEXT' })}
      onGoBack={() => send({ type: 'BACK' })}
      nextDisabled={!hasSavedPaymentMethods || isAddingRemovingPaymentMethod}
      backDisabled={isAddingRemovingPaymentMethod}
    />
  );
};

export default ControlledCreditCard;
