import { FirebaseApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { assign, DoneInvokeEvent, MachineConfig } from 'xstate';
import { getInvoices, StripeInvoice } from '../../api/cloudFunctions';
import { isInvoicePastDue } from '../../util/invoice';
import { UserMetadataWithId } from '../../services/firebase/user';

export enum PaymentType {
  CREDIT_CARD = 'creditCard',
  INVOICE = 'invoice',
}

export interface SelectPaymentTypeContext {
  firebaseApp: FirebaseApp;
  userId: string;
  paymentType: PaymentType;
  error?: string | Error;
  invoices: StripeInvoice[];
  hasLoadedInvoices: boolean;
  hasPastDueInvoice: boolean;
  invoicingDisabled: boolean;
  userMetadata?: UserMetadataWithId;
}

export type SelectPaymentTypeEvent =
  | { type: 'SET_PAYMENT_TYPE'; paymentType: PaymentType }
  | { type: 'RETRY' }
  | { type: 'NEXT' };

export async function getUserInvoices(context: SelectPaymentTypeContext) {
  try {
    const fns = getFunctions(context.firebaseApp);
    const result = await getInvoices(fns, { userId: context.userId });
    const hasPastDueInvoice = result.invoices.some(isInvoicePastDue);
    const invoicingDisabled = context.userMetadata?.invoicingDisabled || false;
    return {
      invoices: result.invoices,
      hasLoadedInvoices: true,
      hasPastDueInvoice,
      invoicingDisabled,
    };
  } catch (e: any) {
    console.error(e);
    throw e;
  }
}
const assignResult = assign(
  (context: SelectPaymentTypeContext, ev: DoneInvokeEvent<any>) => {
    return {
      ...context,
      ...ev.data,
    };
  }
);

const selectPaymentTypeStates: MachineConfig<
  SelectPaymentTypeContext,
  any,
  SelectPaymentTypeEvent
> = {
  initial: 'initial',
  context: {
    firebaseApp: null as any,
    userId: '',
    paymentType: PaymentType.CREDIT_CARD,
    invoices: [],
    hasLoadedInvoices: false,
    hasPastDueInvoice: false,
    invoicingDisabled: false,
  },

  states: {
    // Only get invoices if we don't already have them loaded
    initial: {
      always: [
        {
          target: 'selectPaymentMethod',
          cond: 'hasLoadedInvoices',
        },
        {
          target: 'load',
        },
      ],
    },
    load: {
      invoke: {
        src: getUserInvoices,
        onDone: {
          target: 'selectPaymentMethod',
          actions: assignResult,
        },
        onError: {
          target: 'error',
          actions: assign({
            error: (context, ev) => ev.data,
          }),
        },
      },
    },

    selectPaymentMethod: {
      always: [
        {
          target: 'skip',
          cond: 'isInvoicingDisabled',
        },
      ],
      on: {
        SET_PAYMENT_TYPE: {
          actions: 'setPaymentType',
        },
      },
    },

    skip: {
      type: 'final',
    },

    error: {
      on: {
        RETRY: {
          target: 'load',
        },
      },
    },
  },
};

export default selectPaymentTypeStates;
