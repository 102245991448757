import React from 'react';
import styled from 'styled-components';

interface HrProps extends React.HTMLAttributes<HTMLHRElement> {}

const StyledHr = styled.hr`
  //
`;

const Hr: React.FC<HrProps> = (props) => {
  return <StyledHr {...props} />;
};

export default Hr;
