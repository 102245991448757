import * as A from 'fp-ts/Array';

export enum LOADING_STATE {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
}

export const aggregate = (states: LOADING_STATE[]) => {
  if (hasError(states)) {
    return LOADING_STATE.ERROR;
  }
  if (isLoaded(states)) {
    return LOADING_STATE.LOADED;
  }
  return LOADING_STATE.LOADING;
};

export const hasError = A.some<LOADING_STATE>(
  (state) => state === LOADING_STATE.ERROR
);

export const isLoading = A.some<LOADING_STATE>(
  (state) => state === LOADING_STATE.LOADING
);

export const isLoaded = A.every<LOADING_STATE>(
  (state) => state === LOADING_STATE.LOADED
);
