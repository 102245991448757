import React from 'react';
import TextArea from '../../common/TextArea';
import Typography from '../../common/Typography';
import NavButtons from './NavButtons';

interface SubmitFeedbackProps {
  onBack: () => void;
  onNext: (args: { feedback: string }) => void;
}

const SubmitFeedback: React.FC<SubmitFeedbackProps> = ({ onBack, onNext }) => {
  const [feedback, setFeedback] = React.useState('');
  return (
    <div>
      <Typography>
        Can you please share with us what is not working? We make an effort to
        fix bugs as soon as we come across them. If you have had any issues with
        our service please let us know so we can improve.
      </Typography>

      <Typography margin="24px 0">
        Additionally, you may email us at{' '}
        <a href="mailto:info@pocketpathologist.com">
          info@pocketpathologist.com
        </a>{' '}
        and we would be happy to work through any issues with you.
      </Typography>
      <TextArea
        label="Feedback"
        name="description"
        rows={6}
        resizable={false}
        maxLength={4000}
        helpText="Optional"
        onChange={(e) => setFeedback(e.target.value)}
      />

      <NavButtons
        nextText="Continue"
        prevText="Go back"
        onNext={() => onNext({ feedback })}
        onBack={onBack}
      />
    </div>
  );
};

export default SubmitFeedback;
