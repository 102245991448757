import React from 'react';
import Dialog, { DialogProps, DialogActionButton } from '../common/Dialog';

export interface ConfirmationDialogProps extends Partial<DialogProps> {
  isOpen: boolean;
  callback?: (ok: boolean) => void;
  cancelText?: string;
  confirmText?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  callback,
  cancelText = 'Cancel',
  confirmText = 'OK',
  ...props
}) => {
  return (
    <Dialog
      dialogId="confirmation"
      title=""
      onClose={(action) => {
        callback?.(action === 'accept');
      }}
      actions={
        <>
          <DialogActionButton action="close" emphasis="low">
            {cancelText}
          </DialogActionButton>
          <DialogActionButton action="accept">{confirmText}</DialogActionButton>
        </>
      }
      {...props}
    />
  );
};

export default ConfirmationDialog;
