import React from 'react';

interface MenuAnchorProps extends React.HTMLAttributes<HTMLDivElement> {
  menu: React.ReactNode;
}

const MenuAnchor: React.FC<MenuAnchorProps> = ({
  menu,
  children,
  ...props
}) => {
  return (
    <div className="mdc-menu-surface--anchor" {...props}>
      {children}
      {menu}
    </div>
  );
};

export default MenuAnchor;
