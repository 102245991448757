import { css } from 'styled-components';

export const NoUnderlineMixin = css`
  text-decoration: none;

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
`;
