import React from 'react';
import { App, AppState } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { getDatabase } from 'firebase/database';
import { setNotifCount } from '../services/buildfire/rdb/notif';
import { useFirebase } from '../services/firebase';

function useOnAppStateChange(onAppStateChange: (state: AppState) => void) {
  React.useEffect(() => {
    let handler: PluginListenerHandle;
    let canceled = false;

    (async () => {
      handler = await App.addListener('appStateChange', (state) => {
        console.log(`App state isActive: ${state.isActive}`);
        onAppStateChange(state);
      });

      if (canceled) {
        handler.remove();
      }
    })();

    return () => {
      canceled = true;
      handler?.remove();
    };
  }, [onAppStateChange]);
}

function useNotifCountClear() {
  const { app, currentUser } = useFirebase();
  const database = getDatabase(app);
  const clearNotifs = () => {
    if (!currentUser) {
      return;
    }

    console.log('Clearing device notification count...');
    setNotifCount(database, currentUser.uid, 0);
  };

  // Initial clear on app load
  React.useEffect(() => {
    clearNotifs();
  }, [app, currentUser]);

  // Clear on app becoming active
  useOnAppStateChange((state) => {
    if (state.isActive) {
      clearNotifs();
    }
  });
}

export default useNotifCountClear;
