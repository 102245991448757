import React from 'react';
import { useHistory } from 'react-router';
import Dialog, { DialogActionButton, DialogProps } from '../common/Dialog';

interface SimpleErrorDialogProps extends Partial<DialogProps> {
  redirectUrl?: string;
}

const SimpleErrorDialog: React.FC<SimpleErrorDialogProps> = ({
  children,
  redirectUrl = '..',
  ...props
}) => {
  const history = useHistory();
  return (
    <Dialog
      isOpen
      dialogId="invalid-site"
      title=""
      onClose={() => {
        history.push(redirectUrl);
      }}
      actions={
        <>
          <DialogActionButton action="accept">OK</DialogActionButton>
        </>
      }
      {...props}
    >
      {children || 'Invalid page. Click OK to go back'}
    </Dialog>
  );
};

export default SimpleErrorDialog;
