import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import { useFirebase } from '../services/firebase';
import React from 'react';

function useSignInWithEmailLink() {
  const { app } = useFirebase();
  React.useEffect(() => {
    const auth = getAuth(app);
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');
      if (email) {
        signInWithEmailLink(auth, email, window.location.href)
          .then((userCredential) => {
            window.localStorage.removeItem('emailForSignIn');
            window.history.replaceState(
              {},
              document.title,
              window.location.pathname
            );
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.log('No email found for sign-in');
        // TODO prompt for email
      }
    } else {
      console.log('Not a sign-in with email link');
    }
  }, []);
}

export default useSignInWithEmailLink;
