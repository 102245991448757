import React from 'react';
import Typography, { TypographyProps } from '../../common/Typography';

interface TermsAndConditionsProps
  extends React.HTMLAttributes<HTMLDivElement> {}

const Header: React.FC<TypographyProps> = (props) => (
  <Typography as="h4" fontWeight="500" fontSize="16px" {...props} />
);
const P: React.FC<TypographyProps> = (props) => (
  <Typography as="p" margin="4px 0 16px 0" {...props} />
);
const Italic: React.FC<TypographyProps> = (props) => (
  <Typography as="span" fontStyle="italic" {...props} />
);

const TermsAndConditions: React.FC<TermsAndConditionsProps> = (props) => {
  return (
    <div {...props}>
      <Header>Acknowledgement of Limitations</Header>
      <P>
        Digital/telecytology interpretations are made with the assumption that
        the images are representative of both the slides and the lesion.
        Submission of glass slides and biopsy with histologic examination is
        recommended following digital/telecytology as as indicated during
        consultation. The submitting veterinarian (“the Client”) agrees and
        acknowledges that Dr. Kate Baker/Pocket Pathologist, LLC (“the
        Consultant”) will be providing consultation services only, that the
        Client will be the only party under this Agreement to provide clinical
        services to their patients in connection with any of the services
        provided by Pocket Pathologist, LLC, and that the Client will be solely
        responsible for all patient care and treatment decisions and will be
        free to accept or reject any online second opinion consultation services
        and/or advice provided by Pocket Pathologist, LLC.
      </P>

      <Header>Digital Image Quality</Header>
      <P>
        The Client acknowledges it is their responsibility to submit quality
        images suitable for consultation. The Consultant is at liberty to
        request additional photos in order to complete the consultation and may
        decline consultation for any reason, including image quality. The Client
        acknowledges and accepts that charges will be incurred regardless of the
        diagnostic quality of the provided images and has 24 hours to submit
        additional photos at no extra charge as needed.
      </P>

      <Header>Educational/Promotional Sharing</Header>
      <P>
        Unless requested otherwise, the Client agrees to allow any submitted
        photos to be used by Dr. Kate Baker for educational or promotional
        purposes (including on social media) with the patient’s name
        removed/changed and owner information omitted for confidentiality
        purposes.{' '}
        <Italic>
          (Please indicate if you would like to be excluded from this, and your
          cases will not be shared).
        </Italic>
      </P>

      <Header>Payment of Services</Header>
      <P>
        The Client agrees to pay for services at the advertised, current price,
        at the time of service. The Client acknowledges that they are
        responsible for all incurred charges and that failure to pay will result
        in termination of services.
      </P>

      <Header>Termination of Services</Header>
      <P>
        Both the Client and Consultant are at liberty to terminate services for
        any reason at any time, but the Client will be responsible for any
        charges incurred during the term of service.
      </P>
    </div>
  );
};

export default TermsAndConditions;
