import { AlertOptions, IonAlert } from '@ionic/react';
import React from 'react';

interface ConfirmLeaveProps extends AlertOptions {
  isOpen: boolean;
  onStayHere: () => void;
  onContinue: () => void;
}

const ConfirmLeave: React.FC<ConfirmLeaveProps> = ({
  onStayHere,
  onContinue,
  ...props
}) => {
  return (
    <IonAlert
      buttons={[
        {
          text: 'Stay Here',
          handler: onStayHere,
        },
        {
          text: 'Continue',
          handler: onContinue,
        },
      ]}
      {...props}
    />
  );
};

export default ConfirmLeave;
