import React from 'react';

export enum Corner {
  TOP_LEFT = 0,
  TOP_RIGHT = 4,
  // TODO more corners
}

export interface MenuProps extends React.HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  ulProps?: React.HTMLAttributes<HTMLUListElement>;
  anchorCorner?: Corner;
}

export function useMenu<T extends HTMLElement = HTMLDivElement>() {
  const elementRef = React.useRef<T>(null);
  const mdcComponent = React.useRef<mdc.menu.MDCMenu>();
  React.useEffect(() => {
    if (elementRef.current) {
      mdcComponent.current = new mdc.menu.MDCMenu(elementRef.current);
      (window as any).menu = mdcComponent.current;
      return () => mdcComponent.current!.destroy();
    }
  }, []);
  return { elementRef, mdcComponent };
}

const Menu: React.FC<MenuProps> = ({
  isOpen,
  onOpen = () => {},
  onClose = () => {},
  children,
  anchorCorner,
  ...props
}) => {
  const { elementRef, mdcComponent } = useMenu();
  React.useEffect(() => {
    if (mdcComponent.current) {
      mdcComponent.current.listen('MDCMenuSurface:opened', onOpen);
      mdcComponent.current.listen('MDCMenuSurface:closed', onClose);
      (mdcComponent.current as any).setAnchorCorner(anchorCorner);
      return () => {
        mdcComponent.current?.unlisten('MDCMenuSurface:opened', onOpen);
        mdcComponent.current?.unlisten('MDCMenuSurface:closed', onClose);
      };
    }
  }, [onOpen, onClose]);

  React.useEffect(() => {
    if (mdcComponent.current) {
      mdcComponent.current.open = isOpen;
    }
  }, [isOpen]);

  return (
    <div className="mdc-menu mdc-menu-surface" ref={elementRef} {...props}>
      <ul
        className="mdc-list"
        role="menu"
        aria-hidden="true"
        aria-orientation="vertical"
        tabIndex={-1}
      >
        {children}
      </ul>
    </div>
  );
};

export default Menu;
