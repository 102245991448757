import React, { useEffect, useRef } from 'react';
import { useRipple } from './hooks';
import classnames from 'classnames';
import styled from 'styled-components';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: 'outlined' | 'raised';
  fullWidth?: boolean;
  icon?: React.ReactNode;
}

const StyledButton = styled.button<ButtonProps>`
  ${(props) => `
  width: ${props.fullWidth ? '100%' : 'auto'};
`}
`;

const Button: React.FC<ButtonProps> = ({
  buttonType,
  fullWidth,
  icon,
  className,
  children,
  ...props
}) => {
  const ref = useRipple<HTMLButtonElement>();
  return (
    <StyledButton
      className={classnames([
        'mdc-button',
        'mdc-button--touch',
        buttonType && `mdc-button--${buttonType}`,
        className,
      ])}
      fullWidth={fullWidth}
      ref={ref}
      {...props}
    >
      <span className="mdc-button__ripple"></span>
      <span className="mdc-button__touch"></span>
      {icon}
      <span className="mdc-button__label">{children}</span>
    </StyledButton>
  );
};

export default Button;
