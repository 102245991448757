import React from 'react';
import { useHistory } from 'react-router';
import { Location } from 'history';
import styled from 'styled-components';
import classNames from 'classnames';
import { IonPage } from '@ionic/react';

type PageProps = React.HTMLAttributes<HTMLDivElement> & {
  ContainerComponent?: React.ComponentType<React.HTMLAttributes<HTMLElement>>;
  justifyContent?: React.CSSProperties['justifyContent'];
  alignItems?: React.CSSProperties['alignItems'];
  backgroundType?: 'background' | 'surface';
};

const Container = styled(IonPage)<{
  $justifyContent: PageProps['justifyContent'];
  $alignItems: PageProps['alignItems'];
  $background: PageProps['backgroundType'];
}>`
  grid-area: main;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  // padding-bottom: 70px;
  ${(props) => `
    background-color: var(--mdc-theme-${props.$background});
    justify-content: ${props.$justifyContent};
    align-items: ${props.$alignItems};
  `}
`;

function useLocationChange(onChange: (loc: Location<unknown>) => void) {
  const history = useHistory();
  React.useEffect(() => {
    const unlisten = history.listen((newLocation) => {
      onChange(newLocation);
    });
    return () => {
      unlisten();
    };
  }, [history]);
}

function useScrollOnNavigate<T extends HTMLElement = HTMLElement>(
  el: T | Window | null = window
) {
  useLocationChange(() => {
    const elementToScroll = el || window;
    elementToScroll.scrollTo(0, 0);
  });
}

const Page: React.FC<PageProps> = ({
  ContainerComponent = Container,
  justifyContent,
  alignItems,
  backgroundType = 'background',
  className,
  ...props
}) => {
  useScrollOnNavigate(document.body);

  return (
    <ContainerComponent
      $justifyContent={justifyContent}
      $alignItems={alignItems}
      $background={backgroundType}
      className={classNames('Page', className)}
      {...props}
    />
  );
};

export default Page;
