import {
  IonApp,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonModal,
} from '@ionic/react';
import React from 'react';
import { useLongPress } from 'use-long-press';
import styled from 'styled-components';
import * as LocalStorage from '../../constants/localStorage';

interface SettingsOverrideModalProps {
  onClose: () => void;
}

enum DEBUG_SETTINGS {
  DEV = 'dev',
  STAGING = 'staging',
  PROD = 'prod',
}

const SETTINGS_URLS = {
  [DEBUG_SETTINGS.DEV]: 'config/dev.json',
  [DEBUG_SETTINGS.STAGING]: 'config/staging.json',
  [DEBUG_SETTINGS.PROD]: 'config/prod.json',
};

const ButtonRow = styled(IonRow)`
  margin: 16px 0;
  justify-content: center;
`;

const SettingsOverrideModal: React.FC<SettingsOverrideModalProps> = (props) => {
  const [selectedUrl, setSelectedUrl] = React.useState<string>(
    (window as any).__SETTINGS_URL__
  );

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings Override</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                console.log('click');
                props.onClose();
              }}
            >
              CLOSE
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>
          <IonRadioGroup
            value={selectedUrl}
            onIonChange={(e) => {
              const url = e.detail.value;
              console.log(`Settings url override: ${url}`);
              if (url) {
                localStorage.setItem(LocalStorage.SETTINGS_URL_OVERRIDE, url);
                setSelectedUrl(url);
              }
            }}
          >
            <IonItem>
              <IonRadio
                value={SETTINGS_URLS[DEBUG_SETTINGS.DEV]}
                justify="space-between"
              >
                Development
              </IonRadio>
            </IonItem>
            <IonItem>
              <IonRadio
                value={SETTINGS_URLS[DEBUG_SETTINGS.STAGING]}
                justify="space-between"
              >
                Staging
              </IonRadio>
            </IonItem>
            <IonItem>
              <IonRadio
                value={SETTINGS_URLS[DEBUG_SETTINGS.PROD]}
                justify="space-between"
              >
                Production
              </IonRadio>
            </IonItem>
          </IonRadioGroup>
        </IonList>
        <ButtonRow>
          <IonButton
            onClick={() => {
              localStorage.removeItem(LocalStorage.SETTINGS_URL_OVERRIDE);
              window.location.reload();
            }}
          >
            Reset to default
          </IonButton>
        </ButtonRow>

        <ButtonRow>
          <IonButton
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh
          </IonButton>
        </ButtonRow>
      </IonContent>
    </>
  );
};

export function useSettingsOverrideModalProps() {
  const [present, dismiss] = useIonModal(SettingsOverrideModal, {
    onClose: () => {
      dismiss();
    },
  });
  const bind = useLongPress(
    () => {
      present();
    },
    {
      threshold: 3000,
    }
  );

  return bind();
}

export default SettingsOverrideModal;
