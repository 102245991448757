import { assign } from 'xstate';
import { CreditCardContext, CreditCardEvent } from './creditCard';
import { SubmitCaseEvent, SubmitCaseContext } from './types';
import {
  PaymentType,
  SelectPaymentTypeContext,
  SelectPaymentTypeEvent,
} from './selectPaymentType';

export const setCaseInfo = assign<SubmitCaseContext, SubmitCaseEvent>({
  caseInfo: (context, ev: any) => {
    if (ev.type === 'NEXT') {
      return ev.caseInfo;
    }
    console.warn(
      `WARNING: #setCaseInfo action expected NEXT event but received ${ev.type}`
    );
    return context.caseInfo;
  },
});

// Credit Card events
export const removePaymentMethod = assign<CreditCardContext, CreditCardEvent>(
  (context, ev) => {
    if (ev.type !== 'REMOVE_CREDIT_CARD') {
      console.warn(
        `WARNING: #setCreditCardInfo action expected NEXT event but received ${ev.type}`
      );
      return context;
    }

    const paymentMethods =
      context.paymentMethods?.filter(({ id }) => id !== ev.paymentMethodId) ||
      [];
    return {
      selectedPaymentMethod:
        context.selectedPaymentMethod === ev.paymentMethodId
          ? paymentMethods[0]?.id
          : context.selectedPaymentMethod,
      paymentMethods,
    };
  }
);

export const selectPaymentMethod = assign<CreditCardContext, CreditCardEvent>({
  selectedPaymentMethod: (context, ev) => {
    if (ev.type !== 'SELECT_CARD') {
      console.warn(
        `WARNING: #setCreditCardInfo action expected NEXT event but received ${ev.type}`
      );
      return context.selectedPaymentMethod;
    }

    return ev.id;
  },
});

export const setInvoiceAccount = assign<CreditCardContext, CreditCardEvent>({
  selectedInvoiceAccountUid: (context, ev) => {
    if (ev.type !== 'SET_INVOICE_ACCOUNT') {
      console.warn(
        `WARNING: #setInvoiceAccount action expected SET_INVOICE_ACCOUNT event but received ${ev.type}`
      );
      return context.selectedInvoiceAccountUid;
    }
    return ev.invoiceAccountUid;
  },
});

export const updateParentAccount = assign<SubmitCaseContext, SubmitCaseEvent>({
  parentAccount: (context, ev) => {
    if (ev.type !== 'PARENT_ACCOUNT_CHANGE') {
      console.warn(
        `WARNING: #updateParentAccount action expected PARENT_ACCOUNT_CHANGE event but received ${ev.type}`
      );
      return context.parentAccount;
    }
    return ev.parentAccount;
  },
});

export const setPaymentType = assign<
  SelectPaymentTypeContext,
  SelectPaymentTypeEvent
>((context, ev) => {
  if (ev.type !== 'SET_PAYMENT_TYPE') {
    console.warn(
      `WARNING: #setPaymentType action expected SET_PAYMENT_TYPE event but received ${ev.type}`
    );
    return context;
  }
  return {
    ...context,
    paymentType: ev.paymentType,
    invoicingEnabled: ev.paymentType === PaymentType.INVOICE,
  };
});
