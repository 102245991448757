import React from 'react';
import styled from 'styled-components';

interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  backgroundColor: string;
  fontColor: string;
  uniformWidth?: boolean;
}

const Status = styled.span<{
  $fontColor: string;
  $backgroundColor: string;
  $margin?: string;
  $uniformWidth?: boolean;
}>`
  padding: 4px 6px;
  border-radius: 4px;
  // width: 80px;
  text-align: center;
  font-size: 11px;
  // line-height: 22px;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  color: ${(props) => props.$fontColor};
  background-color: ${(props) => props.$backgroundColor};
  // margin: ${(props) => props.$margin};
  ${(props) => (props.$uniformWidth ? 'width: 70px;' : '')}
`;

const Badge: React.FC<BadgeProps> = ({
  fontColor,
  backgroundColor,
  uniformWidth,
  children,
  ...props
}) => {
  return (
    <Status
      $fontColor={fontColor}
      $backgroundColor={backgroundColor}
      $uniformWidth={uniformWidth}
      {...props}
    >
      {children}
    </Status>
  );
};

export default Badge;
