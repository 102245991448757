import React from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Case } from '../../services/buildfire/rdb/cases';
import Typography from '../common/Typography';
import * as RDBCases from '../../services/buildfire/rdb/cases';
import UnreadNotif, { UnreadNotifProps } from '../common/UnreadNotif';
import { formatAge } from '../../util/age';
import { IonAvatar, IonItem, IonLabel, IonNote } from '@ionic/react';
import classNames from 'classnames';
import { User } from 'firebase/auth';
import useQueryParams from '../../hooks/useQueryParams';
import { useAdminAvatar } from '../../hooks/useAvatar';

const StyledUnreadNotif = styled(UnreadNotif)`
  line-height: 12px;
  margin-left: 6px;
`;

const Item = styled(IonItem)`
  --background-hover: #434180;
  &.selected {
    --background: #43418022;
  }
`;

const EndNote = styled(IonNote)`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const PlaceholderAvatar = styled.div`
  width: 40px;
  height: 40px;
`;

export interface CaseListItemProps {
  currentUser: User;
  case: Case;
  rightHandContent?: string | React.ReactNode;
  isUnread: boolean;
  selected?: boolean;
  UnreadNotifComponent?: React.ComponentType<UnreadNotifProps>;
  assignedTo?: Case['acceptedBy'];
  showAssignment?: boolean;
}

const AssignedTo: React.FC<{ photoUrl?: string; uid?: string }> = ({
  uid,
  photoUrl,
}) => {
  const url = useAdminAvatar(uid);
  const ImgComponent = uid ? 'img' : PlaceholderAvatar;
  return (
    <IonAvatar slot="end">
      <ImgComponent src={url} />
    </IonAvatar>
  );
};

const CaseListItem: React.FC<CaseListItemProps> = ({
  case: c,
  currentUser,
  isUnread,
  rightHandContent,
  selected,
  UnreadNotifComponent = StyledUnreadNotif,
  assignedTo,
  showAssignment,
}) => {
  const match = useRouteMatch();
  const queryParams = useQueryParams();
  const casePath = generatePath(match.path, {
    ...match.params,
    caseId: c.id,
  });
  const queryParamStr = queryParams.toString();
  const history = useHistory();
  return (
    <Item
      onClick={() => {
        history.push(`${casePath}${queryParamStr ? `?${queryParamStr}` : ''}`);
      }}
      className={classNames({
        selected,
      })}
    >
      <IonLabel>
        <Typography
          as="h2"
          variant={isUnread ? 'secondary' : undefined}
          fontSize="16px"
          fontWeight={isUnread ? 'bold !important' : undefined}
          lineHeight="1.2em"
        >
          {c.patient.first} {c.patient.last}
        </Typography>
        <Typography as="p">
          {RDBCases.SpeciesMap[c.species]} · {formatAge(c.age, c.ageMonths)}
        </Typography>
      </IonLabel>
      <EndNote slot="end">
        {rightHandContent}
        {isUnread && !showAssignment && (
          <UnreadNotifComponent variant="secondary" />
        )}
      </EndNote>
      {showAssignment && (
        <AssignedTo photoUrl={assignedTo?.photoUrl} uid={assignedTo?.uid} />
      )}
    </Item>
  );
};

export default CaseListItem;
