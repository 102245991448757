import 'regenerator-runtime/runtime.js'; // TODO this is hack to get around a bug
import { PaymentMethod, SetupIntent } from '@stripe/stripe-js';
import { Case } from '../services/buildfire/rdb/cases';
import { Message } from '../services/buildfire/rdb/messages';
import { httpsCallable, Functions } from 'firebase/functions';
import { User } from 'firebase/auth';
import * as O from 'fp-ts/Option';
import { UserMetadataWithId } from '../services/firebase/user';
import { getVersion } from '../util/app';

interface GetPaymentMethodsResult {
  stripeCustomerId: string;
  paymentMethods: PaymentMethod[];
}
export const getPaymentMethods = async (
  fns: Functions,
  args: {
    userId: string;
    email: string;
    name: string;
  }
) => {
  const appVersion = await getVersion();
  const fn = httpsCallable(fns, 'getStripePaymentMethods');

  const result = await fn({
    userId: args.userId,
    email: args.email,
    name: args.name,
    appVersion,
  });

  return result.data as GetPaymentMethodsResult;
};

interface SetupIntentArgs {
  userId: string;
  email: string;
  name: string;
  confirm?: {
    setupIntentId?: string;
    paymentMethodId: string;
  };
}
interface SetupIntentResult {
  setupIntent: SetupIntent;
  stripeCustomerId: string;
  paymentMethods: PaymentMethod[];
}
export const setupIntent = async (fns: Functions, args: SetupIntentArgs) => {
  const fn = httpsCallable(fns, 'stripeSetupIntent');
  const result = await fn(args);
  return result.data as SetupIntentResult;
};

interface SubmitCaseArguments {
  case: Omit<Case, 'id' | 'createdAt' | 'status' | 'lastModified'>;
  user: {
    id: string;
    name: string;
    email: string;
  };
  paymentMethodId?: string;
  setupIntentId?: string;
  invoiceAccount?: string;
}
interface SubmitCaseResult {
  case: Case;
  customerId: string;
  invoice: {
    products: { id: string; amount: number }[];
    stripeCustomerId: string;
    paymentMethodId: string;
    setupIntentId: string;
  };
}
export const submitCase = async (fns: Functions, args: SubmitCaseArguments) => {
  const fn = httpsCallable(fns, 'submitCase');
  const appVersion = await getVersion();
  const result = await fn({
    ...args,
    appVersion,
  });
  return result.data as SubmitCaseResult;
};

interface UpdateCaseArguments {
  case: Omit<Case, 'createdAt' | 'status' | 'lastModified'>;
  user: {
    id: string;
    name: string;
    email: string;
  };
  paymentMethodId?: string;
  setupIntentId?: string;
  invoiceAccount?: string;
}
interface UpdateCaseResult {
  case: Case;
  customerId: string;
  invoice: {
    products: { id: string; amount: number }[];
    stripeCustomerId: string;
    paymentMethodId: string;
    setupIntentId: string;
  };
}
export const updateCase = async (fns: Functions, args: UpdateCaseArguments) => {
  const fn = httpsCallable(fns, 'updateCase');
  const result = await fn(args);
  return result.data as UpdateCaseResult;
};

interface SendMessageArguments {
  caseId: string;
  message: Omit<Message, 'id' | 'timestamp'>;
}
interface SendMessageResult {
  message: Message;
}
export const sendMessage = async (
  fns: Functions,
  args: SendMessageArguments
) => {
  const fn = httpsCallable(fns, 'sendMessage');
  const result = await fn(args);
  return result.data as UpdateCaseResult;
};

export interface FindUserByPhoneArguments {
  phoneNumber: string;
}
export const findUserByPhoneNumber = async (
  fns: Functions,
  args: FindUserByPhoneArguments
): Promise<O.Option<User>> => {
  try {
    const fn = httpsCallable(fns, 'findUserByPhone');
    const result = await fn(args);
    const user = (result.data as any).user;
    return O.fromNullable(user as User);
  } catch (e) {
    console.error(e);
    return O.none;
  }
};

export interface InviteUserArguments {
  inviteEmail: string;
  parentUid: string;
  parentName: string;
}
export const inviteUser = async (fns: Functions, args: InviteUserArguments) => {
  try {
    const fn = httpsCallable(fns, 'invite');
    return fn(args);
  } catch (e) {
    console.error(e);
  }
};

export interface AcceptInvitationArguments {
  token: string; // database ID of the invitation object
}
export const acceptInvitation = async (
  fns: Functions,
  args: AcceptInvitationArguments
) => {
  try {
    const fn = httpsCallable(fns, 'confirmInvite');
    return fn(args);
  } catch (e) {
    console.error(e);
  }
};

export interface RemoveUserArguments {
  uid?: string;
  email?: string;
}
export const removeUserFromAccount = async (
  fns: Functions,
  args: RemoveUserArguments
) => {
  try {
    const fn = httpsCallable(fns, 'removeUserFromAccount');
    return fn(args);
  } catch (e) {
    console.error(e);
  }
};

export interface SubaccountsArguments {
  uid: string;
}
export interface SubaccountsResult {
  users: Partial<UserMetadataWithId>[];
}
export const getSubaccounts = async (
  fns: Functions,
  args: SubaccountsArguments
) => {
  try {
    const fn = httpsCallable<SubaccountsArguments, SubaccountsResult>(
      fns,
      'subaccounts'
    );
    return fn(args);
  } catch (e) {
    console.error(e);
  }
};

export interface GetUserByIdArguments {
  userId: string;
}
export interface GetUserByIdResult {
  user: User;
}

export const getUserById = async (
  fns: Functions,
  args: GetUserByIdArguments
) => {
  try {
    const fn = httpsCallable<GetUserByIdArguments, GetUserByIdResult>(
      fns,
      'getUserById'
    );
    return (await fn(args)).data!;
  } catch (e) {
    console.error(e);
  }
};

export interface GetParentAccountArguments {
  userId: string;
}

export interface ParentUser {
  uid: string;
  displayName: string;
  photoURL: string;
  email: string;
}
export interface GetParentAccountResult {
  parentUser: ParentUser;
}

export const getParentAccount = async (
  fns: Functions,
  args: GetParentAccountArguments
) => {
  try {
    const fn = httpsCallable<GetParentAccountArguments, GetParentAccountResult>(
      fns,
      'getParentAccount'
    );
    return (await fn(args)).data!;
  } catch (e) {
    console.error(e);
  }
};

export interface AdminUsersArguments {
  pageToken?: string;
  pageSize?: number;
}
export interface AdminUsersResult {
  users: User[];
  pageToken?: string;
}

export const getAdminUsers = async (
  fns: Functions,
  args: AdminUsersArguments
) => {
  const fn = httpsCallable<AdminUsersArguments, AdminUsersResult>(
    fns,
    'adminUsers'
  );
  return (await fn(args)).data!;
};

export interface StripeInvoice {
  amountDue: number;
  amountPaid: number;
  attempted: boolean;
  chargeId: string;
  customerId: string;
  description: string;
  livemode: boolean;
  metadata?: {
    uid: string;
    name: string;
    email: string;
  };
  dueDate: number;
  paid: boolean;
  id: string;
  periodStart?: number;
  periodEnd?: number;
  status: string;
  subtotal: number;
  total: number;
  lineItems: StripeLineItem[];
  invoiceUrl?: string | null;
}

export interface StripeLineItem {
  amount: number;
  currency: string;
  description: string;
  id: string;
  invoiceItem?: string;
  metadata: {};
  period: {
    start: number;
    end: number;
  };
  quantity: number;
}

export interface GetInvoicesArguments {
  userId: string;
}

export interface GetInvoicesResult {
  invoices: StripeInvoice[];
}

export const getInvoices = async (
  fns: Functions,
  args: GetInvoicesArguments
) => {
  const fn = httpsCallable<GetInvoicesArguments, GetInvoicesResult>(
    fns,
    'getInvoices'
  );
  return (await fn(args)).data!;
};

//////////////
/// TEST FUNCTION FOR INVOICING
/// Should not be used in production, only for testing (or forcing invoice generation)
//////////////

export interface GenerateInvoiceArguments {
  userId: string;
}

export interface GenerateInvoiceResult {
  status: 'ok';
}

export const generateInvoice = async (
  fns: Functions,
  args: GenerateInvoiceArguments
) => {
  const fn = httpsCallable<GetInvoicesArguments, GetInvoicesResult>(
    fns,
    'generateInvoice'
  );
  return (await fn(args)).data!;
};

export interface ResetPasswordArguments {
  email: string;
}

export interface ResetPasswordResult {}

export const resetPassword = async (fns: Functions, email: string) => {
  try {
    const fn = httpsCallable<ResetPasswordArguments, ResetPasswordResult>(
      fns,
      'resetPassword'
    );
    return (await fn({ email })).data!;
  } catch (e) {
    console.error(e);
  }
};

export interface SendEmailVerificationArguments {
  email: string;
}

export interface SendEmailVerificationResult {}

export const sendEmailVerification = async (fns: Functions, email: string) => {
  try {
    const fn = httpsCallable<
      SendEmailVerificationArguments,
      SendEmailVerificationResult
    >(fns, 'sendEmailVerification');
    return (await fn({ email })).data!;
  } catch (e) {
    console.error(e);
  }
};

export interface GetCurrentInvoiceArguments {
  userId: string;
}

export interface GetCurrentInvoiceResult {
  invoice?: StripeInvoice;
}

export const getCurrentInvoice = async (fns: Functions, userId: string) => {
  try {
    const fn = httpsCallable<
      GetCurrentInvoiceArguments,
      GetCurrentInvoiceResult
    >(fns, 'getCurrentInvoice');
    return (await fn({ userId })).data!;
  } catch (e) {
    console.error(e);
  }
};

export interface GetOpenInvoicesArguments {}

export interface GetOpenInvoicesResult {
  invoices: StripeInvoice[];
}

export const getOpenInvoices = async (fns: Functions) => {
  try {
    const fn = httpsCallable<GetOpenInvoicesArguments, GetOpenInvoicesResult>(
      fns,
      'getOpenInvoices'
    );
    return (await fn({})).data!;
  } catch (e) {
    console.error(e);
  }
};

export interface SetAdminArguments {
  uid: string;
  admin: boolean;
}

export interface SetAdminResult {
  status: 'ok' | 'error';
}

export const setAdmin = async (fns: Functions, args: SetAdminArguments) => {
  try {
    const fn = httpsCallable<SetAdminArguments, SetAdminResult>(
      fns,
      'setAdmin'
    );
    return (await fn(args)).data!;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export interface ImpersonateUserArguments {
  uid: string;
}

export interface ImpersonateUserResult {
  status: 'ok' | 'error';
  authToken: string;
}
export const impersonateUser = async (
  fns: Functions,
  args: ImpersonateUserArguments
) => {
  try {
    const fn = httpsCallable<ImpersonateUserArguments, ImpersonateUserResult>(
      fns,
      'impersonateUser'
    );
    return (await fn(args)).data!;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export interface SendWelcomeEmailArguments {
  email: string;
}
export interface SendWelcomeEmailResult {
  status: 'ok' | 'error';
}
export const sendWelcomeEmail = async (
  fns: Functions,
  args: SendWelcomeEmailArguments
) => {
  try {
    const fn = httpsCallable<SendWelcomeEmailArguments, SendWelcomeEmailResult>(
      fns,
      'sendWelcomeEmail'
    );
    return (await fn(args)).data!;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export interface GetPasswordSetupLinkArguments {
  token: string;
}
export interface GetPasswordSetupLinkResult {
  link: string;
}
export const getPasswordSetupLink = async (
  fns: Functions,
  args: GetPasswordSetupLinkArguments
) => {
  try {
    const fn = httpsCallable<
      GetPasswordSetupLinkArguments,
      GetPasswordSetupLinkResult
    >(fns, 'getPasswordSetupLink');
    return (await fn(args)).data!;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export interface SignInWithEmailArguments {
  email: string;
}
export interface SignInWithEmailResult {
  status: 'ok' | 'error';
}
export const signInWithEmail = async (
  fns: Functions,
  args: SignInWithEmailArguments
) => {
  try {
    const fn = httpsCallable<SignInWithEmailArguments, SignInWithEmailResult>(
      fns,
      'emailSignIn'
    );
    return (await fn(args)).data!;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export interface GetInvoiceTemplatesArguments {}
export interface GetInvoiceTemplatesResult {
  templates: {
    id: string;
    nickname: string;
    status: 'active' | 'archived';
    version: string;
    created: number;
    livemode: boolean;
    metadata: {
      [name: string]: string;
    };
  }[];
}

export const getInvoiceTemplates = async (fns: Functions) => {
  try {
    const fn = httpsCallable<
      GetInvoiceTemplatesArguments,
      GetInvoiceTemplatesResult
    >(fns, 'getInvoiceTemplates');
    return (await fn({})).data!;
  } catch (e) {
    console.error(e);
  }
};

export interface SetInvoiceTemplateArguments {
  templateId: string;
  uid: string;
}
export interface SetInvoiceTemplateResult {
  status: 'ok' | 'error';
}
export const setInvoiceTemplate = async (
  fns: Functions,
  args: SetInvoiceTemplateArguments
) => {
  try {
    const fn = httpsCallable<
      SetInvoiceTemplateArguments,
      SetInvoiceTemplateResult
    >(fns, 'setInvoiceTemplate');
    return (await fn(args)).data!;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
