import React from 'react';
import * as LocalStorage from '../constants/localStorage';
import { openDialog } from './DialogContext';

export const SETTINGS_URLS = {
  DEV: 'config/dev.json',
  STAGING: 'config/staging.json',
  PROD: 'config/prod.json',
};

const SETTINGS_URL_OVERRIDE: string | null = localStorage.getItem(
  LocalStorage.SETTINGS_URL_OVERRIDE
);
const SETTINGS_URL: string =
  SETTINGS_URL_OVERRIDE || (process.env.REACT_APP_CONFIG as any);
(window as any).__SETTINGS_URL__ = SETTINGS_URL;

interface Settings {
  host: string;
  baseUrl: string;
  prices: {
    singleSite: number;
    additionalSite: number;
    afterHours: number;
  };
  businessHours: {
    start: string;
    end: string;
  };
  stripe: {
    publishableKey: string;
  };
  firebase: {
    apiKey: string;
    authDomain: string;
    appId: string;
    measurementId: string;
    storageBucket: string;
    messagingSenderId: string;
    projectId: string;
    realtimeIntegrationToken: string;
    fcmVapidKey: string;
  };
  debugMode: boolean;
}
interface ISettingsContext {
  settings: Settings;
  isLoading: boolean;
  lastUpdated: string;
  settingsUrl: string;
}

type ReducerState = Pick<
  ISettingsContext,
  'settings' | 'isLoading' | 'lastUpdated'
> & {
  settingsUrl: string;
};

type Action =
  | {
      type: 'SET_SETTINGS';
      settings: Partial<Settings>;
      lastUpdated?: string;
    }
  | {
      type: 'SET_SETTINGS_URL';
      settingsUrl: string;
    };

const SettingsContext = React.createContext<ISettingsContext>(undefined as any);

const PROD_SETTINGS: Settings = {
  baseUrl: '',
  host: 'https://app.pocketpathologist.com/',
  prices: {
    singleSite: 47,
    additionalSite: 37,
    afterHours: 75,
  },
  businessHours: {
    start: '',
    end: '',
  },
  stripe: {
    publishableKey:
      'pk_live_51IeRKDAYUfy0xlF5QC9rWRNbW7gOanVCvDsuSUNAtTCS5pPWv483nI68FTRPVnNoK5gqBy2SurYpHFcLe4PMXhag00VYv6dm68',
  },
  firebase: {
    apiKey: 'AIzaSyBLBvXvOIDfgaZiM15qEEVCHh-HAh0us9w',
    appId: '1:1098827113054:web:9bf780d38050b3a63b4acf',
    measurementId: 'G-XWTV45YXHG',
    messagingSenderId: '1098827113054',
    projectId: 'pocket-pathologist',
    authDomain: 'pocket-pathologist.firebaseapp.com',
    storageBucket: 'pocket-pathologist.appspot.com',
    realtimeIntegrationToken: 'cb8b40da-9f28-11ec-bd24-0a9dc8c0540b',
    fcmVapidKey:
      'BAR3UKBmpcgkftNAE1WgiE6K1YvRKXmE-FWjsUmobg85OdyyjqbS01deMe5vJDSvx6UwBupU0HtbJhzvMD21eEc',
  },
  debugMode: false,
};

const TEST_SETTINGS: Settings = {
  host: 'https://app.jeremyberglund.dev/',
  baseUrl: '',
  prices: {
    singleSite: 47,
    additionalSite: 37,
    afterHours: 75,
  },
  businessHours: {
    start: '',
    end: '',
  },
  stripe: {
    publishableKey: 'pk_test_wr4PR8dzjqjGQRDJADzoHMkf',
  },
  firebase: {
    apiKey: 'AIzaSyCXffpMPdDPY6FfniSOGxJvS9wIvxqNklM',
    appId: '1:634555049217:web:6979bec5dbdde5d6336700',
    measurementId: 'G-XJ3VRZ0RNP',
    messagingSenderId: '634555049217',
    projectId: 'buildfire-test1',
    authDomain: 'buildfire-test1.firebaseapp.com',
    storageBucket: 'buildfire-test1.appspot.com',
    realtimeIntegrationToken: '576976f3-d46e-11eb-9fe6-12a56cc33887',
    fcmVapidKey:
      'BAEd-YPh3cVo5H-wrqmzYcfLkbdTu7FRgw1i5RCZveEGYc1XecdwnV41x1SbKzToFQI3n_jEokR_KMzAndJcwvk',
  },
  debugMode: false,
};

const DEFAULT_SETTINGS: Settings = {
  host: '',
  baseUrl: '',
  prices: {
    singleSite: 47,
    additionalSite: 37,
    afterHours: 75,
  },
  businessHours: {
    start: '',
    end: '',
  },
  stripe: {
    publishableKey: '',
  },
  firebase: {
    projectId: '',
    apiKey: '',
    realtimeIntegrationToken: '',
    authDomain: '',
    storageBucket: '',
    appId: '',
    measurementId: '',
    messagingSenderId: '',
    fcmVapidKey: '',
  },
  debugMode: false,
};

function isEqual(obj1: any, obj2: any) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  return keys1.every((key) => obj1[key] === obj2[key]);
}

export const SettingsProvider: React.FC<{}> = ({ children, ...props }) => {
  function reducer(state: ReducerState, action: Action): ReducerState {
    switch (action.type) {
      case 'SET_SETTINGS':
        return {
          ...state,
          isLoading: false,
          lastUpdated: action.lastUpdated || new Date().toISOString(),
          settings: {
            ...DEFAULT_SETTINGS,
            ...state.settings,
            ...action.settings,
            // ...TEST_SETTINGS,
            // ...PROD_SETTINGS,
          },
        };
      case 'SET_SETTINGS_URL': {
        (window as any).__SETTINGS_URL__ = action.settingsUrl;
        return {
          ...state,
          settingsUrl: action.settingsUrl,
        };
      }
      default:
        throw new Error(`Reducer type ${(action as any).type} not supported`);
    }
  }
  const [state, dispatch] = React.useReducer(reducer, {
    settingsUrl: SETTINGS_URL,
    isLoading: true,
    lastUpdated: '',
    settings: DEFAULT_SETTINGS,
  });

  React.useEffect(() => {
    const baseUrl = window.location.origin;
    console.log(`SETTINGS_URL=${state.settingsUrl}`);
    fetch(`${baseUrl}/${state.settingsUrl}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((settings) => {
        console.log({ settings });
        dispatch({
          type: 'SET_SETTINGS',
          settings,
        });
      })
      .catch((e) => {
        console.error(e);
        openDialog(<div>ERROR: No internet connection</div>);
      });
  }, [state.settingsUrl]);

  return (
    <SettingsContext.Provider
      key={JSON.stringify(state.settings)}
      value={{ ...state }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export function useBuildfireSettings() {
  const context = React.useContext(SettingsContext);
  return context;
}

export default SettingsContext;
