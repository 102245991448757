import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  applyActionCode,
  checkActionCode,
  getAuth,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { IonButton, IonIcon } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import useFetch from '../../hooks/useFetch';
import { useFirebase } from '../../services/firebase';
import useQueryParams from '../../hooks/useQueryParams';
import LoadingScreen from '../../components/common/LoadingScreen';
import Typography from '../../components/common/Typography';
import { Message } from '../login/common/styledComponents';
import { informationCircle } from 'ionicons/icons';

interface RecoverEmailProps {}

const Success = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0;
`;

const URL = process.env.REACT_APP_URL || window.location.origin;

const RecoverEmail: React.FC<RecoverEmailProps> = () => {
  const { app, signOut } = useFirebase();
  const params = useQueryParams();
  const history = useHistory();
  const code = params.get('oobCode') || '';
  const [restoredEmail, setRestoredEmail] = React.useState<string>();
  const [message, setMessage] = React.useState<{
    text: string;
    type: string;
  }>();

  const { loadingStatus, data, error } = useFetch(async () => {
    const auth = getAuth(app);
    console.log('code', code);

    const info = await checkActionCode(auth, code);
    setRestoredEmail(info.data.email || 'N/A');

    return applyActionCode(auth, code)
      .then(signOut)
      .catch((e) => {
        switch (e.code) {
          case 'auth/invalid-action-code':
            throw new Error(
              `Email recovery link invalid or may have already been used`
            );
          case 'auth/expired-action-code': {
            throw new Error(
              'Your email verification link has expired. Please try again or contact an administrator for help.'
            );
          }
          default:
            throw e;
        }
      });
  });
  return (
    <LoadingScreen loadingStatus={loadingStatus} error={error}>
      <Success>
        <IonIcon
          color="success"
          icon={checkmarkCircle}
          style={{ marginRight: '8px', fontSize: '20px' }}
        />

        <Typography variant="primary">
          Email successfully changed back to {restoredEmail}
        </Typography>
      </Success>

      {message && (
        <Message>
          <IonIcon icon={informationCircle} />
          <Typography margin="0">{message.text}</Typography>
        </Message>
      )}

      <IonButton
        expand="block"
        onClick={() => {
          history.push('/login');
        }}
        style={{ margin: '32px 0' }}
      >
        Login
      </IonButton>

      <IonButton
        fill="clear"
        expand="block"
        onClick={async () => {
          try {
            const auth = getAuth(app);
            const baseUrl = URL.endsWith('/') ? URL.slice(0, -1) : URL;
            await sendPasswordResetEmail(auth, restoredEmail!, {
              url: `${baseUrl}/login`,
            });
            setMessage({
              text: 'Please check your email for a link to reset your password.',
              type: '',
            });
          } catch (e) {
            console.error(e);
            setMessage({
              text: 'There was a problem sending your password reset email. Please try again.',
              type: '',
            });
          }
        }}
      >
        Send Password Reset Email
      </IonButton>
    </LoadingScreen>
  );
};

export default RecoverEmail;
