import React from 'react';
import { Redirect } from 'react-router-dom';
import ResetPassword from './resetPassword/ResetPassword';
import RecoverEmail from './RecoverEmail';
import VerifyEmail from './VerifyEmail';
import LoginScreen from '../login/LoginScreen';
import useQueryParams from '../../hooks/useQueryParams';

interface UserManagementProps {}

type Mode = 'resetPassword' | 'recoverEmail' | 'verifyEmail';

const Components = {
  resetPassword: { title: 'Reset Password', component: ResetPassword },
  recoverEmail: { title: 'Recover Email', component: RecoverEmail },
  verifyEmail: { title: 'Verify Email', component: VerifyEmail },
};

const NotFound = () => {
  return <Redirect to="/not-found" />;
};

const UserManagement: React.FC<UserManagementProps> = (props) => {
  const params = useQueryParams();
  const mode = params.get('mode') as Mode;
  const Content = Components[mode]?.component || NotFound;
  const title = Components[mode]?.title || '';

  return (
    <LoginScreen title={title}>
      <Content />
    </LoginScreen>
  );
};

export default UserManagement;
