import React from 'react';
import Alert from '../../common/Alert';
import Typography from '../../common/Typography';
import NavButtons from './NavButtons';

interface WarningProps {
  onBack: () => void;
  onNext: () => void;
}

const Warning: React.FC<WarningProps> = ({ onBack, onNext }) => {
  return (
    <>
      <Alert variant="danger">
        <Typography>
          Warning, this will permanently delete your account. All personal data
          will be deleted and all pending cases will be canceled without charge.
        </Typography>
      </Alert>

      <NavButtons
        nextText="Yes, Delete"
        prevText="Go back"
        onNext={onNext}
        onBack={onBack}
      />
    </>
  );
};

export default Warning;
