import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import SnackbarContext from '../context/SnackbarContext';
import Page from '../components/page/Page';
import PageContent from '../components/page/PageContent';
import { useDialog } from '../context/DialogContext';
import { withAuthRequired } from '../services/buildfire/auth';
import {
  SubmitCaseContextProvider,
  useSubmitCaseContext,
} from '../context/SubmitCaseContext';
import EnterInfo from '../components/submitCase/EnterInfo';
import CreditCard from '../components/submitCase/CreditCard';
import Checkout from '../components/submitCase/Checkout';
import { useNavBlock } from '../services/buildfire/history/useBreadcrumbs';
import CaseContext from '../services/buildfire/rdb/CaseContext';
import TrackView from '../components/analytics/TrackView';
import { VIEW_EVENTS } from '../services/buildfire/analytics/events';
import { useFirebase } from '../services/firebase';
import { IonContent, IonHeader, IonPage, useIonModal } from '@ionic/react';
import Toolbar from '../components/common/Toolbar';
import AlertBanner from '../components/common/AlertBanner';
import EmailVerificationRequiredModal from '../components/modals/EmailVerificationRequiredModal';
import SelectPaymentType from '../components/submitCase/components/SelectPaymentType';

interface SubmitCaseProps {}

const Container = styled.div`
  // padding: 8px;
`;

const StyledPage = styled(Page)`
  align-items: center;
`;

const StyledPageContent = styled(PageContent)`
  width: 100%;
  max-width: 600px;
  > div {
    width: 100%;
  }
`;

export function useEmailVerificationWarning() {
  const { currentUser, app } = useFirebase();
  const history = useHistory();
  const [present, dismiss] = useIonModal(EmailVerificationRequiredModal, {
    app,
    user: currentUser,
    onClose: () => {
      dismiss();
    },
  });
  React.useEffect(() => {
    if (currentUser?.emailVerified) {
      return;
    }

    present({
      onDidDismiss: () => {
        history.replace({
          pathname: '/',
        });
      },
    });

    return () => {
      dismiss();
    };
  }, [currentUser?.uid]);
}

const SubmitCase: React.FC<SubmitCaseProps> = ({}) => {
  const { currentUser, parentAccount } = useFirebase();
  const history = useHistory();
  const { openDialogPromise } = useDialog();
  const { setMessage } = useContext(SnackbarContext);
  const { submitCaseService } = useSubmitCaseContext();
  const [state, send] = useActor(submitCaseService);
  const { addCase } = useContext(CaseContext);

  // Keep parent account in sync since this value might be grabbed after SubmitCaseContext is mounted
  React.useEffect(() => {
    send({ type: 'PARENT_ACCOUNT_CHANGE', parentAccount });
  }, [parentAccount?.uid]);

  // Require email verification before submitting a case
  useEmailVerificationWarning();

  React.useEffect(() => {
    const onDone = () => {
      const { id } = submitCaseService.state.context.createdCase!;
      addCase(submitCaseService.state.context.createdCase!);
      history.replace(`/cases/${id}`);
      setMessage({ text: 'Case successfully created' });
    };
    submitCaseService.onDone(onDone);
    return () => {
      submitCaseService.off(onDone);
    };
  }, []);

  React.useEffect(() => {
    const { error } = state.context;
    if (error) {
      openDialogPromise(
        <div>{typeof error === 'string' ? error : error.message}</div>
      );
    }
  }, [state.context.error]);

  useNavBlock(({ action }) => {
    if (action !== 'POP') {
      return true;
    }

    if (state.matches('enterInfo')) {
      // Allow normal history pop on initial screen
      return true;
    }

    // Otherwise navigate BACK in the state machine
    send({ type: 'BACK' });
    return false;
  });

  if (!currentUser) {
    throw new Error('ERROR not logged in');
  }

  const spreadProps = { currentUser };

  return (
    <StyledPage>
      <StyledPageContent>
        <Container>
          {state.matches('enterInfo') && <EnterInfo {...spreadProps} />}
          {state.matches('selectPaymentType') && (
            <SelectPaymentType {...spreadProps} />
          )}
          {state.matches('creditCard') && <CreditCard {...spreadProps} />}
          {state.matches('checkout') && <Checkout {...spreadProps} />}
        </Container>
      </StyledPageContent>
    </StyledPage>
  );
};

const WrappedSubmitCase: React.FC<{}> = () => {
  return (
    <IonPage>
      <IonHeader>
        <Toolbar showBackButton title="Submit Case" />
        <AlertBanner />
      </IonHeader>
      <IonContent>
        <SubmitCaseContextProvider>
          <TrackView name={VIEW_EVENTS.SUBMIT_CASE}>
            <SubmitCase />
          </TrackView>
        </SubmitCaseContextProvider>
      </IonContent>
    </IonPage>
  );
};

export default withAuthRequired(WrappedSubmitCase);
