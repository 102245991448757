import React from 'react';
import { Field, Formik } from 'formik';
import { IonButton, IonCol, IonRow } from '@ionic/react';
import * as Yup from 'yup';
import Form from '../common/Form';
import LabeledInput from '../../mdc/LabeledInput';
import { uppercaseFirstLetter } from '../../util/string';

interface InviteUserFormProps {
  onSubmit: (values: { email: string }) => Promise<void>;
}

const InviteUserForm: React.FC<InviteUserFormProps> = ({ onSubmit }) => {
  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={onSubmit}
      validateOnMount
      validationSchema={Yup.object({
        email: Yup.string().email().required(),
      })}
    >
      {({ isValid, isSubmitting, touched, errors }) => (
        <Form>
          <IonRow>
            <IonCol>
              <Field
                as={LabeledInput}
                label="Email Address"
                error={
                  touched.email && uppercaseFirstLetter(errors.email as string)
                }
                name="email"
              />
            </IonCol>
          </IonRow>
          <IonButton
            type="submit"
            disabled={!isValid || isSubmitting}
            expand="block"
          >
            Invite User
          </IonButton>
        </Form>
      )}
    </Formik>
  );
};

export default InviteUserForm;
