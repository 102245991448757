import Cookies from 'js-cookie';
import * as H from 'history';
import { AUTH_REF } from './cookie';
import { FirebaseError } from 'firebase/app';

export const AUTH_ERROR_MAP = {
  'auth/wrong-password': 'Incorrect password',
  'auth/email-already-in-use': 'Email already in use',
};

export const redirectAfterAuth = (
  params: URLSearchParams,
  history: H.History
) => {
  const ref = params.get('ref');
  const cookieRef = Cookies.get(AUTH_REF);
  Cookies.remove(AUTH_REF);
  const url = decodeURIComponent(ref || cookieRef || '/');
  history.replace(url);
};

export const isFirebaseError = (error: any): error is FirebaseError =>
  !!error.code;
