import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import React from 'react';
import useIonicModalProps from '../../hooks/useIonicModalProps';
import { JSX } from '@ionic/core/components';
import InviteUserForm from './InviteUserForm';
import styled from 'styled-components';
import Typography from '../common/Typography';
import { useDialog } from '../../context/DialogContext';
import { DialogActionButton } from '../common/Dialog';

interface InviteUserModalProps extends JSX.IonModal {
  onSubmit: (values: { email: string }) => Promise<void>;
}

const ContentWrapper = styled.div`
  margin-top: 50px;
  padding: 16px;
`;

const InviteUserModal: React.FC<InviteUserModalProps> = ({
  onSubmit,
  ...props
}) => {
  const { ref, onClose } = useIonicModalProps();
  const { openDialogPromise, openDialog } = useDialog();
  const [present] = useIonToast();
  const [error, setError] = React.useState<Error>();
  return (
    <IonModal ref={ref} {...props}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Invite User</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ContentWrapper>
          <Typography as="h4">
            Invite a user to join your account on Pocket Pathologist
          </Typography>

          <Typography margin="16px 0">
            Once added, this user will have the ability to bill cases under your
            account
          </Typography>
          <InviteUserForm
            onSubmit={async (values) => {
              try {
                await openDialogPromise(`Invite user ${values.email}?`, {
                  actions: (
                    <>
                      <DialogActionButton action="close" emphasis="low">
                        Cancel
                      </DialogActionButton>
                      <DialogActionButton action="accept" tabIndex={1}>
                        Confirm
                      </DialogActionButton>
                    </>
                  ),
                });
                try {
                  await onSubmit(values);
                  onClose();
                  present(`Invite sent to ${values.email}`, 6000);
                } catch (e) {
                  console.error(e);
                  const err = e as any;
                  openDialog(
                    err.detail?.message ||
                      err.message ||
                      'There was a problem inviting this user. Please try again.'
                  );
                }
              } catch (e) {
                // User declined prompt, ignore
              }
            }}
          />
        </ContentWrapper>
      </IonContent>
    </IonModal>
  );
};

export default InviteUserModal;
