import React from 'react';
import { IonContent, IonHeader, IonPage, useIonToast } from '@ionic/react';
import { getDatabase } from 'firebase/database';
import styled from 'styled-components';
import AlertForm from '../../components/alerts/AlertForm';
import Toolbar from '../../components/common/Toolbar';
import {
  clearAlert,
  getAlert,
  pushMessage,
} from '../../services/buildfire/rdb/alerts';
import { useFirebase } from '../../services/firebase';
import * as O from 'fp-ts/lib/Option';
import { Alert } from '../../context/NotifContext';
import { LOADING_STATE } from '../../util/loading';
import LoadingCover from '../../components/common/LoadingCover';
import { useDialog } from '../../context/DialogContext';
import Typography from '../../components/common/Typography';
import { DialogActionButton } from '../../components/common/Dialog';
import { checkmarkCircleOutline } from 'ionicons/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px 0 42px 0;
  padding: 0 16px;
`;

const ContentContainer = styled.div`
  max-width: 100%;
  width: 400px;
  margin: 48px 0;
`;

interface AlertProps {}

const Alerts: React.FC<AlertProps> = (props) => {
  const [loadingState, setLoadingState] = React.useState(LOADING_STATE.LOADING);
  const { app } = useFirebase();
  const { openDialog } = useDialog();
  const [present] = useIonToast();

  const [alert, setAlert] = React.useState<Alert>();
  React.useEffect(() => {
    getAlert(getDatabase(app))
      .then((alertOpt) => {
        if (O.isSome(alertOpt)) {
          setAlert(alertOpt.value);
        }
        setLoadingState(LOADING_STATE.LOADED);
      })
      .catch((e) => {
        console.error(e);
        setLoadingState(LOADING_STATE.ERROR);
      });
  }, []);

  const isLoading = loadingState === LOADING_STATE.LOADING;

  return (
    <IonPage>
      <IonHeader>
        <Toolbar showBackButton title="Alerts" />
      </IonHeader>
      <IonContent>
        {isLoading && <LoadingCover show={isLoading} />}
        <Container>
          <ContentContainer>
            <Typography fontSize="18px" lineHeight="1.5em" margin="20px 0">
              Messages set here will immediately show in the in-app banner for
              every user
            </Typography>

            {loadingState === LOADING_STATE.ERROR && (
              <h2>There was a problem loading this page</h2>
            )}

            {loadingState === LOADING_STATE.LOADED && (
              <AlertForm
                initialValues={alert}
                onSubmit={async (values) => {
                  const database = getDatabase(app);
                  if (alert) {
                    await clearAlert(database, alert.id);
                  }

                  return pushMessage(database, values).then((alert) => {
                    setAlert(alert);
                    present({
                      message: 'Alert message successfully set',
                      icon: checkmarkCircleOutline,
                      duration: 5000,
                    });
                  });
                }}
                onClear={() => {
                  return new Promise((resolve, reject) => {
                    if (!alert) {
                      // Alert wasn't saved in db, immediately clear from textbox
                      return resolve(true);
                    }

                    openDialog(<Typography>Are you sure?</Typography>, {
                      onClose: (action) => {
                        if (action !== 'accept') {
                          return resolve(false);
                        }

                        clearAlert(getDatabase(app), alert!.id).then(() => {
                          setAlert(undefined);
                          resolve(true);
                          present('Alert message cleared', 5000);
                        });
                      },
                      actions: (
                        <>
                          <DialogActionButton action="close" emphasis="low">
                            Nevermind
                          </DialogActionButton>
                          <DialogActionButton action="accept">
                            Yes, clear
                          </DialogActionButton>
                        </>
                      ),
                    });
                  });
                }}
              />
            )}
          </ContentContainer>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default Alerts;
