import React from 'react';
import CreditCardForm from '../../forms/CreditCardForm';
import { Elements } from '@stripe/react-stripe-js';
import {
  loadStripe,
  Stripe,
  StripeElements,
  StripeConstructorOptions,
} from '@stripe/stripe-js';
import Button from '../../common/Button';
import styled from 'styled-components';
import { useBuildfireSettings } from '../../../context/SettingsContext';

export interface AddCreditCardFormProps {
  clientSecret: string;
  userId: string;
  email: string;
  isSubmitting: boolean;
  onSubmit: (values: {
    stripe: Stripe;
    elements: StripeElements;
  }) => void | Promise<void>;
  Form?: React.ComponentType<{}>;
  renderSubmitButton?: (props: {
    disabled: boolean;
    onClick: () => void;
  }) => React.ReactNode;
}

function useLoadStripe(options?: StripeConstructorOptions) {
  const { settings } = useBuildfireSettings();
  const [stripe] = React.useState(() =>
    loadStripe(settings.stripe.publishableKey, options)
  );
  return stripe;
}

const Spacer = styled.div`
  height: 16px;
`;

const ButtonContainer = styled.div`
  margin-top: 32px;
`;

const AddCreditCardForm: React.FC<AddCreditCardFormProps> = ({
  clientSecret,
  onSubmit,
  userId,
  email,
  isSubmitting,
  Form,
  renderSubmitButton,
}) => {
  const stripe = useLoadStripe();
  const [isFormComplete, setIsFormComplete] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const isDisabled = isSubmitting || !ready || !isFormComplete;

  if (!clientSecret) {
    return null;
  }

  return (
    <Elements
      stripe={stripe}
      options={{
        clientSecret,
        appearance: {
          rules: {
            '.Label': {
              marginLeft: '14px',
            },
            '.TermsText': {
              display: 'none',
            },
          },
        },
      }}
    >
      <Spacer />
      <CreditCardForm
        userId={userId}
        email={email}
        onSubmit={async ({ stripe, elements }) => {
          return onSubmit({ stripe, elements });
        }}
        onChange={(e) => {
          if (e.complete !== isFormComplete) {
            setIsFormComplete(e.complete);
          }
        }}
        onReady={() => setReady(true)}
        Form={Form}
        renderButtons={(props) =>
          renderSubmitButton?.({
            ...props,
            disabled: props.disabled || isDisabled,
          })
        }
      />
    </Elements>
  );
};

export default AddCreditCardForm;
