import { createMachine, MachineConfig } from 'xstate';
import { assign, log } from 'xstate/lib/actions';
import { Context, Event } from './types';
import * as guards from './guards';
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
// import * as actions from './actions';

async function reauth(context: Context) {
  try {
    const credential = EmailAuthProvider.credential(
      context.user.email!,
      context.password!
    );

    const reauthResult = await reauthenticateWithCredential(
      context.user,
      credential
    );

    return reauthResult;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const config: MachineConfig<Context, any, Event> = {
  id: 'reauth',
  initial: 'idle',
  context: {} as any,
  states: {
    idle: {
      on: {
        REAUTH: {
          target: 'beforeReauth',
          actions: 'setPassword',
        },
      },
    },
    beforeReauth: {
      always: [
        {
          target: 'performReauth',
          cond: 'hasPassword',
        },
        {
          target: 'idle',
        },
      ],
    },
    performReauth: {
      invoke: {
        src: reauth,
        onDone: {
          target: 'submitted',
          actions: assign({
            result: (context, ev) => ev.data,
          }),
        },
        onError: {
          target: 'error',
          actions: assign({
            error: (context, ev) => ev.data,
          }),
        },
      },
    },
    error: {
      always: {
        target: 'idle',
        actions: log((context, ev) => {
          console.error(ev);
        }),
      },
    },
    submitted: {
      type: 'final',
    },
  },
};

const reauthMachine = createMachine(config, {
  // actions,
  guards,
});

export default reauthMachine;
