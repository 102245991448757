import { IonButtons, IonFooter, IonToolbar } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import BREAKPOINTS from '../../constants/breakpoints';
import LinkButton from '../common/LinkButton';

interface PageFooterProps extends React.HTMLAttributes<HTMLElement> {}

const Footer = styled(IonFooter)`
  grid-area: footer;
  // padding: 16px 0;
  // height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // padding-bottom: calc(16px + constant(safe-area-inset-bottom));
  // padding-bottom: calc(16px + env(safe-area-inset-bottom));
`;

const ButtonWrapper = styled(IonButtons)`
  display: flex;
  flex: 1;
  padding-top: 16px;
  justify-content: space-between;
  max-width: 800px;
  margin: auto;
  .mdc-button {
    margin: 0 16px;
  }
  .mdc-button:first-child {
    margin-right: 8px;
  }
  .mdc-button:last-child {
    margin-left: 8px;
  }
  @media (max-width: ${BREAKPOINTS.small}px) {
    .mdc-button {
      // min-width: 45%;
      flex: 1;
    }
  }
  @media (min-width: ${BREAKPOINTS.small + 1}px) {
    justify-content: space-around;
    .mdc-button {
      min-width: 216px;
    }
  }
`;

const Toolbar = styled(IonToolbar)`
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  // max-width: 800px;
  background-color: var(--mdc-theme-secondary);
  padding-bottom: max(16px, var(--ion-safe-area-bottom)) !important;
`;

const PageFooter: React.FC<PageFooterProps> = (props) => {
  return (
    <Footer>
      <Toolbar color="secondary">
        <ButtonWrapper>
          <LinkButton to="/cases/create" variant="secondary" outlined>
            Submit Case
          </LinkButton>
          <LinkButton to="/cases" variant="secondary" outlined>
            View Cases
          </LinkButton>
        </ButtonWrapper>
      </Toolbar>
      {/* <ButtonWrapper>
        <LinkButton to="/cases/create" variant="secondary" outlined>
          Submit Case
        </LinkButton>
        <LinkButton to="/cases" variant="secondary" outlined>
          View Cases
        </LinkButton>
      </ButtonWrapper> */}
    </Footer>
  );
};

export default PageFooter;
