import React from 'react';
import styled from 'styled-components';
import { useBuildfireSettings } from '../../../context/SettingsContext';
import Dialog, { DialogActionButton, DialogProps } from '../../common/Dialog';
import Typography from '../../common/Typography';

interface SiteAddConfirmationDialogProps extends Partial<DialogProps> {
  onCancel: () => void;
  onConfirm: () => void;
}

const Price = styled.span`
  font-weight: bold;
`;

const SiteAddConfirmationDialog: React.FC<SiteAddConfirmationDialogProps> = ({
  onCancel,
  onConfirm,
  ...props
}) => {
  const { settings } = useBuildfireSettings();
  return (
    <Dialog
      dialogId="site-add-confirmation"
      title=""
      isOpen
      actions={
        <>
          <DialogActionButton action="close" default emphasis="low">
            Cancel
          </DialogActionButton>
          <DialogActionButton action="accept">Confirm</DialogActionButton>
        </>
      }
      onClose={(action) => {
        if (action === 'accept') {
          onConfirm();
        } else {
          onCancel();
        }
      }}
      {...props}
    >
      <Typography fontSize="16px" lineHeight="20px">
        Adding more than one site will add an additional charge of{' '}
        <Price>${settings.prices.additionalSite}</Price>
      </Typography>
    </Dialog>
  );
};

export default SiteAddConfirmationDialog;
