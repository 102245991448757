import React from 'react';
import styled from 'styled-components';
import Icon from '../../mdc/Icon';

interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'info' | 'danger';
}

const ICON_MAP = {
  info: 'info',
  danger: 'report',
};

const Container = styled.div<{
  $variant: AlertProps['variant'];
}>`
  // border: 1px solid var(--mdc-theme-primary);
  border-radius: 4px;
  padding: 16px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  ${(props) =>
    props.$variant === 'info' &&
    `
    color: var(--ion-color-primary);
    background: #23a89933;
  `}
  ${(props) =>
    props.$variant === 'danger' &&
    `
    color: var(--ion-color-danger);
    background: #f35b7444;
  `}
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
  font-family: 'Material Icons' !important;
`;

const Alert: React.FC<AlertProps> = ({
  variant = 'info',
  children,
  ...props
}) => {
  return (
    <Container $variant={variant} {...props}>
      <StyledIcon variant={ICON_MAP[variant]} />
      {children}
    </Container>
  );
};

export default Alert;
