import { User } from 'firebase/auth';
import React from 'react';
import styled from 'styled-components';
import { IonButton, IonIcon, IonSpinner } from '@ionic/react';
import { checkmark } from 'ionicons/icons';
import Typography from '../common/Typography';
import { LOADING_STATE } from '../../util/loading';
import { getFunctions } from 'firebase/functions';
import { sendEmailVerification } from '../../api/cloudFunctions';
import { FirebaseApp } from 'firebase/app';

interface SendEmailVerificationButtonProps {
  user: User;
  // Passed as a prop so this component can be used inside of an IonModal
  // that has a different react context
  app: FirebaseApp;
}

const EmailSentContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const SendEmailVerificationButton: React.FC<
  SendEmailVerificationButtonProps
> = ({ user, app }) => {
  const [loadingStatus, setLoadingStatus] = React.useState(LOADING_STATE.IDLE);
  const isLoading = loadingStatus === LOADING_STATE.LOADING;
  const emailSent = loadingStatus === LOADING_STATE.LOADED;
  return (
    <>
      {loadingStatus === LOADING_STATE.ERROR && (
        <EmailSentContainer>
          <Typography variant="error">
            There was a problem sending the email, please try again.
          </Typography>
        </EmailSentContainer>
      )}

      {emailSent ? (
        <EmailSentContainer>
          <Typography variant="primary" style={{ display: 'flex' }}>
            <IonIcon icon={checkmark} style={{ marginRight: '4px' }} />
            Email sent, please check your inbox
          </Typography>
        </EmailSentContainer>
      ) : (
        <IonButton
          expand="block"
          disabled={isLoading}
          fill="clear"
          onClick={() => {
            setLoadingStatus(LOADING_STATE.LOADING);

            const fns = getFunctions(app);
            sendEmailVerification(fns, user.email!)
              .then(() => setLoadingStatus(LOADING_STATE.LOADED))
              .catch((e) => {
                console.error(e);
                setLoadingStatus(LOADING_STATE.ERROR);
              });
          }}
        >
          {isLoading ? (
            <>
              <IonSpinner name="crescent" style={{ marginRight: '8px' }} />
              Loading
            </>
          ) : (
            'Send me another verification email'
          )}
        </IonButton>
      )}
    </>
  );
};

export default SendEmailVerificationButton;
