import React from 'react';
import { IonIcon, IonNote } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';

interface EmailVerifiedCheckmarkProps {}

const EmailVerifiedCheckmark: React.FC<EmailVerifiedCheckmarkProps> = (
  props
) => {
  return (
    <IonNote color="success" style={{ display: 'flex' }}>
      <IonIcon icon={checkmarkCircle} style={{ marginRight: '4px' }} />
      <span>Email verified</span>
    </IonNote>
  );
};

export default EmailVerifiedCheckmark;
