import Rollbar from 'rollbar';
import {
  Provider,
  ErrorBoundary as RollbarErrorBoundary,
} from '@rollbar/react';
import 'material-components-web/dist/material-components-web.css';
import React from 'react';
import { render } from 'react-dom';
import * as mdc from 'material-components-web';
(window as any).mdc = mdc;

import { FirebaseProvider } from './services/firebase';
import { BuildfireThemeProvider } from './services/buildfire/appearance/theme';
import { CaseProvider } from './services/buildfire/rdb/CaseContext';
import Widget from './Widget';
import { SnackbarProvider } from './context/SnackbarContext';
import { GlobalLoader } from './components/common/FullscreenLoader';
import { LoadingProvider } from './services/buildfire/loading/LoadingContext';
import DialogProvider from './context/DialogContext';
import ErrorBoundary from './components/common/ErrorBoundary';
import DebugProvider from './components/Debug';
import { SettingsProvider } from './context/SettingsContext';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import NavMenu from './components/common/NavMenu';
import allSettled from 'promise.allsettled';

allSettled.shim();

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './index.css';

import NotifContextProvider from './context/NotifContext';
import Package from '../package.json';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: process.env.REACT_APP_ROLLBAR_API_KEY,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NODE_ENV === 'development' ? 'dev' : 'production',
    client: {
      javascript: {
        code_version: Package.version,
      },
    },
  },
};

setupIonicReact();

console.log(`NODE_ENV=${process.env.NODE_ENV}`);
console.log(
  `REACT_APP_ROLLBAR_API_KEY=${process.env.REACT_APP_ROLLBAR_API_KEY}`
);

const target = document.getElementById('mount');

render(
  <Provider config={rollbarConfig}>
    <RollbarErrorBoundary>
      <IonApp>
        <BuildfireThemeProvider>
          <DialogProvider>
            <SettingsProvider>
              <DebugProvider>
                <FirebaseProvider>
                  <NavMenu />
                  <IonRouterOutlet id="main">
                    {/* <IonReactRouter> */}
                    {/* <IonSplitPane contentId="main"> */}
                    {/* <NavMenu /> */}
                    <ErrorBoundary>
                      <SnackbarProvider>
                        <CaseProvider>
                          <NotifContextProvider>
                            <LoadingProvider defaultValue={true}>
                              <GlobalLoader>
                                <Widget />
                              </GlobalLoader>
                            </LoadingProvider>
                          </NotifContextProvider>
                        </CaseProvider>
                      </SnackbarProvider>
                    </ErrorBoundary>
                    {/* </IonReactRouter> */}
                  </IonRouterOutlet>
                </FirebaseProvider>
              </DebugProvider>
            </SettingsProvider>
            {/* </IonSplitPane> */}
          </DialogProvider>
        </BuildfireThemeProvider>
      </IonApp>
    </RollbarErrorBoundary>
  </Provider>,
  target
);
