import React, { useContext } from 'react';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { archive } from 'ionicons/icons';
import { getDatabase } from 'firebase/database';
import { useDialog } from '../../../../context/DialogContext';
import SnackbarContext from '../../../../context/SnackbarContext';
import CaseContext from '../../../../services/buildfire/rdb/CaseContext';
import {
  Case,
  CaseStatus,
  updateStatus,
} from '../../../../services/buildfire/rdb/cases';
import Typography from '../../../common/Typography';
import ActionItem from '../ActionItem';
import { useFirebase } from '../../../../services/firebase';

interface ArchiveCaseProps {
  case: Case;
}

// TODO confirmation if Case is still open
//    Case will be closed without a resolution, not charged
const ConfirmationText: React.FC<{ status: CaseStatus; isAdmin: boolean }> = ({
  status,
  isAdmin,
}) => {
  if (isAdmin) {
    return (
      <Typography fontSize="18px" lineHeight="1.5em">
        Are you sure you want to close this case? The customer will not be
        charged.
      </Typography>
    );
  }
  return (
    <Typography fontSize="18px" lineHeight="1.5em">
      {status === CaseStatus.OPEN
        ? 'Are you sure you want to close this case? You will not be charged.'
        : 'Are you sure you want to archive this case? You can still view the case information in the "Archived" tab on the Case screen.'}
    </Typography>
  );
};

const StyledIcon = styled(IonIcon)`
  color: var(--ion-color-danger);
  opacity: 0.6;
  margin-right: 12px;
`;
const Text = styled.span`
  color: var(--ion-color-danger);
`;

const ArchiveCase: React.FC<ArchiveCaseProps> = ({ case: c }) => {
  const { openDialogPromise } = useDialog();
  const { updateCase } = useContext(CaseContext);
  const { setMessage } = useContext(SnackbarContext);
  const { app, isAdmin } = useFirebase();

  return (
    <ActionItem
      icon={archive}
      IconComponent={StyledIcon}
      onClick={() => {
        openDialogPromise(
          <ConfirmationText status={c.status} isAdmin={isAdmin} />
        )
          .then(async () => {
            const database = getDatabase(app);
            const prevStatus = c.status;
            const newStatus =
              prevStatus === CaseStatus.OPEN ? CaseStatus.CLOSED : prevStatus;
            await updateStatus(database, c.id, c.uid, newStatus, true);
            const updatedCase = { ...c, status: newStatus, isArchived: true };
            updateCase(updatedCase);
            setMessage({
              text: 'Case moved to Archived',
              timeoutMS: 8000,
              actions: [
                {
                  text: 'UNDO',
                  onClick: () =>
                    updateStatus(database, c.id, c.uid, prevStatus, false),
                },
              ],
            });
          })
          .catch(() => {
            console.log('Archive case dialog canceled');
          });
      }}
      label={<Text>Archive</Text>}
    />
  );
};

export default ArchiveCase;
