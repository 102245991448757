import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import HelperText from './HelperText';
import { InputProps, useTextField } from './LabeledInput';

export interface TextAreaProps extends InputProps<HTMLTextAreaElement> {
  resizable?: boolean;
  rows?: number;
  cols?: number;
}

const Container = styled.div`
  width: 100%;

  .mdc-text-field__input {
    width: 100%;
    margin-top: 16px;
  }
`;

const TextAreaInput = styled.textarea<{ $resizable?: boolean }>`
  ${(props) => (props.$resizable ? '' : 'resize: none;')}
`;

const TextArea: React.FC<TextAreaProps> = ({
  name,
  rows,
  cols,
  label,
  fullWidth,
  className,
  helpText,
  error,
  textFieldType = 'outlined',
  resizable = true,
  ...props
}) => {
  const { textFieldRef } = useTextField<HTMLDivElement>();
  return (
    <>
      <Container
        className={classNames(
          'mdc-text-field',
          'mdc-text-field--textarea',
          !!error && 'mdc-text-field--invalid',
          className
        )}
        ref={textFieldRef}
      >
        <TextAreaInput
          id={name}
          className="mdc-text-field__input"
          rows={rows}
          cols={cols}
          $resizable={resizable}
          {...props}
        />
        <div className="mdc-notched-outline">
          <div className="mdc-notched-outline__leading" />
          {label && (
            <div className="mdc-notched-outline__notch">
              <label htmlFor={name} className="mdc-floating-label">
                {label}
              </label>
            </div>
          )}
          <div className="mdc-notched-outline__trailing" />
        </div>
      </Container>
      {helpText && !error && <HelperText>{helpText}</HelperText>}
      {error && <HelperText type="error">{error}</HelperText>}
    </>
  );
};

export default TextArea;
