import React from 'react';
import styled from 'styled-components';
import Snackbar, { SnackbarAction } from '../mdc/Snackbar';

const Wrapper = styled.div`
  font-size: 14px !important;
`;

interface ISnackbarAction {
  text: string;
  onClick: () => void;
}

interface SnackbarMessage {
  text: string | React.ReactNode;
  actions?: ISnackbarAction[];
  timeoutMS?: number;
}

interface ISnackbarContext {
  setMessage(message: SnackbarMessage): void;
}

const SnackbarContext = React.createContext<ISnackbarContext>(undefined as any);

interface SnackbarProviderProps {}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
  children,
  ...props
}) => {
  const [message, setMessage] = React.useState<SnackbarMessage>();
  (window as any).setMessage = setMessage;
  return (
    <SnackbarContext.Provider value={{ setMessage }} {...props}>
      {children}
      {message && (
        <Snackbar
          actions={
            message.actions?.length ? (
              <>
                {message.actions.map((action) => (
                  <SnackbarAction key={action.text} onClick={action.onClick}>
                    {action.text}
                  </SnackbarAction>
                ))}
              </>
            ) : null
          }
          timeoutMS={message.timeoutMS}
          onClose={() => setMessage(undefined)}
        >
          <Wrapper>{message.text}</Wrapper>
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContext;
