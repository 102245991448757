import React from 'react';
import useSignInWithEmailLink from '../../hooks/useSignInWithEmailLink';

interface SignInWithEmailLinkProps {}

const SignInWithEmailLink: React.FC<SignInWithEmailLinkProps> = (props) => {
  // Checks if this is a sign-in with email link and signs in the user if it is
  useSignInWithEmailLink();

  return <>{/*  */}</>;
};

export default SignInWithEmailLink;
