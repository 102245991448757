import { assign } from 'xstate';
import { EditCaseContext, EditCaseEvent } from './types';

export const loadData = assign<EditCaseContext, EditCaseEvent>(
  (context, ev: any) => {
    const { case: c, selectedPaymentMethod, invoice } = ev.data;
    return {
      ...context,
      selectedPaymentMethod,
      invoice,
      case: c,
      caseInfo: {
        ...c,
        submitterEmailAddress: c?.submitter.email! || context.email,
        submitterFirstName: c?.submitter.first!,
        submitterLastName: c?.submitter.last!,
        patientFirstName: c?.patient.first!,
        patientLastName: c?.patient.last!,
      },
    };
  }
);

export const loadError = assign<EditCaseContext, EditCaseEvent>({
  error: (context, ev: any) => {
    return ev.data;
  },
});

export const setError = assign<EditCaseContext, EditCaseEvent>({
  error: (context, ev: any) => {
    if (ev.type === 'ERROR') {
      return ev.error;
    }
    console.warn(
      `WARNING: #setError action expected ERROR event but received ${ev.type}`
    );
    return context.error;
  },
});

export * from '../submitCase/actions';
