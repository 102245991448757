import { User } from 'firebase/auth';
import * as E from 'fp-ts/Eq';
import { Case, LastMessageStatus } from '../services/buildfire/rdb/cases';

export const caseIsEqual: E.Eq<Case> = {
  equals: (x, y) => x.id === y.id,
};

export function doesCaseHaveUnreadMessage(
  c: Case,
  currentUser: User,
  isAdmin: boolean
) {
  if (!c.lastMessage || c.lastMessageStatus !== LastMessageStatus.UNREAD) {
    return false;
  }

  if (isAdmin) {
    return c.lastMessage.uid === c.uid;
  }
  return c.lastMessage.uid !== currentUser?.uid;
}

export function formatCaseNumber(num: number = 0) {
  return String(num).padStart(4, '0');
}
