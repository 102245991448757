import React from 'react';
import styled from 'styled-components';

interface PageContentProps extends React.HTMLAttributes<HTMLElement> {
  showBorders?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

const Content = styled.div<{ $showBorders?: boolean }>`
  max-width: 800px;
  width: 100%;
  flex: 1;
  ${(props) =>
    props.$showBorders
      ? `
    border-left: 1px solid var(--ion-border-color);
    border-right: 1px solid var(--ion-border-color);
  `
      : ''}
`;

const PageContent: React.FC<PageContentProps> = ({
  showBorders,
  children,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <Content $showBorders={showBorders}>{children}</Content>
    </Wrapper>
  );
};

export default PageContent;
