import {
  Database,
  DataSnapshot,
  getDatabase,
  limitToLast,
  onChildAdded,
  onValue,
  orderByKey,
  query,
  QueryConstraint,
  ref,
  startAfter,
  Unsubscribe,
} from 'firebase/database';
import { pipe } from 'fp-ts/lib/function';
import React from 'react';
import { LOADING_STATE } from '../../../util/loading';
import { useFirebase } from '../../firebase';
import * as A from 'fp-ts/Array';
import { messageIsEqual } from '../../../util/message';

export interface Message {
  id: string;
  content: string;
  // Plain text version of contents
  plainText?: string;
  timestamp: number;
  uid: string;
  type: 'text';
  name: string;
}

export const getLatestMessage = (database: Database) => {
  const messagesRef = ref(database, 'messages/{caseId}/{messageId}');
  const constraints = [orderByKey(), limitToLast(1)].filter(
    Boolean
  ) as QueryConstraint[];
  const getLastCaseRef = query(messagesRef, ...constraints);
  return new Promise<Message | null>((resolve, reject) => {
    onChildAdded(
      getLastCaseRef,
      (snapshot) => {
        if (!snapshot.exists()) {
          return resolve(null);
        }
        const caseId = snapshot.key;

        const messagesRef = ref(database, `messages/${caseId}`);
        const getRef = query(messagesRef, ...constraints);

        onChildAdded(
          getRef,
          (snapshot) => {
            if (!snapshot.exists()) {
              return resolve(null);
            }
            resolve(parseMessageSnapshot(snapshot));
          },
          (err) => {
            console.error(err);
            reject(err);
          }
        );
      },
      (err) => {
        console.error(err);
        reject(err);
      }
    );
  });
};

export const getMessagesByCaseId = (
  database: Database,
  caseId: string
): Promise<Message[]> => {
  const messageRef = ref(database, `messages/${caseId}`);

  return new Promise<Message[]>((resolve, reject) => {
    onValue(
      messageRef,
      (snapshot) => {
        if (!snapshot.exists()) {
          return resolve([]);
        }

        const newMessages: Message[] = [];
        snapshot.forEach((snap) => {
          newMessages.push(parseMessageSnapshot(snap));
        });
        resolve(newMessages);
      },
      (e) => {
        console.error(e);
        reject(e);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

export function useMessages(
  caseId: string,
  callbacks?: { onMessageAdded?: (message: Message) => void }
) {
  const { app } = useFirebase();
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [status, setStatus] = React.useState<LOADING_STATE>(
    LOADING_STATE.LOADING
  );
  const unsub = React.useRef<Unsubscribe>();
  const addMessage = (newMessage: Message) =>
    setMessages((existingMessages) => {
      const allCases = pipe(
        [existingMessages, [newMessage]],
        A.flatten,
        A.uniq(messageIsEqual)
      );
      return allCases;
    });

  const getMessagesAndSubscribe = async () => {
    const database = getDatabase(app);
    // Get initial list of messages
    setStatus(LOADING_STATE.LOADING);
    try {
      const messages = await getMessagesByCaseId(database, caseId);

      // Listen for new messages
      const messageRef = ref(database, `messages/${caseId}`);

      const constraints: QueryConstraint[] =
        messages.length > 0
          ? [orderByKey(), startAfter(messages[messages.length - 1].id)]
          : [];
      const messageQuery = query(messageRef, ...constraints);

      const unsubFn = onChildAdded(
        messageQuery,
        (snapshot) => {
          const newMessage = parseMessageSnapshot(snapshot);
          callbacks?.onMessageAdded?.(newMessage);
          console.log('onMEssageAdded', newMessage);
          addMessage(newMessage);
          setStatus(LOADING_STATE.LOADED);
        },
        (e) => {
          console.error(e);
          setStatus(LOADING_STATE.ERROR);
        }
      );

      setMessages(messages);
      setStatus(LOADING_STATE.LOADED);
      unsub.current = unsubFn;
    } catch (e) {
      console.error(e);
      setStatus(LOADING_STATE.ERROR);
    }
  };
  // Get initial list of messages
  React.useEffect(
    (() => {
      if (!caseId || !app) {
        return;
      }

      getMessagesAndSubscribe();

      return () => unsub.current?.();
    }) as any,
    [caseId, app]
  );

  return { messages, status };
}

// const write = (database: Database, message: Message) => {
//   const messagesRef = ref(database, 'messages');
//   return firebase.database().ref('messages').push(message);
// };

function parseMessageSnapshot(snapshot: DataSnapshot): Message {
  const message = snapshot.val();
  return {
    id: snapshot.key,
    type: 'text',
    ...message,
  };
}

// export { write };
