import React from 'react';
import styled from 'styled-components';
import NavigationButton from '../../components/home/NavigationButton';

interface HomeLinkProps {
  to: string;
  img: string | React.ReactNode;
}

const NavigationButtonText = styled.span`
  color: var(--accent-color2);
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 2px;
`;

const HomeLink: React.FC<HomeLinkProps> = ({ to, img, children, ...props }) => {
  return (
    <NavigationButton to={to} img={img}>
      <NavigationButtonText>{children}</NavigationButtonText>
    </NavigationButton>
  );
};

export default HomeLink;
