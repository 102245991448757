import React from 'react';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonRouterLink,
  IonRow,
  IonText,
} from '@ionic/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Form from '../../components/common/Form';
import { uppercaseFirstLetter } from '../../util/string';
import classNames from 'classnames';
import styled from 'styled-components';
import AddressAutocomplete, {
  Address,
} from '../../components/register/AddressAutocomplete/AddressAutocomplete';
import HospitalTermsAndConditionsCheckbox from './HospitalTermsAndConditonsCheckbox';

export interface RegistrationInfo {
  displayName: string;
  email: string;
  password: string;
  // hospital only
  address?: Address;
}

interface RegistrationFormProps {
  onSubmit: (info: RegistrationInfo) => Promise<void>;
  error?: Error;
  clearError: () => void;
  onBack?: () => void;
}
const ButtonRow = styled(IonRow)`
  margin-top: 8px;
`;
const CenteredRow = styled(IonRow)`
  justify-content: center;
`;
const HiddenSubmitButton = styled.button`
  position: absolute;
  display: none;
`;
const TermsWrapper = styled.div`
  margin: 20px 0;
`;
const PasswordHelperNote = styled(IonNote)`
  margin: 8px 0;
  font-size: 12px;
  line-height: 16px;
  display: block;
`;

export const PasswordSchema = Yup.string()
  .min(8, 'Password must be at least 8 characters')
  .max(100, 'Password is too long')
  .matches(/^(?=.*[A-Z])/, 'Must contain at least 1 uppercase letter')
  .matches(/^(?=.*[a-z])/, 'Must contain at least 1 lowercase letter')
  .matches(/^(?=.{6,20}$)\D*\d/, 'Must contain at least 1 number')
  .matches(/^(?=.*[!@#$%^&*])/, 'Must contain at least 1 symbol')
  .required('Password is required');

export const PasswordHelper: React.FC<{}> = () => (
  <PasswordHelperNote slot="helper">
    Password must be at least 8 characters long and contain an uppercase letter,
    a lowercase letter, a number, and a symbol
  </PasswordHelperNote>
);

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  onSubmit,
  error,
  clearError,
  onBack,
}) => {
  const [hasAcceptedTerms, setHasAcceptedTerms] = React.useState(false);
  return (
    <>
      <Formik
        validateOnMount
        initialValues={{
          displayName: '',
          email: '',
          password: '',
          passwordConfirmation: '',
          // Hospital-only fields
          address: undefined,
        }}
        onSubmit={onSubmit}
        validationSchema={Yup.object({
          displayName: Yup.string()
            .min(3, `Name is too short`)
            .max(120, `Name is too long`)
            .required(`Name is required`),
          address: Yup.object().required(`Address is required`),
          email: Yup.string().email().required(),
          password: PasswordSchema,
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords do not match')
            .required('Must confirm new password'),
        })}
      >
        {({
          values,
          touched,
          errors,
          isValid,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setErrors,
        }) => (
          <Form>
            <IonInput
              className={classNames({
                ['ion-invalid']: errors.displayName,
                ['ion-touched']: touched.displayName,
              })}
              label="Clinic Name"
              labelPlacement="floating"
              type="text"
              name="displayName"
              required
              autofocus
              value={values.displayName}
              onIonInput={(e) => {
                setFieldValue('displayName', e.detail.value);
                setFieldTouched('displayName', true);
              }}
              errorText={uppercaseFirstLetter(errors.displayName as string)}
            />
            <AddressAutocomplete
              className={classNames({
                ['ion-invalid']: errors.address,
                ['ion-touched']: touched.address,
              })}
              label="Address"
              labelPlacement="floating"
              name="address"
              required
              onInvalid={() => {
                setErrors({ address: 'Invalid address' });
              }}
              onIonInput={(e) => {
                setFieldTouched('address', true);
              }}
              onComplete={(address) => {
                setFieldValue('address', address);
              }}
              errorText={uppercaseFirstLetter(errors.address as string)}
            />
            <IonInput
              className={classNames({
                ['ion-invalid']: errors.email,
                ['ion-touched']: touched.email,
              })}
              label="Email"
              labelPlacement="floating"
              type="email"
              name="email"
              required
              inputmode="email"
              value={values.email}
              onIonInput={(e) => {
                setFieldValue('email', e.detail.value);
                setFieldTouched('email', true);
              }}
              errorText={uppercaseFirstLetter(errors.email as string)}
            />
            <IonInput
              className={classNames({
                ['ion-invalid']: errors.password,
                ['ion-touched']: touched.password,
              })}
              label="Password"
              labelPlacement="floating"
              type="password"
              name="password"
              required
              clearOnEdit={false}
              value={values.password}
              onIonInput={(e) => {
                setFieldValue('password', e.detail.value);
                setFieldTouched('password', true);
              }}
              errorText={uppercaseFirstLetter(errors.password as string)}
            />
            <PasswordHelper />
            <IonInput
              className={classNames({
                ['ion-invalid']: errors.passwordConfirmation,
                ['ion-touched']: touched.passwordConfirmation,
              })}
              label="Confirm Password"
              labelPlacement="floating"
              type="password"
              name="passwordConfirmation"
              required
              clearOnEdit={false}
              value={values.passwordConfirmation}
              onIonInput={(e) => {
                setFieldValue('passwordConfirmation', e.detail.value);
                setFieldTouched('passwordConfirmation', true);
              }}
              errorText={uppercaseFirstLetter(
                errors.passwordConfirmation as string
              )}
            />

            <TermsWrapper>
              <HospitalTermsAndConditionsCheckbox
                onChange={(e) => setHasAcceptedTerms(e.target.checked)}
              />
            </TermsWrapper>

            {/* <ButtonRow>
              <IonCol> */}
            <IonButton
              type="submit"
              disabled={!isValid || isSubmitting || !hasAcceptedTerms}
              expand="block"
            >
              Create Account
            </IonButton>
            {/* Need this for Ionic hack... so the form submits on enter */}
            <HiddenSubmitButton type="submit" />

            {onBack && (
              <IonButton expand="block" fill="clear" onClick={onBack}>
                Back
              </IonButton>
            )}
            {/* </IonCol>
            </ButtonRow> */}
            <CenteredRow style={{ margin: '16px 0' }}>
              <IonText style={{ marginRight: '8px' }}>
                Already have an account?
              </IonText>

              <IonRouterLink routerLink="/login">Sign in</IonRouterLink>
            </CenteredRow>
          </Form>
        )}
      </Formik>
      <IonAlert
        isOpen={!!error}
        header="Error"
        message={getErrorMessage(error)}
        buttons={['OK']}
        onDidDismiss={clearError}
      />
    </>
  );
};

const getErrorMessage = (error?: Error) => {
  if (!error) {
    return '';
  }
  if ((error as any).code === 'auth/email-already-in-use') {
    return 'Email address already in use';
  }
  return (
    error?.message ||
    'There was a problem creating your account. Please try again.'
  );
};

export default RegistrationForm;
