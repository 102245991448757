import { IonContent, IonHeader, IonPage } from '@ionic/react';
import { useActor } from '@xstate/react';
import { getFunctions } from 'firebase/functions';
import React, { useContext } from 'react';
import styled from 'styled-components';
import AlertBanner from '../../components/common/AlertBanner';
import ModalLoader from '../../components/common/ModalLoader';
import Toolbar from '../../components/common/Toolbar';
import Typography from '../../components/common/Typography';
import { AddNewPaymentMethod } from '../../components/submitCase/components/paymentList/AddNewPaymentMethod';
import PaymentMethodList from '../../components/submitCase/components/paymentList/PaymentMethodList';
import { removePaymentMethod } from '../../components/submitCase/CreditCard';
import {
  CreditCardContextProvider,
  useCreditCardContext,
} from '../../context/CreditCardContext';
import SnackbarContext from '../../context/SnackbarContext';
import { withAuthRequired } from '../../services/buildfire/auth';
import { useFirebase } from '../../services/firebase';

interface PaymentMethodsProps {}

const Container = styled.div`
  margin: 32px 0;
  display: flex;
  justify-content: center;
`;

const TitleContainer = styled.div`
  padding: 0 16px;
`;

const Content = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
`;

const ManagePaymentMethods = () => {
  const { currentUser, app } = useFirebase();
  const { creditCardContext } = useCreditCardContext();
  const [state, send] = useActor(creditCardContext);
  const { clientSecret } = state.context;
  const [isRemovingPaymentMethod, setIsRemovingPaymentMethod] =
    React.useState(false);
  const { setMessage } = useContext(SnackbarContext);
  const functions = getFunctions(app);
  const isDialogOpen = state.matches('addCreditCard');
  const isInitialLoad = state.matches('load');
  const isLoadingStripe = state.matches({ addCreditCard: 'load' });
  const hasError = state.matches('error');
  const isSubmitDisabled =
    isLoadingStripe ||
    state.matches({ addCreditCard: 'addingCreditCard' }) ||
    state.matches('confirmSetupIntent');
  const onWillAddCreditCard = () => {
    send({
      type: 'WILL_ADD_CREDIT_CARD',
    });
  };
  const onAddCardDialogClose = () => {
    send({ type: 'CLOSE' });
  };

  return (
    <Content>
      <TitleContainer>
        <Typography
          as="h1"
          variant="secondary"
          fontSize="18px"
          fontWeight="bold"
          margin="32px 0"
          letterSpacing="2px"
          textAlign="center"
        >
          Saved Credit Cards
        </Typography>
      </TitleContainer>

      <PaymentMethodList
        paymentMethods={state.context.paymentMethods || []}
        onSelectPaymentMethod={async (id) => {
          send({ type: 'SELECT_CARD', id });
        }}
        error={hasError ? state.context.error || new Error() : undefined}
        onRemovePaymentMethod={async (id) => {
          setIsRemovingPaymentMethod(true);
          try {
            await removePaymentMethod(functions, id);
            send({ type: 'REMOVE_CREDIT_CARD', paymentMethodId: id });
            setMessage({ text: 'Card successfully removed' });
          } catch (e) {
            console.error('ERROR removing payment method', id, e);
            setMessage({ text: 'Error removing card, please try again' });
          }

          setIsRemovingPaymentMethod(false);
        }}
        disabled={isRemovingPaymentMethod}
        selectable={false}
        isLoading={isInitialLoad}
      />
      <AddNewPaymentMethod
        userId={currentUser!.uid}
        email={currentUser!.email!}
        clientSecret={clientSecret!}
        isSubmitting={state.matches({
          addCreditCard: 'addingCreditCard',
        })}
        onSubmit={async ({ stripe, elements }) => {
          await send({
            type: 'ADD_CARD',
            stripe,
            elements,
          });
        }}
        isDisabled={isSubmitDisabled}
        isDialogOpen={isDialogOpen}
        isLoading={isLoadingStripe}
        onWillAddCreditCard={onWillAddCreditCard}
        onAddCardDialogClose={onAddCardDialogClose}
      />
      {isRemovingPaymentMethod && (
        <ModalLoader
          ProgressBarProps={{
            indeterminate: true,
          }}
          progressText={'Removing card...'}
          onClose={() => {}}
        />
      )}
    </Content>
  );
};

const PaymentMethods: React.FC<PaymentMethodsProps> = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <Toolbar showBackButton title="Payment Methods" />
        <AlertBanner />
      </IonHeader>
      <IonContent>
        <Container>
          <CreditCardContextProvider>
            <ManagePaymentMethods />
          </CreditCardContextProvider>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default withAuthRequired(PaymentMethods);
