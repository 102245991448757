import { IonContent, IonList } from '@ionic/react';
import React from 'react';
import SplitPane from '../common/SplitPane';
import InvoiceListItem from './InvoiceListItem';
import Invoice from './Invoice';
import { useParams } from 'react-router-dom';
import { StripeInvoice } from '../../api/cloudFunctions';
import NoInvoiceSelected from './NoInvoiceSelected';

interface InvoicesProps {
  invoices: StripeInvoice[];
  view: 'admin' | 'user';
}

const Invoices: React.FC<InvoicesProps> = ({ view, invoices }) => {
  const showLoading = false;
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const selectedInvoice = invoices.find(({ id }) => id === invoiceId);
  const showInvoice = Boolean(selectedInvoice);
  const list = (
    <IonContent>
      <IonList>
        {invoices.map((invoice) => (
          <InvoiceListItem key={invoice.id} view={view} invoice={invoice} />
        ))}
      </IonList>
    </IonContent>
  );

  const invoiceInfo = (
    <IonContent>
      {showLoading && <div>Loading...</div>}
      {!showLoading && <Invoice invoice={selectedInvoice!} />}
    </IonContent>
  );
  return (
    <SplitPane
      list={list}
      details={
        showInvoice ? invoiceInfo : showLoading ? 'Loading...' : undefined
      }
      fallback={<NoInvoiceSelected />}
    />
  );
};

export default Invoices;
