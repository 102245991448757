import React from 'react';
import * as RDBCases from '../../services/buildfire/rdb/cases';
import { getStatusText } from '../../util/caseStatus';
import Badge from '../common/Badge';

interface StatusBadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  status: RDBCases.CaseStatus;
  isAdmin?: boolean;
  uniformWidth?: boolean;
}

const COLORS = {
  [RDBCases.CaseStatus.OPEN]: {
    font: '#163349',
    background: '#c0b0e1',
  },
  [RDBCases.CaseStatus.SIGNED_OFF]: {
    font: '#016A5F',
    background: '#b3dce2',
  },
  [RDBCases.CaseStatus.CLOSED]: {
    // font: '#4F2428',
    // background: '#F6394955',
    font: 'rgb(95, 33, 32)',
    background: 'rgb(253, 237, 237)',
  },
};

const StatusBadge: React.FC<StatusBadgeProps> = ({
  status,
  isAdmin,
  uniformWidth,
  ...props
}) => {
  return (
    <Badge
      fontColor={COLORS[status].font}
      backgroundColor={COLORS[status].background}
      uniformWidth={uniformWidth}
      {...props}
    >
      {getStatusText(status, isAdmin!)}
    </Badge>
  );
};

export default StatusBadge;
