import React from 'react';
import classnames from 'classnames';
import { TabContext } from './TabList';
import styled from 'styled-components';

export interface TabProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string;
  variant?: 'primary' | 'secondary';
}

const Button = styled.button<TabProps>`
  ${(props) => `
  &.mdc-tab .mdc-tab__text-label {
    font-size: 16px !important;
  }
  &.mdc-tab--active .mdc-tab__text-label {
    color: var(--mdc-theme-${props.variant}) !important;
  }

  .mdc-tab-indicator .mdc-tab-indicator__content--underline {
    border-color: var(--mdc-theme-${props.variant}) !important;
  }

  .mdc-tab__ripple::before, .mdc-tab__ripple::after {
    background-color: var(--mdc-theme-${props.variant}) !important;
  }
`}
`;

const Tab: React.FC<TabProps> = ({
  icon,
  variant = 'primary',
  children,
  className,
  ...props
}) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const mdcComponent = React.useRef<mdc.tab.MDCTab>();
  const { activeTab } = React.useContext(TabContext);
  React.useEffect(() => {
    if (ref.current) {
      mdcComponent.current = new mdc.tab.MDCTab(ref.current);
      return () => mdcComponent.current!.destroy();
    }
  });
  const isActive = activeTab === (props.tabIndex || 0);
  // mdc-tab--active
  return (
    <Button
      className={classnames(
        {
          ['mdc-tab']: true,
          ['mdc-tab--active']: isActive,
        },
        className
      )}
      role="tab"
      tabIndex={0}
      variant={variant}
      ref={ref}
      {...props}
    >
      <span className="mdc-tab__content">
        {icon && (
          <span className="mdc-tab__icon material-icons" aria-hidden="true">
            {icon}
          </span>
        )}
        <span className="mdc-tab__text-label">{children}</span>
      </span>
      <span
        className={classnames({
          ['mdc-tab-indicator']: true,
          ['mdc-tab-indicator--active']: isActive,
        })}
      >
        {/* mdc-tab-indicator--active */}
        <span className="mdc-tab-indicator__content mdc-tab-indicator__content--underline"></span>
      </span>
      <span className="mdc-tab__ripple"></span>
    </Button>
  );
};

export default Tab;
