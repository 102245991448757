import React, { HTMLAttributes } from 'react';
import ListItem from '../../../../mdc/list/ListItem';
import { PaymentMethod } from '@stripe/stripe-js';
import CardIcon from './CardIcon';
import styled from 'styled-components';
import ActionMenu from '../../../common/ActionMenu';
import MenuItem from '../../../common/MenuItem';
import { useDialog } from '../../../../context/DialogContext';
import Typography from '../../../common/Typography';
import RadioButton from '../../../common/RadioButton';

interface PaymentMethodListItemProps {
  paymentMethod: PaymentMethod;
  onRemove: (id: string) => Promise<void>;
  onSelect: (id: string, e: any) => void;
  disabled: boolean;
  isSelected: boolean;
  selectable?: boolean;
}

const HiddenCardNumbers: React.FC<HTMLAttributes<HTMLSpanElement>> = (
  props
) => <span {...props}>&bull;&bull;&bull;&bull;</span>;

const StyledHiddenCardNumbers = styled(HiddenCardNumbers)`
  margin-right: 6px;
`;

const StyledCardNumber = styled(CardIcon)`
  margin-right: 16px;
`;

const TextWrapper = styled.div`
  display: flex;
  font-size: 14px;
`;

const StyledListItem = styled(ListItem)`
  overflow: visible;
`;
const StyledRadioButton = styled(RadioButton)`
  margin-left: -10px;
  margin-right: 8px;
`;

const Text: React.FC<{ brand: string; lastFour: string }> = ({
  brand,
  lastFour,
}) => {
  return (
    <TextWrapper>
      <StyledCardNumber type={brand} />
      <StyledHiddenCardNumbers />
      {lastFour}
    </TextWrapper>
  );
};

const PaymentMethodListItem: React.FC<PaymentMethodListItemProps> = ({
  paymentMethod,
  onRemove,
  onSelect,
  disabled,
  isSelected,
  selectable = true,
}) => {
  const { postal_code } = paymentMethod.billing_details.address || {};
  const { openDialogPromise } = useDialog();
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <>
      <StyledListItem
        disabled={disabled}
        preliminaryContent={
          selectable && (
            <StyledRadioButton
              id={paymentMethod.id}
              checked={isSelected}
              disabled={disabled}
              onChange={(e) => {
                if (e.target.checked) {
                  onSelect(paymentMethod.id, e);
                }
              }}
            />
          )
        }
        text={
          <Text
            brand={paymentMethod.card?.brand!}
            lastFour={paymentMethod.card?.last4!}
          />
        }
        subtext={
          <span>
            Exp. {paymentMethod.card?.exp_month}/{paymentMethod.card?.exp_year}
          </span>
        }
        buttons={
          <>
            <ActionMenu disabled={disabled}>
              <MenuItem
                icon="delete"
                disabled={disabled}
                onClick={() => {
                  openDialogPromise(
                    <Typography fontSize="16px">
                      Are you sure you want to remove this card?
                    </Typography>
                  ).then(() => onRemove(paymentMethod.id));
                }}
              >
                Remove card
              </MenuItem>
            </ActionMenu>
          </>
        }
      />
    </>
  );
};

export default PaymentMethodListItem;
