import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { IonButton, IonCol, IonInput } from '@ionic/react';
import classNames from 'classnames';
import Form from '../../../components/common/Form';
import { ButtonRow } from '../../login/common/styledComponents';
import {
  PasswordHelper,
  PasswordSchema,
} from '../../register/RegistrationForm';
import { uppercaseFirstLetter } from '../../../util/string';

interface ResetPasswordFormProps {
  onSubmit: (password: string) => Promise<void>;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSubmit }) => {
  return (
    <Formik
      validateOnMount
      initialValues={{
        password: '',
        passwordConfirmation: '',
      }}
      onSubmit={async (values) => {
        await onSubmit(values.password);
      }}
      validationSchema={Yup.object({
        password: PasswordSchema,
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords do not match')
          .required('Must confirm new password'),
      })}
    >
      {({
        values,
        touched,
        errors,
        isValid,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form>
          <IonInput
            className={classNames({
              ['ion-invalid']: errors.password,
              ['ion-touched']: touched.password,
            })}
            label="New Password"
            labelPlacement="floating"
            type="password"
            name="password"
            required
            clearOnEdit={false}
            value={values.password}
            onIonInput={(e) => {
              setFieldValue('password', e.detail.value);
              setFieldTouched('password', true);
            }}
            errorText={uppercaseFirstLetter(errors.password as string)}
          />
          <PasswordHelper />
          <IonInput
            className={classNames({
              ['ion-invalid']: errors.passwordConfirmation,
              ['ion-touched']: touched.passwordConfirmation,
            })}
            label="Confirm Password"
            labelPlacement="floating"
            type="password"
            name="passwordConfirmation"
            required
            clearOnEdit={false}
            value={values.passwordConfirmation}
            onIonInput={(e) => {
              setFieldValue('passwordConfirmation', e.detail.value);
              setFieldTouched('passwordConfirmation', true);
            }}
            errorText={uppercaseFirstLetter(
              errors.passwordConfirmation as string
            )}
          />

          <ButtonRow>
            <IonCol>
              <IonButton
                type="submit"
                disabled={!isValid || isSubmitting}
                expand="block"
              >
                Change Password
              </IonButton>
            </IonCol>
          </ButtonRow>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
