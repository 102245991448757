import { assign } from 'xstate';
import { Event, Context } from './types';

export * from '../reauth/actions';

export const setFeedback = assign<Context, Event>({
  feedback: (context, ev: any) => {
    if (ev.type === 'NEXT') {
      return ev.feedback;
    }

    console.warn(
      `WARNING: #setFeedback action expected NEXT event but received ${ev.type}`
    );
    return context.feedback;
  },
});
