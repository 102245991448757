import { IonIcon } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import { informationCircle, warning } from 'ionicons/icons';
import Typography from './Typography';

interface AlertBoxProps {
  icon?: string;
  text: string | React.ReactNode;
  type?: 'info' | 'warning' | 'error';
  width?: number;
}

const Message = styled.div<{ $type: AlertBoxProps['type'] }>`
  padding: 16px;
  ${(props) =>
    props.$type === 'info' &&
    `
    color: #163349;
    background-color: #aecae5;
  `}
  ${(props) =>
    props.$type === 'warning' &&
    `
    color: black;
    background-color: #e0ac08;
  `}
  ${(props) =>
    props.$type === 'error' &&
    `
    color: black;
    background-color: #f35b7444;
  `}

  border-radius: 4px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`;

const Icon = styled(IonIcon)`
  width: 42px;
  height: 42px;
  margin-right: 16px;
  min-width: 24px;
`;

const iconForType = {
  info: informationCircle,
  error: warning,
  warning: warning,
};

const AlertBox: React.FC<AlertBoxProps> = ({
  text,
  type = 'info',
  icon = iconForType[type],
  width,
  ...props
}) => {
  return (
    <Message $type={type} {...props}>
      <Icon icon={icon} />
      <Typography margin="0">{text}</Typography>
    </Message>
  );
};

export default AlertBox;
