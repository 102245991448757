import React from 'react';
import ReactDOM from 'react-dom';
import { MDCDialog } from '@material/dialog';
import { useLocation } from 'react-router';
import MDCDialogComponent, {
  DialogActionButton as MDCDialogActionButton,
  DialogActionButtonProps as MDCDialogActionButtonProps,
  DialogProps,
} from '../../mdc/Dialog';
import { DialogContext } from '../../context/DialogContext';
import Button, { ButtonProps } from './Button';

interface DialogActionButtonProps extends MDCDialogActionButtonProps {}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Dialog: React.FC<DialogProps> = ({
  dialogId,
  onOpen: _onOpen,
  onClose: _onClose,
  ...props
}) => {
  // TODO cant use history here if LoginErrorDialog
  // is above Router

  // const history = useHistory();
  // const query = useQuery();
  // const onOpen = () => {
  //   history.push(`${history.location.pathname}?dialog=${props.dialogId}`);
  //   _onOpen?.();
  // };
  // const onClose = () => {
  //   history.goBack();
  //   _onClose?.();
  // };
  const mdcComponent = React.useRef<MDCDialog>();
  const { openDialogs, addDialog, removeDialog } =
    React.useContext(DialogContext);

  React.useEffect(() => {
    if (!openDialogs.includes(dialogId) && mdcComponent.current) {
      mdcComponent.current.close();
    }
  }, [openDialogs, dialogId]);

  const onOpen = React.useCallback(
    (dialog) => {
      mdcComponent.current = dialog;
      addDialog(dialogId);
      _onOpen?.(dialog);
    },
    [dialogId, _onOpen]
  );
  const onClose = React.useCallback(
    (action?: string) => {
      mdcComponent.current = undefined;
      removeDialog(dialogId);
      _onClose?.(action || 'close');
    },
    [dialogId, _onClose]
  );

  return ReactDOM.createPortal(
    <MDCDialogComponent
      DialogActionButtonComponent={DialogActionButton}
      dialogId={dialogId}
      onOpen={onOpen}
      onClose={onClose}
      {...props}
    />,
    document.getElementById('mount')!
  );

  return (
    <MDCDialogComponent
      DialogActionButtonComponent={DialogActionButton}
      dialogId={dialogId}
      onOpen={onOpen}
      onClose={onClose}
      {...props}
    />
  );
};

export const DialogActionButton: React.FC<
  DialogActionButtonProps & ButtonProps
> = (props) => {
  return <MDCDialogActionButton ButtonComponent={Button} {...props} />;
};

export type { DialogActionButtonProps, DialogProps };

export default Dialog;
