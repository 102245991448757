import {
  IonButton,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import Typography from '../common/Typography';
import { JSX } from '@ionic/core/components';
import { User } from 'firebase/auth';
import SendEmailVerificationButton from '../user/SendEmailVerificationButton';
import { FirebaseApp } from 'firebase/app';

interface EmailVerificationRequiredModalProps extends JSX.IonModal {
  onClose: () => void;
  user: User;
  app: FirebaseApp;
}
const Content = styled.div`
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
`;

const EmailVerificationRequiredModal: React.FC<
  EmailVerificationRequiredModalProps
> = ({ user, app, onClose }) => {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Email verification required</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <Content>
          <Typography margin="24px 0">
            You must verify your email address before submitting a case.
          </Typography>

          <Typography margin="24px 0">
            If you have not received a verification email, click below to have
            the email re-sent to you. Please make sure to check your spam
            folder.
          </Typography>

          <SendEmailVerificationButton user={user} app={app} />

          <IonButton onClick={onClose} style={{ marginTop: '16px' }}>
            Go back
          </IonButton>
        </Content>
      </IonContent>
    </>
  );
};

export default EmailVerificationRequiredModal;
