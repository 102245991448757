import React from 'react';
import { useFirebase } from '../services/firebase';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import { isNotNativeApp } from '../util/device';

function useRecaptcha(
  containerOrId: HTMLElement | string | undefined,
  options: {
    size?: 'invisible';
    callback: () => void;
    expiredCallback: () => void;
  }
) {
  const { app } = useFirebase();
  const recaptcha = React.useRef<RecaptchaVerifier>();

  React.useEffect(() => {
    if (!containerOrId) {
      return;
    }
    if (!isNotNativeApp()) {
      console.log('Recaptcha: not web, exiting...');
      return;
    }

    const auth = getAuth(app);
    auth.useDeviceLanguage();

    try {
      const verifier = new RecaptchaVerifier(
        containerOrId,
        {
          size: options.size,
          callback: options.callback,
          'expired-callback': options.expiredCallback,
        },
        auth
      );
      (window as any).recaptchaVerifier = verifier;
      recaptcha.current = verifier;
      verifier.render();
    } catch (e) {
      const ee = e as any;
      console.error(ee);
      console.error(ee?.message || ee?.detail || ee?.error);
    }
  }, [containerOrId, app]);

  return recaptcha;
}

export default useRecaptcha;
