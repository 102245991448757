import { App } from '@capacitor/app';
import Package from '../../package.json';

export async function getVersion() {
  try {
    // This will error if not on mobile device,
    // in which case we fallback to package.json version
    const info = await App.getInfo();
    return info.version;
  } catch (e) {
    // If e.code === 'UNIMPLEMENTED' this is web
    // only log other error codes
    if ((e as any)?.code !== 'UNIMPLEMENTED') {
      console.log(e);
    }
    return Package.version;
  }
}
