import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography';
import { getVersion } from '../../../util/app';

interface VersionInfoProps {}

const Container = styled.div``;

function useVersion() {
  const [version, setVersion] = React.useState('N/A');
  React.useEffect(() => {
    getVersion().then((retrievedVersion) => setVersion(`v${retrievedVersion}`));
  }, []);

  return version;
}

const VersionInfo: React.FC<VersionInfoProps> = (props) => {
  const version = useVersion();
  return <Typography textAlign="right">{version}</Typography>;
};

export default VersionInfo;
