import React from 'react';
import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { NoUnderlineMixin } from '../../style/mixins';
import classNames from 'classnames';

export type NavigationButtonProps =
  React.AnchorHTMLAttributes<HTMLAnchorElement> &
    LinkProps<HTMLAnchorElement> & {
      img: string | React.ReactNode;
      size?: 'sm' | 'lg';
    };

const Content = styled.span`
  flex: 1;
  text-align: left;
  display: flex;
  align-items: flex-end;
  transition-duration: 0.35s;
  border-bottom: 3px solid var(--accent-color4);
`;

const Button = styled(Link)`
  // background-color: white;
  // border-radius: 999px;
  display: flex;
  align-items: center;
  border: none;
  box-shadow: none;
  // padding: 8px 16px 8px 8px;
  // display: flex;
  // align-items: center;
  // ${NoUnderlineMixin}

  &:hover {
    ${Content} {
      transform: scale(1.02);
    }
  }
`;

const ImageContainer = styled.span<{ $size: string }>`
  max-height: 100%;
  overflow: hidden;
  border-radius: 50%;
  ${(props) => `
  
  width: ${props.$size};
  height: ${props.$size};
  margin-right: 16px;
  `}
`;

const Image = styled.img``;

const NavigationButton: React.FC<NavigationButtonProps> = ({
  children,
  img,
  className,
  size = 'sm',
  ...props
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <Button
      className={classNames(className, {
        'mdc-elevation--z3': true,
        'mdc-elevation--z6': isHovered,
      })}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...(props as any)}
    >
      <ImageContainer $size={size === 'sm' ? '72px' : '100px'}>
        {typeof img === 'string' ? <Image src={img} /> : img}
      </ImageContainer>
      <Content>{children}</Content>
    </Button>
  );
};

export default NavigationButton;
