import React from 'react';
import styled from 'styled-components';
import * as RDBCase from '../../../services/buildfire/rdb/cases';
import { useReport } from '../../../services/buildfire/rdb/reports';
import { LOADING_STATE } from '../../../util/loading';
import withCase from '../../../util/withCase';
import ExpandingButton from '../../common/ExpandingButton';
import ModalLoader from '../../common/ModalLoader';
import Typography from '../../common/Typography';
import { useDownloadReport } from '../actionMenu/actions/DownloadPDF';
import Report from './Report';

interface CustomerReportProps extends React.HTMLAttributes<HTMLElement> {
  case: RDBCase.Case;
}

const ButtonContainer = styled.div`
  text-align: center;
`;

const CustomerReport: React.FC<CustomerReportProps> = ({
  case: c,
  ...props
}) => {
  const { report, loadingStatus: reportLoadingStatus } = useReport(c.id);
  const {
    downloadReport,
    isLoading: isDownloadingReport,
    negate,
  } = useDownloadReport(c, report);
  if (reportLoadingStatus === LOADING_STATE.LOADING) {
    return null;
  }

  if (!report) {
    return (
      <Typography variant="primary" fontSize="24px">
        Report not found for case {c.id}
      </Typography>
    );
  }

  return (
    <>
      <Report
        case={c}
        report={report}
        clinicName={c.user.displayName}
        clinicEmail={c.user.email}
        isAdmin={false}
      >
        <ButtonContainer>
          <ExpandingButton size="large" onClick={downloadReport}>
            DOWNLOAD REPORT
          </ExpandingButton>
        </ButtonContainer>
      </Report>
      {isDownloadingReport && <ModalLoader onClose={negate} />}
    </>
  );
};

export default withCase(CustomerReport);
