import React from 'react';
import styled from 'styled-components';
import logo from '../../images/v2/logo/logo.png';

interface LogoProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

const Image = styled.img`
  max-width: 100%;
`;

const Logo: React.FC<LogoProps> = (props) => {
  return <Image src={logo} {...props} />;
};

export default Logo;
