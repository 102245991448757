import React from 'react';
import { useInterpret } from '@xstate/react';
import { InterpreterFrom } from 'xstate';
import submitCaseMachine from '../stateMachines/submitCase';
import { useBuildfireSettings } from './SettingsContext';
import { useFirebase } from '../services/firebase';

interface SubmitCaseContext {
  submitCaseService: InterpreterFrom<typeof submitCaseMachine>;
}

const SubmitCaseContext = React.createContext<SubmitCaseContext>(
  undefined as any
);

export const SubmitCaseContextProvider: React.FC<{}> = ({ children }) => {
  const { currentUser, app, userMetadata, parentAccount } = useFirebase();
  const { settings } = useBuildfireSettings();
  const submitCaseService = useInterpret(submitCaseMachine, {
    context: {
      firebaseApp: app,
      stripePublishableKey: settings.stripe.publishableKey,
      userId: currentUser?.uid,
      email: currentUser?.email!,
      name: currentUser?.displayName!,
      userMetadata: userMetadata || undefined,
      parentAccount,
      selectedInvoiceAccountUid: parentAccount?.uid || currentUser!.uid!,
    },
  });
  (window as any).submitCaseService = submitCaseService;

  return (
    <SubmitCaseContext.Provider value={{ submitCaseService }}>
      {children}
    </SubmitCaseContext.Provider>
  );
};

export function useSubmitCaseContext() {
  return React.useContext(SubmitCaseContext);
}

export default SubmitCaseContext;
