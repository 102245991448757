import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { JSX } from '@ionic/core/components';
import HospitalTermsAndConditions from './HospitalTermsAndConditions';
import styled from 'styled-components';
import useIonicModalProps from '../../hooks/useIonicModalProps';

interface HospitalTermsAndConditionsModalProps extends JSX.IonModal {}

const Wrapper = styled.div`
  padding: 24px;
`;

const HospitalTermsAndConditionsModal: React.FC<
  HospitalTermsAndConditionsModalProps
> = (props) => {
  const modalProps = useIonicModalProps();
  return (
    <IonModal {...props} {...modalProps}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Terms and Conditions</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => modalProps.ref.current?.dismiss()}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Wrapper>
          <HospitalTermsAndConditions />
        </Wrapper>
      </IonContent>
    </IonModal>
  );
};

export default HospitalTermsAndConditionsModal;
