import {
  IonButton,
  IonCol,
  IonInput,
  IonRouterLink,
  IonRow,
  IonSpinner,
  useIonToast,
} from '@ionic/react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import Form from '../../components/common/Form';
import { uppercaseFirstLetter } from '../../util/string';
import useQueryParams from '../../hooks/useQueryParams';
import { useFirebase } from '../../services/firebase';
import { signInWithEmail } from '../../api/cloudFunctions';
import { getFunctions } from 'firebase/functions';
import { warning } from 'ionicons/icons';

interface MagicLinkFormProps {}

const ButtonRow = styled(IonRow)`
  margin-top: 8px;
`;
const HiddenSubmitButton = styled.button`
  position: absolute;
  display: none;
`;
const CenteredRow = styled(IonRow)`
  justify-content: center;
`;
const Link = styled(IonRouterLink)`
  margin: 16px 0;
`;

const MagicLinkForm: React.FC<MagicLinkFormProps> = ({}) => {
  const { app } = useFirebase();
  const searchParams = useQueryParams();
  const [present] = useIonToast();
  return (
    <Formik
      validateOnMount
      initialValues={{
        email: searchParams.get('email') || '',
      }}
      onSubmit={async (values, helpers) => {
        try {
          const fns = getFunctions(app);
          await signInWithEmail(fns, { email: values.email });

          // Save email for authentication
          window.localStorage.setItem('emailForSignIn', values.email);

          present(
            `Email sent, please check your inbox for a link to login`,
            4000
          );
          helpers.resetForm();
          helpers.setFieldTouched('email', false, false);
        } catch (e) {
          console.error(e);

          present({
            message:
              'Failed to send email, please make sure email is correct and try again',
            icon: warning,
            color: 'danger',
            duration: 6000,
          });
        }
      }}
      validationSchema={Yup.object({
        email: Yup.string().email().required(),
      })}
    >
      {({
        values,
        touched,
        errors,
        setFieldTouched,
        setFieldValue,
        isValid,
        isSubmitting,
      }) => (
        <Form>
          <IonInput
            className={classNames({
              ['ion-invalid']: errors.email,
              ['ion-touched']: touched.email,
            })}
            label="Email"
            labelPlacement="floating"
            type="text"
            name="email"
            required
            autofocus
            value={values.email}
            onIonInput={(e) => {
              setFieldValue('email', e.detail.value);
              setFieldTouched('email', true);
            }}
            errorText={uppercaseFirstLetter(errors.email as string)}
          />

          <ButtonRow>
            <IonCol style={{ paddingLeft: 0, paddingRight: 0 }}>
              <IonButton
                type="submit"
                disabled={!isValid || isSubmitting}
                expand="block"
              >
                {isSubmitting ? (
                  <>
                    <IonSpinner
                      name="crescent"
                      style={{ marginRight: '8px' }}
                    />
                    Loading
                  </>
                ) : (
                  'Send Email'
                )}
              </IonButton>
              {/* Need this for Ionic hack... so the form submits on enter */}
              <HiddenSubmitButton type="submit" />
            </IonCol>
          </ButtonRow>

          <CenteredRow>
            <Link routerLink="/login">Login with password</Link>
          </CenteredRow>
        </Form>
      )}
    </Formik>
  );
};

export default MagicLinkForm;
