import { Formik, FormikConfig, Field, FormikProps } from 'formik';
import React from 'react';
import { Prompt } from 'react-router';
import styled from 'styled-components';
import * as Yup from 'yup';
import { ReportItem } from '../../services/buildfire/rdb/reports';
import { uppercaseFirstLetter } from '../../util/string';
import Form from '../common/Form';
import TextArea from '../common/TextArea';

interface ReportFormProps {
  initialValues?: Partial<ReportItem>;
  onSubmit: FormikConfig<ReportItem>['onSubmit'];
  submitButtons: (state: FormikProps<ReportItem>) => React.ReactNode;
}

const SubmitButtonContainer = styled.div``;

const ReportForm: React.FC<ReportFormProps> = ({
  onSubmit,
  submitButtons,
  initialValues,
}) => {
  return (
    <Formik
      initialValues={
        {
          interpretation: '',
          comments: '',
          nextSteps: '',
          ...initialValues,
        } as ReportItem
      }
      onSubmit={onSubmit}
      validateOnMount
      validationSchema={Yup.object({
        interpretation: Yup.string()
          .trim('Must not be blank')
          .max(4000)
          .required('Required'),
        comments: Yup.string().min(1).max(4000).required('Required'),
        nextSteps: Yup.string().min(1).max(4000).required('Required'),
      })}
    >
      {({ touched, errors, dirty, isSubmitting, ...formikProps }) => (
        <Form>
          <Prompt
            when={dirty && !isSubmitting}
            message="Are you sure you want to leave this page? Changes will not be saved."
          />
          <Field
            as={TextArea}
            name="interpretation"
            label="Microscopic Interpretation"
            rows={4}
            resizable={false}
            maxLength={4000}
            error={
              touched.interpretation &&
              uppercaseFirstLetter(errors.interpretation as string)
            }
          />
          <Field
            as={TextArea}
            name="comments"
            label="Pathologist's Comments"
            rows={4}
            resizable={false}
            maxLength={4000}
            error={
              touched.comments &&
              uppercaseFirstLetter(errors.comments as string)
            }
          />
          <Field
            as={TextArea}
            name="nextSteps"
            label="Suggested Next Steps"
            rows={4}
            resizable={false}
            maxLength={4000}
            error={
              touched.nextSteps &&
              uppercaseFirstLetter(errors.nextSteps as string)
            }
          />
          <SubmitButtonContainer>
            {submitButtons({
              touched,
              errors,
              dirty,
              isSubmitting,
              ...formikProps,
            })}
          </SubmitButtonContainer>
        </Form>
      )}
    </Formik>
  );
};

export default ReportForm;
