import React from 'react';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import classNames from 'classnames';
import Toolbar from '../../components/common/Toolbar';
import LoadingScreen from '../../components/common/LoadingScreen';
import { useFirebase } from '../../services/firebase';
import { isDesktop } from '../../util/device';
import { useRouteMatch } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import InvoiceList from '../../components/invoice/Invoices';
import { getOpenInvoices } from '../../api/cloudFunctions';
import { Functions, getFunctions } from 'firebase/functions';

interface InvoicesProps {}

const fetchData = async (fns: Functions) => {
  return Promise.all([
    getOpenInvoices(fns).then((r) => r?.invoices || []),
  ]).then(([invoices]) => ({
    invoices,
  }));
};

const Invoices: React.FC<InvoicesProps> = (props) => {
  const { isAdmin, app } = useFirebase();
  // const {user} = useUser
  const match = useRouteMatch(
    `${isAdmin ? '/admin' : ''}/users/:userId/invoices/:invoiceId`
  );
  const showMainHeader = !match?.url || isDesktop();
  const { data, loadingStatus, error } = useFetch(
    async () => {
      const fns = getFunctions(app);
      return fetchData(fns);
    },
    () => {},
    []
  );

  return (
    <IonPage>
      <IonHeader
        className={classNames({
          ['ion-no-border']: true,
          // ['hide-sm']: !showMainHeader,
        })}
      >
        <Toolbar
          showBackButton
          defaultBackLocation={'/'}
          title={'Open Invoices'}
        />
      </IonHeader>

      <IonContent>
        <LoadingScreen loadingStatus={loadingStatus} error={error}>
          {data?.invoices && (
            <InvoiceList invoices={data.invoices} view="admin" />
          )}
        </LoadingScreen>
      </IonContent>
    </IonPage>
  );
};

export default Invoices;
