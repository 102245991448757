import { FirebaseApp } from 'firebase/app';
import {
  DataSnapshot,
  getDatabase,
  onChildAdded,
  onChildRemoved,
  onValue,
  query,
  ref,
} from 'firebase/database';
import React from 'react';
import { useFirebase } from '../services/firebase';
// import { head } from 'fp-ts/Array';
import head from 'lodash/fp/head';
import * as O from 'fp-ts/Option';
import * as E from 'fp-ts/Eq';
import * as A from 'fp-ts/Array';

export const NotifContext = React.createContext<INotifContext>(
  undefined as any
);

export interface NotifState {
  messages: boolean;
}
export interface AlertState {
  alerts: Alert[];
  alertsRead: boolean;
}

interface INotifContext {
  notifState: NotifState;
  alertState: AlertState;
  setMessageNotifsEnabled: (enabled: boolean) => void;
  popAlert;
}

type ReducerState = Pick<INotifContext, 'notifState' | 'alertState'>;
type Action =
  | {
      type: 'SET_MESSAGES_ENABLED';
      enabled: boolean;
    }
  | {
      type: 'NEW_ALERT';
      alert: Alert;
    }
  | {
      type: 'REMOVE_ALERT';
      id: string;
    }
  | {
      type: 'SET_ALERTS';
      alerts: Alert[];
    }
  | {
      type: 'MARK_ALERTS_READ';
    };

const alertIsEqual: E.Eq<Alert> = {
  equals: (x, y) => x.id === y.id,
};
const uniqAlerts = A.uniq(alertIsEqual);

function reducer(state: ReducerState, action: Action): ReducerState {
  switch (action.type) {
    case 'SET_MESSAGES_ENABLED': {
      return {
        ...state,
        notifState: {
          ...state.notifState,
          messages: action.enabled,
        },
      };
    }
    case 'SET_ALERTS': {
      return {
        ...state,
        alertState: {
          ...state.alertState,
          alerts: action.alerts,
        },
      };
    }
    case 'NEW_ALERT': {
      const alerts = state.alertState.alerts;
      const newAlerts = uniqAlerts(alerts.concat(action.alert));
      return {
        ...state,
        alertState: {
          ...state.alertState,
          alerts: newAlerts,
        },
      };
    }
    case 'REMOVE_ALERT': {
      const alerts = state.alertState.alerts.filter(
        ({ id }) => id !== action.id
      );
      return {
        ...state,
        alertState: {
          ...state.alertState,
          alerts,
        },
      };
    }
    case 'MARK_ALERTS_READ': {
      return {
        ...state,
        alertState: {
          ...state.alertState,
          alertsRead: true,
        },
      };
    }
    default:
      return state;
  }
}

export type Alert = {
  id: string;
  message: string;
  start?: Date;
  end?: Date;
};

const toAlert = (snap: DataSnapshot): O.Option<Alert> => {
  if (!snap.exists()) {
    return O.none;
  }

  const val = snap.val();
  return O.of({
    ...val,
    start: val.start ? new Date(val.start) : undefined,
    end: val.end ? new Date(val.end) : undefined,
    id: snap.key,
  });
};

const listenForAlerts = (
  app: FirebaseApp,
  onAlert: (alert: Alert) => void,
  onRemoved: (id: string) => void
): (() => void) => {
  const db = getDatabase(app);
  const alertsRef = ref(db, `alerts`);
  const unsub1 = onChildAdded(alertsRef, (snapshot) => {
    const alert = toAlert(snapshot);
    if (O.isSome(alert)) {
      onAlert(alert.value);
    }
  });
  const unsub2 = onChildRemoved(alertsRef, (snapshot) => {
    onRemoved(snapshot.key!);
  });

  return () => {
    unsub1();
    unsub2();
  };
};

const retrieveAlerts = (app: FirebaseApp): Promise<Alert[]> => {
  const db = getDatabase(app);
  const alertsRef = ref(db, `alerts`);
  const q = query(alertsRef);
  return new Promise<Alert[]>((resolve, reject) => {
    onValue(
      q,
      (snapshot) => {
        if (!snapshot.exists()) {
          return resolve([]);
        }

        const alerts: Alert[] = [];
        snapshot.forEach((snap) => {
          const alert = toAlert(snap);
          if (O.isSome(alert)) {
            alerts.push(alert.value);
          }
        });

        resolve(alerts);
      },
      reject,
      {
        onlyOnce: true,
      }
    );
  });
};

const NotifContextProvider: React.FC<{}> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    notifState: {
      messages: true,
    },
    alertState: {
      alerts: [],
      alertsRead: false,
    },
  });
  const setMessageNotifsEnabled = React.useCallback((enabled: boolean) => {
    dispatch({ type: 'SET_MESSAGES_ENABLED', enabled });
  }, []);

  const { app } = useFirebase();
  React.useEffect(
    (async () => {
      if (!app) {
        return;
      }
      const alerts = await retrieveAlerts(app);
      dispatch({ type: 'SET_ALERTS', alerts });
    }) as any,
    [app]
  );

  React.useEffect(() => {
    const unsub = listenForAlerts(
      app,
      (alert) => {
        dispatch({ type: 'NEW_ALERT', alert });
      },
      (id) => dispatch({ type: 'REMOVE_ALERT', id })
    );
    return () => unsub();
  }, []);

  const popAlert = React.useCallback(
    () =>
      dispatch({
        type: 'SET_ALERTS',
        alerts: state.alertState.alerts.slice(1),
      }),
    [state.alertState.alerts]
  );

  return (
    <NotifContext.Provider
      value={{
        setMessageNotifsEnabled,
        notifState: state.notifState,
        alertState: state.alertState,
        popAlert,
      }}
    >
      {children}
    </NotifContext.Provider>
  );
};

export function useAlertsState() {
  const { alertState, popAlert } = React.useContext(NotifContext);
  const alert = head(alertState.alerts);
  return { alert, popAlert };
}

export function useMessageNotifBlock(block: () => boolean) {
  const context = React.useContext(NotifContext);
  React.useEffect(() => {
    const blocked = block();
    if (blocked && context.notifState.messages) {
      // context.setMessageNotifsEnabled(false);
      // return () => {
      //   if (blocked) {
      //     context.setMessageNotifsEnabled(true);
      //   }
      // };
    }
  }, [block, context.notifState]);
}

export default NotifContextProvider;
