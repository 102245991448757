import { getDatabase } from 'firebase/database';
import React from 'react';
import { useFirebase } from '../services/firebase';
import { LOADING_STATE } from '../util/loading';
import { DBUserInfo, getUserInfo } from '../services/firebase/database';

function useRDBUserInfo() {
  const { currentUser, app } = useFirebase();
  const [loadingState, setLoadingState] = React.useState<LOADING_STATE>(
    LOADING_STATE.LOADING
  );
  const [userInfo, setUserInfo] = React.useState<DBUserInfo>();
  React.useEffect(() => {
    if (!currentUser || !app) {
      return;
    }

    const database = getDatabase(app);
    getUserInfo(database, currentUser.uid)
      .then((val) => {
        setUserInfo(val);
        setLoadingState(LOADING_STATE.LOADED);
      })
      .catch((e) => {
        console.error(e);
        setLoadingState(LOADING_STATE.ERROR);
      });
  }, [currentUser, app]);

  return { userInfo, currentUser, loadingState };
}

export default useRDBUserInfo;
