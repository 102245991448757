import { createMachine, MachineConfig } from 'xstate';
import creditCardStates from '../submitCase/creditCard';
import selectPaymentTypeStates, {
  PaymentType,
} from '../submitCase/selectPaymentType';
import checkoutStates from './checkout';
import * as guards from './guards';
import * as actions from './actions';
import { EditCaseEvent, EditCaseContext } from './types';
import * as RDBCases from '../../services/buildfire/rdb/cases';
import { getDatabase } from 'firebase/database';

async function loadCase(context: EditCaseContext) {
  const db = getDatabase(context.firebaseApp);
  const c = await RDBCases.getById(db, context.caseId);

  const invoice = await RDBCases.getInvoice(db, context.caseId);

  return { case: c, invoice, selectedPaymentMethod: invoice?.paymentMethodId };
}

const config: MachineConfig<EditCaseContext, any, EditCaseEvent> = {
  id: 'Edit Case Form',
  initial: 'loadCase',
  context: {
    stripePublishableKey: '',
    creditCards: [],
    userId: '',
    email: '',
    name: '',
    paymentType: PaymentType.CREDIT_CARD,
  } as any,
  states: {
    error: {
      on: {},
    },
    loadCase: {
      invoke: {
        src: loadCase,
        onDone: {
          target: 'enterInfo',
          actions: 'loadData',
        },
        onError: {
          actions: 'loadError',
        },
      },
    },
    enterInfo: {
      on: {
        NEXT: {
          target: 'selectPaymentType',
          cond: 'isCaseInfoValid',
          actions: 'setCaseInfo',
        },
      },
    },
    selectPaymentType: {
      ...(selectPaymentTypeStates as any),
      on: {
        NEXT: 'creditCard',
        BACK: 'enterInfo',
      },
      onDone: 'creditCard',
    },
    creditCard: {
      ...(creditCardStates as any),
      onDone: 'checkout',
      on: {
        BACK: [
          {
            target: 'enterInfo',
            cond: 'isInvoicingDisabled',
          },
          {
            target: 'selectPaymentType',
          },
        ],
      },
    },
    checkout: {
      ...(checkoutStates as any),
      onDone: 'submitted',
      on: {
        BACK: 'creditCard',
      },
    },
    submitted: {
      type: 'final',
    },
  },
};

const submitCaseMachine = createMachine(config, {
  actions: actions as any,
  guards: {
    ...guards,
    every: (ctx, event, { cond }) => {
      if (cond.type !== 'every') {
        throw new Error('Error: "every" condition expected type to be "every"');
      }
      return cond.guards.every((guardKey) => guards[guardKey](ctx, event));
    },
  },
});

export default submitCaseMachine;
