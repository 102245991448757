import { IonContent, IonHeader, IonPage } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import Button from '../components/common/Button';
import Toolbar from '../components/common/Toolbar';
import Typography from '../components/common/Typography';
import useFetch from '../hooks/useFetch';
import useQueryParams from '../hooks/useQueryParams';
import { useFirebase } from '../services/firebase';
import AlertBox from '../components/common/AlertBox';
import { getPasswordSetupLink } from '../api/cloudFunctions';
import { getFunctions } from 'firebase/functions';
import LoadingScreen from '../components/common/LoadingScreen';
import LoginScreen from './login/LoginScreen';
import { useHistory } from 'react-router-dom';

interface NotFoundProps {}

const Container = styled.div`
  margin: 64px 0;
  display: flex;
  flex-direction: column;
`;

const Welcome: React.FC<NotFoundProps> = (props) => {
  const { app } = useFirebase();
  const params = useQueryParams();
  const history = useHistory();
  const token = params.get('token') || '';

  const [message, setMessage] = React.useState<{
    text: string;
    type: 'info' | 'error';
  }>();
  const { loadingStatus, error, data } = useFetch(async () => {
    try {
      // Get password setup link and redirect
      const fns = getFunctions(app);
      const { link } = await getPasswordSetupLink(fns, { token });
      const relativeUrl = link.replace(window.location.origin, '');
      history.replace(relativeUrl);
    } catch (e) {
      console.error(e);
      throw new Error(
        'Error: invalid link, please try again or contact the administrator for help.'
      );
    }
  });

  return (
    <LoginScreen>
      <LoadingScreen loadingStatus={loadingStatus} error={error}>
        <Container>
          {message && <AlertBox text={message.text} type={message.type} />}
        </Container>
      </LoadingScreen>
    </LoginScreen>
  );
};

export default Welcome;
