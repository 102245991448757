import React from 'react';
import { Field, Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import Form from '../common/Form';
import TextArea from '../common/TextArea';
import { uppercaseFirstLetter } from '../../util/string';
import { Alert } from '../../context/NotifContext';
import { IonButton, IonCol } from '@ionic/react';

export type FormValues = Omit<Alert, 'id'>;

interface AlertFormProps {
  onSubmit: (values: FormValues) => Promise<void>;
  onClear: () => Promise<boolean>;
  initialValues?: Partial<FormValues>;
}

const SubmitButtonContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SubmitButton = styled(IonButton)`
  margin-bottom: 32px;
`;

const AlertForm: React.FC<AlertFormProps> = ({
  initialValues,
  onSubmit,
  onClear,
}) => {
  return (
    <Formik
      initialValues={{
        message: '',
        startTime: null,
        endTime: null,
        ...initialValues,
      }}
      onSubmit={async (values, helpers) => {
        await onSubmit(values);
        helpers.resetForm({ values });
      }}
      validateOnMount
      validationSchema={Yup.object({
        message: Yup.string().required('Message is required'),
      })}
    >
      {({
        touched,
        errors,
        dirty,
        isValid,
        isSubmitting,
        values,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form>
          <Field
            as={TextArea}
            label="Alert message"
            name="message"
            rows={4}
            resizable={false}
            maxLength={3000}
            error={
              touched.message && uppercaseFirstLetter(errors.message as string)
            }
          />
          <SubmitButtonContainer>
            <IonCol>
              <SubmitButton
                type="submit"
                disabled={!dirty || !isValid || isSubmitting}
                expand="block"
              >
                Save
              </SubmitButton>
              <IonButton
                expand="block"
                color="danger"
                disabled={!values.message || isSubmitting}
                onClick={async () => {
                  const clear = await onClear();
                  if (clear) {
                    setFieldTouched('message', false);
                    setFieldValue('message', '');
                  }
                }}
              >
                Clear
              </IonButton>
            </IonCol>
          </SubmitButtonContainer>
        </Form>
      )}
    </Formik>
  );
};

export default AlertForm;
