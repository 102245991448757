import React from 'react';
import { useDialog } from '../../../../context/DialogContext';
import { useFirebase } from '../../../../services/firebase';
import {
  setInvoicingStatus,
  UserMetadataWithId,
} from '../../../../services/firebase/user';
import ModalLoader from '../../../common/ModalLoader';
import { getDatabase } from 'firebase/database';
import ActionItem from '../../../case/actionMenu/ActionItem';

interface SetInvoiceStatusProps {
  uid: string;
  user?: Partial<UserMetadataWithId>;
  updateUser: (metadata: Partial<UserMetadataWithId>) => void;
}

const SetInvoiceStatus: React.FC<SetInvoiceStatusProps> = ({
  uid,
  user,
  updateUser,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { openDialog } = useDialog();
  const { app } = useFirebase();
  const invoicingDisabled = user ? user.invoicingDisabled : false;

  return (
    <>
      <ActionItem
        disabled={isLoading}
        onClick={async () => {
          setIsLoading(true);
          try {
            const db = getDatabase(app);
            setInvoicingStatus(db, uid, !invoicingDisabled);
            updateUser({ ...user, invoicingDisabled: !invoicingDisabled });

            setIsLoading(false);
          } catch (e) {
            console.error(e);
            setIsLoading(false);
            openDialog(<div>{(e as any).message || e}</div>);
          }
        }}
        label={invoicingDisabled ? 'Enable invoicing' : 'Disable invoicing'}
      />
      {isLoading && (
        <ModalLoader
          onClose={() => {
            setIsLoading(false);
          }}
        />
      )}
    </>
  );
};

export default SetInvoiceStatus;
