import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import SnackbarContext from '../../context/SnackbarContext';
import { getFunctions } from 'firebase/functions';
import { useFirebase } from '../../services/firebase';
import SubmitCaseViewProps from '../submitCase/types/SubmitCaseViewProps';
import { CreditCard, removePaymentMethod } from '../submitCase/CreditCard';
import { useEditCaseContext } from '../../context/EditCaseContext';
import { PaymentType } from '../../stateMachines/submitCase/selectPaymentType';
import InvoiceSelection from '../submitCase/components/InvoiceSelection';

interface ControlledCreditCardProps extends SubmitCaseViewProps {}

const ControlledCreditCard: React.FC<ControlledCreditCardProps> = ({
  ...props
}) => {
  const { editCaseService } = useEditCaseContext();
  const [state, send] = useActor(editCaseService);
  const isLoading =
    state.matches({ creditCard: 'load' }) ||
    state.matches({ creditCard: 'confirmSetupIntent' });
  const { clientSecret } = state.context;
  const numSavedPaymentMethods = state.context.paymentMethods?.length || 0;
  const hasSavedPaymentMethods = numSavedPaymentMethods > 0;
  const [isAddingRemovingPaymentMethod, setIsAddingRemovingPaymentMethod] =
    React.useState(false);
  const { setMessage } = useContext(SnackbarContext);
  const { app, parentAccount } = useFirebase();
  const functions = getFunctions(app);
  const isInvoicingSelected = state.context.paymentType === PaymentType.INVOICE;

  const showInvoiceSelection = state.matches({
    creditCard: 'selectInvoiceAccount',
  });
  if (showInvoiceSelection) {
    return (
      <InvoiceSelection
        currentUser={props.currentUser}
        parentAccount={parentAccount!}
        selectedInvoiceAccountUid={state.context.selectedInvoiceAccountUid}
        onInvoiceAccountChange={(e) => {
          if (e.target.value !== state.context.selectedInvoiceAccountUid) {
            send({
              type: 'SET_INVOICE_ACCOUNT',
              invoiceAccountUid: e.target.value!,
            });
          }
        }}
        onNext={() => send({ type: 'NEXT' })}
        onGoBack={() => send({ type: 'BACK' })}
      />
    );
  }

  return (
    <CreditCard
      currentUser={props.currentUser}
      isLoading={isLoading}
      isInvoiceEnabled={isInvoicingSelected}
      PaymentMethodListProps={{
        paymentMethods: state.context.paymentMethods!,
        selectedPaymentMethod: state.context.selectedPaymentMethod!,
        onSelectPaymentMethod: async (id) => {
          send({ type: 'SELECT_CARD', id });
        },
        onRemovePaymentMethod: async (id) => {
          setIsAddingRemovingPaymentMethod(true);
          try {
            await removePaymentMethod(functions, id);
            send({ type: 'REMOVE_CREDIT_CARD', paymentMethodId: id });
            setMessage({ text: 'Card successfully removed' });
          } catch (e) {
            console.error('ERROR removing payment method', id, e);
            setMessage({ text: 'Error removing card, please try again' });
          }

          setIsAddingRemovingPaymentMethod(false);
        },
        isLoading,
        disabled: isInvoicingSelected || isAddingRemovingPaymentMethod,
      }}
      AddNewPaymentMethodProps={{
        userId: props.currentUser.uid,
        email: props.currentUser.email!,
        clientSecret: clientSecret!,
        isSubmitting: state.matches({
          creditCard: { addCreditCard: 'addingCreditCard' },
        }),
        onSubmit: async ({ stripe, elements }) => {
          send({
            type: 'ADD_CARD',
            stripe,
            elements,
          });
        },
        isDisabled: isInvoicingSelected || isAddingRemovingPaymentMethod,
        isDialogOpen: state.matches({ creditCard: 'addCreditCard' }),
        isLoading: state.matches({ creditCard: { addCreditCard: 'load' } }),
        onWillAddCreditCard: () => {
          send({
            type: 'WILL_ADD_CREDIT_CARD',
          });
        },
        onAddCardDialogClose: () => {
          send({ type: 'CLOSE' });
        },
      }}
      onNext={() => send({ type: 'NEXT' })}
      onGoBack={() => send({ type: 'BACK' })}
      nextDisabled={!hasSavedPaymentMethods || isAddingRemovingPaymentMethod}
      backDisabled={isAddingRemovingPaymentMethod}
    />
  );
};

export default ControlledCreditCard;
