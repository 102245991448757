function uppercaseFirstLetter(str: string[]): string[];
function uppercaseFirstLetter(str: string): string;
function uppercaseFirstLetter(str?: string | string[]) {
  if (Array.isArray(str)) {
    return str.map(uppercaseFirstLetter);
  }
  return str ? str[0].toUpperCase() + str.slice(1) : str;
}

export { uppercaseFirstLetter };
