const defaultCreateCacheKey = <A extends any[]>(...args: A) =>
  JSON.stringify(args);

const memoizePromiseFn = <A extends any[] = [], T = {}>(
  fn: (...a: A) => Promise<T>,
  createCacheKey: (...a: A) => string = defaultCreateCacheKey
) => {
  const cache = new Map<string, Promise<T>>();

  return (...args: A) => {
    const key = createCacheKey(...args);

    if (cache.has(key)) {
      return cache.get(key);
    }

    cache.set(
      key,
      fn(...args).catch((error) => {
        // Delete cache entry if API call fails
        cache.delete(key);
        return Promise.reject(error);
      })
    );

    return cache.get(key);
  };
};

export default memoizePromiseFn;
