import React from 'react';
import { useActor } from '@xstate/react';
import CaseCheckout from '../case/CaseCheckout';
import SubmitCaseViewProps from '../submitCase/types/SubmitCaseViewProps';
import { useEditCaseContext } from '../../context/EditCaseContext';
import { PaymentType } from '../../stateMachines/submitCase/selectPaymentType';

interface CheckoutProps extends SubmitCaseViewProps {}

const Checkout: React.FC<CheckoutProps> = ({ ...props }) => {
  const { editCaseService } = useEditCaseContext();
  const [state, send] = useActor(editCaseService);

  const { caseInfo, uploadProgress } = state.context;

  const isLoading = state.matches({ checkout: 'submitCase' });

  if (!caseInfo) {
    return null;
  }

  return (
    <CaseCheckout
      caseUserId={state.context.userId}
      clinicName={state.context.name}
      clinicEmail={state.context.email}
      invoicingEnabled={state.context.paymentType === PaymentType.INVOICE}
      parentAccount={state.context.parentAccount}
      onSubmit={() => send('NEXT')}
      onBack={() => send('BACK')}
      caseInfo={caseInfo}
      isLoading={isLoading}
      progress={uploadProgress}
      submitText="Update Case"
      selectedInvoiceAccountUid={state.context.selectedInvoiceAccountUid}
    />
  );
};

export default Checkout;
