import moment from 'moment';
import { StripeInvoice } from '../api/cloudFunctions';

// Stripe has timestamps in seconds, not ms so need to multiply by 1000
export const formatStripeTimestamp = (
  timestamp: number,
  format = 'MMM D, YYYY'
) => {
  const ms = timestamp * 1000;
  return moment(ms).format(format);
};

export const getInvoiceMonth = (invoice: StripeInvoice) => {
  if (!invoice.periodStart) {
    return null;
  }
  return formatStripeTimestamp(invoice.periodStart, 'MMMM YYYY');
};
