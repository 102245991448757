import { IonButton } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';

interface NavButtonsProps {
  nextText: JSX.Element | string;
  prevText: JSX.Element | string;
  onNext: () => void;
  onBack: () => void;
  disabled?: boolean;
}

const Container = styled.div`
  margin: 48px 0;
`;

const Button = styled(IonButton)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const NavButtons: React.FC<NavButtonsProps> = ({
  nextText,
  onNext,
  prevText,
  onBack,
  disabled,
}) => {
  return (
    <Container>
      <Button
        color="danger"
        onClick={() => onNext()}
        expand="block"
        disabled={disabled}
      >
        {nextText}
      </Button>
      <Button color="medium" fill="outline" onClick={onBack} expand="block">
        {prevText}
      </Button>
    </Container>
  );
};

export default NavButtons;
