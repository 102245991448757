import React from 'react';
import styled from 'styled-components';
import { Case } from '../../services/buildfire/rdb/cases';
import UnreadNotif from '../common/UnreadNotif';
import { doesCaseHaveUnreadMessage } from '../../util/case';
import StatusBadge from '../case/StatusBadge';
import GenericCaseListItem from '../case/CaseListItem';
import { useFirebase } from '../../services/firebase';
import useCaseIdParam from '../../hooks/useCaseIdParam';

interface CaseListItemProps {
  case: Case;
}

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledUnreadNotif = styled(UnreadNotif)`
  line-height: 12px;
  margin-left: 6px;
  align-self: center;
`;

const CaseListItem: React.FC<CaseListItemProps> = ({ case: c }) => {
  const { currentUser, isAdmin } = useFirebase();
  const canMessage = currentUser?.uid === c.uid || isAdmin;
  const isUnread =
    canMessage && doesCaseHaveUnreadMessage(c, currentUser!, isAdmin);
  const selectedCaseId = useCaseIdParam();
  return (
    <GenericCaseListItem
      case={c}
      rightHandContent={
        <StatusContainer>
          <StatusBadge status={c.status} isAdmin={isAdmin} uniformWidth />
        </StatusContainer>
      }
      currentUser={currentUser!}
      isUnread={isUnread}
      UnreadNotifComponent={StyledUnreadNotif}
      selected={c.id === selectedCaseId}
    />
  );
};

export default CaseListItem;
