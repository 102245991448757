import React from 'react';
import styled from 'styled-components';
import { PaymentType } from '../../../stateMachines/submitCase/selectPaymentType';
import { User } from 'firebase/auth';
import { IonIcon } from '@ionic/react';
import { useSubmitCaseContext } from '../../../context/SubmitCaseContext';
import { useActor } from '@xstate/react';
import Button from '../../common/Button';
import Typography from '../../common/Typography';
import { checkmarkCircle } from 'ionicons/icons';
import creditCardIcon from '../../../images/submit-case/credit-card.svg';
import invoiceIcon from '../../../images/submit-case/invoice.svg';
import FullscreenLoader from '../../common/FullscreenLoader';
import AlertBox from '../../common/AlertBox';

const Radio = styled.input`
  opacity: 0.01;
  z-index: 100;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;
const Content = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #ccc;
  cursor: pointer;
  z-index: 90;
`;
const OptionLabel = styled.label`
  font-weight: bold;
  font-size: 20px;
  // color: var(--ion-color-primary);
`;

const DetailsContainer = styled.div`
  display: flex;

  // align-items: center;
  flex-direction: column;

  padding: 16px;
`;
const OptionContainer = styled('li')`
  float: left;
  margin: 0 5px 0 0;
  width: 80%;
  height: 200px;
  position: relative;
  cursor: pointer;
  margin: 20px;

  display: flex;
  justify-content: center;

  list-style-type: none;

  & ${Radio}:disabled + ${Content} {
    opacity: 0.5;
  }
  & ${Radio}:checked + ${Content} {
    // background-color: var(--accent-color6);
    // background-color: var(--color-primary-light3);
    background-color: #d7ccec33;
  }
  &:hover {
    & ${Content} {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      // background-color: var(--ion-color-light-shade);
      background-color: #d7ccec11;
    }
  }
`;

const OptionDetails = styled(Typography)`
  margin-top: 16px;
  // color: var(--ion-color-secondary);
`;

const CheckedIcon = styled(IonIcon)`
  position: absolute;
  bottom: -16px;
  // right: -16px;
  left: calc(50% - 16px);
  color: var(--accent-color4);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: white;
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  padding: 16px;
`;

interface OptionProps extends React.LiHTMLAttributes<HTMLLIElement> {
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  icon: React.ReactNode;
  label: string;
  details: string;
}

const Option: React.FC<OptionProps> = ({
  label,
  icon,
  details,
  inputProps,
  ...props
}) => {
  return (
    <OptionContainer {...props}>
      <Radio type="radio" {...inputProps} />
      <Content>
        <IconContainer>{icon}</IconContainer>
        <DetailsContainer>
          <OptionLabel htmlFor={props.id}>{label}</OptionLabel>

          <OptionDetails>{details}</OptionDetails>

          {inputProps.checked && <CheckedIcon icon={checkmarkCircle} />}
        </DetailsContainer>
      </Content>
    </OptionContainer>
  );
};

const OptionList = styled.ul`
  list-style-type: none;
  margin: 25px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ButtonContainer = styled.div`
  margin-top: 64px;
  padding: 0 8px;
`;
const InvoiceAlertContainer = styled.div`
  padding: 0 16px;
`;

interface SelectPaymentTypeProps {
  value: PaymentType;
  onChange: (value: PaymentType) => void;
  onNext: () => void;
  onBack: () => void;
  isLoading: boolean;
  hasPastDueInvoice: boolean;
  invoicingDisabled: boolean;
}

export const SelectPaymentType: React.FC<SelectPaymentTypeProps> = ({
  value,
  onChange,
  onNext,
  onBack,
  isLoading,
  hasPastDueInvoice,
  invoicingDisabled,
}) => {
  return (
    <FullscreenLoader show={isLoading}>
      {!isLoading && (
        <div>
          <Typography
            as="h3"
            fontSize="20px"
            variant="secondary"
            margin="16px 8px"
          >
            Payment Type
          </Typography>
          <Typography fontSize="12px" margin="16px 8px">
            Choose payment method for this case
          </Typography>

          <OptionList>
            <Option
              inputProps={{
                name: 'selectPaymentType',
                value: PaymentType.CREDIT_CARD,
                checked: value === PaymentType.CREDIT_CARD,
                onChange: (e) => onChange(PaymentType.CREDIT_CARD),
              }}
              onClick={() => onChange(PaymentType.CREDIT_CARD)}
              label="Credit card"
              details="Your card will be charged once the case is signed off."
              icon={<img src={creditCardIcon} alt="Credit card" />}
            />
            <Option
              inputProps={{
                name: 'selectPaymentType',
                value: PaymentType.INVOICE,
                checked: value === PaymentType.INVOICE,
                onChange: (e) => onChange(PaymentType.INVOICE),
                disabled: hasPastDueInvoice || invoicingDisabled,
              }}
              onClick={() => onChange(PaymentType.INVOICE)}
              label="Invoice"
              details="Invoices are sent at the beginning of the next month and are due within 14 days of receipt."
              icon={<img src={invoiceIcon} alt="Invoice" />}
            />
          </OptionList>

          {hasPastDueInvoice && (
            <InvoiceAlertContainer>
              <AlertBox
                type="warning"
                text={
                  <>
                    Invoicing has been disabled for your account because you
                    have a past due invoice. If you think this is in error,
                    please contact info@pocketpathologist.com.
                  </>
                }
              />
            </InvoiceAlertContainer>
          )}

          <ButtonContainer>
            <Button fullWidth type="button" onClick={onNext}>
              NEXT
            </Button>
            <Button fullWidth emphasis="low" type="button" onClick={onBack}>
              GO BACK
            </Button>
          </ButtonContainer>
        </div>
      )}
    </FullscreenLoader>
  );
};

const ControlledSelectPaymentType: React.FC<{ currentUser: User }> = (
  props
) => {
  const { submitCaseService } = useSubmitCaseContext();
  const [state, send] = useActor(submitCaseService);
  const isLoading = state.matches({ selectPaymentType: 'load' });
  return (
    <SelectPaymentType
      {...props}
      value={state.context.paymentType}
      onChange={(value) => {
        send({ type: 'SET_PAYMENT_TYPE', paymentType: value });
      }}
      onNext={() => send({ type: 'NEXT' })}
      onBack={() => send({ type: 'BACK' })}
      isLoading={isLoading}
      hasPastDueInvoice={state.context.hasPastDueInvoice}
      invoicingDisabled={false} // TODO
    />
  );
};

export default ControlledSelectPaymentType;
