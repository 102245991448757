import React from 'react';
import styled from 'styled-components';
import MDCTextArea, { TextAreaProps } from '../../mdc/TextArea';

const StyledTextArea = styled(MDCTextArea)`
  .mdc-floating-label {
    text-transform: uppercase;
  }

  .mdc-floating-label {
    text-transform: uppercase;
  }

  &.mdc-text-field:not(.mdc-text-field--focused)
    .mdc-floating-label:not(.mdc-floating-label--float-above) {
    font-size: 14px !important;
  }

  &.mdc-text-field .mdc-floating-label.mdc-floating-label--float-above {
    margin-top: -4px;
    vertical-align: top;
  }

  &.mdc-text-field .mdc-floating-label {
    font-size: 14px !important;
  }

  &.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid)
    .mdc-floating-label {
    color: var(--mdc-theme-text-secondary-on-light) !important;
  }
  &.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid)
    .mdc-floating-label {
    color: var(--mdc-theme-primary) !important;
    font-size: 14px !important;
  }

  &.mdc-text-field textarea.mdc-text-field__input {
    font-size: 14px;
  }
`;

const Counter = styled.div``;

const TextArea: React.FC<TextAreaProps> = ({ ...props }) => {
  const counter =
    props.maxLength && !props.helpText
      ? `${(props.value as any)?.length || 0} / ${props.maxLength}`
      : null;
  return <StyledTextArea helpText={counter} {...props} />;
};

export default TextArea;
