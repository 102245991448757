import React, { useContext } from 'react';
import * as RDBCase from '../services/buildfire/rdb/cases';
import { useHistory } from 'react-router';
import CaseContext from '../services/buildfire/rdb/CaseContext';
import styled from 'styled-components';
import withCase from '../util/withCase';
import { useDialog } from '../context/DialogContext';
import SnackbarContext from '../context/SnackbarContext';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import Toolbar from '../components/common/Toolbar';
import Page from '../components/page/Page';
import PageContent from '../components/page/PageContent';
import { useNavBlock } from '../services/buildfire/history/useBreadcrumbs';
import { useFirebase } from '../services/firebase';
import CreditCard from '../components/editCase/CreditCard';
import { VIEW_EVENTS } from '../services/buildfire/analytics/events';
import {
  EditCaseContextProvider,
  useEditCaseContext,
} from '../context/EditCaseContext';
import TrackView from '../components/analytics/TrackView';
import { useActor } from '@xstate/react';
import EnterInfo from '../components/editCase/EnterInfo';
import ProgressBar from '../components/common/ProgressBar';
import Checkout from '../components/editCase/Checkout';
import SelectPaymentType from '../components/editCase/SelectPaymentType';

interface EditCaseProps {
  case: RDBCase.Case;
}

const StyledPage = styled(Page)`
  align-items: center;
`;

const StyledPageContent = styled(PageContent)`
  width: 100%;
  max-width: 600px;
  > div {
    width: 100%;
  }
`;

type SCREENS = 'EDIT' | 'CARD' | 'REVIEW';
const SCREENS: {
  [key in SCREENS]: {
    back?: SCREENS;
    next?: SCREENS;
  };
} = {
  EDIT: {
    next: 'CARD',
  },
  CARD: {
    back: 'EDIT',
    next: 'REVIEW',
  },
  REVIEW: {
    back: 'CARD',
  },
};

const EditCase: React.FC<EditCaseProps> = ({ case: c }) => {
  const { updateCase } = useContext(CaseContext);
  const history = useHistory();
  const { setMessage } = useContext(SnackbarContext);
  const { currentUser } = useFirebase();
  const { editCaseService } = useEditCaseContext();
  const [state, send] = useActor(editCaseService);
  const { openDialogPromise } = useDialog();

  // Navigate to case screen on finish
  React.useEffect(() => {
    const onDone = () => {
      const { caseId, case: c } = editCaseService.state.context;
      updateCase(c!);
      history.replace(`/cases/${caseId}`);
      setMessage({ text: 'Case details successfully updated' });
    };
    editCaseService.onDone(onDone);
    return () => {
      editCaseService.off(onDone);
    };
  }, []);

  // Proper back navigation between screens
  useNavBlock(({ action }) => {
    if (action !== 'POP') {
      return true;
    }

    if (state.matches('enterInfo')) {
      // Allow normal history pop on initial screen
      return true;
    }

    // Otherwise navigate BACK in the state machine
    send({ type: 'BACK' });
    return false;
  });

  // Handle error messages
  React.useEffect(() => {
    const { error } = state.context;
    if (error) {
      openDialogPromise(
        <div>{typeof error === 'string' ? error : error.message}</div>
      );
    }
  }, [state.context.error]);

  if (!currentUser) {
    throw new Error('ERROR not logged in');
  }

  const spreadProps = { currentUser };

  return (
    <StyledPage>
      <StyledPageContent>
        {state.matches('loadCase') && <ProgressBar indeterminate />}
        {state.matches('enterInfo') && <EnterInfo {...spreadProps} />}
        {state.matches('selectPaymentType') && (
          <SelectPaymentType {...spreadProps} />
        )}
        {state.matches('creditCard') && <CreditCard {...spreadProps} />}
        {state.matches('checkout') && <Checkout {...spreadProps} />}
      </StyledPageContent>
    </StyledPage>
  );
};

const WrappedEditCase: React.FC<{ case: RDBCase.Case }> = ({ case: c }) => {
  return (
    <IonPage>
      <IonHeader>
        <Toolbar
          showBackButton
          defaultBackLocation={`/cases/${c.id}`}
          title="Edit Case"
        />
      </IonHeader>
      <IonContent>
        <EditCaseContextProvider case={c}>
          <TrackView name={VIEW_EVENTS.EDIT_CASE}>
            <EditCase case={c} />
          </TrackView>
        </EditCaseContextProvider>
      </IonContent>
    </IonPage>
  );
};

export default withCase(WrappedEditCase);
