import { IonButtons } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import { JSX } from '@ionic/core/components';

const Buttons = styled(IonButtons)`
  @media (min-width: 768px) {
    display: none;
  }
`;

export default Buttons;
