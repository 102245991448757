import React from 'react';
import Dropzone, {
  Input,
  IInputProps,
  IDropzoneProps,
} from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import styled from 'styled-components';
import { useDialog } from '../context/DialogContext';
import Typography from './common/Typography';
import HelperText from '../mdc/HelperText';
import { isPlatform } from '@ionic/react';

interface ImageUploadProps extends Partial<IDropzoneProps> {
  onFileAdd: (files: File) => void;
  onFileRemove: (files: File) => void;
  error?: string;
  inputProps?: IInputProps;
}

const StyledHelperText = styled(HelperText)`
  font-size: 14px;
  margin-top: 4px;
`;

const DialogText: React.FC<{}> = (props) => (
  <Typography fontSize="16px" {...props} />
);

const MAX_SIZE_B = 80_000_000;
const MAX_SIZE_STR = '80MB';

const ImageUpload: React.FC<ImageUploadProps> = ({
  onFileAdd,
  onFileRemove,
  error: externalError,
  inputProps,
  maxFiles = 20,
  ...props
}) => {
  const { openDialog } = useDialog();
  const [internalError, setInternalError] = React.useState<string>();
  const error = externalError || internalError;
  const initialFiles = React.useRef(props.initialFiles); // use a ref here so this doesn't change

  return (
    <>
      <Dropzone
        styles={{
          dropzone: {
            border: `1px solid ${error ? '#f24965' : 'rgba(0, 0, 0, 0.38)'}`,
          },
          inputLabelWithFiles: {
            margin: '16px 0',
            alignSelf: 'center',
          },
          input: {
            display: 'none',
          },
        }}
        inputContent={<InputContent />}
        onChangeStatus={(e, status) => {
          switch (status) {
            case 'done':
              // If this is called as result of the initialFiles being added, skip
              if (initialFiles.current?.find((f) => f === e.file)) {
                return;
              }
              onFileAdd(e.file);
              return;
            case 'removed':
              onFileRemove(e.file);
              return;
            case 'error_file_size':
              openDialog(
                <DialogText>File cannot exceed {MAX_SIZE_STR}</DialogText>,
                {
                  onClose: () => e.remove(),
                }
              );
              onFileRemove(e.file);
              return;
            case 'error_upload':
              openDialog(
                <DialogText>Error uploading file, please try again</DialogText>,
                {
                  onClose: () => e.remove(),
                }
              );
              return;
            case 'error_upload_params':
              openDialog(
                <DialogText>Error uploading file, please try again</DialogText>,
                {
                  onClose: () => e.remove(),
                }
              );
              return;
            case 'error_validation':
              openDialog(
                <DialogText>Error uploading file, please try again</DialogText>,
                {
                  onClose: () => e.remove(),
                }
              );
              return;
          }
        }}
        autoUpload={false}
        maxFiles={maxFiles} // TODO move this to config???
        maxSizeBytes={MAX_SIZE_B}
        accept="image/*,video/*"
        inputWithFilesContent="Upload more images"
        InputComponent={(inputProps) => (
          <Input
            {...inputProps}
            onFiles={(f) => {
              if (f.length > maxFiles) {
                openDialog(
                  <DialogText>
                    Cannot upload more than {maxFiles} files
                  </DialogText>,
                  {
                    onClose: () => {},
                  }
                );
              } else {
                inputProps.onFiles(f);
              }
            }}
          />
        )}
        {...props}
        initialFiles={initialFiles.current}
      />
      {error && <StyledHelperText type="error">{error}</StyledHelperText>}
    </>
  );
};

const InputContent: React.FC<{}> = () => {
  const isMobile = isPlatform('ios') || isPlatform('android');
  return isMobile ? (
    <div>Tap to upload photos</div>
  ) : (
    <div>Drag Files or Click to Browse</div>
  );
};

export default ImageUpload;
