import React from 'react';
import {
  IonBackButton,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import Platform from './Platform';
import ToolbarLogo from './ToolbarLogo';
import styled from 'styled-components';
import { useSettingsOverrideModalProps } from './SettingsOverrideModal';

interface ToolbarProps {
  showBackButton?: boolean;
  defaultBackLocation?: string;
  title?: string | React.ReactNode;
  showMenu?: boolean;
}

const LogoContainer = styled.div`
  margin: 16px 24px;
`;

const Toolbar: React.FC<ToolbarProps> = ({
  showBackButton,
  showMenu = true,
  defaultBackLocation = '/',
  title,
}) => {
  const settingsOverrideProps = useSettingsOverrideModalProps();
  return (
    <IonToolbar {...settingsOverrideProps}>
      <Platform.Mobile>
        {showBackButton && (
          <IonButtons slot="start">
            <IonBackButton defaultHref={defaultBackLocation} />
          </IonButtons>
        )}
      </Platform.Mobile>
      <Platform.Mobile>
        <IonTitle>{title}</IonTitle>
      </Platform.Mobile>
      <Platform.Desktop>
        <LogoContainer>
          <ToolbarLogo />
        </LogoContainer>
      </Platform.Desktop>
      {showMenu && (
        <IonButtons slot="end">
          <IonMenuButton />
        </IonButtons>
      )}
    </IonToolbar>
  );
};

export default Toolbar;
