import { PaymentType } from './selectPaymentType';
import { SubmitCaseContext } from './types';

export const isCaseInfoValid = (context: SubmitCaseContext) => true;
export const areSitesValid = (context: SubmitCaseContext) =>
  (context.caseInfo?.sites.length || 0) > 0;
export const canSetupIntent = (context: SubmitCaseContext) =>
  !!(context.userId && context.email && context.name);
export const canAddPaymentMethod = (context: SubmitCaseContext) =>
  !!(context.clientSecret && context.stripe && context.elements);
export const hasPaymentMethod = (context: SubmitCaseContext) =>
  !!context.paymentMethods && context.paymentMethods.length > 0;
export const hasConfirmedSetupIntent = ({
  setupIntent,
  selectedPaymentMethod,
}: SubmitCaseContext) =>
  !!setupIntent &&
  setupIntent.next_action === null &&
  setupIntent.status === 'succeeded' &&
  setupIntent.payment_method === selectedPaymentMethod;
export const hasNoSetupIntent = ({ setupIntent }: SubmitCaseContext) =>
  !setupIntent;
export const isAttachedToHospital = (context: SubmitCaseContext) => {
  return Boolean(context.parentAccount);
};
export const isInvoicing = (context: SubmitCaseContext) => {
  return context.paymentType === PaymentType.INVOICE;
};
export const isInvoicingParentAccount = (context: SubmitCaseContext) => {
  return context.selectedInvoiceAccountUid === context.parentAccount?.uid;
};
export const hasLoadedInvoices = (context: SubmitCaseContext) => {
  return context.hasLoadedInvoices;
};
export const isInvoicingDisabled = (context: SubmitCaseContext) => {
  return context.invoicingDisabled;
};
