import React from 'react';
import { Formik, FieldArray, Field } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import LabeledInput from '../../mdc/LabeledInput';
import SelectInput, { Option } from '../../mdc/SelectInput';
import Button from '../common/Button';
import * as RDBCase from '../../services/buildfire/rdb/cases';
import Typography from '../common/Typography';
import TextArea from '../common/TextArea';
import Bool from '../../constants/bool';
import { SpayNeuterLabel } from '../../constants/spayNeuter';
import { uppercaseFirstLetter } from '../../util/string';
import Form from '../common/Form';
import List from '../../mdc/list/List';
import SiteListItem from './site/SiteListItem';
import SiteAddConfirmationDialog from './site/SiteAddConfirmationDialog';
import {
  getImageAsFile,
  isPreuploadSite,
} from '../../services/firebase/storage';
import FullscreenLoader from '../common/FullscreenLoader';
import { useFirebase } from '../../services/firebase';
import SiteFormDialog from './site/SiteFormDialog';
import Platform from '../common/Platform';
import Prompt from '../common/Prompt';
import ExpandingButton from '../common/ExpandingButton';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import { getExtension } from '../../util/file';
import { useDialog } from '../../context/DialogContext';

interface CaseFormProps {
  case?: RDBCase.Case | FormValues;
  title: string;
  initialValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => Promise<void>;
  submitText?: string;
  renderButtons?: (props: { disabled: boolean }) => React.ReactNode;
}

export interface PreUploadSite {
  location: string;
  description: string;
  additionalInfo?: string;
  files: File[];
}

export interface FormValues {
  submitterEmailAddress: string;
  submitterFirstName: string;
  submitterLastName: string;
  patientFirstName: string;
  patientLastName: string;
  age: number;
  ageMonths?: number;
  sex: RDBCase.Sex;
  fixed: boolean;
  species: RDBCase.AllowedSpecies;
  description: string;
  sites: (PreUploadSite | RDBCase.Site)[];
}

const Container = styled.div`
  padding: 16px 0 32px 0;
`;

const Padding = styled.div`
  padding: 0 8px;
`;

const SubmitButtonContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubmitButton = styled(ExpandingButton)`
  margin-top: 32px;
  margin-bottom: 16px;
`;

const SplitInputContainer = styled.div`
  width: 100%;
`;

const SplitContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -8px;
  > ${SplitInputContainer} {
    margin: 0 8px 0 0 !important;

    &:first-child {
      margin: 0 8px !important;
    }
  }
`;

const AlertText = styled(Typography)`
  color: var(--mdc-theme-secondary) !important;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
`;

const Title: React.FC<{}> = ({ children }) => (
  <Typography
    as="h1"
    variant="secondary"
    fontSize="24px"
    fontWeight="bold"
    textAlign="center"
    uppercase
    margin="32px 0"
    letterSpacing="2px"
  >
    {children}
  </Typography>
);

const getImagesAsFiles = (
  storage: FirebaseStorage,
  images: string[],
  userId: string
) => Promise.all(images.map((i) => getImageAsFile(storage, i, userId)));

const fixFileName = (files: File[], siteIndex: number) => {
  const id = `${Date.now()}`;
  return files.map((f, i) => {
    const ext = getExtension(f.name);
    const name = `case-attachment-${id}-${siteIndex + 1}-${i + 1}${ext}`;
    return new File([f], name, { type: f.type });
  });
};

const CaseForm: React.FC<CaseFormProps> = ({
  case: c,
  title,
  onSubmit,
  submitText = 'Submit',
  renderButtons,
  ...props
}) => {
  const [status, setStatus] = React.useState('idle');
  const { currentUser, app } = useFirebase();
  // Remove files from 'initialValues', since react-dropzone will execute the onFilesAdd
  // for initial files, so they will end up getting duplicated in the formik.values
  // if we don't remove them here
  // const { files: initialFiles, ...initialValuesExcludingFiles } = initialValues;

  React.useEffect(() => {
    window.addEventListener('beforeunload', () => {
      return 'Are you sure?';
    });
  }, []);

  const [siteFormDialogIndex, setSiteFormDialogIndex] =
    React.useState<number>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [confirmationDialogSiteIndex, setConfirmationDialogSiteIndex] =
    React.useState<number>();

  const isSiteFormDialogOpen = siteFormDialogIndex !== undefined;
  const [initialSites, setInitialSites] = React.useState<PreUploadSite[]>();

  const { sites: initialSitesToLoad, ...initialValues } =
    props.initialValues || {};
  const { openDialog } = useDialog();

  React.useEffect(() => {
    if (!initialSitesToLoad) {
      setIsLoading(false);
      return;
    }

    const loadCase = async () => {
      try {
        const initialSites = await Promise.all(
          initialSitesToLoad.map(async (site): Promise<PreUploadSite> => {
            if (!isPreuploadSite(site)) {
              const storage = getStorage(app);
              const files = await getImagesAsFiles(
                storage,
                site.images,
                currentUser!.uid
              );
              return {
                location: site.location,
                description: site.description,
                additionalInfo: site.additionalInfo,
                files,
              } as PreUploadSite;
            }
            return Promise.resolve(site);
          })
        );
        setInitialSites(initialSites);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
        openDialog(
          <Typography fontSize="16px">
            Error loading site info, please try again.
          </Typography>
        );
      }
    };

    loadCase();
  }, [initialSitesToLoad]);

  if (isLoading) {
    return <FullscreenLoader show />;
  }

  return (
    <Container>
      <Platform.Desktop>
        <Title>{title}</Title>
      </Platform.Desktop>

      <Formik
        initialValues={{
          submitterEmailAddress: currentUser?.email || '',
          submitterFirstName: '',
          submitterLastName: '',
          patientFirstName: '',
          patientLastName: '',
          age: '' as any,
          ageMonths: '' as any,
          sex: '' as any,
          description: '',
          species: '' as any,
          sites: initialSites || [],
          ...initialValues,
          fixed:
            initialValues.fixed === undefined
              ? ('' as any)
              : String(initialValues.fixed === Boolean(Bool.TRUE)),
        }}
        onSubmit={(values) => {
          const correctedValues: FormValues = {
            ...values,
            sites: values.sites.map((site, siteIndex) => ({
              ...site,
              files: fixFileName(site.files, siteIndex),
            })),
            fixed: values.fixed === Bool.TRUE,
          };

          return onSubmit(correctedValues)
            .then(() => setStatus('idle'))
            .catch(() => setStatus('error'));
        }}
        validationSchema={Yup.object({
          submitterEmailAddress: Yup.string()
            .email('Invalid email address')
            .optional(),
          submitterFirstName: Yup.string().min(1).max(50).required('Required'),
          submitterLastName: Yup.string().min(1).max(50).required('Required'),
          patientFirstName: Yup.string().min(1).max(50).required('Required'),
          patientLastName: Yup.string().min(1).max(50).required('Required'),
          age: Yup.number().min(0).max(99).required('Required'),
          ageMonths: Yup.number().min(0).max(11).optional(),
          species: Yup.string()
            .oneOf([
              RDBCase.AllowedSpecies.DOG,
              RDBCase.AllowedSpecies.CAT,
              RDBCase.AllowedSpecies.HORSE,
            ])
            .required('Required'),
          sex: Yup.string()
            .oneOf([RDBCase.Sex.MALE, RDBCase.Sex.FEMALE])
            .required('Required'),
          fixed: Yup.string()
            .oneOf([Bool.TRUE, Bool.FALSE])
            .required('Required'),
          sites: Yup.array().min(1),
          description: Yup.string().max(3000).optional(),
        })}
        validateOnMount
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          isValid,
          isSubmitting,
          setFieldTouched,
          dirty,
        }) => {
          const openSiteFormDialog = async (
            index: number,
            showConfirm: boolean = true
          ) => {
            if (showConfirm) {
              setConfirmationDialogSiteIndex(index);
            } else {
              // setIsLoading(true);
              // const site = values.sites[index];
              // if(site && !isPreuploadSite(site)) {
              //   const files = await getImagesAsFiles(site.images, currentUser!.uid)
              // setSiteFormDialogIndex(index);
              // } else {
              // }
              setSiteFormDialogIndex(index);
              setIsLoading(false);
            }
          };
          return (
            <Form>
              <Padding>
                {dirty && !isSubmitting && !isSiteFormDialogOpen && (
                  <Prompt
                    when
                    message="Are you sure you want to leave this page?"
                  />
                )}
                <Typography
                  as="h3"
                  fontSize="20px"
                  variant="secondary"
                  margin="16px 0"
                >
                  Veterinarian Info
                </Typography>
                <Field
                  as={LabeledInput}
                  label="First name"
                  error={
                    touched.submitterFirstName &&
                    uppercaseFirstLetter(errors.submitterFirstName as string)
                  }
                  name="submitterFirstName"
                />
                <Field
                  as={LabeledInput}
                  label="Last name"
                  error={
                    touched.submitterLastName &&
                    uppercaseFirstLetter(errors.submitterLastName as string)
                  }
                  name="submitterLastName"
                />
                <Field
                  as={LabeledInput}
                  label="Email Address"
                  type="email"
                  inputMode="email"
                  error={
                    touched.submitterEmailAddress &&
                    uppercaseFirstLetter(errors.submitterEmailAddress as string)
                  }
                  name="submitterEmailAddress"
                  helpText="An email report will be sent to this address when your case is signed off"
                />

                <Typography
                  as="h3"
                  fontSize="20px"
                  variant="secondary"
                  margin="16px 0"
                >
                  Patient Info
                </Typography>
                <Field
                  as={LabeledInput}
                  label="Patient first name"
                  error={
                    touched.patientFirstName &&
                    uppercaseFirstLetter(errors.patientFirstName as string)
                  }
                  name="patientFirstName"
                />
                <Field
                  as={LabeledInput}
                  label="Patient last name"
                  error={
                    touched.patientLastName &&
                    uppercaseFirstLetter(errors.patientLastName as string)
                  }
                  name="patientLastName"
                />
                <SplitContainer>
                  <SplitInputContainer>
                    <Field
                      as={LabeledInput}
                      label="Age"
                      name="age"
                      type="number"
                      min={0}
                      max={99}
                      error={
                        touched.age &&
                        uppercaseFirstLetter(errors.age as string)
                      }
                      suffix="YEARS"
                    />
                  </SplitInputContainer>
                  <SplitInputContainer>
                    <Field
                      as={LabeledInput}
                      label="Age"
                      name="ageMonths"
                      type="number"
                      min={0}
                      max={12}
                      helpText="Optional"
                      suffix="MONTHS"
                      error={
                        touched.ageMonths &&
                        uppercaseFirstLetter(errors.ageMonths as string)
                      }
                    />
                  </SplitInputContainer>
                </SplitContainer>
                <SelectInput
                  label="Species"
                  name="species"
                  value={values.species}
                  handleChange={(newValue) => {
                    if (newValue !== values.species) {
                      setFieldTouched('species', true);
                      setFieldValue('species', newValue);
                    }
                  }}
                  error={
                    touched.species
                      ? uppercaseFirstLetter(errors.species as string)
                      : ''
                  }
                >
                  <Option value={RDBCase.AllowedSpecies.DOG}>Dog</Option>
                  <Option value={RDBCase.AllowedSpecies.CAT}>Cat</Option>
                  <Option value={RDBCase.AllowedSpecies.HORSE}>Horse</Option>
                </SelectInput>
                <SelectInput
                  label="Sex"
                  name="gender"
                  value={values.sex}
                  handleChange={(newValue) => {
                    if (newValue !== values.sex) {
                      setFieldTouched('sex', true);
                      setFieldValue('sex', newValue);
                    }
                  }}
                  error={
                    touched.sex
                      ? uppercaseFirstLetter(errors.sex as string)
                      : ''
                  }
                >
                  <Option value={RDBCase.Sex.MALE}>Male</Option>
                  <Option value={RDBCase.Sex.FEMALE}>Female</Option>
                </SelectInput>
                <SelectInput
                  label={
                    SpayNeuterLabel[values.sex as RDBCase.Sex] ||
                    SpayNeuterLabel.male
                  }
                  name="fixed"
                  value={values.fixed}
                  handleChange={(newValue) => {
                    if (newValue !== values.fixed) {
                      setFieldTouched('fixed', true);
                      setFieldValue('fixed', newValue);
                    }
                  }}
                  error={
                    touched.fixed
                      ? uppercaseFirstLetter(errors.fixed as string)
                      : ''
                  }
                >
                  <Option value={Bool.TRUE}>Yes</Option>
                  <Option value={Bool.FALSE}>No</Option>
                </SelectInput>
                <Field
                  as={TextArea}
                  label="Relevant clinical history"
                  name="description"
                  rows={4}
                  resizable={false}
                  maxLength={3000}
                  helpText="Optional"
                  error={
                    touched.description &&
                    uppercaseFirstLetter(errors.description as string)
                  }
                />
              </Padding>
              <div>
                <Typography
                  as="h3"
                  fontSize="20px"
                  variant="secondary"
                  margin="24px 8px 16px 8px"
                >
                  Sites
                </Typography>
                <FieldArray name="sites">
                  {({ push, remove, form }) => (
                    <List listStyle="two-line">
                      {form.values.sites.map((s: PreUploadSite, i: number) => (
                        <SiteListItem
                          key={`${s.location}-${i}`}
                          // case={c}
                          siteIndex={i}
                          site={s}
                          userId={currentUser?.uid!}
                          showButtons={true}
                          onEdit={(s, e) => {
                            setSiteFormDialogIndex(i);
                          }}
                          onDelete={(s, e) => {
                            remove(i);
                          }}
                          onClickBehavior="edit"
                        />
                      ))}
                    </List>
                  )}
                </FieldArray>
                <Padding>
                  <Button
                    fullWidth
                    // onClick={() => openDialog(sites.length > 0)}
                    onClick={() =>
                      openSiteFormDialog(
                        values.sites.length,
                        values.sites.length > 0
                      )
                    }
                    emphasis="transparent"
                    variant="primary"
                    type="button"
                  >
                    + Add Site
                  </Button>
                </Padding>
              </div>
              <SubmitButtonContainer>
                <AlertText>
                  * Please submit 5+ photos of unique fields. Include photos at
                  10x, 40x, and 100x.
                </AlertText>
                {renderButtons ? (
                  renderButtons({ disabled: !isValid || isSubmitting })
                ) : (
                  <SubmitButton
                    fullWidth
                    buttonType="raised"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    {submitText}
                  </SubmitButton>
                )}
              </SubmitButtonContainer>

              <SiteFormDialog
                isOpen={isSiteFormDialogOpen}
                SiteFormProps={{
                  initialValue: values.sites[siteFormDialogIndex as any] as any,
                  onSubmit: (value) => {
                    const existingSite =
                      values.sites[siteFormDialogIndex as any];
                    if (existingSite) {
                      const updatedSites = values.sites.map((s, i) =>
                        i === siteFormDialogIndex ? value : s
                      );
                      setFieldValue('sites', updatedSites);
                    } else {
                      setFieldValue('sites', values.sites.concat(value));
                    }
                    setSiteFormDialogIndex(undefined);
                  },
                }}
                onClose={() => setSiteFormDialogIndex(undefined)}
              />
            </Form>
          );
        }}
      </Formik>

      <SiteAddConfirmationDialog
        onCancel={() => {
          setConfirmationDialogSiteIndex(undefined);
        }}
        onConfirm={() => {
          setConfirmationDialogSiteIndex(undefined);
          setSiteFormDialogIndex(confirmationDialogSiteIndex);
        }}
        isOpen={!!confirmationDialogSiteIndex}
      />
    </Container>
  );
};

export default CaseForm;
