import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
} from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getDatabase } from 'firebase/database';
import styled from 'styled-components';
import * as O from 'fp-ts/Option';
import { withAuthRequired } from '../services/buildfire/auth';
import {
  Invitation,
  getInvitation,
} from '../services/buildfire/rdb/invitation';
import { LOADING_STATE } from '../util/loading';
import Toolbar from '../components/common/Toolbar';
import { useFirebase } from '../services/firebase';
import useQueryParams from '../hooks/useQueryParams';
import Typography from '../components/common/Typography';
import { acceptInvitation } from '../api/cloudFunctions';
import { getFunctions } from 'firebase/functions';
import { useDialog } from '../context/DialogContext';

interface InvitationProps {}

const Container = styled.div`
  margin: 64px 0;
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-width: 400px;
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 48px;
`;
const Accent = styled.span`
  color: var(--ion-color-secondary);
`;
const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;
const Icon = styled.i`
  font-family: 'Material Icons' !important;
  color: var(--ion-color-danger);
`;

function useInvitation(token: string) {
  const { app } = useFirebase();
  const [invitation, setInvitation] = React.useState<Invitation>();
  const [loadingState, setLoadingState] = React.useState(LOADING_STATE.LOADING);
  const [error, setError] = React.useState<Error>();

  React.useEffect(() => {
    setLoadingState(LOADING_STATE.LOADING);
    try {
      const database = getDatabase(app);
      const retrieve = async () => {
        try {
          const i = await getInvitation(database, token);
          if (O.isSome(i)) {
            setInvitation(i.value);
            setLoadingState(LOADING_STATE.LOADED);
          } else {
            setError(new Error('Not found'));
            setLoadingState(LOADING_STATE.ERROR);
          }
        } catch (e) {
          console.error(e);
          setError(e as any);
          setLoadingState(LOADING_STATE.ERROR);
        }
      };
      retrieve();
    } catch (e) {
      console.error(e);
      setError(e as any);
      setLoadingState(LOADING_STATE.ERROR);
    }
  }, [token]);

  return { invitation, loadingState, error };
}

const AcceptInvitation: React.FC<{}> = ({}) => {
  const { app } = useFirebase();
  const params = useQueryParams();
  const base64Token = params.get('token');
  const token = atob(base64Token || '');
  const [submitLoadingState, setLoadingState] = React.useState(
    LOADING_STATE.IDLE
  );
  const [submitError, setError] = React.useState<Error>();
  const { invitation, loadingState, error } = useInvitation(token!);
  const isLoading =
    loadingState === LOADING_STATE.LOADING ||
    submitLoadingState === LOADING_STATE.LOADING;
  const e = error || submitError;
  const hasError = !!e;
  const history = useHistory();
  const { openDialog } = useDialog();

  const onClick = async () => {
    setLoadingState(LOADING_STATE.LOADING);
    try {
      const fns = getFunctions(app);
      const result = await acceptInvitation(fns, { token: token! });
      console.log(result);
      setLoadingState(LOADING_STATE.LOADED);
      history.push('/');
      openDialog(`Successfully joined ${invitation?.parentName}'s account`);
    } catch (e) {
      console.error(e);
      setError(e as any);
      setLoadingState(LOADING_STATE.ERROR);
    }
  };

  return (
    <Container>
      {!isLoading && (
        <>
          <Typography as="h2">
            Join <Accent>{invitation?.parentName}'s</Accent> account
          </Typography>

          <Typography margin="16px 0">
            Once you join, you will have the ability to bill cases to this
            hospital
          </Typography>
        </>
      )}
      {isLoading && (
        <LoadingContainer>
          <IonSpinner color="primary" />
        </LoadingContainer>
      )}
      {hasError && (
        <ErrorContainer>
          <Icon className="material-icons">error</Icon>

          <Typography fontSize="16px" margin="16px 0 16px 16px">
            {'An error occurred. Please refresh and try again. '}
            Error: {(e as any)?.details?.message || e?.message || 'unknown'}
          </Typography>
        </ErrorContainer>
      )}
      <IonButton disabled={isLoading} onClick={onClick}>
        Accept Invitation
      </IonButton>
    </Container>
  );
};

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;

const InvitationComponent: React.FC<InvitationProps> = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <Toolbar showBackButton />
      </IonHeader>
      <IonContent>
        <ContentContainer>
          <AcceptInvitation />
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
};

export default withAuthRequired(InvitationComponent);
