import { IonItem, IonLabel, IonNote, useIonToast } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import { StripeInvoice } from '../../api/cloudFunctions';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import useQueryParams from '../../hooks/useQueryParams';
import classNames from 'classnames';
import Typography from '../common/Typography';
import { formatStripeTimestamp } from '../../util/stripe';
import { formatCurrency } from '../../util/currency';
import InvoiceStatusBadge from './InvoiceStatusBadge';

interface InvoiceListItemProps {
  invoice: StripeInvoice;
  view: 'admin' | 'user';
  // selected: boolean;
}

const Item = styled(IonItem)`
  --background-hover: #434180;
  &.selected {
    --background: #43418022;
  }
`;

const Total = styled.span`
  margin-right: 16px;
  font-size: 12px;
`;

const InvoiceListItem: React.FC<InvoiceListItemProps> = ({ view, invoice }) => {
  const match = useRouteMatch();
  const queryParams = useQueryParams();
  const invoiceId = invoice.id;
  const currentUserId = match.params['userId'];
  const userPath = generatePath(match.path, {
    userId: invoice.metadata?.uid || currentUserId,
    invoiceId,
  });
  const selected = match.params['invoiceId'] === invoiceId;
  const isAnythingSelected = Boolean(match.params['invoiceId']);
  const queryParamStr = queryParams.toString();
  const history = useHistory();
  const isAdminView = view === 'admin';
  const total = formatCurrency(invoice.total / 100.0);

  return (
    <Item
      onClick={() => {
        const path = `${userPath}${queryParamStr ? `?${queryParamStr}` : ''}`;
        if (isAnythingSelected) {
          history.replace(path);
        } else {
          history.push(path);
        }
      }}
      className={classNames({
        selected,
      })}
    >
      <IonLabel>
        <Typography as="h2" fontSize="16px" lineHeight="1.2em">
          {isAdminView ? invoice.metadata?.name || 'Unknown name' : total}
        </Typography>
        {invoice.periodStart && (
          <IonNote>
            {invoice.status === 'draft'
              ? 'Current'
              : formatStripeTimestamp(invoice.periodStart)}
          </IonNote>
        )}
      </IonLabel>
      <IonNote slot="end">
        <Total>{total}</Total>
        <InvoiceStatusBadge invoice={invoice} />
      </IonNote>
    </Item>
  );
};

export default InvoiceListItem;
