import { httpsCallable, getFunctions } from 'firebase/functions';
import { createMachine, MachineConfig } from 'xstate';
import { assign } from 'xstate/lib/actions';
import { Context, Event } from './types';
import { config as reauthConfig } from '../reauth';
import * as guards from './guards';
import * as actions from './actions';

async function deleteAccount(context: Context) {
  const fns = getFunctions(context.app);
  const fn = httpsCallable(fns, 'deleteAccount');
  try {
    return fn({ uid: context.user.uid, feedback: context.feedback });
  } catch (e: any) {
    console.error(e);
    throw new Error(
      e?.error?.message ||
        e?.error?.status ||
        e?.error ||
        e?.message ||
        'Error deleting account, please try again'
    );
  }
}

const config: MachineConfig<Context, any, Event> = {
  id: 'Delete account',
  initial: 'initial',
  context: {} as any,
  states: {
    initial: {
      on: {
        BACK: {
          actions: 'exit',
        },
        NEXT: 'submitFeedback',
      },
    },
    submitFeedback: {
      on: {
        BACK: 'initial',
        NEXT: {
          target: 'reauth',
          actions: 'setFeedback',
        },
      },
    },
    reauth: {
      ...(reauthConfig as any),
      onDone: {
        target: 'deleteAccount',
      },
      on: {
        BACK: 'submitFeedback',
      },
    },
    deleteAccount: {
      invoke: {
        src: deleteAccount,
        onDone: {
          target: 'submitted',
          actions: 'onSuccessfulAccountDeletion',
        },
        onError: {
          target: 'reauth',
          actions: assign({
            error: (context, ev) => ev.data,
          }),
        },
      },
    },
    error: {},
    submitted: {
      type: 'final',
    },
  },
};

const deleteAccountMachine = createMachine(config, {
  actions: actions as any,
  guards,
});

export default deleteAccountMachine;
