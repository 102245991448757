import React from 'react';
import MarketingPage from '../components/page/MarketingPage';
import pattern from '../images/patterns/pattern-18.png';
import ElevatedContainer from '../components/common/ElevatedContainer';
import PricingItem from './pricing/PricingItem';
import Typography from '../components/common/Typography';
import Hr from '../components/common/Hr';
import styled from 'styled-components';
import TrackView from '../components/analytics/TrackView';
import { VIEW_EVENTS } from '../services/buildfire/analytics/events';
import { useBuildfireSettings } from '../context/SettingsContext';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import Toolbar from '../components/common/Toolbar';
import AlertBanner from '../components/common/AlertBanner';

interface PricingProps {}

const Description = styled.div`
  text-align: left;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Deemphasized = styled.span`
  // opacity: 0.6;
`;

const Pricing: React.FC<PricingProps> = (props) => {
  const { settings } = useBuildfireSettings();
  return (
    <IonPage>
      <IonHeader>
        <Toolbar showBackButton showMenu />
        <AlertBanner />
      </IonHeader>
      <IonContent>
        <TrackView name={VIEW_EVENTS.PRICES}>
          <MarketingPage
            pageTitle="Pricing"
            pageSubtitle="What is the cost?"
            pageHeadingBackground={pattern}
          >
            <ElevatedContainer maxWidth="600px">
              <PricingItem
                price={`$${settings.prices.singleSite}`}
                title={
                  (
                    <>
                      Single Site Cytology
                      <br /> Or Hematology Consultation
                    </>
                  ) as any
                }
                iconBackground="pawprints3"
                description={
                  <Description>
                    <Typography
                      as="p"
                      variant="body"
                      fontSize="12px"
                      lineHeight="16px"
                    >
                      Includes review of case information, submitted cytology
                      photos, and any additional applicable documents (i.e. CBC,
                      gross lesion photos, radiology report, etc).
                    </Typography>
                    <Typography
                      as="p"
                      variant="body"
                      fontSize="12px"
                      lineHeight="16px"
                      margin="8px 0"
                    >
                      <Bold>Guaranteed same-day response</Bold>
                      <Deemphasized>
                        {' '}
                        if received by {settings.businessHours.end} CST
                        (Monday-Friday).
                      </Deemphasized>
                    </Typography>
                    {/* <Typography
                  as="p"
                  variant="body"
                  fontSize="12px"
                  lineHeight="16px"
                >
                  <Bold>Business hours STAT consultation</Bold>
                  <Deemphasized>
                    {' '}
                    +$
                    {settings.prices.afterHours} (guaranteed response within 2
                    hours)
                  </Deemphasized>
                </Typography> */}
                  </Description>
                }
              />
              <Hr />
              <PricingItem
                price={`+$${settings.prices.additionalSite}`}
                title="Additional Site"
                iconBackground="pawprints1"
                showSubmitCase={false}
                description={
                  <Description>
                    <Typography
                      as="p"
                      variant="body"
                      fontSize="12px"
                      lineHeight="16px"
                    >
                      Add-on fee when when a case is submitted with multiple
                      sites (e.g. skin mass + lymph node).
                    </Typography>
                  </Description>
                }
              />
            </ElevatedContainer>
          </MarketingPage>
        </TrackView>
      </IonContent>
    </IonPage>
  );
};

export default Pricing;
