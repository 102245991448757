import { IonButton, IonButtons, IonToolbar } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import { useAlertsState } from '../../context/NotifContext';

interface AlertBannerProps {
  onClose: () => void;
}

const Toolbar = styled(IonToolbar)`
  --background: var(--mdc-theme-primary);
  --color: white;
`;

const Icon = styled.i`
  font-family: 'Material Icons' !important;
  color: white;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;

  & ${Icon} {
    margin: 0 8px;
  }
`;

export const AlertBanner: React.FC<AlertBannerProps> = ({
  children,
  onClose,
}) => {
  if (!children) {
    return null;
  }

  return (
    <Toolbar>
      <MessageContainer>
        <Icon className="material-icons">info</Icon>
        {children}
      </MessageContainer>
      <IonButtons slot="end">
        <IonButton title="Close" onClick={onClose}>
          <Icon className="material-icons">close</Icon>
        </IonButton>
      </IonButtons>
    </Toolbar>
  );
};

const ConnectedAlertBanner: React.FC<{}> = (props) => {
  const { alert, popAlert } = useAlertsState();
  return <AlertBanner onClose={popAlert}>{alert?.message}</AlertBanner>;
};

export default ConnectedAlertBanner;
