import React from 'react';
import { useInterpret } from '@xstate/react';
import { createMachine, InterpreterFrom, MachineConfig } from 'xstate';
import creditCardStates, {
  CreditCardContext as CreditCardConfigContext,
  CreditCardEvent,
} from '../stateMachines/submitCase/creditCard';
import * as actions from '../stateMachines/submitCase/actions';
import * as guards from '../stateMachines/submitCase/guards';
import { useBuildfireSettings } from './SettingsContext';
import { useFirebase } from '../services/firebase';

const config: MachineConfig<CreditCardConfigContext, any, CreditCardEvent> = {
  id: 'creditCard',
  ...creditCardStates,
};

const creditCardMachine = createMachine(config, {
  actions: actions as any,
  guards: {
    ...(guards as any),
    every: (ctx, event, { cond }) => {
      if (cond.type !== 'every') {
        throw new Error('Error: "every" condition expected type to be "every"');
      }
      return cond.guards.every((guardKey) => guards[guardKey](ctx, event));
    },
  },
});

interface CreditCardContext {
  creditCardContext: InterpreterFrom<typeof creditCardMachine>;
}

const CreditCardContext = React.createContext<CreditCardContext>(
  undefined as any
);

export const CreditCardContextProvider: React.FC<{}> = ({ children }) => {
  const { currentUser, app, parentAccount } = useFirebase();
  const { settings } = useBuildfireSettings();
  const creditCardContext = useInterpret(creditCardMachine, {
    context: {
      firebaseApp: app,
      stripePublishableKey: settings.stripe.publishableKey,
      userId: currentUser?.uid,
      email: currentUser?.email!,
      name: currentUser?.displayName!,
      parentAccount,
      selectedInvoiceAccountUid: parentAccount?.uid || currentUser!.uid!,
    },
  });
  (window as any).creditCardContext = creditCardContext;

  return (
    <CreditCardContext.Provider value={{ creditCardContext }}>
      {children}
    </CreditCardContext.Provider>
  );
};

export function useCreditCardContext() {
  return React.useContext(CreditCardContext);
}

export default CreditCardContext;
