import React from 'react';
import { IonAvatar } from '@ionic/react';
import styled from 'styled-components';
import useAvatar from '../../hooks/useAvatar';

interface AvatarProps {
  photoUrl?: string;
  userIdentifier: string;
}

const StyledAvatar = styled(IonAvatar)`
  width: 32px;
  height: 32px;
`;

const Avatar: React.FC<AvatarProps> = ({ photoUrl, userIdentifier }) => {
  const url = useAvatar(photoUrl, userIdentifier);
  return (
    <StyledAvatar>
      <img src={url} />
    </StyledAvatar>
  );
};

export default Avatar;
