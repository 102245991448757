import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

interface ListProps extends React.HTMLAttributes<HTMLUListElement> {
  divider?: React.ReactNode;
  listStyle?: 'one-line' | 'two-line';
  interactive?: boolean;
}

const Container = styled.ul`
  padding: 0;
  list-styled-type: none;
`;

const Divider = styled.div``;

const List: React.FC<ListProps> = ({
  children,
  listStyle = 'one-line',
  interactive = true,
  ...props
}) => {
  const content: React.ReactNode[] = [];
  const numChildren = React.Children.count(children);
  React.Children.forEach(children, (child, i) => {
    if (!child) {
      return;
    }

    content.push(<Divider key={`divider-${i}`} className="mdc-list-divider" />);
    content.push(child);
    if (i === numChildren - 1) {
      content.push(
        <Divider key={`divider-${numChildren}`} className="mdc-list-divider" />
      );
    }
  });
  return (
    <ListContext.Provider value={{ listStyle }}>
      <Container
        className={classnames({
          ['mdc-list']: true,
          ['mdc-list--two-line']: listStyle === 'two-line',
          ['mdc-list--non-interactive']: !interactive,
        })}
        {...props}
      >
        {content}
      </Container>
    </ListContext.Provider>
  );
};

export const ListContext = React.createContext<{
  listStyle: 'one-line' | 'two-line';
}>(undefined as any);

export default List;
