import React from 'react';
import { Case } from '../../services/buildfire/rdb/cases';
import CaseListItem from '../../components/admin/CaseListItem';
import * as RDBCases from '../../services/buildfire/rdb/cases';
import CaseContext, {
  PageArgs,
} from '../../services/buildfire/rdb/CaseContext';
import CasesScreen from '../Cases';
import { LOADING_STATE } from '../../util/loading';
import { withAuthRequired } from '../../services/buildfire/auth';
import { useFirebase } from '../../services/firebase';
import { IonHeader, IonPage } from '@ionic/react';
import Toolbar from '../../components/common/Toolbar';
import AdminCaseInfo from '../../components/case/caseInfo/AdminCaseInfo';
import { CaseInfoProps } from '../../components/case/caseInfo/CaseInfo';
import AdminReport from './AdminReport';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { isDesktop } from '../../util/device';
import {
  endBefore,
  getDatabase,
  limitToLast,
  onChildAdded,
  orderByKey,
  query,
  QueryConstraint,
  ref,
  startAfter,
} from 'firebase/database';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import { caseIsEqual } from '../../util/case';
import { FirebaseApp } from 'firebase/app';
import DownloadPDF from '../../components/case/actionMenu/actions/DownloadPDF';
import DownloadSiteAttachments from '../../components/case/actionMenu/actions/DownloadSiteAttachments';
import ArchiveCase from '../../components/case/actionMenu/actions/ArchiveCase';
import AdminContext from '../../context/AdminContext';

interface CaseViewProps {}

function useNewCases({
  app,
  onCaseAdded,
}: {
  app: FirebaseApp;
  onCaseAdded: (c: Case) => void;
}) {
  React.useEffect(
    (async () => {
      const database = getDatabase(app);
      const latestCase = await RDBCases.getLatestCase(database);
      if (!latestCase) {
        console.log('Cannot listen for case additions, no latest case found');
        return;
      }
      const casesRef = ref(database, 'cases');
      // const constraints = [];
      const constraints = [orderByKey(), startAfter(latestCase.id)].filter(
        Boolean
      ) as QueryConstraint[];
      const getRef = query(casesRef, ...constraints);
      const unsub = onChildAdded(getRef, (snapshot) => {
        const c = RDBCases.toCase(snapshot);
        console.log('onCaseAdded', c);
        onCaseAdded(c);
      });
      return () => unsub();
    }) as any,
    []
  );
}

const AdminCaseActionItems: React.FC<{
  isReportAvailable: boolean;
  case: Case;
}> = ({ isReportAvailable, case: c }) => (
  <>
    {isReportAvailable && <DownloadPDF case={c!} />}
    <DownloadSiteAttachments case={c!} />
    {c.status === RDBCases.CaseStatus.OPEN && <ArchiveCase case={c!} />}
  </>
);

const AdminCases: React.FC<CaseViewProps> = ({ children }) => {
  const [loadingStatus, setLoadingStatus] = React.useState(
    LOADING_STATE.LOADING
  );
  const {
    cases,
    setCases,
    adminGetCasesPaginated,
    hasRetrievedAllCases,
    setHasRetrievedAllCases,
  } = React.useContext(CaseContext);
  const { currentUser, app } = useFirebase();

  const match = useRouteMatch('/admin/cases/:caseId');
  const showMainHeader = !match?.url || isDesktop();

  const prependCases = (newCases: Case[]) =>
    setCases((existingCases) => {
      const allCases = pipe(
        [existingCases, newCases],
        A.flatten,
        A.uniq(caseIsEqual)
      );
      return allCases;
    });
  const appendCases = (newCases: Case[]) =>
    setCases((existingCases) => {
      // IMPORTANT to concat newCases before cases, to preserve order in DB
      const allCases = pipe(
        [newCases, existingCases],
        A.flatten,
        A.uniq(caseIsEqual)
      );
      return allCases;
    });

  useNewCases({ app, onCaseAdded: (c) => prependCases([c]) });

  const isLoading = loadingStatus === LOADING_STATE.LOADING;

  const getAllCases = async () => {
    setLoadingStatus(LOADING_STATE.LOADING);
    try {
      const database = getDatabase(app);
      const allCases = await RDBCases.getCases(database);
      setCases(allCases);
      setHasRetrievedAllCases(true);
      setLoadingStatus(LOADING_STATE.LOADED);
    } catch (e) {
      console.error(e);
      setLoadingStatus(LOADING_STATE.ERROR);
    }
  };

  // OPEN:
  // - admin: OPEN
  // - customer: OPEN OR (SIGNED_OFF with isArchived=false)
  const getOpenCases = async () => {
    const openCases = await adminGetCasesPaginated(
      RDBCases.CaseStatus.OPEN,
      {}
    );
    appendCases(openCases);
  };

  // CLOSED:
  // - admin: NOT OPEN
  // - customer: CLOSED or isArchived=true
  const getClosedCases = async (userId: string, pageArgs: PageArgs) => {
    const database = getDatabase(app);
    const pageSize = pageArgs.pageSize || 25;
    const casesToAdd = await RDBCases.getCases(
      database,
      [
        orderByKey(),
        pageArgs.startAfter ? endBefore(pageArgs.startAfter.id) : undefined,
        limitToLast(pageSize),
      ].filter(Boolean) as QueryConstraint[]
    );
    const hasRetrievedAllCases = casesToAdd.length < (pageArgs.pageSize || 1);
    if (hasRetrievedAllCases) {
      console.log('Retrieved all user cases');
      setHasRetrievedAllCases(true);
    }
    appendCases(casesToAdd);
  };

  // Get initial cases on page load
  React.useEffect(() => {
    setLoadingStatus(LOADING_STATE.LOADING);
    // Get ALL open cases and the first page of closed cases
    getOpenCases()
      .then(() => getClosedCases('', {}))
      .then(() => {
        setLoadingStatus(LOADING_STATE.LOADED);
      })
      .catch((e) => {
        console.error(e);
        setLoadingStatus(LOADING_STATE.ERROR);
      });
  }, []);

  return (
    <IonPage>
      <IonHeader
        className={classNames({
          ['ion-no-border']: true,
          ['hide-sm']: !showMainHeader,
        })}
      >
        <Toolbar showBackButton={false} title="Cases" />
      </IonHeader>

      <CasesScreen
        cases={cases}
        currentUser={currentUser!}
        caseUser={currentUser!}
        isLoading={isLoading}
        isAdmin={true}
        hasRetrievedAllCases={hasRetrievedAllCases}
        CaseInfo={AdminCaseInfoWithActionItems}
        Report={AdminReport}
        CaseListItemComponent={CaseListItem}
        getOpenCases={getOpenCases}
        getClosedCases={getClosedCases}
        onLoadSearchCases={getAllCases}
      />
    </IonPage>
  );
};

const AdminCaseInfoWithActionItems: React.FC<CaseInfoProps> = (props) => (
  <AdminCaseInfo
    {...props}
    actionItems={
      <AdminCaseActionItems
        case={props.case}
        isReportAvailable={
          props.case?.status === RDBCases.CaseStatus.SIGNED_OFF
        }
      />
    }
  />
);

const WithAdminMetadata: React.FC<{}> = ({ children }) => {
  return (
    <AdminContext>
      <AdminCases />
      {children}
    </AdminContext>
  );
};

export default withAuthRequired(WithAdminMetadata);
