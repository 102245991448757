import React from 'react';
import { IonContent } from '@ionic/react';
import styled from 'styled-components';
import * as RDBCases from '../../../services/buildfire/rdb/cases';
import PatientInfo from '../PatientInfo';
import SitesList from '../site/SitesList';
import SubmitterInfo from '../SubmitterInfo';
import MessageFab from '../../messages/MessageFab';
import { doesCaseHaveUnreadMessage } from '../../../util/case';
import { User } from 'firebase/auth';
import { ParentUser } from '../../../api/cloudFunctions';
import PaymentInfo from './PaymentInfo';
import MessageProvider from '../../../context/MessageContext';

export interface CaseInfoProps {
  case: RDBCases.Case;
  currentUser: User;
  isAdmin: boolean;
  actionItems?: React.ReactNode;
  parentAccount?: ParentUser;
  bannerContent?: React.ReactNode | string;
}

const TextSection = styled.div`
  // padding: 8px 8px 0 8px;
  // &:last-child {
  //   padding-bottom: 8px;
  // }
  padding: 16px;
`;

const Content = styled.div`
  margin-bottom: 32px;
`;

const Banner = styled.div`
  padding: 4px;
  // border-radius: 4px;
  background: var(--ion-color-secondary);
  color: white;
`;

const CaseInfo: React.FC<CaseInfoProps> = ({
  case: userCase,
  isAdmin,
  currentUser,
  actionItems,
  parentAccount,
  children,
  bannerContent,
  ...props
}) => {
  const canMessage = currentUser.uid === userCase.uid || isAdmin;
  const isUnread =
    canMessage && doesCaseHaveUnreadMessage(userCase, currentUser!, isAdmin);
  return (
    <>
      <IonContent>
        <Content>
          {bannerContent && <Banner>{bannerContent}</Banner>}

          <TextSection>
            <SubmitterInfo
              case={userCase}
              clinicName={userCase.user.displayName}
              clinicEmail={userCase.user.email}
              mailtoEmail={isAdmin}
            />
            <PaymentInfo case={userCase} />
            <PatientInfo case={userCase} includeName />
          </TextSection>
          <SitesList
            case={userCase}
            sites={userCase.sites}
            userId={userCase.uid}
          />
          {children}
        </Content>
      </IonContent>
      {canMessage && (
        <MessageProvider>
          <MessageFab unread={isUnread} />
        </MessageProvider>
      )}
    </>
  );
};

export default CaseInfo;
