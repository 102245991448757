import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DialogContext } from '../../../context/DialogContext';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { flow } from 'fp-ts/lib/function';
import { Location } from 'history';

const last = (arr: string[]) => A.last(arr);

export type BlockCallback = (args: {
  action: 'POP' | 'REPLACE' | 'PUSH';
  location: Location<unknown>;
}) => boolean;
const NAV_BLOCKS: (BlockCallback | undefined)[] = [];
(window as any).__NAV_BLOCKS__ = NAV_BLOCKS;

function useBreadcrumbs() {
  const history = useHistory();
  const { openDialogsRef, removeDialog } = useContext(DialogContext);
  const exitDialogOrGoBack = flow(last, O.fold(history.goBack, removeDialog));
  React.useEffect(() => {
    const unblock = history.block((loc, action) => {
      // If back button is pressed when a dialog is open,
      // close the dialog instead of navigating
      if (openDialogsRef.current.length > 0 && action === 'POP') {
        exitDialogOrGoBack(openDialogsRef.current);
        return false;
      }

      if (NAV_BLOCKS.some((fn) => fn?.({ action, location: loc }) === false)) {
        return false;
      }
    });
    return () => {
      unblock();
    };
  }, [openDialogsRef, removeDialog, history]);
}

export function useNavBlock(onBack: BlockCallback) {
  const indexRef = React.useRef(NAV_BLOCKS.length);
  React.useEffect(() => {
    NAV_BLOCKS[indexRef.current!] = onBack;
    return () => {
      NAV_BLOCKS[indexRef.current!] = undefined;
    };
  }, [onBack]);
}

export default useBreadcrumbs;
