import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Case } from '../../services/buildfire/rdb/cases';
import UnreadNotif from '../common/UnreadNotif';
import { doesCaseHaveUnreadMessage } from '../../util/case';
import GenericCaseListItem from '../case/CaseListItem';
import Badge from '../common/Badge';
import { useFirebase } from '../../services/firebase';
import { useParams } from 'react-router-dom';
import useCaseIdParam from '../../hooks/useCaseIdParam';

const Timestamp = styled.div`
  display: flex;
  justify-content: center;
  font-size: 10px;
`;

interface CaseListItemProps {
  case: Case;
}

const BadgeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;
const StyledUnreadNotif = styled(UnreadNotif)`
  line-height: 12px;
  margin-left: 6px;
`;

const NewNotif: React.FC<{}> = () => {
  return (
    <BadgeContainer>
      <Badge fontColor="#163349" backgroundColor="#AECAE5">
        NEW
      </Badge>
    </BadgeContainer>
  );
};

const CaseListItem: React.FC<CaseListItemProps> = ({ case: c }) => {
  const { currentUser, isAdmin } = useFirebase();
  const isUnread = doesCaseHaveUnreadMessage(c, currentUser!, isAdmin);
  const isNew = !c.viewedAt;
  const selectedCaseId = useCaseIdParam();
  return (
    <GenericCaseListItem
      case={c}
      rightHandContent={
        isNew ? (
          <NewNotif />
        ) : (
          <Timestamp>{moment(c.createdAt).format('MMM DD')}</Timestamp>
        )
      }
      currentUser={currentUser!}
      isUnread={isUnread}
      UnreadNotifComponent={StyledUnreadNotif}
      selected={c.id === selectedCaseId}
      assignedTo={c.acceptedBy}
      showAssignment
    />
  );
};

export default CaseListItem;
