import React from 'react';
import styled from 'styled-components';
import { useBuildfireSettings } from '../../../context/SettingsContext';
import Typography from '../../common/Typography';
import { formatCurrency } from '../../../util/currency';

interface SubtotalProps extends React.HTMLAttributes<HTMLDivElement> {
  numSites: number;
}

const Container = styled.div``;

const LineItem = styled.div<{ $bold?: boolean; $justifyContent?: string }>`
  display: flex;
  ${(props) => `justify-content: ${props.$justifyContent || 'space-between'};`}
  ${(props) => props.$bold && 'font-weight: 600;'}
`;

const Total = styled.span`
  margin-right: 32px;
`;

const Hr = styled.hr`
  margin: 8px 0;
`;

const Subtotal: React.FC<SubtotalProps> = ({ numSites, ...props }) => {
  const { settings } = useBuildfireSettings();
  const { additionalSite, singleSite } = settings.prices;

  const hasAdditionalSite = numSites > 1;
  const hasMultipleAdditionalSites = numSites > 2;
  const additionalSitePrice = additionalSite * (numSites - 1);
  const total = hasAdditionalSite
    ? singleSite + additionalSitePrice
    : singleSite;

  return (
    <Container {...props}>
      <Typography
        as="p"
        fontSize="16px"
        variant="secondary"
        margin="24px 0 16px 0"
      >
        Summary of charges
      </Typography>
      <LineItem>
        <span>Single site</span>
        <span>{formatCurrency(singleSite)}</span>
      </LineItem>
      {hasAdditionalSite && (
        <LineItem>
          <span>
            Additional site{hasMultipleAdditionalSites ? 's' : ''} (x
            {numSites - 1})
          </span>
          <span>{formatCurrency(additionalSitePrice)}</span>
        </LineItem>
      )}

      <Hr />

      <LineItem $bold $justifyContent="flex-end">
        <Total>Total</Total>
        <span>{formatCurrency(total)}</span>
      </LineItem>
    </Container>
  );
};

export default Subtotal;
