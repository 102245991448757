import { IonContent, IonHeader, IonPage } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import Button from '../components/common/Button';
import Toolbar from '../components/common/Toolbar';
import Typography from '../components/common/Typography';

interface NotFoundProps {}

const Container = styled.div`
  margin: 64px 0;
  display: flex;
  flex-direction: column;
`;
const ButtonContainer = styled.div`
  margin: 24px;
  display: flex;
  justify-content: center;
`;

const NotFound: React.FC<NotFoundProps> = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <Toolbar showBackButton />
      </IonHeader>
      <IonContent>
        <Container>
          <Typography
            margin="24px 0 0 0"
            fontSize="24px"
            textAlign="center"
            variant="secondary"
          >
            Oops!
          </Typography>
          <Typography
            margin="12px 0 24px 0"
            fontSize="24px"
            textAlign="center"
            variant="secondary"
          >
            Page not found
          </Typography>
          <ButtonContainer>
            <Button
              as="a"
              {...{
                href: '/',
              }}
            >
              Go back to homepage
            </Button>
          </ButtonContainer>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default NotFound;
