import React from 'react';
import { StripeInvoice } from '../../api/cloudFunctions';
import { IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import InfoSection, { LineItem } from '../case/caseInfo/InfoSection';
import styled from 'styled-components';
import { useFirebase } from '../../services/firebase';
import Hr from '../common/Hr';
import { formatStripeTimestamp, getInvoiceMonth } from '../../util/stripe';
import { useLinkButtonProps } from '../common/LinkButton';
import { formatInvoiceAmount } from '../../util/invoice';

interface InvoiceProps {
  invoice: StripeInvoice;
}

const Link = styled.a`
  //
`;

const Content = styled.div`
  padding: 32px;
`;

const LineItemsContainer = styled.div`
  // display: flex;
  // align-items: space-between;
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  font-size: 14px;
  padding: 4px 0;
`;

const LineItemColumn = styled(Column)`
  font-size: 14px;
  padding: 4px 0;
  color: var(--ion-color-medium);
`;

const LinkContainer = styled.div`
  margin: 32px 0;
`;

const STRIPE_BASE_URL = 'https://dashboard.stripe.com/invoices';
const STRIPE_TEST_BASE_URL = 'https://dashboard.stripe.com/test/invoices';

const Invoice: React.FC<InvoiceProps> = ({ invoice }) => {
  const { currentUser, isAdmin } = useFirebase();
  const linkButtonProps = useLinkButtonProps();
  const isPendingInvoice = invoice.id === 'pending';
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {invoice.periodStart
              ? `Invoice for ${getInvoiceMonth(invoice)}`
              : 'Current invoice'}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Content>
          {invoice.metadata && (
            <InfoSection title="Customer">
              <LineItem name="Name" description={invoice.metadata.name} />
              <LineItem name="Email" description={invoice.metadata.email} />
            </InfoSection>
          )}
          <InfoSection title="Details">
            <LineItem name="Status" description={invoice.status} />
            {invoice.periodStart && invoice.status !== 'draft' && (
              <LineItem
                name="Date finalized"
                description={formatStripeTimestamp(invoice.periodStart)}
              />
            )}
            <LineItem
              name="Due date"
              description={formatStripeTimestamp(invoice.dueDate)}
            />
            <LineItem
              name="Description"
              description={invoice.description || 'N/A'}
            />
            <LineItem name="Paid" description={invoice.paid ? 'Yes' : 'No'} />
            {/* <LineItem
              name="Amount Paid"
              description={formatCurrency(invoice.amountPaid / 100.0)}
            /> */}
          </InfoSection>

          <InfoSection title="Line items">
            <LineItemsContainer>
              {/* Reverse line items for proper order */}
              {[...invoice.lineItems].reverse().map((lineItem) => (
                <Row key={lineItem.id}>
                  <LineItemColumn>{lineItem.description}</LineItemColumn>
                  <LineItemColumn>
                    {formatInvoiceAmount(lineItem.amount)}
                  </LineItemColumn>
                </Row>
              ))}

              <Hr />

              <Row>
                <Column>Total</Column>
                <Column>{formatInvoiceAmount(invoice.total)}</Column>
              </Row>
            </LineItemsContainer>
          </InfoSection>

          {isAdmin && !isPendingInvoice && (
            <LinkContainer>
              <Link
                target="_blank"
                href={`${
                  invoice.livemode ? STRIPE_BASE_URL : STRIPE_TEST_BASE_URL
                }/${invoice.id}`}
              >
                View in Stripe
              </Link>
            </LinkContainer>
          )}

          {invoice.invoiceUrl && (
            <LinkContainer>
              <Link target="_blank" href={invoice.invoiceUrl}>
                {invoice.status === 'paid' ? 'View receipt' : 'Pay invoice'}
              </Link>
            </LinkContainer>
          )}
        </Content>
      </IonContent>
    </>
  );
};

export default Invoice;
