import React from 'react';

export interface IMessageContext {
  selectedImage?: string;
  setSelectedImage: (image?: string) => void;
}

export const MessageContext = React.createContext<IMessageContext>(
  undefined as any
);

interface MessageProviderProps {}

const MessageProvider: React.FC<MessageProviderProps> = ({
  children,
  ...props
}) => {
  const [selectedImage, setSelectedImage] = React.useState<string>();

  return (
    <MessageContext.Provider
      value={{
        selectedImage,
        setSelectedImage,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export function useDialog() {
  return React.useContext(MessageContext);
}

export default MessageProvider;
