import {
  IonButton,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonRow,
} from '@ionic/react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import Form from '../../components/common/Form';
import { uppercaseFirstLetter } from '../../util/string';
import { PasswordHelper, PasswordSchema } from '../register/RegistrationForm';
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  updatePassword,
  User,
} from 'firebase/auth';
import { useFirebase } from '../../services/firebase';
import { ButtonRow, HiddenSubmitButton } from './common/styledComponents';

interface ChangePasswordFormProps {
  user: User;
  onSuccess: (message: string) => void;
  onError: (err: string) => void;
}
const CenteredRow = styled(IonRow)`
  justify-content: center;
  margin: 16px 0;
`;

const ERROR_MESSAGE = {
  'auth/wrong-password':
    'Error: your old password was not correct. Please try again.',
  'auth/weak-password':
    'Error: your password was not strong enough. Please try again.',
};
const URL = process.env.REACT_APP_URL || window.location.origin;

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  user,
  onSuccess,
  onError,
}) => {
  const { app } = useFirebase();
  return (
    <Formik
      validateOnMount
      initialValues={{
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      }}
      onSubmit={async (values, helpers) => {
        try {
          const credential = EmailAuthProvider.credential(
            user.email!,
            values.oldPassword
          );
          const reauthResult = await reauthenticateWithCredential(
            user,
            credential
          );
          console.log(reauthResult);

          await updatePassword(user, values.newPassword);

          onSuccess('Password successfully changed');
          helpers.resetForm();
          helpers.setTouched({
            oldPassword: false,
            newPassword: false,
            newPasswordConfirmation: false,
          });
        } catch (e) {
          console.error(e);
          const message =
            ERROR_MESSAGE[(e as any)?.code] ||
            'Error: there was a problem updating your password. Please try again';
          onError(message);
        }
      }}
      validationSchema={Yup.object({
        oldPassword: Yup.string().required('Old password is required'),
        newPassword: PasswordSchema,
        newPasswordConfirmation: Yup.string()
          .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match')
          .required('Must confirm new password'),
      })}
    >
      {({
        values,
        touched,
        errors,
        setFieldTouched,
        setFieldValue,
        isValid,
        isSubmitting,
      }) => (
        <Form>
          <IonInput
            className={classNames({
              ['ion-invalid']: errors.oldPassword,
              ['ion-touched']: touched.oldPassword,
            })}
            label="Old Password"
            labelPlacement="floating"
            tabIndex={0}
            type="password"
            name="oldPassword"
            required
            autofocus
            value={values.oldPassword}
            onIonInput={(e) => {
              setFieldValue('oldPassword', e.detail.value);
              setFieldTouched('oldPassword', true);
            }}
            errorText={uppercaseFirstLetter(errors.oldPassword as string)}
          />
          <IonInput
            className={classNames({
              ['ion-invalid']: errors.newPassword,
              ['ion-touched']: touched.newPassword,
            })}
            label="New Password"
            labelPlacement="floating"
            type="password"
            name="newPassword"
            required
            value={values.newPassword}
            onIonInput={(e) => {
              setFieldValue('newPassword', e.detail.value);
              setFieldTouched('newPassword', true);
            }}
            errorText={uppercaseFirstLetter(errors.newPassword as string)}
          />
          <PasswordHelper />
          <IonInput
            className={classNames({
              ['ion-invalid']: errors.newPasswordConfirmation,
              ['ion-touched']: touched.newPasswordConfirmation,
            })}
            label="Confirm New Password"
            labelPlacement="floating"
            type="password"
            name="newPasswordConfirmation"
            required
            value={values.newPasswordConfirmation}
            onIonInput={(e) => {
              setFieldValue('newPasswordConfirmation', e.detail.value);
              setFieldTouched('newPasswordConfirmation', true);
            }}
            errorText={uppercaseFirstLetter(
              errors.newPasswordConfirmation as string
            )}
          />
          <ButtonRow>
            <IonCol>
              <IonButton
                type="submit"
                disabled={!isValid || isSubmitting}
                expand="block"
              >
                Change Password
              </IonButton>
              {/* Need this for Ionic hack... so the form submits on enter */}
              <HiddenSubmitButton type="submit" />
            </IonCol>
          </ButtonRow>

          <CenteredRow>
            <IonButton
              fill="clear"
              expand="block"
              onClick={async () => {
                try {
                  const auth = getAuth(app);
                  const baseUrl = URL.endsWith('/') ? URL.slice(0, -1) : URL;
                  await sendPasswordResetEmail(auth, user.email!, {
                    url: `${baseUrl}/login`,
                  });
                  onSuccess(
                    'Please check your email for a link to reset your password.'
                  );
                } catch (e) {
                  console.error(e);
                  onError(
                    'There was a problem sending your password reset email. Please try again.'
                  );
                }
              }}
            >
              Send Password Reset Email
            </IonButton>
          </CenteredRow>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
