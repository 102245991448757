import moment from 'moment';
import { StripeInvoice } from '../api/cloudFunctions';
import { formatCurrency } from './currency';

export function isInvoicePastDue(invoice: StripeInvoice) {
  const dueDateMoment = moment(invoice.dueDate * 1000);
  return invoice.status === 'open' && moment().isSameOrAfter(dueDateMoment);
}

export function formatInvoiceAmount(amount?: number) {
  if (amount !== undefined) {
    return formatCurrency(amount / 100.0);
  }

  return formatCurrency(0);
}
