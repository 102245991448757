import React from 'react';
import { AuthCredential, updatePassword, UserCredential } from 'firebase/auth';
import { Formik } from 'formik';
import { IonButton, IonIcon, IonInput, useIonToast } from '@ionic/react';
import { HiddenSubmitButton, Message } from '../common/styledComponents';
import { uppercaseFirstLetter } from '../../../util/string';
import {
  PasswordHelper,
  PasswordSchema,
} from '../../register/RegistrationForm';
import Form from '../../../components/common/Form';
import classNames from 'classnames';
import { informationCircle } from 'ionicons/icons';
import Typography from '../../../components/common/Typography';
import * as Yup from 'yup';
import styled from 'styled-components';

interface NewPasswordFormProps {
  userCredential: UserCredential;
  authCredential: AuthCredential;
  onComplete: () => void;
}

const ERROR_MESSAGE = {
  'auth/weak-password':
    'Error: your password was not strong enough. Please try again.',
};

const TextContainer = styled.div`
  margin: 16px;
`;

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({
  userCredential,
  authCredential,
  onComplete,
}) => {
  const [present] = useIonToast();
  const [message, setMessage] = React.useState<{
    text: string;
    type: string;
  }>();
  return (
    <>
      <TextContainer>
        <Typography fontSize="16px" lineHeight="1.4em" margin="16px 0">
          Due to a system upgrade, all existing users are required to reset
          their passwords.
        </Typography>
        <Typography
          fontSize="16px"
          lineHeight="1.4em"
          margin="32px 0"
          fontWeight="bold"
        >
          If you would like to keep your existing password, you may make your
          new password the same as your old password.
        </Typography>
      </TextContainer>
      {message && (
        <Message>
          <IonIcon icon={informationCircle} />
          <Typography margin="0">{message.text}</Typography>
        </Message>
      )}
      <Formik
        validateOnMount
        initialValues={{
          newPassword: '',
          newPasswordConfirmation: '',
        }}
        onSubmit={async (values, helpers) => {
          try {
            await updatePassword(userCredential.user, values.newPassword);

            present('Password successfully updated', 5000);
            helpers.resetForm();
            helpers.setTouched({
              newPassword: false,
              newPasswordConfirmation: false,
            });

            onComplete();
          } catch (e) {
            console.error(e);
            const text =
              ERROR_MESSAGE[(e as any)?.code] ||
              'Error: there was a problem changing your password. Please try again';
            setMessage({
              text,
              type: '',
            });
          }
        }}
        validationSchema={Yup.object({
          newPassword: PasswordSchema,
          newPasswordConfirmation: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match')
            .required('Must confirm new password'),
        })}
      >
        {({
          values,
          touched,
          errors,
          setFieldTouched,
          setFieldValue,
          isValid,
          isSubmitting,
          submitForm,
        }) => (
          <Form
            onSubmit={(e) => {
              e.stopPropagation();
              submitForm();
            }}
          >
            <IonInput
              className={classNames({
                ['ion-invalid']: errors.newPassword,
                ['ion-touched']: touched.newPassword,
              })}
              label="New Password"
              labelPlacement="floating"
              type="password"
              name="newPassword"
              required
              autofocus
              value={values.newPassword}
              onIonInput={(e) => {
                setFieldValue('newPassword', e.detail.value);
                setFieldTouched('newPassword', true);
              }}
              errorText={uppercaseFirstLetter(errors.newPassword as string)}
            />
            <PasswordHelper />
            <IonInput
              className={classNames({
                ['ion-invalid']: errors.newPasswordConfirmation,
                ['ion-touched']: touched.newPasswordConfirmation,
              })}
              label="Confirm New Password"
              labelPlacement="floating"
              type="password"
              name="newPasswordConfirmation"
              required
              value={values.newPasswordConfirmation}
              onIonInput={(e) => {
                setFieldValue('newPasswordConfirmation', e.detail.value);
                setFieldTouched('newPasswordConfirmation', true);
              }}
              errorText={uppercaseFirstLetter(
                errors.newPasswordConfirmation as string
              )}
            />

            <IonButton
              type="submit"
              disabled={!isValid || isSubmitting}
              expand="block"
            >
              Change Password
            </IonButton>
            {/* Need this for Ionic hack... so the form submits on enter */}
            <HiddenSubmitButton type="submit" />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewPasswordForm;
