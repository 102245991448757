import { useActor } from '@xstate/react';
import React from 'react';
import { useEditCaseContext } from '../../context/EditCaseContext';
import CaseForm from '../case/CaseForm';
import Button from '../common/Button';

interface EnterInfoProps {}

const EnterInfo: React.FC<EnterInfoProps> = (props) => {
  const { editCaseService } = useEditCaseContext();
  const [state, send] = useActor(editCaseService);

  return (
    <CaseForm
      case={state.context.caseInfo}
      initialValues={state.context.caseInfo}
      title="Edit case"
      onSubmit={async (values) => {
        send({ type: 'NEXT', caseInfo: values });
      }}
      renderButtons={({ disabled }) => (
        <>
          <Button
            fullWidth
            buttonType="raised"
            type="submit"
            disabled={disabled}
          >
            Payment
          </Button>
        </>
      )}
    />
  );
};

export default EnterInfo;
