import { MachineConfig, assign } from 'xstate';
import { StripeElements, Stripe } from '@stripe/stripe-js';
import {
  CreditCardContext,
  getSetupIntent,
  retrievePaymentMethods,
} from './creditCard';

interface Context extends CreditCardContext {}

export type AddCreditCardEvent =
  | { type: 'CLOSE' }
  | {
      type: 'ADD_CARD';
      stripe: Stripe;
      elements: StripeElements;
    };

export type AddCreditCardState = {};

async function addNewPaymentMethod(context: CreditCardContext) {
  if (context.setupIntent) {
    // TODO cancel existing setup intent
  }

  const { error, setupIntent } = await context.stripe!.confirmSetup({
    elements: context.elements!,
    confirmParams: {
      return_url: '',
      payment_method_data: {
        billing_details: {
          name: context.name,
          email: context.email,
        },
      },
    },
    redirect: 'if_required',
  });

  if (error) {
    console.error(error);
    throw error;
  }
  if (
    !setupIntent ||
    (setupIntent.status !== 'processing' && setupIntent.status !== 'succeeded')
  ) {
    console.error('SetupIntent error', setupIntent);
    throw new Error('Failed to process payment method');
  }

  return setupIntent;
}

async function addAndRetrievePaymentMethod(context: Context) {
  const setupIntent = await addNewPaymentMethod(context);
  const paymentMethodResult = await retrievePaymentMethods(context);
  return {
    setupIntent,
    ...paymentMethodResult,
    selectedPaymentMethod: setupIntent.payment_method,
  };
}

const addCreditCardStates: MachineConfig<Context, any, AddCreditCardEvent> = {
  initial: 'load',

  states: {
    load: {
      invoke: {
        src: getSetupIntent,
        onDone: {
          target: 'idle',
          actions: assign((context, ev) => {
            return {
              ...context,
              ...ev.data,
            };
          }),
        },
        onError: {
          // actions: escalate((context, ev) => ev.data),
          actions: assign({
            error: (context, ev) => ev.data,
          }),
        },
      },
    },

    idle: {
      on: {
        ADD_CARD: {
          actions: assign((context, ev) => {
            return {
              ...context,
              elements: ev.elements,
              stripe: ev.stripe,
            };
          }),
          target: 'addingCreditCard',
        },
      },
    },

    addingCreditCard: {
      invoke: {
        src: addAndRetrievePaymentMethod,
        onDone: {
          target: 'done',
          actions: assign((context, ev) => {
            return {
              ...context,
              ...ev.data,
            };
          }),
        },
        onError: {
          target: 'idle',
          // actions: escalate((context, ev) => ev.data),
          actions: assign({
            error: (context, ev) => ev.data,
          }),
        },
      },
    },

    done: {
      type: 'final',
    },
  },
};

export default addCreditCardStates;
