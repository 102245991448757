import React from 'react';
import { warning } from 'ionicons/icons';
import { useIonToast } from '@ionic/react';
import { useFirebase } from '../../../../services/firebase';
import ActionItem from '../../../case/actionMenu/ActionItem';
import ModalLoader from '../../../common/ModalLoader';
import { getFunctions } from 'firebase/functions';
import { sendWelcomeEmail } from '../../../../api/cloudFunctions';

interface SendWelcomeEmailProps {
  email?: string;
}

const SendWelcomeEmail: React.FC<SendWelcomeEmailProps> = ({ email }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { app } = useFirebase();
  const [present] = useIonToast();

  return (
    <>
      <ActionItem
        disabled={isLoading || !email}
        onClick={async () => {
          if (!email) {
            return;
          }

          setIsLoading(true);
          try {
            const fns = getFunctions(app);
            await sendWelcomeEmail(fns, { email });

            present('Email successfully sent', 6000);

            setIsLoading(false);
          } catch (e) {
            console.error(e);
            setIsLoading(false);
            present({
              message: 'Error sending password setup email, please try again',
              icon: warning,
              color: 'danger',
              duration: 6000,
            });
          }
        }}
        label={'Send password setup email'}
      />
      {isLoading && (
        <ModalLoader
          onClose={() => {
            setIsLoading(false);
          }}
        />
      )}
    </>
  );
};

export default SendWelcomeEmail;
