import React from 'react';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import classNames from 'classnames';
import Toolbar from '../components/common/Toolbar';
import LoadingScreen from '../components/common/LoadingScreen';
import { useFirebase } from '../services/firebase';
import { isDesktop } from '../util/device';
import { useParams, useRouteMatch } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import Invoices from '../components/invoice/Invoices';
import { Database, getDatabase } from 'firebase/database';
import memoizePromiseFn from '../util/memoizePromiseFn';
import { getInvoices, getUserById } from '../api/cloudFunctions';
import { Functions, getFunctions } from 'firebase/functions';

interface UserInvoicesProps {}

const fetchData = async (
  userId: string,
  database: Database,
  fns: Functions
) => {
  return Promise.all([
    getUserById(fns, {
      userId,
    }).then((r) => r?.user),
    getInvoices(fns, { userId }).then((r) => r?.invoices || []),
  ]).then(([user, invoices]) => ({
    user,
    invoices,
  }));
};

const fetchDataMemoized = memoizePromiseFn(fetchData, (userId) => userId);

const UserInvoices: React.FC<UserInvoicesProps> = (props) => {
  const { isAdmin, app } = useFirebase();
  const { userId, invoiceId } = useParams<{
    userId: string;
    invoiceId: string;
  }>();
  // const {user} = useUser
  const match = useRouteMatch(
    `${isAdmin ? '/admin' : ''}/users/:userId/invoices/:invoiceId`
  );
  const showMainHeader = !match?.url || isDesktop();
  const { data, loadingStatus, error } = useFetch(
    async () => {
      if (!userId) {
        return;
      }

      const database = getDatabase(app);
      const fns = getFunctions(app);
      return fetchDataMemoized(userId, database, fns);
    },
    () => {},
    [userId]
  );

  return (
    <IonPage>
      <IonHeader
        className={classNames({
          ['ion-no-border']: true,
          // ['hide-sm']: !showMainHeader,
        })}
      >
        <Toolbar
          showBackButton
          defaultBackLocation={`${isAdmin ? `/admin/users/${userId}` : `/`}`}
          title={
            data?.user ? `${data.user.displayName?.trim()}'s invoices` : ''
          }
        />
      </IonHeader>

      <IonContent>
        <LoadingScreen loadingStatus={loadingStatus} error={error}>
          {data?.invoices && <Invoices invoices={data.invoices} view="user" />}
        </LoadingScreen>
      </IonContent>
    </IonPage>
  );
};

export default UserInvoices;
