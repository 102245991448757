import React from 'react';
import visa from '../../../../images/cards/visa.svg';
import mastercard from '../../../../images/cards/mastercard.svg';
import discover from '../../../../images/cards/discover.svg';
import amex from '../../../../images/cards/discover.svg';
import unknown from '../../../../images/cards/credit_card.png';

interface CardIconProps extends React.HTMLAttributes<HTMLImageElement> {
  type: string;
}

function getSrcForCard(type: string) {
  switch (type) {
    case 'visa':
      return visa;
    case 'mastercard':
      return mastercard;
    case 'discover':
      return discover;
    case 'amex':
      return amex;
    default:
      return unknown;
  }
}

const CardIcon: React.FC<CardIconProps> = ({ type, ...props }) => {
  return <img src={getSrcForCard(type)} {...props} />;
};

export default CardIcon;
