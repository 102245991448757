import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import useIonicModalProps from '../../../../hooks/useIonicModalProps';
import Form from '../../../common/Form';
import ProgressBar from '../../../common/ProgressBar';
import AddCreditCardForm, {
  AddCreditCardFormProps,
} from '../AddCreditCardForm';

interface AddCardDialogProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  FormProps: AddCreditCardFormProps;
}

const StyledForm = styled(Form)`
  padding: 16px;
`;
const CreditCardForm: React.FC<{}> = ({ children }) => (
  <IonContent>
    <StyledForm>{children}</StyledForm>
  </IonContent>
);

const AddCardDialog: React.FC<AddCardDialogProps> = ({
  isOpen,
  onClose,
  FormProps,
  isLoading,
  children,
}) => {
  const modalProps = useIonicModalProps();
  const title = 'Add Card';
  return (
    <IonModal
      title={title}
      isOpen={isOpen}
      onDidDismiss={onClose}
      {...modalProps}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {/* <IonContent> */}
      {isLoading ? (
        <ProgressBar indeterminate />
      ) : (
        <AddCreditCardForm
          {...FormProps}
          Form={CreditCardForm}
          renderSubmitButton={({ disabled, onClick }) => (
            <IonFooter style={{ padding: '16px 8px' }}>
              <IonButton expand="full" onClick={onClick} disabled={disabled}>
                Save Card
              </IonButton>
            </IonFooter>
          )}
        />
      )}
      {/* </IonContent> */}
    </IonModal>
  );
};

export default AddCardDialog;
