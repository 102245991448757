import React from 'react';
import classnames from 'classnames';

interface IconProps extends React.HTMLAttributes<HTMLSpanElement> {
  variant: string;
  as?: string | React.ComponentType<React.HTMLAttributes<HTMLElement>>;
}

const Icon: React.FC<IconProps> = ({
  variant,
  as = 'span',
  className,
  ...props
}) => {
  const Component = as;
  return (
    <Component className={classnames('material-icons', className)} {...props}>
      {variant}
    </Component>
  );
};

export default Icon;
