import React from 'react';
import styled from 'styled-components';
import logo from '../../images/v2/logo/Pp-Alt-Landscape-Full-Colour-RGB.svg';
import Typography from '../common/Typography';

interface NoUserSelectedProps {}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Wrapper = styled.div`
  margin: auto;
  text-align: center;
`;

const Image = styled.img`
  max-width: 100%;
  width: 300px;
  margin-top: -100px;
`;

const NoUserSelected: React.FC<NoUserSelectedProps> = (props) => {
  return (
    <Container>
      <Wrapper>
        <Image src={logo} />
        <Typography as="h3" fontSize="20px" variant="primary" margin="24px 0">
          No user selected
        </Typography>
      </Wrapper>
    </Container>
  );
};

export default NoUserSelected;
