import {
  Database,
  getDatabase,
  onValue,
  ref,
  set,
  update,
} from 'firebase/database';
import { User } from 'firebase/auth';
import { Address } from '../../components/register/AddressAutocomplete/AddressAutocomplete';
import React from 'react';
import useFirebase from './useFirebase';
import { LOADING_STATE } from '../../util/loading';

export interface UserMetadata {
  address?: Address;
  displayName: string;
  email: string;
  createdAt: string;
  /** Added by the backend when querying users */
  isPending?: boolean;
  parentAccount?: {
    uid: string;
    displayName: string;
    email: string;
  };
  photoUrl?: string;

  // Admin only
  signature?: string;
  invoicingDisabled?: boolean;
}

export type UserMetadataWithId = UserMetadata & {
  uid: string;
};

export const setUserMetadata = (
  database: Database,
  user: User,
  userMetadata: Partial<UserMetadata>
) => {
  const { uid, displayName } = user;
  const data = {
    ...(userMetadata.address
      ? {
          address: userMetadata.address,
        }
      : {}),
    ...userMetadata,
    // ...(isParent
    //   ? {
    //       [`parentAccounts/${uid}`]: {
    //         address: userMetadata.address,
    //         displayName,
    //       },
    //     }
    //   : {}),
  };
  return update(ref(database, `/userMetadata/${uid}`), data);
};

export const getUserMetadata = (
  database: Database,
  userId: string,
  user?: User
): Promise<UserMetadataWithId> => {
  const userInfoRef = ref(database, `userMetadata/${userId}`);
  return new Promise<UserMetadataWithId>((resolve, reject) => {
    onValue(
      userInfoRef,
      (snap) => {
        if (!snap.exists()) {
          if (user) {
            return resolve({
              displayName: user.displayName!,
              createdAt: user.metadata.creationTime!,
              email: user.email!,
              uid: user.uid,
            });
          }

          return reject('Not found');
        }

        const val = snap.val();
        const info = {
          ...val,
          uid: snap.key,
        };
        resolve(info);
      },
      (err) => {
        console.error(err);
        reject(err);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

export function useUserMetadata() {
  const { currentUser, app } = useFirebase();
  const [loadingState, setLoadingState] = React.useState<LOADING_STATE>(
    LOADING_STATE.LOADING
  );
  const [userMetadata, setUserMetadata] = React.useState<UserMetadata>();
  React.useEffect(() => {
    if (!currentUser || !app) {
      return;
    }

    const database = getDatabase(app);
    getUserMetadata(database, currentUser.uid, currentUser)
      .then((val) => {
        setUserMetadata(val);
        setLoadingState(LOADING_STATE.LOADED);
      })
      .catch((e) => {
        console.error(e);
        setLoadingState(LOADING_STATE.ERROR);
      });
  }, [currentUser, app]);

  return { userMetadata, currentUser, loadingState };
}

export function setInvoicingStatus(
  database: Database,
  userId: string,
  invoicingDisabled: boolean
) {
  return set(
    ref(database, `userMetadata/${userId}/invoicingDisabled`),
    invoicingDisabled
  );
}
