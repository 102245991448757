import React from 'react';

interface ILoadingContext {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const LoadingContext = React.createContext<ILoadingContext>(undefined as any);

interface LoadingProviderProps {
  defaultValue?: boolean;
}

export const LoadingProvider: React.FC<LoadingProviderProps> = ({
  children,
  defaultValue = false,
  ...props
}) => {
  const [isLoading, setIsLoading] = React.useState(defaultValue);
  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export function useLoading() {
  return React.useContext(LoadingContext);
}

export default LoadingContext;
