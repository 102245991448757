import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

export interface CheckboxProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  label?: string | React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  margin: 0;
  line-height: 1em;
`;

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  onChange,
  className,
  ...props
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const mdcComponentRef = React.useRef<mdc.checkbox.MDCCheckbox>();
  React.useEffect(() => {
    mdcComponentRef.current = new mdc.checkbox.MDCCheckbox(ref.current!);
    return () => {
      mdcComponentRef.current?.destroy();
    };
  }, []);

  return (
    <Container
      ref={ref}
      className={classNames('mdc-form-field', className)}
      {...props}
    >
      <div className="mdc-checkbox">
        <input
          type="checkbox"
          className="mdc-checkbox__native-control"
          id={id}
          onChange={onChange}
        />
        <div className="mdc-checkbox__background">
          <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
            <path
              className="mdc-checkbox__checkmark-path"
              fill="none"
              d="M1.73,12.91 8.1,19.28 22.79,4.59"
            />
          </svg>
          <div className="mdc-checkbox__mixedmark"></div>
        </div>
        <div className="mdc-checkbox__ripple"></div>
      </div>
      {label && <Label htmlFor={id}>{label}</Label>}
    </Container>
  );
};

export default Checkbox;
