import { MachineConfig, assign, Sender } from 'xstate';
import { log } from 'xstate/lib/actions';
import { StripeElements, Stripe, SetupIntent } from '@stripe/stripe-js';
import { FormValues, PreUploadSite } from '../../components/case/CaseForm';
import { Case, Site } from '../../services/buildfire/rdb/cases';
import * as Storage from '../../services/firebase/storage';
import * as API from '../../api/cloudFunctions';
import { getFunctions } from 'firebase/functions';
import { FirebaseApp } from 'firebase/app';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import { PaymentType } from './selectPaymentType';

export interface CheckoutContext {
  firebaseApp: FirebaseApp;
  caseInfo: FormValues;
  userId: string;
  email: string;
  name: string;
  createdCase?: Case;
  elements: StripeElements;
  stripe: Stripe;
  setupIntent: SetupIntent;
  selectedPaymentMethod: string;
  error?: string | Error;
  uploadProgress?: {
    percent: number;
    message: string;
  };
  selectedInvoiceAccountUid: string;
  paymentType: PaymentType;
}

export type CheckoutEvent =
  | { type: 'NEXT' }
  | { type: 'BACK' }
  | { type: 'SET_UPLOAD_PROGRESS'; data: { percent: number; message: string } };

function calculatePercent(num: number, total: number) {
  return Math.min((num + 1) / total, 0.99);
}

export async function uploadSites({
  storage,
  sites,
  userId,
  send,
  submitMessage = 'Submitting case...',
}: {
  storage: FirebaseStorage;
  sites: (PreUploadSite | Site)[];
  userId: string;
  send: Sender<CheckoutEvent>;
  submitMessage?: string;
}) {
  const uploadedSites = await Storage.uploadSites(
    storage,
    sites,
    userId,
    (num, total, name, siteIndex) => {
      const siteNum = siteIndex + 1;
      const numTotalAttachments = sites
        .filter(Storage.isPreuploadSite)
        .reduce((acc, site) => site.files.length + acc, 0);
      send({
        type: 'SET_UPLOAD_PROGRESS',
        data: {
          message: `Uploaded ${
            num + 1
          } of ${numTotalAttachments} files (site #${siteNum})`,
          percent: calculatePercent(num, numTotalAttachments),
        },
      });
    }
  );

  send({
    type: 'SET_UPLOAD_PROGRESS',
    data: {
      message: submitMessage,
      percent: 0.99,
    },
  });

  return uploadedSites;
}

function submitCase({
  caseInfo,
  userId,
  name,
  email,
  selectedPaymentMethod,
  setupIntent,
  firebaseApp,
  paymentType,
  selectedInvoiceAccountUid,
}: CheckoutContext) {
  return async (send: Sender<CheckoutEvent>) => {
    try {
      send({
        type: 'SET_UPLOAD_PROGRESS',
        data: {
          message: `Uploading files...`,
          percent: 0,
        },
      });

      const storage = getStorage(firebaseApp);

      const {
        submitterEmailAddress,
        submitterFirstName,
        submitterLastName,
        patientFirstName,
        patientLastName,
        sites,
        ...rest
      } = caseInfo;

      const uploadedSites = await uploadSites({
        storage,
        sites,
        userId,
        send,
      });

      const invoicingEnabled = paymentType === PaymentType.INVOICE;
      const functions = getFunctions(firebaseApp);
      const result = await API.submitCase(functions, {
        case: {
          ...rest,
          sites: uploadedSites,
          user: {
            email,
            displayName: name,
          },
          submitter: {
            first: submitterFirstName,
            last: submitterLastName,
            email: submitterEmailAddress,
          },
          patient: {
            first: patientFirstName,
            last: patientLastName,
          },
          uid: userId,
          billToParentAccount:
            invoicingEnabled && selectedInvoiceAccountUid !== userId,
        },
        user: {
          id: userId,
          name,
          email,
        },
        paymentMethodId: invoicingEnabled ? undefined : selectedPaymentMethod,
        setupIntentId: invoicingEnabled ? undefined : setupIntent.id,
        invoiceAccount: invoicingEnabled
          ? selectedInvoiceAccountUid
          : undefined,
      });

      return result;
    } catch (e: any) {
      console.error(e);
      throw new Error(
        e?.error?.message ||
          e?.error?.status ||
          e?.error ||
          e?.message ||
          'Error submitting case, please try again'
      );
    }
  };
}

const checkoutStates: MachineConfig<CheckoutContext, any, CheckoutEvent> = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        NEXT: 'submitCase',
      },
    },
    submitCase: {
      on: {
        SET_UPLOAD_PROGRESS: {
          actions: [
            log((context, ev) => {
              console.log(ev);
            }),
            assign({
              uploadProgress: (c, { data }) => data,
            }),
          ],
        },
      },
      invoke: {
        src: submitCase,
        onDone: {
          target: 'success',
          actions: assign({
            createdCase: (context, ev) => ev.data.case,
          }),
        },
        onError: {
          target: 'idle',
          actions: [
            log((context, ev) => {
              console.error(ev);
            }),
            assign({
              error: (context, ev) => ev.data,
            }),
          ],
        },
      },
    },
    error: {
      always: {
        target: 'idle',
        actions: log((context, ev) => {
          console.error(ev);
        }),
      },
    },
    success: {
      type: 'final',
    },
  },
};

export default checkoutStates;
