import React, { useContext } from 'react';
import { initializeApp, FirebaseApp } from 'firebase/app';
import {
  initializeAuth,
  indexedDBLocalPersistence,
  UserCredential,
  User,
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  signInWithCredential,
  AuthCredential,
  signInWithCustomToken,
} from 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { useBuildfireSettings } from '../../context/SettingsContext';
import { removeFcmInfo } from './database';
import { getDatabase } from 'firebase/database';
import useOnAuthStateChanged from '../../hooks/useOnAuthStateChanged';
import {
  UserMetadata,
  UserMetadataWithId,
  getUserMetadata,
  setUserMetadata,
} from './user';
import { ParentUser, getParentAccount } from '../../api/cloudFunctions';
import { getFunctions } from 'firebase/functions';

type IFirebaseContext = {
  app: FirebaseApp;
  loginState: LoginState;
  currentUser: User | null;
  userMetadata: UserMetadataWithId | null;
  parentAccount?: ParentUser;
  isAdmin: boolean;
  isImpersonatingUser: boolean;
  signIn: (email: string, password: string) => Promise<UserCredential>;
  signInWithCredential: (credential: AuthCredential) => Promise<UserCredential>;
  signInWithCustomToken: (
    token: string,
    isImpersonatingUser: boolean
  ) => Promise<UserCredential>;
  createUser: (
    email: string,
    password: string,
    displayName: string,
    metadata: UserMetadata
  ) => Promise<UserCredential>;
  signOut: () => Promise<void>;
  triggerUpdate: () => void;
  onUpdate: (hander: () => void) => () => void;
  setCurrentFcmTokenId: (id: string) => void;
};

const FirebaseContext = React.createContext<IFirebaseContext>(null as any);

interface FirebaseProviderProps {}

export enum LoginState {
  LOADING = 'LOADING',
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
}

type ReducerState = {
  currentUser: User | null;
  userMetadata: UserMetadataWithId | null;
  loginState: LoginState;
  isAdmin: boolean;
  parentAccount?: ParentUser;
  isImpersonatingUser: boolean;
};

type Action = {
  type: 'SET_STATE';
  state: Partial<ReducerState>;
};
const FirebaseProvider: React.FC<FirebaseProviderProps> = ({ children }) => {
  const { isLoading, settings } = useBuildfireSettings();
  const [state, dispatch] = React.useReducer(
    (state: ReducerState, action: Action): ReducerState => {
      switch (action.type) {
        case 'SET_STATE': {
          return {
            ...state,
            ...action.state,
          };
        }
        default:
          return state;
      }
    },
    {
      loginState: LoginState.LOADING,
      currentUser: null,
      userMetadata: null,
      isAdmin: false,
      isImpersonatingUser: false,
    }
  );
  const [updateHandlers, setUpdateHandlers] = React.useState<(() => void)[]>(
    []
  );
  const [currentFcmTokenId, setCurrentFcmTokenId] = React.useState<string>();
  // TODO see if I need to move this into a useEffect hook when loading config dynamically
  const [app, setApp] = React.useState<FirebaseApp>();
  React.useEffect(() => {
    if (!isLoading && settings && !app) {
      const app = initializeApp(settings.firebase);
      setApp(app);

      if (Capacitor.isNativePlatform()) {
        initializeAuth(app, {
          persistence: indexedDBLocalPersistence,
        });
      }
    }
  }, [isLoading, settings, app]);

  const setUser = React.useCallback(
    async (user: User | null, isImpersonatingUser = false) => {
      if (!user) {
        return dispatch({
          type: 'SET_STATE',
          state: {
            isAdmin: false,
            loginState: LoginState.LOGGED_OUT,
            currentUser: null,
            userMetadata: null,
            isImpersonatingUser: false,
          },
        });
      }

      if (user.uid === state.currentUser?.uid) {
        console.trace('Duplicate call to setUser detected');
        return;
      }

      const userMetadata = await getUserMetadata(
        getDatabase(app),
        user.uid,
        user
      );

      const tokenResult = await user.getIdTokenResult();
      const isAdmin = !!tokenResult.claims.admin;

      // Asynchronously fetch parent account (if any)
      getParentAccount(getFunctions(app), {
        userId: user.uid,
      })
        .then((result) => {
          dispatch({
            type: 'SET_STATE',
            state: {
              parentAccount: result?.parentUser!,
            },
          });
        })
        .catch((e) => {
          // Silently fail
          console.log('No parent user for account');
        });

      dispatch({
        type: 'SET_STATE',
        state: {
          isAdmin,
          loginState: LoginState.LOGGED_IN,
          currentUser: user,
          userMetadata,
          isImpersonatingUser,
        },
      });
    },
    []
  );

  const createUser = React.useCallback(
    async (
      email: string,
      password: string,
      displayName: string,
      metadata: UserMetadata
    ) => {
      const auth = getAuth(app);
      // Remove potentially undefined keys from the metadata
      const cleanedMetadata = Object.keys(metadata).reduce((acc, key) => {
        const val = metadata[key];
        if (val !== null && val !== undefined) {
          acc[key] = val;
        }
        return acc;
      }, {} as UserMetadata);
      return createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          await updateProfile(userCredential.user, {
            displayName,
          });
          await setUserMetadata(
            getDatabase(app),
            userCredential.user,
            cleanedMetadata
          );
          return userCredential;
        })
        .then(async (userCredential) => {
          await setUser(userCredential.user);
          return userCredential;
        });
    },
    [app]
  );

  const signIn = React.useCallback(
    (email: string, password: string) => {
      const auth = getAuth(app);
      return signInWithEmailAndPassword(auth, email, password).then(
        async (userCredential) => {
          await setUser(userCredential.user);
          return userCredential;
        }
      );
    },
    [app]
  );

  const _signInWithCredential = React.useCallback(
    (credential: AuthCredential) => {
      const auth = getAuth(app);
      return signInWithCredential(auth, credential).then(
        async (userCredential) => {
          await setUser(userCredential.user);
          return userCredential;
        }
      );
    },
    [app]
  );

  const _signInWithCustomToken = React.useCallback(
    (token: string, isImpersonatingUser = true) => {
      const auth = getAuth(app);
      return signInWithCustomToken(auth, token).then(async (userCredential) => {
        await setUser(userCredential.user, isImpersonatingUser);
        return userCredential;
      });
    },
    [app]
  );

  const revokeFcmToken = React.useCallback(async () => {
    if (!currentFcmTokenId || !state.currentUser) {
      return;
    }

    const database = getDatabase(app);
    await removeFcmInfo(database, state.currentUser.uid, currentFcmTokenId);
    setCurrentFcmTokenId(undefined);
  }, [app, currentFcmTokenId]);

  const signOut = React.useCallback(async () => {
    await revokeFcmToken();

    const auth = getAuth(app);
    await setUser(null);
    await firebaseSignOut(auth);
  }, [app, revokeFcmToken]);

  const triggerUpdate = React.useCallback(() => {
    updateHandlers.forEach((handler) => handler());
  }, [updateHandlers]);

  const onUpdate = React.useCallback((handler: () => void) => {
    setUpdateHandlers((all) => all.concat(handler));
    return () => {
      setUpdateHandlers((all) => all.filter((h) => h !== handler));
    };
  }, []);

  useOnAuthStateChanged({ app, callback: setUser });

  if (!settings || !app) {
    return null;
  }

  return (
    <FirebaseContext.Provider
      value={{
        app,
        triggerUpdate,
        onUpdate,
        createUser,
        signIn,
        signInWithCredential: _signInWithCredential,
        signInWithCustomToken: _signInWithCustomToken,
        signOut,
        setCurrentFcmTokenId,
        ...state,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

/**
 * Triggers a re-render when the user has been updated in Firebase
 */
export function useUserUpdated() {
  const { onUpdate } = useContext(FirebaseContext);
  const [dummy, triggerRender] = React.useState(Date.now());
  React.useEffect(() => {
    const off = onUpdate(() => {
      triggerRender(Date.now());
    });
    return () => off();
  }, []);
}

export { FirebaseProvider };

export default FirebaseContext;
