import React from 'react';
import { useInterpret } from '@xstate/react';
import { InterpreterFrom } from 'xstate';
import machine from '../stateMachines/deleteAccount';
import { useFirebase } from '../services/firebase';
import { useHistory } from 'react-router-dom';
import { useIonToast } from '@ionic/react';

interface DeleteAccountContext {
  deleteAccountService: InterpreterFrom<typeof machine>;
}

const DeleteAccountContext = React.createContext<DeleteAccountContext>(
  undefined as any
);

export const DeleteAccountContextProvider: React.FC<{}> = ({ children }) => {
  const { currentUser, app, signOut } = useFirebase();
  const history = useHistory();
  const [present] = useIonToast();
  const deleteAccountService = useInterpret(machine, {
    context: {
      app,
      history,
      user: currentUser!,
    },
    actions: {
      exit: () => {
        history.goBack();
      },
      onSuccessfulAccountDeletion: async () => {
        await signOut();
        present('Account successfully deleted', 6000);
        history.push('/');
      },
    },
  });
  (window as any).deleteAccountService = deleteAccountService;

  return (
    <DeleteAccountContext.Provider value={{ deleteAccountService }}>
      {children}
    </DeleteAccountContext.Provider>
  );
};

export function useDeleteAccountContext() {
  return React.useContext(DeleteAccountContext);
}

export default DeleteAccountContext;
