import React from 'react';
import { IonIcon, useIonAlert } from '@ionic/react';
import styled from 'styled-components';
import { camera } from 'ionicons/icons';
import defaultAvatar from 'ionicons/dist/svg/person.svg';

interface ProfilePictureUploadProps {
  photoURL: string | null;
  name: string;
  inputProps?: React.HTMLAttributes<HTMLInputElement>;
  onChange: (file: File | null | undefined) => void;
}

const Container = styled.label<{ $photoURL: string | null }>`
  ${(props) =>
    props.$photoURL && `background-image: url('${props.$photoURL}');`}
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  display: block;
  width: 124px;
  height: 124px;
  position: relative;
  cursor: pointer;
`;
const IconContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  background-color: var(--ion-color-primary);
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;
const Camera = styled(IonIcon)`
  color: white;
  width: 24px;
  height: 24px;
`;
const Input = styled.input`
  display: none;
`;

const readFile = (file: File) => {
  const reader = new FileReader();
  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    reader.onloadend = () => {
      if (reader.result === null) {
        reject(null);
      } else {
        resolve(reader.result);
      }
    };
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(file);
  });
};

const ProfilePictureUpload: React.FC<ProfilePictureUploadProps> = ({
  photoURL: existingPhotoUrl,
  name,
  onChange,
}) => {
  const [photoURL, setPhotoURL] = React.useState(existingPhotoUrl);
  const [present] = useIonAlert();
  return (
    <Container htmlFor={name} $photoURL={photoURL || defaultAvatar}>
      <IconContainer>
        <Camera icon={camera} />
      </IconContainer>
      <Input
        id={name}
        type="file"
        accept="image/*"
        onChange={async (e) => {
          const file = e.target.files?.[0];
          if (file) {
            if (!file.type.startsWith('image')) {
              console.log(file.type);
              present(
                'Only images are allowed as profile pictures (.jpg, .png)'
              );
              return;
            }
            const data = await readFile(e.target.files?.[0]!);
            setPhotoURL(data as any);
          }

          onChange(file);
        }}
      />
    </Container>
  );
};

export default ProfilePictureUpload;
