import { IonBadge, IonFab, IonFabButton, IonIcon } from '@ionic/react';
import React, { useContext } from 'react';
import { chatbubbles } from 'ionicons/icons';
import styled from 'styled-components';
import MessageModal from './MessageModal';
import useIsMounted from '../../hooks/useIsMounted';
import { MessageContext } from '../../context/MessageContext';
import FullscreenAttachments from '../case/siteAttachment/FullscreenAttachments';
import useIonicModalProps from '../../hooks/useIonicModalProps';

interface MessageFabProps {
  unread: boolean;
}

const Notif = styled(IonBadge)`
  left: -2px;
  z-index: 3;
  position: absolute;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f53c20;
`;

const MessageFab: React.FC<MessageFabProps> = ({ unread }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const isMounted = useIsMounted();
  const state = useContext(MessageContext);
  const modalProps = useIonicModalProps();
  return (
    <>
      <IonFab vertical="bottom" horizontal="end">
        {unread && <Notif>{''}</Notif>}
        <IonFabButton
          title="Chat"
          onClick={() => {
            setIsOpen((_isOpen) => !_isOpen);
          }}
        >
          <IonIcon title="Chat" icon={chatbubbles} />
        </IonFabButton>
      </IonFab>
      <MessageModal
        isOpen={isOpen}
        onDidDismiss={() => {
          isMounted.current ? setIsOpen(false) : null;
        }}
        onClose={() => {
          if (isMounted.current) {
            setIsOpen(false);
          }
        }}
      />
      <FullscreenAttachments
        images={state.selectedImage ? [state.selectedImage] : []}
        initialImage={state.selectedImage}
        isOpen={!!state.selectedImage}
        onDidDismiss={() => {
          if (isMounted.current) {
            state.setSelectedImage(undefined);
          }
        }}
        isNonCaseImage
        {...modalProps}
      />
    </>
  );
};

export default MessageFab;
