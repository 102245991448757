import { getDownloadURL, getMetadata, getStorage } from 'firebase/storage';
import React from 'react';
import Attachment from '../../../constants/attachment';
import { useFirebase } from '../../../services/firebase';
import { getFileRef } from '../../../services/firebase/storage';
import { LOADING_STATE } from '../../../util/loading';
import ImagePlaceholder from '../../common/ImagePlaceholder';
import { CaseImageProps } from './CaseImage';
import CaseVideo, { CaseVideoProps } from './CaseVideo';

export interface CaseAttachmentProps extends React.HTMLAttributes<HTMLElement> {
  fileId: string;
  userId: string;
  imageProps?: Partial<CaseImageProps>;
  videoProps?: Partial<CaseVideoProps>;
}

function getAttachmentTypeFromContentType(contentType?: string | null) {
  if (contentType?.startsWith('image')) {
    return Attachment.IMAGE;
  }
  if (contentType?.startsWith('video')) {
    return Attachment.VIDEO;
  }

  return Attachment.UNKNOWN;
}

function useFile(fileId: string, userId: string) {
  const isBlob = fileId.startsWith('blob:');
  const { app } = useFirebase();
  const [fileUrl, setFileUrl] = React.useState(isBlob ? fileId : undefined);
  const [contentType, setContentType] = React.useState<string | null>();
  const [attachmentType, setAttachmentType] = React.useState<Attachment>();
  const [loadingStatus, setStatus] = React.useState<LOADING_STATE>(
    isBlob ? LOADING_STATE.LOADED : LOADING_STATE.LOADING
  );

  React.useEffect(
    (async () => {
      if (loadingStatus === LOADING_STATE.LOADED || !app) {
        return;
      }

      const storage = getStorage(app);
      const ref = getFileRef(storage, fileId, userId);

      const fileUrl = await getDownloadURL(ref);
      const metadata = await getMetadata(ref);
      const { contentType } = metadata;
      const attachmentType = getAttachmentTypeFromContentType(contentType);
      setContentType(contentType);
      setFileUrl(fileUrl);
      setAttachmentType(attachmentType);
      setStatus(LOADING_STATE.LOADED);
    }) as any,
    [fileId, userId, loadingStatus, app]
  );

  return { fileUrl, attachmentType, loadingStatus, contentType };
}

const CaseAttachment: React.FC<CaseAttachmentProps> = ({
  fileId,
  userId,
  imageProps,
  videoProps,
  ...props
}) => {
  const { fileUrl, attachmentType, loadingStatus, contentType } = useFile(
    fileId,
    userId
  );
  if (loadingStatus === LOADING_STATE.LOADING) {
    return <ImagePlaceholder {...imageProps?.placeholderProps} {...props} />;
  }
  if (fileId.startsWith('blob:')) {
    return <img src={fileUrl} {...props} />;
  }

  if (attachmentType === Attachment.IMAGE) {
    const { placeholderProps, ...otherImageProps } = imageProps || {};
    return <img src={fileUrl} {...otherImageProps} {...props} />;
    // return <CaseImage file={fileUrl!} {...imageProps} {...props} />;
  }
  if (attachmentType === Attachment.VIDEO) {
    return (
      <CaseVideo
        file={fileUrl!}
        contentType={contentType}
        {...videoProps}
        {...props}
      />
    );
  }

  return null;
};

export default CaseAttachment;
