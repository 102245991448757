import React from 'react';
import {
  BrowserRouter,
  BrowserRouterProps,
  HashRouter,
  HashRouterProps,
  useHistory,
} from 'react-router-dom';
import useBasename from '../../hooks/useBasename';
import Typography from '../common/Typography';
import ConfirmLeave from '../dialogs/ConfirmLeave';
import { IonReactRouter } from '@ionic/react-router';

interface RouterProps extends HashRouterProps {}

export function useBadPracticeHistory() {
  const history = useHistory();
  React.useEffect(() => {
    (window as any).__HISTORY__ = history;
  }, [history]);
}

const Router: React.FC<RouterProps> = ({ children, ...props }) => {
  const [userConfirmation, setUserConfirmation] = React.useState<{
    message: string;
    callback: (ok: boolean) => void;
  }>();
  const { basename } = useBasename();

  return (
    <IonReactRouter
      getUserConfirmation={(message, callback) => {
        setUserConfirmation({ message, callback });
      }}
      basename={basename}
      {...props}
    >
      {children}
      <ConfirmLeave
        isOpen={!!userConfirmation}
        onStayHere={() => {
          userConfirmation!.callback(false);
          setUserConfirmation(undefined);
        }}
        onContinue={() => {
          userConfirmation!.callback(true);
          setUserConfirmation(undefined);
        }}
        message={userConfirmation?.message}
      />
    </IonReactRouter>
  );
};

export default Router;
