import { DoneInvokeEvent, MachineConfig, assign } from 'xstate';
import {
  StripeElements,
  Stripe,
  StripeError,
  SetupIntent,
  PaymentMethod,
} from '@stripe/stripe-js';
import {
  ParentUser,
  getPaymentMethods,
  setupIntent,
} from '../../api/cloudFunctions';
import { getFunctions } from 'firebase/functions';
import { FirebaseApp } from 'firebase/app';
import addCreditCardStates from './addCreditCard';
import { Case } from '../../services/buildfire/rdb/cases';
import { hasPaymentMethod } from './guards';

export interface CreditCardContext {
  firebaseApp: FirebaseApp;
  error?: string | Error | StripeError;
  stripePublishableKey: string;
  userId: string;
  email: string;
  name: string;
  clientSecret?: string;
  stripeCustomerId?: string;
  elements?: StripeElements;
  stripe?: Stripe;
  setupIntent?: SetupIntent;
  paymentMethods?: PaymentMethod[];
  selectedPaymentMethod?: string;
  parentAccount?: ParentUser;
  selectedInvoiceAccountUid: string;
  case?: Case;
}

export type CreditCardEvent =
  | { type: 'WILL_ADD_CREDIT_CARD' }
  | {
      type: 'ADD_CARD';
      stripe: Stripe;
      elements: StripeElements;
    }
  | { type: 'SELECT_CARD'; id: string }
  | { type: 'TOGGLE_INVOICING'; invoicingEnabled: boolean }
  | { type: 'SET_INVOICE_ACCOUNT'; invoiceAccountUid: string }
  | { type: 'REMOVE_CREDIT_CARD'; paymentMethodId: string }
  | { type: 'RETRY' }
  | { type: 'NEXT' }
  | { type: 'BACK' }
  | { type: 'CLOSE' };

export type CreditCardState =
  | { value: 'load'; context: CreditCardContext & { clientSecret: undefined } }
  | {
      value: 'selectPaymentMethod';
      context: CreditCardContext & { clientSecret: string };
    };

interface SetupIntentResult {
  setupIntent: SetupIntent;
  stripeCustomerId: string;
  paymentSources: PaymentMethod[];
}

export async function getSetupIntent(context: CreditCardContext) {
  if (context.setupIntent?.status === 'requires_payment_method') {
    return context.setupIntent;
  }

  try {
    const functions = getFunctions(context.firebaseApp);
    const {
      setupIntent: createdSetupIntent,
      paymentMethods,
      stripeCustomerId,
    } = await setupIntent(functions, {
      userId: context.userId,
      email: context.email,
      name: context.name,
    });

    const selectedPaymentMethod =
      createdSetupIntent.payment_method ||
      context.selectedPaymentMethod ||
      paymentMethods[0]?.id;
    return {
      clientSecret: createdSetupIntent.client_secret,
      setupIntent: createdSetupIntent,
      stripeCustomerId,
      paymentMethods,
      selectedPaymentMethod,
    };
  } catch (e: any) {
    console.error(e);
    throw e;
  }
}

async function confirmSetupIntent(context: CreditCardContext) {
  const functions = getFunctions(context.firebaseApp);
  // Only use saved setupIntent if it matches the selected payment method
  const setupIntentId =
    context.setupIntent?.payment_method === context.selectedPaymentMethod
      ? context.setupIntent?.id
      : undefined;
  const result = await setupIntent(functions, {
    userId: context.userId,
    email: context.email,
    name: context.email,
    confirm: {
      setupIntentId,
      paymentMethodId: context.selectedPaymentMethod!,
    },
  });

  return result.setupIntent;
}

export async function retrievePaymentMethods(context: CreditCardContext) {
  const fns = getFunctions(context.firebaseApp);
  const result = await getPaymentMethods(fns, {
    userId: context.userId,
    email: context.email,
    name: context.name,
  });

  // Ensure selectedPaymentMethod exists on list of payment methods
  // If selected payment method is not in list of saved payment methods, unselect
  // This can happen if a credit card was removed from the account
  const paymentMethodMatch = result.paymentMethods?.find(
    ({ id }) => id === context.selectedPaymentMethod
  );
  return {
    paymentMethods: result.paymentMethods,
    customerId: result.stripeCustomerId,
    selectedPaymentMethod:
      paymentMethodMatch?.id || result.paymentMethods?.[0]?.id,
    selectedInvoiceAccountUid:
      context.case?.invoiceAccount?.uid ||
      context.parentAccount?.uid ||
      context.userId,
  };
}

const every = (...guards) => ({
  type: 'every',
  guards,
});
const assignResult = assign(
  (context: CreditCardContext, ev: DoneInvokeEvent<any>) => {
    return {
      ...context,
      ...ev.data,
    };
  }
);

const creditCardStates: MachineConfig<CreditCardContext, any, CreditCardEvent> =
  {
    initial: 'initial',
    context: {
      firebaseApp: null as any,
      stripePublishableKey: '',
      userId: '',
      email: '',
      name: '',
      selectedInvoiceAccountUid: '',
    },
    states: {
      initial: {
        always: [
          {
            target: 'selectInvoiceAccount',
            cond: every('isInvoicing', 'hasPaymentMethod'),
          },
          {
            target: 'selectPaymentMethod',
            cond: 'hasPaymentMethod',
          },
          {
            target: 'load',
          },
        ],
      },

      load: {
        invoke: {
          src: retrievePaymentMethods,
          onDone: [
            // If attached to hospital, they can pick which account to invoice
            {
              target: 'selectInvoiceAccount',
              cond: every('isAttachedToHospital', 'isInvoicing'),
              actions: assignResult,
            },
            // If invoicing own account, make sure there is a default payment method set up
            {
              target: 'success',
              cond: every('isInvoicing', 'hasPaymentMethod'),
              actions: assignResult,
            },
            // Otherwise select credit card
            {
              target: 'selectPaymentMethod',
              actions: assignResult,
            },
          ],
          onError: {
            // actions: escalate((context, ev) => ev.data),
            target: 'error',
            actions: assign({
              error: (context, ev) => ev.data,
            }),
          },
        },
      },

      selectInvoiceAccount: {
        on: {
          SET_INVOICE_ACCOUNT: {
            actions: 'setInvoiceAccount',
          },
          NEXT: [
            {
              target: 'success',
              cond: 'isInvoicingParentAccount',
            },
            // If invoicing own account, make sure there is a default payment method set up
            {
              target: 'selectPaymentMethod',
              cond: (arg) => !hasPaymentMethod(arg as any),
            },
            {
              target: 'success',
            },
          ],
        },
      },

      selectPaymentMethod: {
        on: {
          WILL_ADD_CREDIT_CARD: {
            target: 'addCreditCard',
          },
          REMOVE_CREDIT_CARD: {
            actions: 'removePaymentMethod',
          },
          SELECT_CARD: {
            actions: 'selectPaymentMethod',
          },
          NEXT: [
            // Don't confirm setup intent if invoicing parent
            {
              target: 'success',
              cond: 'isInvoicing',
            },
            // If credit card payment, confirm setup intent
            {
              target: 'success',
              cond: 'hasConfirmedSetupIntent',
            },
            {
              target: 'confirmSetupIntent',
              cond: 'hasPaymentMethod',
            },
          ],
          BACK: [
            {
              target: 'selectInvoiceAccount',
              cond: 'isInvoicing',
            },
          ],
        },
      },

      addCreditCard: {
        ...(addCreditCardStates as any),
        onDone: 'selectPaymentMethod',
        on: {
          CLOSE: 'selectPaymentMethod',
        },
      },

      confirmSetupIntent: {
        invoke: {
          src: confirmSetupIntent,
          onDone: {
            target: 'success',
            actions: assign({
              setupIntent: (context, ev) => ev.data,
            }),
          },
          onError: {
            target: 'selectPaymentMethod',
            actions: assign({
              error: (context, ev) => ev.data,
            }),
          },
        },
      },

      success: {
        type: 'final',
      },

      error: {
        on: {
          RETRY: {
            target: 'load',
          },
        },
      },
    },
  };

export default creditCardStates;
