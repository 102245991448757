import { User } from 'firebase/auth';
import React from 'react';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { useFirebase } from '../../firebase';
import { LoginState } from '../../firebase/FirebaseContext';
import { AUTH_REF } from '../../../util/cookie';

interface AuthRequiredProps {}

const AuthRequired: React.FC<AuthRequiredProps> = (props) => {
  const { currentUser, loginState } = useFirebase();
  const history = useHistory();

  React.useEffect(() => {
    if (loginState === LoginState.LOGGED_OUT) {
      const url = encodeURIComponent(
        `${window.location.pathname}${window.location.search}`
      );
      // Set return URL as cookie in case search params get overridden
      Cookies.set(AUTH_REF, url);
      history.replace({
        pathname: '/login',
        search: `?ref=${url}`,
      });
    }
  }, [loginState]);

  if (!currentUser) {
    return null;
  }

  return (
    <>
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child as any, {
          currentUser,
          loginState,
        })
      )}
    </>
  );
};

export function withAuthRequired<T = {}>(
  Component: React.ComponentType<
    T extends { currentUser: User } ? T & { currentUser: User } : T
  >
) {
  const WrappedComponent: React.FC<Omit<T, 'currentUser'>> = (props) => {
    return (
      <AuthRequired>
        <Component {...(props as any)} />
      </AuthRequired>
    );
  };
  return WrappedComponent;
}

export default AuthRequired;
