import React from 'react';
import Users from '../Users';
import { LOADING_STATE } from '../../util/loading';
import { useFirebase } from '../../services/firebase';
import { ref } from 'firebase/database';
import { getAdminUsers } from '../../api/cloudFunctions';
import { Functions, getFunctions } from 'firebase/functions';
import { User } from 'firebase/auth';
import useQueryParams from '../../hooks/useQueryParams';

interface UsersProps {}

const PAGE_SIZE = 50;

const AdminUsers: React.FC<UsersProps> = () => {
  const [loadingState, setIsLoading] = React.useState(LOADING_STATE.LOADING);
  const [isComplete, setIsComplete] = React.useState(false);
  const [pageToken, setPageToken] = React.useState<string>();
  const [users, setUsers] = React.useState<User[]>([]);
  const queryParams = useQueryParams();
  const search = queryParams.get('search');
  const isSearching = Boolean(search);
  const { app } = useFirebase();

  const isLoading = loadingState === LOADING_STATE.LOADING;

  const fetchUsers = async (fns: Functions) => {
    try {
      // If searching, get all users
      const pageSize = isSearching ? -1 : PAGE_SIZE;
      const result = await getAdminUsers(fns, { pageToken, pageSize });
      if (result) {
        // Filter out old buildfire accounts that are not verified (no email)
        const usersToAdd = result.users.filter((u) => Boolean(u.email));
        setUsers((u) => u.concat(usersToAdd));
        setPageToken(result.pageToken);
        if (!result.pageToken) {
          setIsComplete(true);
        }
        setIsLoading(LOADING_STATE.LOADED);
      } else {
        throw new Error('Error fetching users');
      }
    } catch (e) {
      console.error(e);
      setIsLoading(LOADING_STATE.ERROR);
    }
  };

  React.useEffect(() => {
    const fns = getFunctions(app);
    fetchUsers(fns);
  }, []);

  return (
    <Users
      isAdmin
      users={users}
      isLoading={isLoading}
      disableInfiniteScroll={isComplete}
      onLoadMoreUsers={async () => {
        const fns = getFunctions(app);
        await fetchUsers(fns);
      }}
      onSearchUsers={async (searchTerm) => {
        if (!isComplete) {
          setIsLoading(LOADING_STATE.LOADING);
          try {
            const fns = getFunctions(app);
            const result = await getAdminUsers(fns, { pageSize: -1 });
            const usersToAdd = result!.users.filter((u) => Boolean(u.email));
            setUsers(usersToAdd);
            setIsComplete(true);
            setIsLoading(LOADING_STATE.LOADED);
          } catch (e) {
            setIsLoading(LOADING_STATE.ERROR);
          }
        }
      }}
    />
  );
};

export default AdminUsers;
