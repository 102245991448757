import classNames from 'classnames';
import React, { useRef } from 'react';

export interface ProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  indeterminate?: boolean;
  reversed?: boolean;
  show?: boolean;
  progress?: number;
}

export function useProgressBar<T extends HTMLElement = HTMLDivElement>() {
  const elementRef = useRef<T>(null);
  const mdcComponentRef = useRef<mdc.linearProgress.MDCLinearProgress>();
  React.useEffect(() => {
    if (elementRef.current) {
      mdcComponentRef.current = new mdc.linearProgress.MDCLinearProgress(
        elementRef.current
      );
      (window as any).progressbar = mdcComponentRef.current;
      return () => mdcComponentRef.current!.destroy();
    }
  }, []);
  return { elementRef, mdcComponentRef };
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  show,
  indeterminate,
  reversed,
  progress,
  className,
  ...props
}) => {
  const { elementRef, mdcComponentRef } = useProgressBar();

  React.useEffect(() => {
    if (mdcComponentRef.current) {
      if (show) {
        mdcComponentRef.current.open();
      } else {
        mdcComponentRef.current.close();
      }
    }
  }, [show]);
  React.useEffect(() => {
    if (mdcComponentRef.current) {
      mdcComponentRef.current.foundation_.setDeterminate(!indeterminate);
    }
  }, [indeterminate]);
  React.useEffect(() => {
    if (mdcComponentRef.current && typeof reversed !== 'undefined') {
      mdcComponentRef.current.foundation_.setReverse(reversed);
    }
  }, [reversed]);
  React.useEffect(() => {
    if (mdcComponentRef.current && typeof progress !== 'undefined') {
      mdcComponentRef.current.foundation_.setProgress(progress);
    }
  }, [progress]);

  return (
    <div
      role="progressbar"
      className={classNames('mdc-linear-progress', className)}
      ref={elementRef}
      {...props}
    >
      <div className="mdc-linear-progress__buffering-dots"></div>
      <div className="mdc-linear-progress__buffer"></div>
      <div className="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
        <span className="mdc-linear-progress__bar-inner"></span>
      </div>
      <div className="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
        <span className="mdc-linear-progress__bar-inner"></span>
      </div>
    </div>
  );
};

export default ProgressBar;
