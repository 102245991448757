import React from 'react';
import styled from 'styled-components';
import ProgressBar from './ProgressBar';

interface LoadingCoverProps extends React.HTMLAttributes<HTMLDivElement> {
  show?: boolean;
}

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 9;
`;

const LoadingCover: React.FC<LoadingCoverProps> = ({
  show,
  children,
  ...props
}) => {
  return (
    <Container {...props}>
      <ProgressBar indeterminate show={show} />
      {children}
    </Container>
  );
};

export default LoadingCover;
