import { Database, onValue, push, ref, remove, set } from 'firebase/database';

export interface FCMData {
  token?: string;
  timestamp?: number;
  invalid?: string;
  devices?: string[];
  id?: string;
}
export interface DBUserInfo {
  stripeCustomerId?: string;
  fcm: StringMap<FCMData>;
}

export const getUserInfo = (database: Database, uid: string) => {
  const userInfoRef = ref(database, `users/${uid}`);
  return new Promise<DBUserInfo>((resolve, reject) => {
    onValue(
      userInfoRef,
      (snap) => {
        if (!snap.exists()) {
          return resolve({
            stripeCustomerId: '',
            fcm: {},
          });
        }

        const val = snap.val();
        const info = {
          ...val,
          stripeCustomerId: val.stripeCustomerId || '',
          fcm: val.fcm || {},
        };
        resolve(info);
      },
      (err) => {
        console.error(err);
        reject(err);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

export const setFcmInfo = (
  database: Database,
  uid: string,
  fcmInfo: FCMData
): Promise<FCMData> => {
  const dataRef = ref(database, `users/${uid}/fcm`);
  return push(dataRef, fcmInfo).then((snap) => ({
    ...fcmInfo,
    id: snap.key as string,
  }));
};

export const updateFcmTimestamp = (
  database: Database,
  uid: string,
  fcmId: string
): Promise<void> => {
  const dataRef = ref(database, `users/${uid}/fcm/${fcmId}/timestamp`);
  return set(dataRef, Date.now());
};

export const removeFcmInfo = (
  database: Database,
  uid: string,
  key: string
): Promise<void> => {
  const dataRef = ref(database, `users/${uid}/fcm/${key}`);
  return remove(dataRef);
};

export const modEmail = (email: string) => email.replace(/[\.\[\]$#]/g, '%%');

/**
 * NOTE: returns email list with %% in place of special characters
 * Use `modEmail` function to compare emails against this list
 * @returns
 */
export const getUsersInNeedOfMigration = (
  database: Database
): Promise<string[]> => {
  const r = ref(database, 'usersInNeedOfMigration');
  return new Promise<string[]>((resolve, reject) => {
    onValue(
      r,
      (snap) => {
        const map = snap.val() || {};
        const users = Object.keys(map).filter((key) => !!map[key]);
        resolve(users);
      },
      (err) => {
        console.error(err);
        reject(err);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

export const setUserInNeedOfMigrationStatus = (
  database: Database,
  email: string,
  needsMigration: boolean
) => {
  const r = ref(database, `usersInNeedOfMigration/${modEmail(email)}`);
  return set(r, needsMigration);
};
