import React from 'react';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import classNames from 'classnames';
import Toolbar from '../components/common/Toolbar';
import AlertBanner from '../components/common/AlertBanner';
import Cases from './Cases';
import CustomerCaseInfo from '../components/case/caseInfo/CustomerCaseInfo';
import { CaseInfoProps } from '../components/case/caseInfo/CaseInfo';
import Typography from '../components/common/Typography';
import Dialog, { DialogActionButton } from '../components/common/Dialog';
import { LOADING_STATE } from '../util/loading';
import { useFirebase } from '../services/firebase';
import { useParams, useRouteMatch } from 'react-router-dom';
import { isDesktop } from '../util/device';
import useFetch from '../hooks/useFetch';
import { getUserById } from '../api/cloudFunctions';
import { getFunctions } from 'firebase/functions';
import { getByUser_Deprecated } from '../services/buildfire/rdb/cases';
import { FirebaseApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import CustomerReport from '../components/case/report/CustomerReport';
import CaseListItem from '../components/customer/CaseListItem';
import ProgressBar from '../components/common/ProgressBar';

interface UserCasesProps {}

const getUserCases = async (app: FirebaseApp, userId: string) => {
  const fns = getFunctions(app);
  const result = await getUserById(fns, { userId });
  if (!result?.user) {
    throw new Error('User not found');
  }

  const db = getDatabase(app);
  const cases = await getByUser_Deprecated(db, userId);

  return { user: result.user, cases };
};

function useUser(userId: string) {
  const { app } = useFirebase();
  return useFetch(() => getUserCases(app, userId));
}

const UserCases: React.FC<UserCasesProps> = (props) => {
  const { userId } = useParams<{ userId: string }>();
  const { currentUser, isAdmin } = useFirebase();
  const { loadingStatus, data: { user, cases } = {}, error } = useUser(userId);
  const isLoading = loadingStatus === LOADING_STATE.LOADING;

  const match = useRouteMatch(
    `${isAdmin ? '/admin' : ''}/users/:userId/cases/:caseId`
  );
  const showMainHeader = !match?.url || isDesktop();

  return (
    <IonPage>
      <IonHeader
        className={classNames({
          ['ion-no-border']: true,
          ['hide-sm']: !showMainHeader,
        })}
      >
        <Toolbar
          showBackButton
          defaultBackLocation={`/users/${user?.uid}`}
          title={user ? `${user.displayName?.trim()}'s cases` : ''}
        />
        <AlertBanner />
      </IonHeader>
      {isLoading && (
        <IonContent>
          <ProgressBar indeterminate />
        </IonContent>
      )}

      {user && (
        <Cases
          cases={cases || []}
          currentUser={currentUser!}
          caseUser={user}
          isLoading={isLoading}
          CaseInfo={CustomerCaseInfoWithActionItems}
          Report={CustomerReport}
          CaseListItemComponent={CaseListItem}
          hasRetrievedAllCases
          isAdmin={false}
          getOpenCases={async () => {}}
          getClosedCases={async () => {}}
          fabDisabled
        />
      )}

      {/* {isOnCaseListScreen && <NewCaseButton />} */}

      <Dialog
        title=""
        dialogId="cases-error"
        isOpen={loadingStatus === LOADING_STATE.ERROR}
        actions={<DialogActionButton action="accept">Ok</DialogActionButton>}
      >
        <Typography fontSize="16px">
          There was a problem retrieving your cases. Please refresh and try
          again.
        </Typography>
      </Dialog>
    </IonPage>
  );
};

const CustomerCaseInfoWithActionItems: React.FC<CaseInfoProps> = (props) => (
  <CustomerCaseInfo {...props} actionItems={null} />
);

export default UserCases;
