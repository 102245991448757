import { useRef, useEffect } from 'react';

interface RippleOptions {
  unbounded?: boolean;
}

function useRipple<T extends HTMLElement = HTMLElement>(
  options: RippleOptions = {}
) {
  const ref = useRef<T>(null);
  useEffect(() => {
    if (ref.current) {
      const mdcComponent = new mdc.ripple.MDCRipple(ref.current!);
      if (options.unbounded !== undefined) {
        mdcComponent.unbounded = options.unbounded;
      }
      return () => mdcComponent.destroy();
    }
  }, []);
  return ref;
}

export { useRipple };
