import { Field, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import LabeledInput from '../../../mdc/LabeledInput';
import ImageUpload from '../../ImageUpload';
import { PreUploadSite } from '../CaseForm';
import TextArea from '../../common/TextArea';
import { uppercaseFirstLetter } from '../../../util/string';
import Form from '../../common/Form';
import Typography from '../../common/Typography';
import { IonButton } from '@ionic/react';
import HelperText from '../../../mdc/HelperText';

export interface SiteFormSubmitButtonProps {
  onClick: () => void;
  disabled: boolean;
}
export interface SiteFormProps {
  initialValue?: PreUploadSite;
  onSubmit: (site: PreUploadSite) => void;
  maxImages?: number;
  Form?: React.ComponentType<{}>;
  renderSubmitButton?: (props: SiteFormSubmitButtonProps) => React.ReactNode;
  promptMessage?: string;
}

const StyledForm = styled(Form)`
  margin: 24px 0;
`;

const UploadWrapper = styled.div`
  margin-top: 24px;

  .mdc-text-field-helper-text {
    margin: 8px 0;
    padding: 0 16px;
  }
`;

const AlertText = styled(Typography)`
  color: var(--mdc-theme-secondary) !important;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
`;

const Label: React.FC<{}> = ({ children }) => (
  <Typography margin="8px 16px -4px 16px" fontSize="12px">
    {children}
  </Typography>
);

const SiteForm: React.FC<SiteFormProps> = ({
  initialValue,
  onSubmit,
  maxImages = 20,
  renderSubmitButton,
  Form,
  promptMessage,
}) => {
  // const initialFiles =
  //   initialValue && isPreuploadSite(initialValue)
  //     ? initialValue.files
  //     : initialValue?.images;
  const _renderSubmitButton =
    renderSubmitButton ||
    (({ onClick, disabled }: SiteFormSubmitButtonProps) => (
      <IonButton expand="full" onClick={onClick} disabled={disabled}>
        Done
      </IonButton>
    ));
  const FormComponent = Form || StyledForm;
  return (
    <Formik
      validateOnMount
      initialValues={{
        location: initialValue?.location || '',
        description: initialValue?.description || '',
        additionalInfo: initialValue?.additionalInfo || '',
        files: initialValue?.files || ([] as File[]),
      }}
      onSubmit={onSubmit}
      validationSchema={Yup.object({
        location: Yup.string().max(100).required('Required'),
        description: Yup.string().max(1000).required('Required'),
        additionalInfo: Yup.string().max(4000).optional(),
        files: Yup.array().min(1).max(maxImages).required(),
      })}
    >
      {({
        setFieldValue,
        values,
        submitForm,
        isValid,
        dirty,
        touched,
        errors,
        isSubmitting,
      }) => (
        <>
          {/* {dirty && !isSubmitting && (
            <Prompt
              when
              message={
                promptMessage ||
                'Are you sure you want to close this dialog? This site will not be added to your case submission'
              }
            />
          )} */}
          <FormComponent>
            <Label>Location</Label>
            <Field
              as={LabeledInput}
              name="location"
              // label="Location"
              tabIndex={0}
              error={
                touched.location &&
                uppercaseFirstLetter(errors.location as string)
              }
              maxLength={100}
            />
            <Label>Description</Label>
            <Field
              as={LabeledInput}
              name="description"
              // label="Description"
              error={
                touched.description &&
                uppercaseFirstLetter(errors.description as string)
              }
              maxLength={1000}
            />
            <Label>
              Clinical concern/differentials and specific questions for
              pathologist
            </Label>
            <Field
              as={TextArea}
              name="additionalInfo"
              label=""
              helpText="Optional"
              // helpText={<AdditionalInfoHelpText />}
              error={
                touched.additionalInfo &&
                uppercaseFirstLetter(errors.additionalInfo as string)
              }
              maxLength={4000}
            />
            <UploadWrapper>
              <AlertText>
                Please submit 5+ photos of unique fields. Include photos at 10x,
                40x, and 100x.
              </AlertText>
              <ImageUpload
                initialFiles={values.files}
                inputProps={{ name: 'site-upload' } as any}
                onFileAdd={(file) => {
                  setFieldValue('files', (values.files as any).concat(file));
                }}
                onFileRemove={(file) => {
                  setFieldValue(
                    'files',
                    (values.files as any).filter((f: any) => f !== file)
                  );
                }}
                maxFiles={maxImages}
              />
              <HelperText>Maximum of {maxImages} files</HelperText>
            </UploadWrapper>
          </FormComponent>

          {_renderSubmitButton({
            onClick: submitForm,
            disabled: !isValid || isSubmitting,
          })}
        </>
      )}
    </Formik>
  );
};

const AdditionalInfoHelpText = () => (
  // <div style={{display: 'flex', marginTop: '-12px'}}>
  //   <Icon variant="info" />
  //   Clinical concern/differentials and specific questions for pathologist (optional)
  // </div>
  <span>
    Clinical concern/differentials and specific questions for pathologist
    (optional)
  </span>
);

export default SiteForm;
