import React from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import styled from 'styled-components';
import Form from '../../common/Form';
import SiteForm, { SiteFormProps } from './SiteForm';
import useIonicModalProps from '../../../hooks/useIonicModalProps';

interface SiteFormDialogProps {
  isOpen: boolean;
  onClose: () => void;
  SiteFormProps: SiteFormProps;
}

const SiteModal = styled(IonModal)`
  @media only screen and (min-width: 768px) and (min-height: 768px) {
    --height: 768px;
  }
`;

const StyledSiteForm = styled(Form)`
  padding: 16px;
`;

const SiteFormDialog: React.FC<SiteFormDialogProps> = ({
  isOpen,
  onClose,
  SiteFormProps,
}) => {
  const modalProps = useIonicModalProps();
  const title = SiteFormProps.initialValue
    ? 'Edit Site Details'
    : 'Add Site Details';
  const saveText = SiteFormProps.initialValue ? 'Save' : 'Done';
  return (
    <SiteModal
      title={title}
      isOpen={isOpen}
      onDidDismiss={onClose}
      {...modalProps}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <SiteForm
        Form={({ children }) => (
          <IonContent>
            <StyledSiteForm>{children}</StyledSiteForm>
          </IonContent>
        )}
        renderSubmitButton={({ onClick, disabled }) => (
          <IonFooter style={{ padding: '16px 8px' }}>
            <IonButton expand="full" onClick={onClick} disabled={disabled}>
              {saveText}
            </IonButton>
          </IonFooter>
        )}
        {...SiteFormProps}
      />
    </SiteModal>
  );
};

export default SiteFormDialog;
