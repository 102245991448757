import React from 'react';
import { IonIcon } from '@ionic/react';
import styled from 'styled-components';
import ChangePasswordForm from './login/ChangePasswordForm';
import { informationCircle } from 'ionicons/icons';
import Typography from '../components/common/Typography';
import LoginScreen from './login/LoginScreen';
import { withAuthRequired } from '../services/buildfire/auth';
import { User } from 'firebase/auth';
import { Message } from './login/common/styledComponents';

interface ChangePasswordProps {
  currentUser: User;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ currentUser }) => {
  const [message, setMessage] = React.useState<{
    text: string;
    type: string;
  }>();
  return (
    <LoginScreen title="Change Password">
      {message && (
        <Message>
          <IonIcon icon={informationCircle} />
          <Typography margin="0">{message.text}</Typography>
        </Message>
      )}
      <ChangePasswordForm
        user={currentUser!}
        onSuccess={(text) => {
          setMessage({
            text,
            type: '',
          });
        }}
        onError={(text) => {
          setMessage({
            text,
            type: '',
          });
        }}
      />
    </LoginScreen>
  );
};

export default withAuthRequired(ChangePassword);
