import React from 'react';
import { Case } from '../../../../services/buildfire/rdb/cases';
import { useDialog } from '../../../../context/DialogContext';
import Dialog, { DialogProps } from '../../../common/Dialog';
import ModalLoader from '../../../common/ModalLoader';
import { images } from 'ionicons/icons';
import { JSX } from '@ionic/core/components';
import ActionItem from '../ActionItem';
import { Functions, getFunctions, httpsCallable } from 'firebase/functions';
import { useFirebase } from '../../../../services/firebase';
import {
  FirebaseStorage,
  getDownloadURL,
  getStorage,
  ref,
} from 'firebase/storage';

interface DownloadSiteAttachmentsProps extends JSX.IonItem {
  case: Case;
}

const downloadSiteAttachments = (
  fns: Functions,
  storage: FirebaseStorage,
  filePaths: string[],
  uid: string
) => {
  const fn = httpsCallable<any, { zip: string }>(
    fns,
    'downloadSiteAttachments'
  );
  return fn({ filePaths, uid }).then((result) => {
    console.log('downloadFilePaths result', result);
    const { zip } = result.data;
    const r = ref(storage, zip);
    return getDownloadURL(r);
  });
};

const DownloadSiteAttachments: React.FC<DownloadSiteAttachmentsProps> = ({
  case: c,
  ...props
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const negated = React.useRef(false);
  const { openDialog } = useDialog();
  const { app } = useFirebase();
  const functions = getFunctions(app);
  const storage = getStorage(app);

  return (
    <>
      <ActionItem
        disabled={isLoading}
        onClick={async () => {
          const filePaths = c.sites.reduce(
            (acc, site) => acc.concat(site.images),
            [] as string[]
          );
          setIsLoading(true);
          try {
            const url = await downloadSiteAttachments(
              functions,
              storage,
              filePaths,
              c.uid
            );
            setIsLoading(false);
            if (!negated.current) {
              window.open(url, '_blank');
              // openDialog(
              //   <a href={url} target="_blank">
              //     Click to download site attachments
              //   </a>
              // );
              // buildfire.navigation.openWindow(url, '_blank');
            }
            negated.current = false;
          } catch (e) {
            console.error(e);
            setIsLoading(false);
            openDialog(<div>{(e as any).message || e}</div>);
            negated.current = false;
          }
        }}
        icon={images}
        label={isLoading ? 'Loading...' : 'Download attachments'}
      />
      {isLoading && (
        <ModalLoader
          onClose={() => {
            setIsLoading(false);
            negated.current = true;
          }}
        />
      )}
    </>
  );
};

interface LoadingDialogProps extends Partial<DialogProps> {}

const LoadingDialog: React.FC<LoadingDialogProps> = ({ isOpen, ...props }) => {
  return (
    <Dialog dialogId="loading" title="" isOpen={isOpen || false} {...props}>
      Loading...
    </Dialog>
  );
};

export default DownloadSiteAttachments;
