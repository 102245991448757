import React from 'react';
import { getAuth, AuthProvider, AuthCredential, Auth } from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useQueryParams from '../../../hooks/useQueryParams';
import { useFirebase } from '../../../services/firebase';
import { redirectAfterAuth } from '../../../util/auth';

interface SignInButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  provider: AuthProvider;
  imageSrc: string;
  signIn: (auth: Auth) => Promise<AuthCredential>;
}

const Button = styled.button<{ $imageSrc: string }>`
  ${(props) => `background-image: url('${props.$imageSrc}');`}
  background-position: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid lightgrey;
  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

const SignInButton: React.FC<SignInButtonProps> = ({
  imageSrc,
  provider,
  signIn,
  children,
  ...props
}) => {
  const history = useHistory();
  const params = useQueryParams();
  const { app, signInWithCredential } = useFirebase();

  const onClick = async () => {
    try {
      const auth = getAuth(app);
      const authCredential = await signIn(auth);
      await signInWithCredential(authCredential);

      redirectAfterAuth(params, history);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Button onClick={onClick} $imageSrc={imageSrc} {...props}>
      {children}
    </Button>
  );
};

export default SignInButton;
