import { isPlatform } from '@ionic/core';
import React from 'react';
import styled from 'styled-components';
import Button, { ButtonProps } from './Button';

interface ExpandingButtonProps extends ButtonProps {
  width?: string;
}

const StyledButton = styled<
  React.FC<
    ButtonProps & {
      as?: string | React.ComponentType<any> | undefined;
    } & { $width: string }
  >
>(Button)`
  @media (min-width: 768px) {
    width: ${(props) => props.$width};
  }
  width: 100%;
`;

/**
 * Button that has fullWidth=true on mobile, but a fixed width on desktop
 */
const ExpandingButton: React.FC<ExpandingButtonProps> = ({
  width = '240px',
  ...props
}) => {
  // const isDesktop = isPlatform('desktop');
  // const buttonProps = isDesktop
  //   ? { style: { width, ...props.style }, fullWidth: false }
  //   : { fullWidth: true };
  return <StyledButton $width={width} {...props} />;
};

export default ExpandingButton;
