import React from 'react';
import styled from 'styled-components';
import { Case, Site } from '../../../services/buildfire/rdb/cases';
import Typography, { TypographyProps } from '../../common/Typography';
import DownloadSiteAttachments from '../actionMenu/actions/DownloadSiteAttachments';
import { isPreUpload } from './SiteListItem';
import { PreUploadSite } from '../CaseForm';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import useIonicModalProps from '../../../hooks/useIonicModalProps';
import { JSX } from '@ionic/core/components';
import { ellipsisVertical } from 'ionicons/icons';
import Popover from '../../common/Popover';
import AttachmentList from '../siteAttachment/AttachmentList';

interface SiteDialogProps extends JSX.IonModal {
  case?: Case;
  site: Site | PreUploadSite;
  siteIndex: number;
  userId: string;
  showActionMenu: boolean;
}

const ContentWrapper = styled.div`
  padding: 0 20px;
`;

const TypographyContainer = styled.div`
  display: flex;
  align-items: center;
  p {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const SiteTitle = styled<{ $showActionMenu: boolean } & any>(IonTitle)`
  max-width: ${(props) =>
      props.$showActionMenu ? 'calc(100% - 52px);' : '100%;'}
    &.ios {
    padding: 3px 12px;
  }
`;

const SubHeader: React.FC<TypographyProps> = (props) => (
  <Typography fontSize="14px" margin="12px 0 4px 0" {...props} />
);
const StyledSubheader = styled(SubHeader)`
  color: var(--mdc-theme-text-secondary-on-background) !important;
`;
const Text: React.FC<TypographyProps> = (props) => (
  <Typography fontSize="16px" {...props} />
);

const SiteDialog: React.FC<SiteDialogProps> = ({
  site,
  case: c,
  userId,
  isOpen,
  siteIndex,
  showActionMenu,
  ...props
}) => {
  const imageUrls = isPreUpload(site)
    ? site.files.map(URL.createObjectURL)
    : site.images;
  const modalProps = useIonicModalProps();

  return (
    <IonModal isOpen={isOpen} {...modalProps} {...props}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Site #{siteIndex + 1}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => modalProps.ref.current?.dismiss()}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonToolbar>
          {/* <DialogHeader> */}
          <SiteTitle slot="start" $showActionMenu={showActionMenu}>
            <TypographyContainer>
              <Typography
                variant="secondary"
                fontSize="20px"
                // margin="32px 0 16px 0"
                margin="0"
                lineHeight="1em"
                title={site.location}
              >
                {site.location}
              </Typography>
            </TypographyContainer>
          </SiteTitle>

          {showActionMenu && (
            <IonButtons slot="end">
              <IonButton id="menu" fill="clear">
                <IonIcon icon={ellipsisVertical} slot="icon-only" />
              </IonButton>
            </IonButtons>
          )}

          <Popover trigger="menu" side="left">
            <IonContent>
              <IonList lines="none">
                {c && <DownloadSiteAttachments case={c} />}
              </IonList>
            </IonContent>
          </Popover>
          {/* </DialogHeader> */}
        </IonToolbar>
        <ContentWrapper>
          <StyledSubheader>Description</StyledSubheader>
          <Text>{site.description}</Text>
          <StyledSubheader>Clinical concern/differentials</StyledSubheader>
          <Text>{site.additionalInfo || 'N/A'}</Text>

          {isOpen && <AttachmentList images={imageUrls} userId={userId} />}
        </ContentWrapper>
      </IonContent>
    </IonModal>
  );
};

export default SiteDialog;
