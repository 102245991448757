import React from 'react';
import { useActor } from '@xstate/react';
import CaseForm, { FormValues } from '../case/CaseForm';
import SubmitCaseViewProps from './types/SubmitCaseViewProps';
import { useSubmitCaseContext } from '../../context/SubmitCaseContext';
import Button from '../common/Button';

interface EnterInfoProps extends SubmitCaseViewProps {
  onSubmit?: (values: FormValues) => void;
}

const EnterInfo: React.FC<EnterInfoProps> = ({ currentUser }) => {
  const { submitCaseService } = useSubmitCaseContext();
  const [state, send] = useActor(submitCaseService);

  return (
    <CaseForm
      case={state.context.caseInfo}
      initialValues={state.context.caseInfo}
      title="Submit case"
      onSubmit={async (values) => {
        send({ type: 'NEXT', caseInfo: values });
      }}
      renderButtons={({ disabled }) => (
        <>
          <Button
            fullWidth
            buttonType="raised"
            type="submit"
            disabled={disabled}
          >
            Payment
          </Button>
        </>
      )}
    />
  );
};

export default EnterInfo;
