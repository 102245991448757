import React from 'react';
import { Redirect, useHistory } from 'react-router';
import styled from 'styled-components';
import SitesList from '../../components/case/site/SitesList';
import Typography from '../../components/common/Typography';
import SimpleErrorDialog from '../../components/dialogs/SimpleErrorDialog';
import Page from '../../components/page/Page';
import Button from '../../components/common/Button';
import ReportForm from '../../components/report/ReportForm';
import { Case } from '../../services/buildfire/rdb/cases';
import { LOADING_STATE } from '../../util/loading';
import * as RDBReport from '../../services/buildfire/rdb/reports';
import * as A from 'fp-ts/Array';
import { useDialog } from '../../context/DialogContext';
import withCase from '../../util/withCase';
import useQueryParams from '../../hooks/useQueryParams';
import { withAuthRequired } from '../../services/buildfire/auth';
import { pipe } from 'fp-ts/lib/function';
import * as H from 'history';
import { useFirebase } from '../../services/firebase';
import { getDatabase } from 'firebase/database';

interface EnterReportProps {
  case: Case;
  siteIndex: number;
}

const Content = styled.div`
  padding: 0 16px;
  margin: 16px 0;
`;

const SubmitButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

function updateArray<T>(i: number, a: T, arr: T[]) {
  if (!arr[i]) {
    return arr.concat(a);
  }
  return A.unsafeUpdateAt(i, a, arr);
}

function getPreviousAction(currentSite: number, c: Case, history: H.History) {
  if (currentSite === 0) {
    return {
      text: 'Exit',
      action: () => history.go(-1),
    };
  }
  return {
    text: 'Previous site',
    action: () => history.go(-1),
  };
}

function getNextAction(currentSite: number, c: Case, history: H.History) {
  // Last site, go to report
  if (currentSite === c.sites.length - 1) {
    return {
      text: 'Review',
      action: () => history.push('.'),
    };
  }

  return {
    text: 'Next site',
    action: () => history.push(`create?siteIndex=${currentSite + 1}`),
  };
}

const EnterReport: React.FC<EnterReportProps> = ({
  case: c,
  siteIndex,
  ...props
}) => {
  const site = c.sites[siteIndex];
  const { currentUser, app } = useFirebase();
  const { openDialog } = useDialog();
  const history = useHistory();
  const { report, loadingStatus, updateReport } =
    RDBReport.useGetOrCreateReport(c.id, currentUser!);
  if (!site) {
    return (
      <SimpleErrorDialog redirectUrl="..">
        Invalid site. Click OK to go back to the report
      </SimpleErrorDialog>
    );
  }
  if (loadingStatus === LOADING_STATE.LOADING) {
    return null;
  }

  const prev = getPreviousAction(siteIndex, c, history);
  const next = getNextAction(siteIndex, c, history);

  return (
    <Page backgroundType="surface">
      <Content>
        <Typography variant="secondary" fontSize="32px" textAlign="center">
          Site #{siteIndex + 1}
        </Typography>
        {/* <Typography
          as="h3"
          variant="secondary"
          fontSize="20px"
          fontWeight="400"
          lineHeight="32px"
          margin="0"
        >
          {c.patient.first} {c.patient.last}
        </Typography> */}
      </Content>

      <SitesList case={c} sites={[site]} userId={c.uid} showTitle={false} />

      <Content>
        <ReportForm
          key={siteIndex}
          initialValues={report?.items?.[siteIndex]}
          onSubmit={async (values, { resetForm }) => {
            try {
              const database = getDatabase(app);
              await updateReport(database, {
                ...report!,
                items: updateArray(siteIndex, values, report!.items || []),
              });
              next.action();
              resetForm();
            } catch (e: any) {
              openDialog(
                e?.message ||
                  e ||
                  'There was a problem saving your data. Please try again.'
              );
            }
          }}
          submitButtons={({ isValid, dirty, resetForm, values, setValues }) => (
            <>
              <SubmitButtons>
                <Button
                  emphasis="low"
                  type="button"
                  onClick={async () => {
                    if (dirty) {
                      const database = getDatabase(app);
                      await updateReport(database, {
                        ...report!,
                        items: updateArray(
                          siteIndex,
                          values,
                          report!.items || []
                        ),
                      });
                    }

                    prev.action();
                    resetForm({ values });
                  }}
                >
                  {prev.text}
                </Button>
                <Button type="submit" disabled={!isValid}>
                  {next.text}
                </Button>
              </SubmitButtons>
            </>
          )}
        />
      </Content>
    </Page>
  );
};

type ConnectedEnterReportProps = {
  case: Case;
};

const ConnectedEnterReport: React.FC<ConnectedEnterReportProps> = ({
  case: c,
  ...props
}) => {
  const queryParams = useQueryParams();
  const siteIndex = queryParams.get('siteIndex');
  if (!siteIndex) {
    // TODO?
    return <Redirect to={`create?siteIndex=0`} />;
  }
  return <EnterReport siteIndex={Number(siteIndex)} case={c} {...props} />;
};

export default pipe(ConnectedEnterReport, withCase, withAuthRequired);
