import React from 'react';
import { App } from '@capacitor/app';
import { useHistory } from 'react-router-dom';
import { useBuildfireSettings } from '../../../context/SettingsContext';
import { PluginListenerHandle } from '@capacitor/core';

export function useBackButton() {
  const history = useHistory();
  React.useEffect(() => {
    let handle: PluginListenerHandle;
    (async function () {
      handle = await App.addListener('backButton', (e) => {
        if (e.canGoBack) {
          history.goBack();
        } else {
          App.exitApp();
        }
      });
    })();
    return () => {
      handle?.remove();
    };
  }, []);
}

function stripTrailingSlash(url: string = '') {
  return url.endsWith('/') ? url.slice(0, url.length - 1) : url;
}

export function useUrlOpen() {
  const history = useHistory();
  const { settings } = useBuildfireSettings();

  React.useEffect(() => {
    let handle: PluginListenerHandle;
    (async function () {
      handle = await App.addListener('appUrlOpen', (e) => {
        if (process.env.NODE_ENV !== 'production') {
          alert('appUrlOpen');
        }
        const host = stripTrailingSlash(settings?.host || '');
        const slug = e.url.split(`${host}/links`).pop();
        if (process.env.NODE_ENV !== 'production') {
          alert(`appUrlOpen: url=${e.url} host=${host} slug=${slug}`);
        }
        if (slug) {
          history.push(slug);
        }
      });
    })();

    return () => {
      handle?.remove();
    };
  }, [settings?.host]);
}
