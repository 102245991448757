import React from 'react';
import styled from 'styled-components';
import CaseAttachment from './CaseAttachment';
import FullscreenAttachments from './FullscreenAttachments';

interface AttachmentListProps {
  images: string[];
  userId: string;
}

const Attachment = styled(CaseAttachment)`
  margin-top: 16px;
  cursor: pointer;
`;

const AttachmentList: React.FC<AttachmentListProps> = ({ images, userId }) => {
  const [selectedImage, setSelectedImage] = React.useState<string>();

  return (
    <>
      {images.map((image) => (
        <div key={image}>
          <Attachment
            fileId={image}
            userId={userId}
            onClick={() => setSelectedImage(image)}
          />
        </div>
      ))}
      <FullscreenAttachments
        isOpen={!!selectedImage}
        images={images}
        initialImage={selectedImage}
        userId={userId}
        onDidDismiss={() => setSelectedImage(undefined)}
        onClose={() => setSelectedImage(undefined)}
      />
    </>
  );
};

export default AttachmentList;
