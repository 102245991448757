import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import HelperText from './HelperText';

interface SelectInputProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  helpText?: string;
  error?: string;
  fullWidth?: boolean;
  selectType?: 'filled' | 'outlined';
  handleChange?: (newValue: string) => void;
}

const Container = styled.div<any>`
  &.mdc-select {
    height: 56px;
    display: inline-flex;
    width: 100%;
  }
  &.mdc-select .mdc-floating-label {
    vertical-align: bottom;
    font-size: 14px !important;
    &.mdc-floating-label--float-above {
      top: 8px;
    }
  }
  &.mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
    color: var(--mdc-theme-text-secondary-on-light) !important;
  }
  &.mdc-select:not(.mdc-select--disabled).mdc-select--focused
    .mdc-floating-label {
    color: var(--mdc-theme-primary) !important;
    font-size: 14px !important;
  }

  &.mdc-select .mdc-select__selected-text {
    font-size: 14px;
    align-items: center;
  }

  .mdc-list-item {
    font-size: 14px;
  }

  ${(props) =>
    props.$error &&
    `
    &.mdc-select:not(.mdc-select--disabled) label.mdc-floating-label,
    &.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
      color: var(--mdc-theme-error) !important;
    }
    div[class^='mdc-notched-outline__'] {
      border-color: var(--mdc-theme-error) !important;
    }
  `}
`;

const Anchor = styled.div`
  width: 100%;
  display: flex !important;
`;

const Menu = styled.div`
  width: 100%;
`;

const Label = styled.label`
  text-transform: uppercase;
`;

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  labelProps,
  name,
  children,
  helpText,
  error,
  value,
  handleChange,
  fullWidth = true,
  selectType = 'outlined',
  ...props
}) => {
  const [selectedValue, setSelectedValue] = React.useState(-1);
  const ref = useRef<HTMLDivElement>(null);
  const mdcComponent = useRef<mdc.select.MDCSelect>();
  useEffect(() => {
    if (ref.current) {
      mdcComponent.current = new mdc.select.MDCSelect(ref.current);
      return () => mdcComponent.current!.destroy();
    }
  }, []);

  useEffect(() => {
    if (mdcComponent.current) {
      const _onChange = () => {
        setSelectedValue(mdcComponent.current!.selectedIndex);
        handleChange?.(mdcComponent.current!.value);
      };
      mdcComponent.current.listen('MDCSelect:change', _onChange);
      return () => {
        mdcComponent.current?.unlisten('MDCSelect:change', _onChange);
      };
    }
  }, [handleChange]);

  useEffect(() => {
    if (mdcComponent.current && typeof value === 'string') {
      const foundation = mdcComponent.current.foundation_;
      const values = foundation.menuItemValues_;
      const newIndex = values.indexOf(value);
      if (newIndex !== -1 && newIndex !== foundation.getSelectedIndex()) {
        foundation.setSelectedIndex(newIndex);
        foundation.handleChange();
      }
    }
  }, [value]);

  useEffect(() => mdcComponent.current?.layout(), [label]);

  const anchorContent =
    selectType === 'filled' ? (
      <>
        <Label as="span" className="mdc-floating-label" htmlFor={name}>
          {label}
        </Label>
      </>
    ) : (
      <div className="mdc-notched-outline">
        <div className="mdc-notched-outline__leading"></div>
        <div className="mdc-notched-outline__notch">
          <Label className="mdc-floating-label" htmlFor={name}>
            {label}
          </Label>
        </div>
        <div className="mdc-notched-outline__trailing"></div>
      </div>
    );
  return (
    <>
      <Container
        className={classnames({
          'mdc-select': true,
          'mdc-select--outlined': selectType === 'outlined',
          'mdc-select--no-label': !label,
        })}
        ref={ref}
        name={name}
        $error={!!error}
        {...props}
      >
        <Anchor className="mdc-select__anchor">
          <i className="mdc-select__dropdown-icon"></i>
          <div className="mdc-select__selected-text" id={name}></div>
          {anchorContent}
          {selectType === 'filled' && <div className="mdc-line-ripple"></div>}
        </Anchor>

        <Menu
          className="mdc-select__menu mdc-menu mdc-menu-surface"
          onChange={(e) => {
            console.log(e);
            props.onChange?.(e as any);
          }}
          {...(props as any)}
        >
          <ul className="mdc-list">
            <li className="mdc-list-item" data-value="">
              {' '}
            </li>
            {children}
          </ul>
        </Menu>
      </Container>
      {helpText && !error && <HelperText>{helpText}</HelperText>}
      {error && <HelperText type="error">{error}</HelperText>}
    </>
  );
};

interface OptionsProps extends React.LiHTMLAttributes<HTMLLIElement> {}

export const Option: React.FC<OptionsProps> = ({
  value,
  children,
  ...props
}) => {
  return (
    <li className="mdc-list-item" data-value={value} {...props}>
      {children}
    </li>
  );
};

export default SelectInput;
