import React from 'react';
import { useInterpret } from '@xstate/react';
import { InterpreterFrom } from 'xstate';
import editCaseMachine from '../stateMachines/editCase';
import { useBuildfireSettings } from './SettingsContext';
import { useFirebase } from '../services/firebase';
import { useParams } from 'react-router-dom';
import { Case } from '../services/buildfire/rdb/cases';
import { PaymentType } from '../stateMachines/submitCase/selectPaymentType';

interface EditCaseContext {
  editCaseService: InterpreterFrom<typeof editCaseMachine>;
}

const EditCaseContext = React.createContext<EditCaseContext>(undefined as any);

export const EditCaseContextProvider: React.FC<{ case: Case }> = ({
  case: c,
  children,
}) => {
  const { currentUser, app, userMetadata, parentAccount } = useFirebase();
  const { caseId } = useParams<{ caseId: string }>();
  const { settings } = useBuildfireSettings();
  const editCaseService = useInterpret(editCaseMachine, {
    context: {
      caseId,
      firebaseApp: app,
      stripePublishableKey: settings.stripe.publishableKey,
      userId: currentUser?.uid,
      email: currentUser?.email!,
      name: currentUser?.displayName!,
      userMetadata: userMetadata || undefined,
      parentAccount,
      case: c,
      paymentType: c.invoiceAccount
        ? PaymentType.INVOICE
        : PaymentType.CREDIT_CARD,
    },
  });
  (window as any).editCaseService = editCaseService;

  return (
    <EditCaseContext.Provider value={{ editCaseService }}>
      {children}
    </EditCaseContext.Provider>
  );
};

export function useEditCaseContext() {
  return React.useContext(EditCaseContext);
}

export default EditCaseContext;
