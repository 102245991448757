import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { User, AuthCredential } from 'firebase/auth';
import React from 'react';
import styled from 'styled-components';
import LoginForm from '../../views/login/LoginForm';

interface ReauthEmailModalProps {
  onClose: () => void;
  user: User;
  onReauth: (credential: AuthCredential) => Promise<void>;
}
const Content = styled.div`
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
`;

const ReauthEmailModal: React.FC<ReauthEmailModalProps> = ({
  onClose,
  user,
  onReauth,
}) => {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Reauthenticate to continue</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <Content>
          <LoginForm user={user} reauth onReauth={onReauth} />
        </Content>
      </IonContent>
    </>
  );
};

export default ReauthEmailModal;
