import React from 'react';
import styled from 'styled-components';
import Typography from '../../common/Typography';

const Container = styled.div`
  margin: 32px 0;
`;

const LineItemContainer = styled.div<{ $multiLine?: boolean; $pre?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  // needed to enable correct selection
  ${(props) => !props.$multiLine && 'display: flex;'}
  ${(props) => props.$pre && 'white-space: pre-wrap;'}

  > div:first-child,
  > span:first-child {
    opacity: 0.6;
    margin-right: 4px;
  }

  > div:last-child,
  > span:last-child {
    opacity: 0.9;
  }
`;

interface LineItemProps {
  name: string;
  description?: string | React.ReactNode;
  multiLine?: boolean;
  pre?: boolean;
}

export const LineItem: React.FC<LineItemProps> = ({
  name,
  description,
  multiLine,
  pre,
  ...props
}) => {
  const Item = multiLine ? 'span' : 'div';
  return (
    <LineItemContainer $multiLine={multiLine} $pre={pre} {...props}>
      <Item className="LineItem_name">{name}:</Item>
      <Item className="LineItem_description">{description}</Item>
    </LineItemContainer>
  );
};

interface InfoSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
}

const InfoSection: React.FC<InfoSectionProps> = ({
  title,
  children,
  ...props
}) => {
  return (
    <Container {...props}>
      <Typography variant="secondary" fontSize="16px" fontWeight="400">
        {title}
      </Typography>
      {children}
    </Container>
  );
};

export default InfoSection;
