import React from 'react';
import { IonButton, IonCol, IonRow } from '@ionic/react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Form from '../../../components/common/Form';
import { uppercaseFirstLetter } from '../../../util/string';
import { useFirebase } from '../../../services/firebase';
import { getAuth, signInWithPhoneNumber } from 'firebase/auth';
import {
  ButtonRow,
  CenteredRow,
  HiddenSubmitButton,
  Link,
} from '../common/styledComponents';
import Typography from '../../../components/common/Typography';
import styled from 'styled-components';
import { isNotNativeApp } from '../../../util/device';
import { PhoneAuthProvider as CustomPhoneAuthProvider } from '../../../capactior/plugins/firebase-auth';
import useRecaptcha from '../../../hooks/useRecaptcha';
import { findUserByPhoneNumber } from '../../../api/cloudFunctions';
import { getFunctions } from 'firebase/functions';
import * as O from 'fp-ts/Option';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import LabeledInput from '../../../mdc/LabeledInput';
interface EnterPhoneNumberProps {
  onSubmit: (verificationId: string) => void;
}

const RECAPTCHA_CONTAINER_ID = 'recaptcha-container';
let recaptchaVerifier: any;

const Message = styled.div`
  margin: 24px 0;
`;
const RecaptchaContainer = styled(IonRow)`
  margin: 16px 0;
  justify-content: center;
`;
const StyledPhoneInput = styled(PhoneInput)`
  width: 100%;
  padding-left: 4px;
`;
const ErrorContainer = styled.p`
  color: var(--ion-color-danger);
`;

const PHONE_REGEX =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const EnterPhoneNumberForm: React.FC<
  FormikProps<{ phone: string; recaptcha: boolean }>
> = ({
  values,
  touched,
  errors,
  submitForm,
  setFieldTouched,
  setFieldValue,
  isValid,
  isSubmitting,
  validateForm,
}) => {
  const recaptcha = useRecaptcha(RECAPTCHA_CONTAINER_ID, {
    callback: () => setFieldValue('recaptcha', true),
    expiredCallback: () => setFieldValue('recaptcha', false),
  });
  const { app } = useFirebase();

  React.useEffect(() => {
    recaptchaVerifier = recaptcha.current;
    validateForm();
    setFieldTouched('');
  }, [recaptcha]);

  return (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();
        submitForm();
      }}
    >
      <Message>
        <Typography lineHeight="1.5em" fontSize="18px" textAlign="center">
          Enter your phone number to receive a one-time sign in code
        </Typography>
      </Message>
      <IonRow>
        <IonCol>
          <StyledPhoneInput
            name="phone"
            color="secondary"
            type="tel"
            inputMode="tel"
            required
            autoFocus
            label="Phone number"
            inputComponent={LabeledInput}
            defaultCountry="US"
            value={values.phone}
            onChange={(phoneNumber) => {
              console.log('phone input change', phoneNumber);
              const value = phoneNumber || '';
              setFieldValue('phone', value);
              if (!touched.phone) {
                setFieldTouched('phone', true);
              }
            }}
          />
        </IonCol>
      </IonRow>

      <RecaptchaContainer>
        <div id={RECAPTCHA_CONTAINER_ID} />
      </RecaptchaContainer>

      {touched.phone && (
        <ErrorContainer>
          {uppercaseFirstLetter(errors.phone as string)}
        </ErrorContainer>
      )}

      <ButtonRow>
        <IonCol>
          <IonButton
            type="submit"
            disabled={!isValid || isSubmitting}
            expand="block"
          >
            Send Code
          </IonButton>
        </IonCol>
      </ButtonRow>

      {/* Need this for Ionic hack... so the form submits on enter */}
      <HiddenSubmitButton type="submit" />

      <CenteredRow>
        <Link routerLink="/login">Login with email address</Link>
      </CenteredRow>
    </Form>
  );
};

const EnterPhoneNumber: React.FC<EnterPhoneNumberProps> = ({ onSubmit }) => {
  const { app } = useFirebase();
  console.log({ isWeb: isNotNativeApp() });
  return (
    <Formik
      initialValues={{ phone: '', recaptcha: !isNotNativeApp() }}
      validateOnMount
      onSubmit={async (values, helpers) => {
        try {
          const phoneNumber = values.phone;
          const auth = getAuth(app);
          const fns = getFunctions(app);

          console.log('Checking if user exists...');
          const user = await findUserByPhoneNumber(fns, { phoneNumber });
          if (O.isNone(user)) {
            throw new Error('Cannot find user with that phone number');
          }

          console.log(`Sending code to ${phoneNumber}`);

          let verificationId: string;
          if (isNotNativeApp()) {
            const result = await signInWithPhoneNumber(
              auth,
              phoneNumber,
              recaptchaVerifier
            );
            verificationId = result.verificationId;
            console.log(verificationId);
          } else {
            console.log('CALLING CUSTOM AUTH PLUGIN');
            const result = await CustomPhoneAuthProvider.verify({
              phoneNumber,
            });
            verificationId = result.verificationId;
            console.log(verificationId);
          }

          onSubmit(verificationId);
        } catch (e) {
          console.error(e);
          const subMessage =
            (e as any).message || 'Please check phone number and try again.';
          helpers.setFieldError(
            'phone',
            `Error sending verification code. ${subMessage}`
          );
          recaptchaVerifier?.recaptcha.reset(recaptchaVerifier?.widgetId);
        }
      }}
      validationSchema={Yup.object({
        phone: Yup.string()
          .matches(PHONE_REGEX, 'Phone number is not valid')
          .required('Phone number is required'),
        recaptcha: Yup.boolean().oneOf([true]),
      })}
    >
      {(helpers) => <EnterPhoneNumberForm {...helpers} />}
    </Formik>
  );
};

export default EnterPhoneNumber;
