import React from 'react';

// Hack to close to make sure the modal closes if navigating away
function useIonicModalProps() {
  const ref = React.useRef<HTMLIonModalElement>(null);
  const onClose = () => ref.current?.dismiss?.();

  React.useEffect(() => {
    const controller = ref.current;
    return () => {
      if (controller?.isOpen) {
        controller?.dismiss?.();
      }
    };
  }, []);

  const onWillDismiss = React.useCallback(() => {
    if (ref.current) {
      ref.current.isOpen = false;
    }
  }, []);

  return { ref, onClose };
}

export default useIonicModalProps;
