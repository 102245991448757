import React from 'react';
import Typography, {
  TypographyProps,
} from '../../components/common/Typography';

interface TermsAndConditionsProps
  extends React.HTMLAttributes<HTMLDivElement> {}

const Header: React.FC<TypographyProps> = (props) => (
  <Typography as="h4" fontWeight="600" fontSize="18px" {...props} />
);
const P: React.FC<TypographyProps> = (props) => (
  <Typography as="p" margin="4px 0 16px 0" fontSize="14px" {...props} />
);
const Italic: React.FC<TypographyProps> = (props) => (
  <Typography as="span" fontStyle="italic" {...props} />
);
const Bold: React.FC<TypographyProps> = (props) => (
  <Typography as="span" fontWeight="600" {...props} />
);

const TermsAndConditions: React.FC<TermsAndConditionsProps> = (props) => {
  return (
    <div {...props}>
      <Header>Acknowledgement of Limitations</Header>
      <P>
        Digital/telecytology interpretations are made with the assumption that
        the images are representative of both the slides and the lesion.
        Submission of glass slides and biopsy with histologic examination is
        recommended following digital/telecytology as indicated during
        consultation. The submitting veterinarian ("the Client") agrees and
        acknowledges that Pocket Pathologist, LLC ("the Consultant") will be
        providing consultation services only, that the Client will be the only
        party under this Agreement to provide clinical services to their
        patients in connection with any of the services provided by Pocket
        Pathologist, LLC, and that the Client will be solely responsible for all
        patient care and treatment decisions and will be free to accept or
        reject any online second opinion consultation services and/or advice
        provided by Pocket Pathologist, LLC.
      </P>

      <Header>Digital Image Quality</Header>
      <P>
        The Client acknowledges it is their responsibility to submit quality
        images suitable for consultation. The Consultant is at liberty to
        request additional photos in order to complete the consultation and may
        decline consultation for any reason, including image quality. The Client
        acknowledges and accepts that charges will be incurred regardless of the
        diagnostic quality of the provided images and has 24 hours to submit
        additional photos at no extra charge as needed.
      </P>

      <Header>Educational/Promotional Sharing</Header>
      <P>
        Unless requested otherwise, the Client agrees to allow any submitted
        photos to be used by Dr. Kate Baker for educational or promotional
        purposes (including on social media) with the patient’s name
        removed/changed and owner information omitted for confidentiality
        purposes.{' '}
        <Italic>
          (Please email{' '}
          <a href="mailto:info@pocketpathologist.com">
            info@pocketpathologist.com
          </a>{' '}
          if you would like to be excluded from this, and your cases will not be
          shared).
        </Italic>
      </P>

      <Header>Payment of Services</Header>
      <P>
        The Client agrees to pay for services at the advertised, current price,
        at the time of service. The Client acknowledges that they are
        responsible for all incurred charges and that failure to pay will result
        in termination of services.
      </P>

      <Header>Termination of Services</Header>
      <P>
        The Client agrees to pay for services at the advertised, current price,
        at the time of service unless they have selected the invoicing option.
        Clients have the option to choose either of the following payment
        methods:
      </P>
      <P>
        <Bold>Credit Card Payment</Bold>: The Client can choose to pay for
        services at the time of case submission via credit card. Payment will be
        processed immediately, and no invoice will be generated.
      </P>
      <P>
        <Bold>Monthly Invoicing</Bold>: The Client can choose to have the case
        added to their monthly invoice. Invoices will be emailed to the email
        address on file. The Client acknowledges that invoices are due within 14
        days of receipt. Payment can be made electronically only; no cash or
        checks will be accepted. Failure to pay the invoice within 14 days of
        receipt will subject the Client to a late fee of 2% of the total invoice
        amount.
      </P>
    </div>
  );
};

export default TermsAndConditions;
