import React from 'react';
import styled from 'styled-components';
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
  useIonToast,
} from '@ionic/react';
import { UserMetadataWithId } from '../../services/firebase/user';
import Typography from '../common/Typography';
import { trash } from 'ionicons/icons';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import useQueryParams from '../../hooks/useQueryParams';
import Badge from '../common/Badge';
import { useDialog } from '../../context/DialogContext';
import { User } from 'firebase/auth';
import classNames from 'classnames';

interface UserListItemProps {
  user: Partial<UserMetadataWithId> | User;
  onRemove?: () => Promise<void> | void;
  selected?: boolean;
}

const Item = styled(IonItem)`
  --background-hover: #434180;
  &.selected {
    --background: #43418022;
  }
`;

const UserListItem: React.FC<UserListItemProps> = ({
  user,
  onRemove,
  selected,
}) => {
  const match = useRouteMatch();
  const queryParams = useQueryParams();
  const userId = user.uid;
  const userPath = generatePath(match.path, {
    userId,
  });
  const queryParamStr = queryParams.toString();
  const isPending = !user.displayName || (user as any).isPending;
  const { openDialogPromise } = useDialog();
  const [present] = useIonToast();
  const history = useHistory();
  return (
    <Item
      // $isPending={isPending}
      onClick={() => {
        history.replace(
          `${userPath}${queryParamStr ? `?${queryParamStr}` : ''}`
        );
      }}
      className={classNames({
        selected,
      })}
    >
      <IonLabel>
        {user.displayName && (
          <Typography as="h2" fontSize="16px" lineHeight="1.2em">
            {user.displayName}
          </Typography>
        )}
        <Typography as="p" opacity={isPending ? 0.5 : 1}>
          {user.email}
        </Typography>
      </IonLabel>
      {isPending && (
        <IonNote slot="end">
          <Badge
            fontColor="var(--ion-color-light)"
            backgroundColor="var(--ion-color-medium)"
          >
            PENDING
          </Badge>
        </IonNote>
      )}
      <IonButtons slot="end">
        {onRemove && (
          <IonButton
            shape="round"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              openDialogPromise(
                `Are you sure you want to remove this user from your account?
                
                Your account may still be billed for any open cases from this user.
                `
              ).then(async () => {
                await onRemove();
                present(
                  `${
                    user.email || 'User'
                  } successfully removed from your account`,
                  5000
                );
              });
            }}
          >
            <IonIcon icon={trash} color="danger" />
          </IonButton>
        )}
      </IonButtons>
    </Item>
  );
};

export default UserListItem;
