import { Database, onValue, push, ref, remove } from 'firebase/database';
import * as O from 'fp-ts/lib/Option';
import { Alert } from '../../../context/NotifContext';

export const getAlert = (database: Database): Promise<O.Option<Alert>> => {
  const databaseRef = ref(database, 'alerts');
  return new Promise((resolve, reject) => {
    onValue(
      databaseRef,
      (snap) => {
        if (!snap.exists()) {
          return resolve(O.none);
        }
        const alertMap = snap.val() as StringMap<Omit<Alert, 'id'>>;
        const alerts = Object.keys(alertMap).map((key) => ({
          ...alertMap[key],
          id: key,
        }));
        const firstAlert = alerts[0];
        resolve(O.of(firstAlert));
      },
      reject,
      { onlyOnce: true }
    );
  });
};

export const clearAlert = (
  database: Database,
  alertId: string
): Promise<void> => {
  const databaseRef = ref(database, `alerts/${alertId}`);
  return remove(databaseRef);
};

export const pushMessage = (
  database: Database,
  alert: Omit<Alert, 'id'>
): Promise<Alert> => {
  const databaseRef = ref(database, 'alerts');
  return push(databaseRef, alert).then((snap) => {
    return {
      ...alert,
      id: snap.key!,
    };
  });
};
