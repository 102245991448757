import React from 'react';
import MarketingPage from '../components/page/MarketingPage';
import pattern from '../images/patterns/pattern-15.png';
import BaseElevatedContainer from '../components/common/ElevatedContainer';
import Typography, { TypographyProps } from '../components/common/Typography';
import Button, { ButtonProps } from '../components/common/Button';
import TrackView from '../components/analytics/TrackView';
import { VIEW_EVENTS } from '../services/buildfire/analytics/events';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import Toolbar from '../components/common/Toolbar';
import styled from 'styled-components';
import AlertBanner from '../components/common/AlertBanner';
import Hr from '../components/common/Hr';
import course1 from '../images/course1.jpg';
import course2 from '../images/course2.jpg';

interface EducationProps {}

const Text: React.FC<TypographyProps> = (props) => (
  <Typography
    as="h5"
    fontSize="15px"
    lineHeight="1.4em"
    margin="0 0 16px 0"
    {...props}
  />
);

const Bold: React.FC<TypographyProps> = (props) => (
  <Typography as="span" fontWeight="500" {...props} />
);
const Italic: React.FC<TypographyProps> = (props) => (
  <Typography
    as="p"
    fontStyle="italic"
    fontSize="12px"
    margin="8px 0"
    {...props}
  />
);
const BoldHr = styled.hr`
  margin: 16px 0;
  background: var(--ion-color-medium);
`;
const CourseImage = styled.img`
  // margin-top: 8px;
`;
const CourseContainer = styled.div`
  margin: 0 0 16px 0;
`;

const LinkButtonContainer = styled.div`
  margin: 0 0 48px 0;
`;
const LinkButton: React.FC<ButtonProps & { href: string }> = ({
  href,
  ...props
}) => (
  <LinkButtonContainer>
    <Button
      as="a"
      fullWidth
      {...{
        href,
      }}
      {...props}
    />
  </LinkButtonContainer>
);

const ElevatedContainer = styled(BaseElevatedContainer)`
  padding: 24px 16px;
`;

const Course: React.FC<{
  title: string;
  text: string;
  italic: string;
  link: string;
  img: string;
}> = ({ title, text, italic, link, img }) => {
  return (
    <CourseContainer>
      <CourseImage src={img} />
      <Typography
        as="h2"
        fontWeight="bold"
        fontSize="14px"
        margin="8px 0"
        textAlign="center"
      >
        {title}
      </Typography>
      <Typography as="h3" fontSize="14px" margin="8px 0" textAlign="center">
        5 RACE-Approved Hours
      </Typography>
      <Text>{text}</Text>
      <Italic>{italic}</Italic>

      <Button as="a" fullWidth {...{ href: link, target: '_blank' }}>
        Take the course
      </Button>
    </CourseContainer>
  );
};

const Education: React.FC<EducationProps> = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <Toolbar showBackButton showMenu />
        <AlertBanner />
      </IonHeader>
      <IonContent>
        <TrackView name={VIEW_EVENTS.EDUCATION}>
          <MarketingPage pageTitle="Education" pageHeadingBackground={pattern}>
            <ElevatedContainer maxWidth="600px">
              <Typography
                variant="secondary"
                fontSize="28px"
                textAlign="center"
                fontWeight="400"
                as="h4"
                margin="0 0 16px 0"
              >
                Available Courses
              </Typography>

              <Course
                title="MASTERING CYTOLOGY: SKIN AND SUBCUTIS"
                text="Boost your confidence and skills in interpretation of
  fine-needle aspirate (FNA) cytology from masses/lesions in the
  skin and subcutis."
                link="https://www.pocketpathologist.com/resource_redirect/landing_pages/2149559758"
                italic="*RACE Program #20-844340; Non-interactive, Distance*"
                img={course1}
              />

              <BoldHr />

              <Course
                title="MASTERING HEMATOLOGY: PRACTICAL APPROACH TO CBC AND BLOOD SMEAR INTERPRETATION"
                text="
  Strengthen your identification skills and use that information
  in conjunction with the CBC to interpret what abnormalities mean
  for the patient."
                link="https://www.pocketpathologist.com/resource_redirect/landing_pages/2149559943"
                italic="*RACE Program #20-960904; Non-interactive, Distance*"
                img={course2}
              />
            </ElevatedContainer>

            <ElevatedContainer maxWidth="600px">
              <Text>
                Review this article on telecytology in veterinary practice! In
                this study, they found that telediagnosis provides{' '}
                <Bold>acceptable efficiency</Bold> and a{' '}
                <Bold>fast turnaround time.</Bold>
              </Text>
              <LinkButton href="https://onlinelibrary.wiley.com/doi/epdf/10.1111/j.1939-165X.2006.tb00135.x">
                VIEW ARTICLE
              </LinkButton>

              <Hr />

              <Text>
                Take a look at this article on telecytology using smartphone
                image capture! This study showed that{' '}
                <Bold>
                  easy, fast, and high-quality image capturing and transfer if
                  possible from cytology slides using smartphones.
                </Bold>
              </Text>
              <LinkButton href="https://pubmed.ncbi.nlm.nih.gov/29115040/">
                VIEW ARTICLE
              </LinkButton>

              <Hr />

              <Text>
                Check out this free full-length review article on telecytology
                in the clinic setting. It found that telecytology is suitable
                for clinical use, and it{' '}
                <Bold>
                  increases time efficiency without loss of quality and improves
                  patient care.
                </Bold>
              </Text>
              <LinkButton href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5846691/pdf/nihms919817.pdf">
                VIEW ARTICLE
              </LinkButton>
            </ElevatedContainer>
          </MarketingPage>
        </TrackView>
      </IonContent>
    </IonPage>
  );
};

export default Education;
