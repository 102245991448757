import React from 'react';
import styled from 'styled-components';
import { ProgressBarProps } from '../../mdc/ProgressBar';
import { useLoading } from '../../services/buildfire/loading/LoadingContext';
import FixedProgressBar from './FixedProgressBar';

interface FullscreenLoaderProps extends ProgressBarProps {}

const Container = styled.div`
  position: relative;
  min-height: 100%;
  height: 100%;
`;

const FullscreenLoader: React.FC<FullscreenLoaderProps> = ({
  children,
  ...props
}) => {
  return (
    <>
      <FixedProgressBar indeterminate {...props} />
      {children}
    </>
  );
};

export const GlobalLoader: React.FC<Omit<ProgressBarProps, 'show'>> = (
  props
) => {
  const { isLoading } = useLoading();
  return <FullscreenLoader show={isLoading} {...props} />;
};

export default FullscreenLoader;
