import { IonIcon, IonItem } from '@ionic/react';
import React from 'react';
import { logInOutline } from 'ionicons/icons';

interface LoginItemProps {}

const LoginItem: React.FC<LoginItemProps> = () => {
  return (
    <IonItem routerLink="/login">
      <IonIcon icon={logInOutline} slot="start" />
      Log In
    </IonItem>
  );
};

export default LoginItem;
