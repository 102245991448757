import { IonRouterLink, IonRow } from '@ionic/react';
import styled from 'styled-components';

export const ButtonRow = styled(IonRow)`
  margin-top: 8px;
`;
export const CenteredRow = styled(IonRow)`
  justify-content: center;
`;
export const Link = styled(IonRouterLink)`
  margin: 16px 0;
`;
export const HiddenSubmitButton = styled.button`
  position: absolute;
  display: none;
`;

export const Message = styled.div`
  padding: 16px;
  // color: white;
  color: #163349;
  background-color: #aecae5;
  border-radius: 4px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  ion-icon {
    width: 42px;
    height: 42px;
    margin-right: 16px;
  }
`;
