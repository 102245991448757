import React from 'react';
import { SelectPaymentType as SelectPaymentTypeBase } from '../submitCase/components/SelectPaymentType';
import { useEditCaseContext } from '../../context/EditCaseContext';
import { useActor } from '@xstate/react';
import { User } from 'firebase/auth';

interface SelectPaymentTypeProps {
  currentUser: User;
}

const SelectPaymentType: React.FC<SelectPaymentTypeProps> = (props) => {
  const { editCaseService } = useEditCaseContext();
  const [state, send] = useActor(editCaseService);
  const isLoading = state.matches({ selectPaymentType: 'load' });
  const userCase = state.context.case!;
  // If this case is already invoiced, let user keep invoice status
  const hasPastDueInvoice = userCase.invoiceAccount
    ? false
    : state.context.hasPastDueInvoice;
  return (
    <SelectPaymentTypeBase
      {...props}
      value={state.context.paymentType}
      onChange={(value) => {
        send({ type: 'SET_PAYMENT_TYPE', paymentType: value });
      }}
      onNext={() => send({ type: 'NEXT' })}
      onBack={() => send({ type: 'BACK' })}
      isLoading={isLoading}
      hasPastDueInvoice={hasPastDueInvoice}
      invoicingDisabled={false} // TODO
    />
  );
};

export default SelectPaymentType;
