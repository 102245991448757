import { User } from 'firebase/auth';
import React from 'react';
import { ParentUser } from '../../../api/cloudFunctions';
import {
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  RadioGroupChangeEventDetail,
} from '@ionic/react';
import { IonRadioGroupCustomEvent } from '@ionic/core/dist/types/components';
import Button from '../../common/Button';
import styled from 'styled-components';
import Typography from '../../common/Typography';

interface InvoiceSelectionProps {
  currentUser: User;
  parentAccount: ParentUser;
  selectedInvoiceAccountUid: string;
  onInvoiceAccountChange: (
    e: IonRadioGroupCustomEvent<RadioGroupChangeEventDetail<any>>
  ) => void;
  onNext: () => void;
  onGoBack: () => void;
}
const Container = styled.div``;

const ButtonContainer = styled.div`
  margin-top: 64px;
  padding: 0 8px;
`;

const InvoiceSelection: React.FC<InvoiceSelectionProps> = ({
  currentUser,
  parentAccount,
  selectedInvoiceAccountUid,
  onInvoiceAccountChange,
  onNext,
  onGoBack,
}) => {
  return (
    <Container>
      <Typography as="h3" fontSize="20px" variant="secondary" margin="16px 8px">
        Select invoice account
      </Typography>

      <Typography fontSize="12px" margin="16px 8px">
        You can either invoice this case to your hospital or to your own
        account.
      </Typography>

      <IonList>
        <IonRadioGroup
          value={selectedInvoiceAccountUid}
          onIonChange={onInvoiceAccountChange}
        >
          <IonItem>
            <IonRadio value={parentAccount.uid} justify="space-between">
              {parentAccount.displayName} ({parentAccount.email})
            </IonRadio>
          </IonItem>
          <IonItem>
            <IonRadio value={currentUser.uid} justify="space-between">
              {currentUser.displayName} (you)
            </IonRadio>
          </IonItem>
        </IonRadioGroup>
      </IonList>

      <ButtonContainer>
        <Button fullWidth type="button" onClick={onNext}>
          Continue
        </Button>
        <Button fullWidth emphasis="low" type="button" onClick={onGoBack}>
          GO BACK
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default InvoiceSelection;
