import React from 'react';
import Checkbox, { CheckboxProps } from '../../mdc/Checkbox';
import Typography from '../../components/common/Typography';
import styled from 'styled-components';
import HospitalTermsAndConditionsModal from './HospitalTermsAndConditionsModal';

interface HospitalTermsAndConditionsCheckboxProps
  extends Omit<CheckboxProps, 'id'> {}

const TermsAndConditionsButton = styled.button`
  border: none;
  background: none;
  color: var(--mdc-theme-secondary);
  opacity: 0.8;
  text-decoration: underline;
  margin-left: 6px;
  font-size: 14px;

  &:hover {
    opacity: 1;
  }
`;

const HospitalTermsAndConditionsCheckbox: React.FC<
  HospitalTermsAndConditionsCheckboxProps
> = (props) => {
  return (
    <>
      <Checkbox
        id="hospital-terms-and-conditions"
        label={
          <Typography as="span" fontSize="14px">
            I accept the
            <TermsAndConditionsButton
              id="terms-btn"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              Terms and Conditions
            </TermsAndConditionsButton>
          </Typography>
        }
        {...props}
      />
      <HospitalTermsAndConditionsModal trigger="terms-btn" />
    </>
  );
};

export default HospitalTermsAndConditionsCheckbox;
