import React from 'react';
import classnames from 'classnames';
import {
  MDCDialog,
  MDCDialogFoundation,
  MDCDialogAdapter,
  cssClasses,
  numbers,
  strings,
} from '@material/dialog';
import * as util from '@material/dialog/util';
import { MDCDialogCloseEventDetail } from '@material/dialog/types';
import { closest, matches } from '@material/dom/ponyfill';
import styled from 'styled-components';
import Button, { ButtonProps } from './Button';

class CustomFoundation extends MDCDialogFoundation {
  private isOpen_: boolean = false;
  private animationFrame_: number = -1;
  private animationTimer_: any = -1;

  close(action = '') {
    if (!this.isOpen_) {
      // Avoid redundant close calls (and events), e.g. from keydown on elements that inherently emit click
      return;
    }

    this.isOpen_ = false;
    const r = this.adapter_.notifyClosing(action);
    this.adapter_.addClass(cssClasses.CLOSING);
    this.adapter_.removeClass(cssClasses.OPEN);
    this.adapter_.removeBodyClass(cssClasses.SCROLL_LOCK);

    cancelAnimationFrame(this.animationFrame_);
    this.animationFrame_ = 0;

    clearTimeout(this.animationTimer_);
    this.animationTimer_ = setTimeout(() => {
      this.adapter_.releaseFocus();
      (this as any).handleAnimationTimerEnd_();
      this.adapter_.notifyClosed(action);
    }, numbers.DIALOG_ANIMATION_CLOSE_TIME_MS);
  }
}
class CustomDialog extends MDCDialog {
  // private buttonRipples_!: MDCRipple[]; // assigned in initialize()
  // private buttons_!: HTMLElement[]; // assigned in initialize()
  // private container_!: HTMLElement; // assigned in initialize()
  // private content_!: HTMLElement | null; // assigned in initialize()
  // private defaultButton_!: HTMLElement | null; // assigned in initialize()
  // private focusTrap_!: FocusTrap; // assigned in initialSyncWithDOM()
  // private focusTrapFactory_?: util.MDCDialogFocusTrapFactory; // assigned in initialize()
  // private handleClick_!: SpecificEventListener<'click'>; // assigned in initialSyncWithDOM()
  // private handleKeydown_!: SpecificEventListener<'keydown'>; // assigned in initialSyncWithDOM()
  // private handleDocumentKeydown_!: SpecificEventListener<'keydown'>; // assigned in initialSyncWithDOM()
  // private handleLayout_!: EventListener; // assigned in initialSyncWithDOM()
  // private handleOpening_!: EventListener; // assigned in initialSyncWithDOM()
  // private handleClosing_!: () => void; // assigned in initialSyncWithDOM()

  getDefaultFoundation = () => {
    const adapter: MDCDialogAdapter = {
      addBodyClass: (className) => document.body.classList.add(className),
      addClass: (className) => this.root_.classList.add(className),
      // @ts-ignore
      areButtonsStacked: () => util.areTopsMisaligned(this.buttons_),
      clickDefaultButton: () =>
        // @ts-ignore
        this.defaultButton_ && this.defaultButton_.click(),
      eventTargetMatches: (target, selector) =>
        target ? matches(target as Element, selector) : false,
      getActionFromEvent: (evt: Event) => {
        if (!evt.target) {
          return '';
        }
        const element = closest(
          evt.target as Element,
          `[${strings.ACTION_ATTRIBUTE}]`
        );
        return element && element.getAttribute(strings.ACTION_ATTRIBUTE);
      },
      getInitialFocusEl: () => this.getInitialFocusEl_(),
      hasClass: (className) => this.root_.classList.contains(className),
      // @ts-ignore
      isContentScrollable: () => util.isScrollable(this.content_),
      notifyClosed: (action) =>
        this.emit<MDCDialogCloseEventDetail>(
          strings.CLOSED_EVENT,
          action ? { action } : {}
        ),
      notifyClosing: (action) =>
        this.emit<MDCDialogCloseEventDetail>(
          strings.CLOSING_EVENT,
          action ? { action } : {}
        ),
      notifyOpened: () => this.emit(strings.OPENED_EVENT, {}),
      notifyOpening: () => this.emit(strings.OPENING_EVENT, {}),
      // @ts-ignore
      releaseFocus: () => this.focusTrap_.deactivate(),
      removeBodyClass: (className) => document.body.classList.remove(className),
      removeClass: (className) => this.root_.classList.remove(className),
      reverseButtons: () => {
        // @ts-ignore
        this.buttons_.reverse();
        // @ts-ignore
        this.buttons_.forEach((button) => {
          button.parentElement!.appendChild(button);
        });
      },
      // @ts-ignore
      trapFocus: () => this.focusTrap_.activate(),
    };
    return new CustomFoundation(adapter);
  };

  private getInitialFocusEl_(): HTMLElement | null {
    return document.querySelector(`[${strings.INITIAL_FOCUS_ATTRIBUTE}]`);
  }
}

const Container = styled.div`
  &.mdc-dialog--fullscreen {
    width: 100%;

    > .mdc-dialog__container {
      width: 100%;
    }
  }
`;

const Surface = styled.div`
  &.mdc-dialog__surface--fullscreen {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
  }
`;

const ExitButton = styled.button`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;
  font-family: 'Material Icons' !important;
`;

const Header = styled.h2<{ isFullscreen: boolean }>`
  // ${(props) => (props.isFullscreen ? `padding-left: 64px;` : '')}
  &.mdc-dialog__title {
    color: var(--mdc-theme-secondary) !important;
  }
  font-size: 32px;
  line-height: 1em;
  text-align: center;
`;

export interface DialogProps extends React.HTMLAttributes<HTMLDivElement> {
  dialogId: string;
  title: string;
  isOpen: boolean;
  type?: 'fullscreen';
  showCloseButton?: boolean;
  actions?: React.ReactNode;
  onOpen?: (dialog: MDCDialog) => void;
  onOpening?: () => void;
  onClose?: (action?: string) => void;
  onClosing?: () => Promise<void>;
  DialogActionButtonComponent?: React.ComponentType<DialogActionButtonProps>;
}

const Dialog: React.FC<DialogProps> = ({
  dialogId,
  title,
  type,
  isOpen,
  showCloseButton = true,
  actions,
  children,
  onOpen,
  onOpening,
  onClose,
  onClosing,
  DialogActionButtonComponent = DialogActionButton,
  ...props
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const mdcComponent = React.useRef<MDCDialog>();
  React.useEffect(() => {
    if (ref.current) {
      mdcComponent.current = new CustomDialog(ref.current);
      // mdcComponent.current.initialize();
      return () => mdcComponent.current!.destroy();
    }
  }, []);

  React.useEffect(() => {
    const handleOpen = () => {
      onOpen?.(mdcComponent.current!);
    };
    const handleOpening = () => {
      onOpening?.();
    };
    const handleClose = (e: Event) => {
      onClose?.((e as any).detail.action);
    };
    const handleClosing = () => {
      onClosing?.();
    };
    mdcComponent.current?.listen('MDCDialog:opened', handleOpen);
    mdcComponent.current?.listen('MDCDialog:opening', handleOpening);
    mdcComponent.current?.listen('MDCDialog:closed', handleClose);
    mdcComponent.current?.listen('MDCDialog:closing', handleClosing);

    return () => {
      mdcComponent.current?.unlisten('MDCDialog:opened', handleOpen);
      mdcComponent.current?.unlisten('MDCDialog:opening', handleOpening);
      mdcComponent.current?.unlisten('MDCDialog:closed', handleClose);
      mdcComponent.current?.unlisten('MDCDialog:closing', handleClosing);
    };
  }, [onOpen, onOpening, onClose, onClosing]);

  React.useEffect(() => {
    if (mdcComponent.current) {
      if (isOpen) {
        mdcComponent.current.open();
      } else {
        mdcComponent.current.close();
      }
    }
  }, [isOpen, mdcComponent.current]);

  return (
    <Container
      className={classnames({
        ['mdc-dialog']: true,
        // ['mdc-dialog--open']: isOpen,
        ['mdc-dialog--fullscreen']: type === 'fullscreen',
      })}
      ref={ref}
      aria-modal="true"
      aria-labelledby={`${dialogId}-title`}
      aria-describedby={`${dialogId}-content`}
      {...props}
    >
      <div className="mdc-dialog__container">
        <Surface
          className={classnames({
            ['mdc-dialog__surface']: true,
            ['mdc-dialog__surface--fullscreen']: type === 'fullscreen',
          })}
        >
          <Header
            className="mdc-dialog__title"
            id={`${dialogId}-title`}
            isFullscreen={type === 'fullscreen'}
          >
            {title}
          </Header>
          <div className="mdc-dialog__content" id={`${dialogId}-content`}>
            {children}
          </div>
          {actions && (
            <footer className="mdc-dialog__actions">{actions}</footer>
          )}
          {type === 'fullscreen' && (
            <ExitButton
              className="mdc-icon-button material-icons mdc-dialog__close"
              data-mdc-dialog-action="close"
              type="button"
            >
              close
            </ExitButton>
          )}
        </Surface>
      </div>
      <div className="mdc-dialog__scrim"></div>
    </Container>
  );
};

export interface DialogActionButtonProps extends ButtonProps {
  default?: boolean;
  action: 'accept' | 'close';
  ButtonComponent?: React.ComponentType<ButtonProps>;
}

export const DialogActionButton: React.FC<DialogActionButtonProps> = ({
  default: def,
  action,
  ButtonComponent = Button,
  ...props
}) => {
  return (
    <ButtonComponent
      type="button"
      className="mdc-dialog__button"
      data-mdc-dialog-action={action}
      data-mdc-dialog-button-default={!!def}
      {...props}
    />
  );
};

export default Dialog;
