import React from 'react';
import CaseContext from './CaseContext';
import { LOADING_STATE } from '../../../util/loading';
import { User } from 'firebase/auth';
import {
  Database,
  DataSnapshot,
  getDatabase,
  onChildChanged,
  onValue,
  ref,
  set,
  update as firebaseUpdate,
} from 'firebase/database';
import { useFirebase } from '../../firebase';

export interface ReportItem {
  interpretation: string;
  comments: string;
  nextSteps: string;
}

export interface Report {
  caseId: string;
  items: ReportItem[];
  pathologist: {
    name: string;
    email: string;
    uid: string;
  };
  fileName?: string;
}

export const addReport = (
  database: Database,
  report: Omit<Report, 'id'>,
  caseId: string
): Promise<Report> => {
  return new Promise<Report>((resolve, reject) => {
    set(ref(database, `report/${caseId}`), report)
      .then((snap) => resolve(report))
      .catch(reject);
  });
};

export const update = (database: Database, report: Report): Promise<Report> => {
  return new Promise<Report>(async (resolve, reject) => {
    const reportRef = ref(database, `report/${report.caseId}`);
    await firebaseUpdate(reportRef, report);
    resolve(report);
  });
};

export const get = (
  database: Database,
  caseId: string
): Promise<Report | undefined> => {
  return new Promise<Report | undefined>((resolve, reject) => {
    const reportRef = ref(database, `report/${caseId}`);
    onValue(
      reportRef,
      (snapshot) => {
        if (!snapshot.exists()) {
          resolve(undefined);
        } else {
          resolve(toReport(caseId)(snapshot));
        }
      },
      (err) => {
        console.error(err);
        reject(err);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

const toReport =
  (caseId: string) =>
  (snapshot: DataSnapshot): Report => {
    const val = snapshot.val();
    return {
      ...val,
      items: (val.items || []).map((item: any) => ({
        interpretation:
          item.interpretation || item.microscopicInterpretation || '',
        comments: item.comments || item.microscopicDescription || '',
        nextSteps: item.nextSteps || '',
      })),
      caseId,
      id: snapshot.key,
    };
  };

export function useReport(caseId: string) {
  const { app } = useFirebase();
  const [state, setState] = React.useState<{
    report?: Report;
    loadingStatus: LOADING_STATE;
    err?: string;
  }>({
    loadingStatus: LOADING_STATE.LOADING,
  });

  React.useEffect(() => {
    const database = getDatabase(app);
    get(database, caseId)
      .then((report) => {
        if (!report) {
          setState({
            loadingStatus: LOADING_STATE.ERROR,
            err: 'Could not find report',
          });
        } else {
          setState({
            report,
            loadingStatus: LOADING_STATE.LOADED,
          });
        }
      })
      .catch((e) => {
        setState({ err: e, loadingStatus: LOADING_STATE.ERROR });
      });
  }, [app, caseId]);

  React.useEffect(() => {
    const database = getDatabase(app);
    const reportRef = ref(database, 'report');
    const unsub = onChildChanged(reportRef, (snap) => {
      console.log('report child_changed');
      console.log(snap.val());
    });
    return () => unsub();
  }, [app, caseId]);

  const updateReport = async (database: Database, newReport: Report) => {
    await update(database, newReport);
    setState({ ...state, report: newReport });
  };

  return {
    ...state,
    updateReport,
  };
}

export function useGetOrCreateReport(caseId: string, pathologistUser: User) {
  const { reports, setReports } = React.useContext(CaseContext);
  const [report, setReport] = React.useState<Report | undefined>(
    reports[caseId]
  );
  const [loadingStatus, setLoadingStatus] = React.useState(
    report ? LOADING_STATE.LOADED : LOADING_STATE.LOADING
  );
  const [err, setErr] = React.useState<string>();
  const { app } = useFirebase();

  React.useEffect(() => {
    if (report && report.caseId === caseId) {
      return;
    }
    const database = getDatabase(app);
    get(database, caseId)
      .then((report) => {
        return (
          report ||
          addReport(
            database,
            {
              items: [],
              caseId,
              pathologist: {
                email: pathologistUser.email!,
                // name: `${pathologistUser.firstName} ${pathologistUser.lastName}`,
                name: pathologistUser.displayName!,
                uid: pathologistUser.uid,
              },
            },
            caseId
          )
        );
      })
      .then((report) => {
        setReport(report);
        setErr(undefined);
        setLoadingStatus(LOADING_STATE.LOADED);
      })
      .catch((e) => {
        setErr(e);
        setLoadingStatus(LOADING_STATE.ERROR);
      });
  }, [app, caseId]);

  const updateReport = async (database: Database, newReport: Report) => {
    await update(database, newReport);
    setReports((reports) => {
      const updated: StringMap<Report> = {};
      for (const r in reports) {
        updated[reports[r].caseId] =
          reports[r].caseId === newReport.caseId ? newReport : reports[r];
      }
      return updated;
    });
    setReport(newReport);
  };

  return { report, loadingStatus, err, updateReport };
}
