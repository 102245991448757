import React from 'react';
import { LOADING_STATE } from '../../util/loading';
import ProgressBar from './ProgressBar';
import styled from 'styled-components';
import Typography from './Typography';
import Alert from './Alert';

interface LoadingScreenProps {
  LoadingComponent?: React.ComponentType;
  loadingStatus: LOADING_STATE;
  error?: any;
  errorContent?: React.ReactNode;
}

const Container = styled.div`
  height: 100%;
`;

const ErrorContainer = styled.div`
  margin: 32px 16px;
  display: flex;
  flex-direction: column;
`;

const ErrorAlert = styled(Alert)`
  margin-bottom: 32px;
`;

const LoadingScreen: React.FC<LoadingScreenProps> = ({
  loadingStatus,
  error,
  LoadingComponent = ProgressBar,
  children,
  errorContent,
}) => {
  switch (loadingStatus) {
    case LOADING_STATE.LOADING: {
      return <LoadingComponent indeterminate />;
    }
    case LOADING_STATE.ERROR: {
      const err = error instanceof Error ? error.message : error;
      return (
        <ErrorContainer>
          {err && <ErrorAlert variant="danger">{err}</ErrorAlert>}
          {errorContent}
        </ErrorContainer>
      );
    }
    case LOADING_STATE.LOADED: {
      return <Container>{children}</Container>;
    }
    default:
      return null;
  }
};

export default LoadingScreen;
