import React from 'react';
import { ThemeProvider } from 'styled-components';

interface IBuildfireThemeContext {
  theme: any;
}

const BuildfireThemeContext = React.createContext<IBuildfireThemeContext>(
  undefined as any
);

interface BuildfireThemeProviderProps {}

const BuildfireThemeProvider: React.FC<BuildfireThemeProviderProps> = ({
  children,
}) => {
  const [theme, setTheme] = React.useState<any>();

  React.useEffect(() => {
    // buildfire.appearance.getAppTheme((err, theme) => {
    //   if (err) {
    //     console.error(err);
    //   } else {
    //     setTheme(theme);
    //   }
    // });
  }, []);

  return (
    <BuildfireThemeContext.Provider value={{ theme }}>
      {/* {theme && <ThemeProvider theme={theme}>{children}</ThemeProvider>} */}
      <ThemeProvider
        theme={{
          colors: {
            titleBar: '',
            titleBarTextAndIcons: '',
          },
        }}
      >
        {children}
      </ThemeProvider>
    </BuildfireThemeContext.Provider>
  );
};

function useTheme() {
  return React.useContext(BuildfireThemeContext).theme;
}

export { BuildfireThemeProvider, BuildfireThemeContext, useTheme };
