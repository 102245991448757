import React from 'react';
import styled from 'styled-components';
import { getExtension } from '../../../util/file';

export interface CaseVideoProps
  extends React.MediaHTMLAttributes<HTMLVideoElement> {
  file: string;
  contentType?: string | null;
  showPlayOverlay?: boolean;
}

function getTypeForFile(fileName: string) {
  const ext = getExtension(fileName);
  switch (ext) {
    case '.mp4':
      return 'video/mp4';
    case '.ogg':
      return 'video/ogg';
    case '.webm':
      return 'video/webm';
    default:
      return '';
  }
}

const Wrapper = styled.div`
  position: relative;
`;

const Video = styled.video`
  max-width: 100%;
`;

const PlayButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: none;
`;

const CaseVideo: React.FC<CaseVideoProps> = ({
  file,
  contentType,
  controls = true,
  showPlayOverlay,
  ...props
}) => {
  // const [src, setSrc] = React.useState<string>();
  // useEffect(() => {
  //   getFileDownloadUrl(fileName, userId).then((src) => {
  //     setSrc(src);
  //   });
  // }, []);
  const showControls = !!controls && !showPlayOverlay;

  if (contentType === 'video/quicktime') {
    return <Video controls={showControls} src={file} {...props} />;
  }

  return (
    // <Wrapper>
    <Video controls={showControls} {...props}>
      <source src={file} type={contentType!} />
    </Video>
    //   {showPlayOverlay && <PlayButton onClick={(e) => {}}>{'>'}</PlayButton>}
    // </Wrapper>
  );
};

export default CaseVideo;
