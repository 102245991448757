import React from 'react';
import { Case, CaseStatus } from '../../../services/buildfire/rdb/cases';
import InfoSection, { LineItem } from './InfoSection';
import Typography from '../../common/Typography';
import { IonRouterLink } from '@ionic/react';

interface PaymentInfoProps {
  case: Case;
}

const Success = ({ children }) => (
  <Typography color="var(--ion-color-success) !important">
    {children}
  </Typography>
);

const getPaymentStatusDescription = (c: Case) => {
  if (c.invoiceAccount) {
    // invoiced case
    if (c.uid !== c.invoiceAccount.uid) {
      return (
        <span>
          Invoiced to{' '}
          <IonRouterLink
            routerLink={`/admin/users/${c.invoiceAccount.uid}`}
            style={{ textDecoration: 'underline' }}
          >
            {c.invoiceAccount.displayName}
          </IonRouterLink>
        </span>
      );
    }
    return `Invoiced`;
  }

  // otherwise immediate payment
  if (c.status === CaseStatus.SIGNED_OFF) {
    return 'Paid';
  }
  return 'Pending';
};

const PaymentInfo: React.FC<PaymentInfoProps> = ({ case: c }) => {
  return (
    <InfoSection title="Payment Info">
      <LineItem
        name="Payment status"
        description={getPaymentStatusDescription(c)}
      />
    </InfoSection>
  );
};

export default PaymentInfo;
