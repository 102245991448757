import React from 'react';
import {
  CompleteFn,
  ErrorFn,
  getAuth,
  NextOrObserver,
  onAuthStateChanged,
  User,
} from 'firebase/auth';
import { FirebaseApp } from 'firebase/app';

function useOnAuthStateChanged({
  callback,
  error,
  completed,
  app,
}: {
  callback: NextOrObserver<User>;
  error?: ErrorFn;
  completed?: CompleteFn;
  app?: FirebaseApp;
}) {
  React.useEffect(() => {
    if (!app) {
      return;
    }

    const auth = getAuth(app);
    const unsub = onAuthStateChanged(auth, callback, error, completed);

    return () => {
      unsub();
    };
  }, [app, callback, error, completed]);
}

export default useOnAuthStateChanged;
