import React from 'react';
import styled from 'styled-components';
import Icon from '../../mdc/Icon';
import MDCMenuItem, {
  MenuItemProps as MDCMenuItemProps,
} from '../../mdc/menu/MenuItem';

export interface MenuItemProps extends MDCMenuItemProps {
  icon?: string;
}

const StyledItem = styled(MDCMenuItem)`
  font-size: 12px;

  &.mdc-list-item {
    height: 48px !important;
  }

  .mdc-list-item__text {
    display: flex;
    align-items: center;
    height: 100%;

    .material-icons {
      font-family: 'Material Icons' !important;
      margin-right: 4px;
      opacity: 0.7;
      color: var(--mdc-theme-text-primary-on-light);
    }
  }
`;

const MenuItem: React.FC<MenuItemProps> = ({ icon, children, ...props }) => {
  return (
    <StyledItem {...props}>
      {icon && <Icon variant={icon} />}
      {children}
    </StyledItem>
  );
};

export default MenuItem;
