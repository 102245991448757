export enum VIEW_EVENTS {
  HOME = 'home',
  SUBMIT_CASE = 'submit_case',
  EDIT_CASE = 'edit_case',
  CASES = 'cases',
  CASE = 'case',
  ABOUT = 'about',
  FAQ = 'faq',
  PRICES = 'prices',
  EDUCATION = 'education',
  DELETE_ACCOUNT = 'delete',
}

export enum ACTION_EVENTS {}
//
