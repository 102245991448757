import React from 'react';
import styled from 'styled-components';
import Typography from '../../common/Typography';
import logo from '../../../images/v2/logo/Pp-Alt-Landscape-Full-Colour-RGB.svg';

interface NoCaseSelectedProps {}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Wrapper = styled.div`
  margin: auto;
  text-align: center;
`;

const Image = styled.img`
  max-width: 100%;
  width: 300px;
  margin-top: -100px;
`;

const NoCaseSelected: React.FC<NoCaseSelectedProps> = (props) => {
  return (
    <Container>
      <Wrapper>
        <Image src={logo} />
        <Typography as="h3" fontSize="24px" variant="primary" margin="16px 0">
          No case selected
        </Typography>
        <Typography as="p" fontSize="16px" variant="body">
          Select a case to get started
        </Typography>
      </Wrapper>
    </Container>
  );
};

export default NoCaseSelected;
