import React from 'react';
import styled from 'styled-components';
import { NavigationButtonProps } from '../../components/home/NavigationButton';
import { Link } from 'react-router-dom';
import { NoUnderlineMixin } from '../../style/mixins';
import submitIcon from '../../images/v2/home-icons/SVG/Pocket Pathologist-Icon_Submit-transparent.svg';

interface SubmitButtonProps extends Partial<NavigationButtonProps> {}

const Button = styled<any>(Link)`
  // background-color: white;
  // border-radius: 999px;
  display: flex;
  align-items: center;
  border: none;
  box-shadow: none;
  // padding: 8px 16px 8px 8px;
  // display: flex;
  // align-items: center;
  // ${NoUnderlineMixin}
  padding-left: 20px;
`;

const MainButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  padding: 24px 12px 24px 60px;
  border: 4px solid var(--accent-color4);
  box-shadow: rgba(99, 99, 99, 0.4) 4px 4px 8px 0px;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.6) 4px 4px 8px 0px;
  }
`;

const MainButtonSubmitText = styled.span`
  flex: 1;
  text-align: left;
  display: flex;
  align-items: flex-end;
  color: var(--accent-color2);
  font-size: 20px;
  font-size: max(min(4.5vw, 24px), 15px);
  letter-spacing: 2px;
`;

const Underline = styled.span`
  border-bottom: 3px solid var(--accent-color4);
`;

const Subtext = styled.span`
  color: var(--accent-color2);
  font-size: 14px;
  margin-top: 4px;
  // max-width: 230px;
`;

const Image = styled.div`
  position: absolute;
  left: 0;

  overflow: hidden;
  border: 4px solid var(--ion-color-secondary);
  flex-shrink: 0;
  width: 72px;
  height: 72px;

  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--accent-color2);

  margin-right: 16px;

  span.material-icons {
    font-family: 'Material Icons' !important;
    color: white;
    font-size: 52px;
  }
`;

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  return (
    <Button to="/cases/create" size="lg" {...props}>
      <Image>
        <img src={submitIcon} />
      </Image>
      <MainButtonContent>
        <MainButtonSubmitText>
          <Underline>SUBMIT YOUR CASE</Underline>
        </MainButtonSubmitText>
        <Subtext>Your pathologist is ready to help!</Subtext>
      </MainButtonContent>
    </Button>
  );
};

export default SubmitButton;
