import React from 'react';
import { StripeInvoice } from '../../api/cloudFunctions';
import { LineItem } from '../case/caseInfo/InfoSection';
import { formatInvoiceAmount, isInvoicePastDue } from '../../util/invoice';
import styled from 'styled-components';
import { IonRouterLink } from '@ionic/react';

interface BillSummaryProps {
  uid: string;
  invoices: StripeInvoice[];
}

const PastDueLineItem = styled(LineItem)`
  & div.LineItem_description {
    color: var(--ion-color-danger);
  }
`;

const InternalLink = styled(IonRouterLink)`
  font-size: 14px;
`;

const BillSummary: React.FC<BillSummaryProps> = ({ uid, invoices }) => {
  const currentMonthInvoice = invoices.find(
    (invoice) => invoice.status === 'pending'
  );
  const pastDueAmount = invoices
    .filter(isInvoicePastDue)
    .reduce((total, invoice) => {
      return total + invoice.amountDue;
    }, 0);

  const hasPastDueAmount = pastDueAmount > 0;
  const PastDueComponent = hasPastDueAmount ? PastDueLineItem : LineItem;
  return (
    <>
      <LineItem
        name="Current month's total"
        description={formatInvoiceAmount(currentMonthInvoice?.amountDue)}
      />
      <PastDueComponent
        name="Past due"
        description={formatInvoiceAmount(pastDueAmount)}
      />
      <InternalLink target="_blank" href={`/admin/users/${uid}/invoices`}>
        View all invoices
      </InternalLink>
    </>
  );
};

export default BillSummary;
