import React from 'react';
import { Platforms, getPlatforms } from '@ionic/core';
import { MOBILE_PLATFORMS } from '../../util/device';

interface PlatformProps {
  platforms?: Platforms | Platforms[];
  mode?: 'inclusive' | 'exclusive';
  render?: (platforms: Platforms[]) => React.ReactElement<any>;
}

type Platform = React.FC<PlatformProps> & {
  Desktop: React.FC<PlatformProps>;
  Mobile: React.FC<PlatformProps>;
  MobileApp: React.FC<PlatformProps>;
};

const PlatformComponent: React.FC<PlatformProps> = ({
  platforms = [],
  mode = 'inclusive',
  render,
  children,
}) => {
  const platformsArr = typeof platforms === 'string' ? [platforms] : platforms;
  const currentPlatforms = getPlatforms();
  if (render) {
    return render(currentPlatforms) || null;
  }

  const includesPlatform = currentPlatforms.some(
    (p) => platformsArr.indexOf(p) !== -1
  );
  const shouldRender =
    (includesPlatform && mode === 'inclusive') ||
    (!includesPlatform && mode === 'exclusive');
  return shouldRender ? <>{children}</> : null;
};

const Platform: Platform = PlatformComponent as any;
Platform.Desktop = (props) => (
  <Platform mode="exclusive" platforms={MOBILE_PLATFORMS} {...props} />
);
Platform.Mobile = (props) => (
  <Platform platforms={MOBILE_PLATFORMS} {...props} />
);
Platform.MobileApp = (props) => (
  <Platform platforms={['mobile', 'pwa']} {...props} />
);

export default Platform;
