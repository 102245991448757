import { getPlatforms, Platforms, isPlatform } from '@ionic/core';

export function iOS() {
  const { platform = null } = navigator;
  // return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  return !!platform && /iPad|iPhone|iPod/.test(platform);
}

export const MOBILE_PLATFORMS: Platforms[] = ['ios', 'ipad', 'android'];

export function isWeb() {
  return getDevice() === 'web';
}

export function isNotNativeApp() {
  const currentPlatforms = getPlatforms();
  const isDesktop = currentPlatforms.includes('desktop');
  const isMobileWeb = currentPlatforms.includes('mobileweb');
  return isDesktop || isMobileWeb;
}

export function isDesktop() {
  return !isMobile();
}

export function isMobile() {
  const currentPlatforms = getPlatforms();
  return currentPlatforms.some(
    (platform) => MOBILE_PLATFORMS.indexOf(platform) !== -1
  );
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);

export function isIosApp() {
  return isPlatform('ios') && !isPlatform('mobileweb');
}

export function getDevice() {
  if (isPlatform('ios')) {
    return 'ios';
  } else if (isPlatform('android')) {
    return 'android';
  }
  return 'web';
}
