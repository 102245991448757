import { CaseStatus } from '../services/buildfire/rdb/cases';

export const STATUS_TEXT = {
  [CaseStatus.OPEN]: 'In review',
  [CaseStatus.CLOSED]: 'Closed',
  [CaseStatus.SIGNED_OFF]: 'Signed off',
};

export const STATUS_TEXT_ADMIN = {
  [CaseStatus.OPEN]: 'Ready to review',
  [CaseStatus.CLOSED]: 'Closed by user',
  [CaseStatus.SIGNED_OFF]: 'Signed off',
};

export const getStatusText = (status: CaseStatus, isAdmin: boolean) =>
  isAdmin ? STATUS_TEXT_ADMIN[status] : STATUS_TEXT[status];
