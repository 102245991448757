import { IonIcon, IonItem } from '@ionic/react';
import React from 'react';
import { logOutOutline } from 'ionicons/icons';

interface LogoutItemProps {
  onClick: () => void;
}

const LogoutItem: React.FC<LogoutItemProps> = ({ onClick }) => {
  return (
    <IonItem onClick={onClick} button>
      <IonIcon icon={logOutOutline} slot="start" />
      Log Out
    </IonItem>
  );
};

export default LogoutItem;
