import React from 'react';
import classnames from 'classnames';
import { useRipple } from './hooks';
import styled from 'styled-components';

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: string;
}

const Button = styled.button`
  font-family: 'Material Icons' !important;
  color: #5f6368;
  &:hover {
    color: inherit;
  }
`;

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  className,
  ...props
}) => {
  const ref = useRipple<HTMLButtonElement>({
    unbounded: true,
  });
  return (
    <Button
      className={classnames('mdc-icon-button', 'material-icons', className)}
      ref={ref}
      {...props}
    >
      <div className="mdc-icon-button__ripple"></div>
      {icon}
    </Button>
  );
};

export default IconButton;
