import React from 'react';
import { VIEW_EVENTS } from '../../services/buildfire/analytics/events';

interface TrackViewProps {
  name: VIEW_EVENTS;
  metadata?: buildfire.analytics.AnalyticsMetadata;
}

const TrackView: React.FC<TrackViewProps> = ({ name, metadata, children }) => {
  React.useEffect(() => {
    // buildfire.analytics.trackView(name, metadata);
    // TODO analyitcs
  }, [name, metadata]);

  return React.Children.only(children) as any;
};

export default TrackView;
