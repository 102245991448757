import { Database, onValue, ref } from 'firebase/database';
import * as O from 'fp-ts/Option';

export interface Invitation {
  parentUid: string;
  parentName: string;
  email: string;
  id: string;
}

export const getInvitation = (database: Database, id: string) => {
  const tokenRef = ref(database, `invitations/${id}`);
  return new Promise<O.Option<Invitation>>((resolve, reject) => {
    onValue(
      tokenRef,
      (snap) => {
        if (!snap.exists()) {
          return resolve(O.none);
        }

        resolve(
          O.of({
            ...snap.val(),
            id: snap.key,
          })
        );
      },
      (e) => {
        reject(e);
      },
      { onlyOnce: true }
    );
  });
};
