import React from 'react';
import styled from 'styled-components';
import Typography from '../../components/common/Typography';

interface PriceIconProps extends React.HTMLAttributes<HTMLElement> {
  price: string;
  multi?: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  // width: 120px;
  // height: 120px;
  // background-image: TODO
`;

const PriceIcon: React.FC<PriceIconProps> = ({ price, multi, ...props }) => {
  return (
    <Container {...props}>
      <Typography fontSize="40px" variant="secondary">
        {price}
      </Typography>
    </Container>
  );
};

export default PriceIcon;
