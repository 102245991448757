import React from 'react';
import styled from 'styled-components';
import Gradient from './Gradient';

interface ImageOverlayProps extends React.HTMLAttributes<HTMLElement> {
  overlay?: 'gradient' | 'solid';
}

const SolidOverlay = styled.div`
  background-image: linear-gradient(0deg, #00000030 0%, #00000030 100%);
`;

const ImageOverlay: React.FC<ImageOverlayProps> = ({ overlay, ...props }) => {
  return overlay === 'gradient' ? (
    <Gradient {...props} />
  ) : (
    <SolidOverlay {...props} />
  );
};

export const ImageOverlayBackground = styled(ImageOverlay)`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 32px 0;
  position: absolute;
`;

export default ImageOverlay;
