import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { assign, MachineConfig, Sender } from 'xstate';
import { log } from 'xstate/lib/actions';
import submitCaseCheckoutStates, {
  CheckoutContext as SubmitCaseCheckoutContext,
  CheckoutEvent,
  uploadSites,
} from '../submitCase/checkout';
import * as RDBCases from '../../services/buildfire/rdb/cases';
import * as API from '../../api/cloudFunctions';
import { PaymentType } from '../submitCase/selectPaymentType';

type CheckoutContext = SubmitCaseCheckoutContext & {
  case: RDBCases.Case;
};

function updateCase(context: CheckoutContext) {
  return async (send: Sender<CheckoutEvent>) => {
    const {
      caseInfo,
      firebaseApp,
      case: c,
      selectedPaymentMethod,
      userId,
      setupIntent,
      paymentType,
      selectedInvoiceAccountUid,
    } = context;

    try {
      const functions = getFunctions(firebaseApp);
      const storage = getStorage(firebaseApp);

      const {
        sites,
        submitterFirstName,
        submitterLastName,
        patientFirstName,
        patientLastName,
        ...rest
      } = caseInfo!;

      const uploadedSites = await uploadSites({
        storage,
        sites,
        userId,
        send,
        submitMessage: 'Finalizing case update',
      });

      const invoicingEnabled = paymentType === PaymentType.INVOICE;
      const result = await API.updateCase(functions, {
        case: {
          ...rest,
          invoiceAccount: undefined, // need to cancel this out here since added on backend
          id: c.id,
          sites: uploadedSites,
          user: {
            email: c.user.email,
            displayName: c.user.displayName,
          },
          submitter: {
            first: submitterFirstName,
            last: submitterLastName,
          },
          patient: {
            first: patientFirstName,
            last: patientLastName,
          },
          uid: c.uid,
          billToParentAccount:
            invoicingEnabled && selectedInvoiceAccountUid !== userId,
        },
        user: {
          id: c.uid,
          name: c.user.displayName,
          email: c.user.email,
        },
        paymentMethodId: invoicingEnabled ? undefined : selectedPaymentMethod,
        setupIntentId: invoicingEnabled ? undefined : setupIntent.id,
        invoiceAccount: invoicingEnabled
          ? selectedInvoiceAccountUid
          : undefined,
      });
      return result;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };
}

const checkoutStates: MachineConfig<CheckoutContext, any, CheckoutEvent> = {
  ...(submitCaseCheckoutStates as any),
  states: {
    ...(submitCaseCheckoutStates.states as any),
    submitCase: {
      ...submitCaseCheckoutStates.states!.submitCase,
      invoke: {
        src: updateCase,
        onDone: {
          target: 'success',
          actions: assign({
            case: (context, ev) => ev.data.case,
          }),
        },
        onError: {
          target: 'idle',
          actions: [
            log((context, ev) => {
              console.error(ev);
            }),
            assign({
              error: (context, ev) => ev.data,
            }),
          ],
        },
      },
    },
  },
};

export default checkoutStates;
