import classNames from 'classnames';
import React from 'react';

export interface MenuItemProps extends React.HTMLAttributes<HTMLLIElement> {
  disabled?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({
  disabled,
  children,
  className,
  ...props
}) => {
  return (
    <li
      className={classNames(
        {
          'mdc-list-item': true,
          'mdc-list-item--disabled': !!disabled,
        },
        className
      )}
      role="menuitem"
      {...props}
    >
      <span className="mdc-list-item__text">{children}</span>
    </li>
  );
};

export default MenuItem;
