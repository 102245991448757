import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

interface ElevatedContainerProps extends React.HTMLAttributes<HTMLElement> {
  maxWidth?: string;
}

const Container = styled.div<{ $maxWidth?: string }>`
  padding: 24px 8px;
  border-radius: 12px;
  margin: 16px;
  background-color: var(--mdc-theme-surface);
  flex: 1;
  align-self: center;
  ${(props) => `max-width: ${props.$maxWidth};`}
`;

const ElevatedContainer: React.FC<ElevatedContainerProps> = ({
  className,
  children,
  maxWidth,
  ...props
}) => {
  return (
    <Container
      className={classNames('mdc-elevation--z3', className)}
      $maxWidth={maxWidth}
      {...props}
    >
      {children}
    </Container>
  );
};

export default ElevatedContainer;
