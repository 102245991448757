import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

interface RadioButtonProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
  checked: boolean;
  label?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  label,
  checked,
  className,
  onChange,
  disabled,
  ...props
}) => {
  return (
    <div className={classNames('mdc-form-field', className)} {...props}>
      <div className="mdc-radio">
        <input
          className="mdc-radio__native-control"
          type="radio"
          id={id}
          name="radios"
          checked={checked}
          onChange={onChange}
          aria-disabled={disabled}
          disabled={disabled}
        />
        <div className="mdc-radio__background">
          <div className="mdc-radio__outer-circle"></div>
          <div className="mdc-radio__inner-circle"></div>
        </div>
        <div className="mdc-radio__ripple"></div>
      </div>
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  );
};

export default RadioButton;
