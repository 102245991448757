import { IonInput } from '@ionic/react';
import classNames from 'classnames';
import { FirebaseError } from 'firebase/app';
import React from 'react';
import Typography from '../../common/Typography';
import NavButtons from './NavButtons';
import { AUTH_ERROR_MAP, isFirebaseError } from '../../../util/auth';

interface ConfirmProps {
  onBack: () => void;
  onNext: (args: { password: string }) => void;
  isSubmitting: boolean;
  error?: string | Error | FirebaseError;
}

const Confirm: React.FC<ConfirmProps> = ({
  isSubmitting,
  onBack,
  onNext,
  error,
}) => {
  const [password, setPassword] = React.useState('');
  const [touched, setTouched] = React.useState(false);
  return (
    <div>
      <Typography>Please enter your password to confirm</Typography>
      <IonInput
        style={{ marginTop: '24px' }}
        className={classNames({
          ['ion-invalid']: !!error,
          ['ion-touched']: touched || error,
        })}
        label="Password"
        labelPlacement="floating"
        type="password"
        value={password}
        onIonInput={(e) => {
          setPassword(e.detail.value!);
          setTouched(true);
        }}
        errorText={
          typeof error === 'object'
            ? isFirebaseError(error)
              ? AUTH_ERROR_MAP[error.code] || error.message
              : error.message
            : error || 'Error'
        }
      />

      <NavButtons
        nextText="Delete My Account"
        prevText="Go back"
        onNext={() => onNext({ password })}
        onBack={onBack}
        disabled={!password || isSubmitting}
      />
    </div>
  );
};

export default Confirm;
