import { FirebaseApp } from 'firebase/app';
import { getAuth, getRedirectResult, UserCredential } from 'firebase/auth';
import React from 'react';
import { useFirebase } from '../services/firebase';
import { LOADING_STATE } from '../util/loading';

function useRedirectResult(
  callback: (result: UserCredential | null) => Promise<void> | void
) {
  const [state, setState] = React.useState<{
    result?: UserCredential | null;
    loadingState: LOADING_STATE;
  }>({ loadingState: LOADING_STATE.LOADING });
  const { app } = useFirebase();

  React.useEffect(() => {
    if (!app) {
      return;
    }

    const auth = getAuth(app);
    getRedirectResult(auth)
      .then(async (result) => {
        await callback(result);

        setState({
          result,
          loadingState: LOADING_STATE.LOADED,
        });
      })
      .catch(() =>
        setState({
          loadingState: LOADING_STATE.ERROR,
        })
      );
  }, [app, callback]);

  return state;
}

export default useRedirectResult;
