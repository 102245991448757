import React from 'react';
import { IonInput } from '@ionic/react';
import { JSX } from '@ionic/core/components';
import styled from 'styled-components';

export type Address = {
  name: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  formatted: string;
  lat: number;
  lng: number;
};

export type GoogleAddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

interface AddressAutocompleteProps extends JSX.IonInput {
  onComplete: (address: Address) => void;
  onInvalid: () => void;
  className?: string;
}

const getAddressComponent = (
  type: string,
  addressComponents: GoogleAddressComponent[]
) => {
  return addressComponents.find((c) => c.types.includes(type));
};

const Input = styled(IonInput)`
  & ::placeholder {
    color: red;
  }
  --placeholder-color: black;
`;

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  onComplete,
  onInvalid,
  ...props
}) => {
  const ref = React.useRef<any>(null);

  React.useEffect(() => {
    async function setup() {
      if (!ref.current) {
        console.warn('AddressAutocomplete could not find input ref');
        return;
      }

      const input = await ref.current.getInputElement();
      if (!input) {
        setTimeout(setup, 1000);
        return;
      }

      const autocomplete = new (window as any).google.maps.places.Autocomplete(
        input
      );
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const address = place.address_components as GoogleAddressComponent[];

        if (!place.geometry) {
          return onInvalid();
        }

        const zip = getAddressComponent('postal_code', address);
        const city = getAddressComponent(
          'administrative_area_level_2',
          address
        );
        const state = getAddressComponent(
          'administrative_area_level_1',
          address
        );
        onComplete({
          name: place.name,
          streetAddress: `${address[0].long_name} ${address[1].long_name}`,
          city: city?.long_name!,
          state: state?.long_name!,
          zipCode: zip?.short_name!,
          formatted: place.formatted_address,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      });
    }

    setup();
  }, []);
  return <IonInput ref={ref} type="text" required {...props} />;
};

export default AddressAutocomplete;
