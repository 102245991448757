import React from 'react';
import List from '../../../../mdc/list/List';
import { PaymentMethod, StripeError } from '@stripe/stripe-js';
import PaymentMethodListItem from './PaymentMethodListItem';
import Typography from '../../../common/Typography';
import { IonSpinner } from '@ionic/react';
import styled from 'styled-components';

export interface PaymentMethodListProps {
  paymentMethods: PaymentMethod[];
  onRemovePaymentMethod: (id: string) => Promise<void>;
  onSelectPaymentMethod: (id: string, e: any) => void;
  disabled: boolean;
  isLoading: boolean;
  selectable?: boolean;
  selectedPaymentMethod?: string;
  error?: Error | string | StripeError;
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 48px;
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;
const Icon = styled.i`
  font-family: 'Material Icons' !important;
  color: var(--ion-color-danger);
`;

const PaymentMethodList: React.FC<PaymentMethodListProps> = ({
  paymentMethods = [],
  onSelectPaymentMethod,
  onRemovePaymentMethod,
  disabled,
  selectedPaymentMethod,
  selectable,
  isLoading,
  error,
}) => {
  return (
    <List interactive={false}>
      {error && !isLoading && (
        <ErrorContainer>
          <Icon className="material-icons">error</Icon>
          <Typography fontSize="16px" margin="16px 0 16px 16px">
            There was a problem loading your payment methods. Please try again.{' '}
          </Typography>
        </ErrorContainer>
      )}

      {paymentMethods.length === 0 && !isLoading && !error && (
        <Typography fontSize="16px" margin="24px 0" textAlign="center">
          No saved cards
        </Typography>
      )}
      {isLoading && (
        <LoadingContainer>
          <IonSpinner color="primary" />
        </LoadingContainer>
      )}
      {!isLoading &&
        paymentMethods.map((method) => (
          <PaymentMethodListItem
            key={method.id}
            paymentMethod={method}
            onRemove={onRemovePaymentMethod}
            onSelect={onSelectPaymentMethod}
            disabled={disabled}
            isSelected={selectedPaymentMethod === method.id}
            selectable={selectable}
          />
        ))}
    </List>
  );
};

export default PaymentMethodList;
