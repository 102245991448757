import React from 'react';
import styled from 'styled-components';

interface GradientProps {
  direction?: 'vertical';
}

const Styled = styled.div`
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 35.29%, 0) 0%,
    hsla(0, 0%, 34.53%, 0.034375) 16.36%,
    hsla(0, 0%, 32.42%, 0.125) 33.34%,
    hsla(0, 0%, 29.18%, 0.253125) 50.1%,
    hsla(0, 0%, 24.96%, 0.4) 65.75%,
    hsla(0, 0%, 19.85%, 0.546875) 79.43%,
    hsla(0, 0%, 13.95%, 0.675) 90.28%,
    hsla(0, 0%, 7.32%, 0.765625) 97.43%,
    hsla(0, 0%, 0%, 0.8) 100%
  );

  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
`;

const Gradient: React.FC<GradientProps> = (props) => {
  return <Styled {...props} />;
};

export const GradientBackground = styled(Gradient)`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
`;

export default Gradient;
