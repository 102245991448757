import React from 'react';
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonMenu,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { getMode } from '@ionic/core';
import {
  personCircleOutline,
  homeOutline,
  addOutline,
  listOutline,
  cardOutline,
  notificationsOutline,
  people,
  pricetagsOutline,
} from 'ionicons/icons';
import styled from 'styled-components';
import { useFirebase } from '../../services/firebase';
import LogoutItem from './navmenu/LogoutItem';
import LoginItem from './navmenu/LoginItem';
import UserProfileBar from './navmenu/UserProfileBar';
import { useUserUpdated } from '../../services/firebase/FirebaseContext';
import VersionInfo from './navmenu/VersionInfo';

interface NavMenuProps {}

const Title = styled<{ $isIOS: boolean } & any>(IonTitle as any)`
  ${(props) =>
    props.$isIOS &&
    `
      padding-left: 48px;
      padding-right: 48px;
    `}
`;

const FooterContainer = styled.div`
  padding: 4px 8px;
`;

const NavMenu: React.FC<NavMenuProps> = (props) => {
  const { currentUser, signOut, isAdmin } = useFirebase();
  const isIOS = getMode() === 'ios';
  useUserUpdated();
  const ref = React.useRef<HTMLIonMenuElement>(null);

  const [present] = useIonToast();

  return (
    <IonMenu side="end" contentId="main" ref={ref}>
      <IonHeader>
        <IonToolbar>
          {currentUser ? (
            <UserProfileBar user={currentUser} isAdmin={isAdmin} />
          ) : (
            <Title $isIOS={isIOS}>Pocket Pathologist</Title>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem routerLink="/">
            <IonIcon icon={homeOutline} slot="start" />
            Home
          </IonItem>
          {!isAdmin && (
            <IonItem routerLink="/cases/create">
              <IonIcon icon={addOutline} slot="start" />
              Submit Case
            </IonItem>
          )}
          <IonItem routerLink="/cases">
            <IonIcon icon={listOutline} slot="start" />
            {isAdmin ? 'Cases' : 'My Cases'}
          </IonItem>
          <IonItem href="/account">
            <IonIcon icon={personCircleOutline} slot="start" />
            Account
          </IonItem>
          {currentUser && isAdmin && (
            <IonItem href="/admin/alerts">
              <IonIcon icon={notificationsOutline} slot="start" />
              In-app Alerts
            </IonItem>
          )}
          {currentUser && !isAdmin && (
            <IonItem href="/payment-methods">
              <IonIcon icon={cardOutline} slot="start" />
              Payment Methods
            </IonItem>
          )}
          {currentUser && (
            <IonItem
              href={
                isAdmin
                  ? '/admin/invoices'
                  : `/users/${currentUser.uid}/invoices`
              }
            >
              <IonIcon icon={pricetagsOutline} slot="start" />
              {isAdmin ? 'Open Invoices' : 'Invoices'}
            </IonItem>
          )}
          {currentUser && (
            <IonItem href="/users">
              <IonIcon icon={people} slot="start" />
              Users
            </IonItem>
          )}
          {currentUser ? (
            <LogoutItem
              onClick={async () => {
                await signOut();
                ref.current?.close();

                (window as any).__HISTORY__?.push('/');
                present('Logged out successfully', 4000);
              }}
            />
          ) : (
            <LoginItem />
          )}
        </IonList>
      </IonContent>
      <IonFooter>
        <FooterContainer>
          <VersionInfo />
        </FooterContainer>
      </IonFooter>
    </IonMenu>
  );
};

export default NavMenu;
