import React from 'react';
import { ListItemProps } from '../../../mdc/list/ListItem';
import { DialogActionButton } from '../../common/Dialog';
import IconButton from '../../../mdc/IconButton';
import { PreUploadSite } from '../CaseForm';
import { Case, Site } from '../../../services/buildfire/rdb/cases';
import Typography from '../../common/Typography';
import styled from 'styled-components';
import CaseAttachment, {
  CaseAttachmentProps,
} from '../siteAttachment/CaseAttachment';
import SiteDialog from './SiteDialog';
import { useDialog } from '../../../context/DialogContext';
import { IonItem, IonLabel, IonNote } from '@ionic/react';
import { getMode } from '@ionic/core';

interface SiteListItemProps extends Partial<ListItemProps> {
  case?: Case;
  site: Site | PreUploadSite;
  userId: string;
  onEdit?: (
    site: Site,
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onDelete?: (
    site: Site,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  siteIndex: number;
  showButtons?: boolean;
  onClickBehavior?: 'edit' | 'show';
}

const PreviewImage = styled<
  React.FC<
    CaseAttachmentProps & {
      $isIOS: boolean;
    }
  >
>(CaseAttachment)`
  height: 64px;
  width: 64px;
  object-fit: cover;
  ${(props) => props.$isIOS && 'vertical-align: middle;'}
`;

const Item = styled(IonItem)`
  --background: transparent;
`;

export const isPreUpload = (site: any): site is PreUploadSite => !!site.files;

function getSitePreviewUrl(site: Site | PreUploadSite) {
  let url: string | undefined = '';
  if (isPreUpload(site)) {
    url = site.files?.[0] ? URL.createObjectURL(site.files?.[0]) : undefined;
  } else {
    url = site.images?.[0];
  }
  return url;
}

const SiteListItem: React.FC<SiteListItemProps> = ({
  case: c,
  site,
  userId,
  onEdit,
  onDelete,
  showButtons,
  siteIndex,
  onClickBehavior = 'show',
  ...props
}) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const { openDialog } = useDialog();
  const imageToPreview = getSitePreviewUrl(site);
  const imageUrls = isPreUpload(site)
    ? site.files.map(URL.createObjectURL)
    : site.images;

  const ios = getMode() === 'ios';
  const rightHandContent = showButtons ? (
    <>
      <IconButton
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          onEdit?.(site as any, e);
        }}
        icon="edit"
      />
      <IconButton
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          openDialog(
            <Typography variant="body" fontSize="14px">
              Delete this site?
            </Typography>,
            {
              onClose: (action) =>
                action === 'accept' ? onDelete?.(site as any, e) : null,
              actions: (
                <>
                  <DialogActionButton action="close" emphasis="low">
                    Nevermind
                  </DialogActionButton>
                  <DialogActionButton action="accept">
                    Yes, delete
                  </DialogActionButton>
                </>
              ),
            }
          );
        }}
        icon="delete"
      />
    </>
  ) : imageToPreview ? (
    <PreviewImage
      $isIOS={ios}
      userId={userId}
      fileId={imageToPreview}
      videoProps={{
        controls: false,
      }}
      imageProps={{
        placeholderProps: {
          style: {
            width: '64px',
            height: '64px',
          },
        },
      }}
    />
  ) : null;

  return (
    <>
      <Item
        button
        onClick={() => {
          if (onClickBehavior === 'show') {
            setShowDialog(true);
          } else {
            onEdit?.(site as any);
          }
        }}
      >
        <IonLabel>
          <Typography as="h2" fontSize="16px" lineHeight="1.2em">
            {site.location}
          </Typography>
          <Typography as="p">{site.description}</Typography>
        </IonLabel>
        <IonNote slot="end">{rightHandContent}</IonNote>
      </Item>
      <SiteDialog
        case={c}
        site={site}
        siteIndex={siteIndex}
        userId={userId}
        isOpen={showDialog}
        onDidDismiss={() => setShowDialog(false)}
        showActionMenu={!isPreUpload(site)}
      />
    </>
  );
};

export default SiteListItem;
