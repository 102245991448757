import React from 'react';

const PLUGIN_ID_REGEXP = /\/plugins\/([0-9a-z-]*)\/widget/i;

export function calculateBasename() {
  if (process.env.NODE_ENV === 'production') {
    const pluginId = location.href.match(PLUGIN_ID_REGEXP)?.[1];
    if (pluginId) {
      console.log(`Plugin id: ${pluginId}`);
      return `/${pluginId}/widget`;
    }
  }
  return '/widget';
}

function useBasename() {
  // const basename = React.useRef(calculateBasename());
  const basename = React.useRef(undefined);

  return { basename: basename.current };
}

export default useBasename;
